import React from 'react';
import { Route, Switch } from 'react-router';

import { LoginContainer } from '../containers/auth/LoginContainer';
import { DirectAccess } from '../containers/auth/DirectAccess';

export const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/auth/login" component={LoginContainer} />
      
      <Route
        exact
        path="/auth/direct-access"
        component={DirectAccess}
      />

      <Route
        exact
        path="/auth/access"
        component={DirectAccess}
      />
    </Switch>
  );
};
