import React from "react";
import {Route, Switch} from "react-router";

import {DashboardContainer} from "../containers/dashboard/DashboardContainer";
import {LayoutAdmin} from "../layouts/admin/LayoutAdmin";
import {AttendanceDriverContainer} from "../containers/attendance-driver/AttendanceDriverContainer";
import RoleAccess from "../containers/roleAccess/RoleAccess";

export const DashboardRouter = () => {
  return (
    <Switch>
      <Route exact path='/role' render={(props) => <RoleAccess {...props} />} />
      <Route
        exact
        path='/admin/dashboard'
        render={(props) => (
          <LayoutAdmin>
            <DashboardContainer {...props} />
          </LayoutAdmin>
        )}
      />
      {/* <Route
        exact
        path='/admin/attendance-driver'
        render={(props) => (
          <LayoutAdmin>
            <AttendanceDriverContainer {...props} />
          </LayoutAdmin>
        )}
      /> */}
    </Switch>
  );
};
