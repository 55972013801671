import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Flex } from 'reflexbox';
import { IAuditTrackingCodFilterContainerValue } from './IAuditTrackingCodFilterContainerValue';
import { Panel } from 'primereact/panel';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';
import BranchDropdown from "../attendance-driver/dropdown/BranchDropdown";
import SiteDropdown from "../attendance-driver/dropdown/SiteDropdown";
import BranchCodeDropdown from "../attendance-driver/dropdown/BranchCodeDropdown";
import SiteCodeDropdown from "../attendance-driver/dropdown/SiteCodeDropdown";
import SiteCodeDropdownCopy from "../attendance-driver/dropdown/SiteCodeDropdownCopy";
import moment from "moment";
import AppDateRangePicker from '../../utils/AppDateRangePicker';


export class AuditTrackingSummaryFilterContainer  extends React.PureComponent<any, any> {
  initialFormValues: IAuditTrackingCodFilterContainerValue = {
    startDate: moment(new Date()).startOf("month").toDate(),
    endDate: moment(new Date()).toDate(),
    periodeDate: [new Date(), new Date()],
    awbNumber: '',
    cabang: '',
    siteCode:'',
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      isRenderawbNumber:false,
      isRenderBranch: false,
      dataLocal: '',
      periode: '',
      city: '',
      showDateToleransiFirst: false,
      showDateToleransi2: false,
      showDateToleransi3: false,
      showDateToleransi4: false,
      showDateToleransi5: false,
      showDateToleransi6: false,
      showDateToleransiLast: false,
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  // componentDidMount() { 
  //   // eslint-disable-next-line no-unused-vars
  //   let dataLocal = localStorage.getItem('userData');
  //   this.setState({
  //     dataLocal:dataLocal,
  //   })
    
  // }

  onChangeawbNumber = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("awbNumber", e);
      await this.setState({
        isRenderawbNumber: false,
        isRenderBranch:false,
      });
    } else {
        formikProps.setFieldValue("awbNumber", "");
      formikProps.setFieldValue("branchCode", "");
        await this.setState({
          isRenderawbNumber: true,
          isRenderBranch:true,
          branchCode:'',
        });
    }
  };

  

  render() {
    if (localStorage.getItem('userData')) {
      var userData = JSON.parse(localStorage.userData)
      // const dataLocal = JSON.parse(localStorage.getItem('userData')); 
    }
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <div className="row">
              <Flex wrap w={1}>
                <Flex column style={{ marginTop:"5px",width: "375px"}}>
                  <div className="form-group">
                    <label className="col-md-4" style={{ marginTop:"2%", paddingLeft:"10%"}}>Tanggal</label>
                    <div className="col-md-8">
                    <Flex column>
                            {/* <Calendar
                              name='startDate'
                              readOnlyInput={true}
                              dateFormat='dd-mm-yy'
                              showWeek={false}
                              // maxDate={formikProps.values.endDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange("startDate")}
                        value={formikProps.values.startDate}
                        disabledDays={[0,7]} 
                            />
                            <div className='mt-2 mb-2 text-center'>s/d</div>
                            <Calendar
                              name='endDate'
                              readOnlyInput={true}
                              dateFormat='dd-mm-yy'
                              showWeek={false}
                              // minDate={formikProps.values.startDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange("endDate")}
                        value={formikProps.values.endDate}
                        disabledDays={[0,7]} 
                        /> */}
                        <AppDateRangePicker
                          name="periodeDate"
                          onChange={formikProps.handleChange('periodeDate')}
                          value={formikProps.values.periodeDate}
                          // className={style.sizingPicker}
                          maxSelection={[7, 'd']}
                          showButtonBar={true}
                          clearButtonStyleClass="p-button-danger"
                        />
                      </Flex>
                    </div>
                  </div>    
                </Flex>
                
                <Flex style={{width: "350px"}} column >
                    <div className="form-group">
                      <label className="col-md-4"style={{ marginTop:"3%"}} >cabang</label>
                      <div className="col-md-8" style={{ marginTop: "2%" }}>
                        <InputText
                          name="cabang"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.cabang}
                        />
                      </div>
                    </div>
                </Flex>
                  <Flex column>
                    <div className="form-group">
                      <label className="col-md-4" style={{ marginTop: "3%" }}>No Resi</label>
                      <div className="col-md-8" style={{ marginTop: "2%" }}>
                        <InputText
                          name="awbNumber"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.awbNumber}
                        />
                      </div>
                    </div>
                  </Flex>
              </Flex>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
