import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Flex } from 'reflexbox';
import { IDeliveryCapacitySummaryFilterContainerValue } from './IDeliveryCapacitySummaryFilterContainerValue';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { LAYANAN_OPTIONS } from "../../constants";

export class DeliveryCapacitySummaryFilterContainer extends React.Component<any, any> {
  initialFormValues: IDeliveryCapacitySummaryFilterContainerValue = {
    date: new Date(),
    endDate: new Date(),
    isLastMile: false,
    isHub: false,
    isPudo: false,
    isLineHaul: false,
    otherFunctionType: false,
    city: '',
    province: '',
    trackingSite: '',
    cod: '',
    layanan: ''
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionsCod = [
    {
      label: 'ALL',
      value: '',
    },
    {
      label: 'COD',
      value: 'Y',
    },
    {
      label: 'NON COD',
      value: 'N',
    },
  ];

  optionSiteType = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Branch',
      value: 'BRANCH',
    },
    {
      label: 'Drop Point',
      value: 'DROPPOINT',
    },
    {
      label: 'Transit',
      value: 'TRANSIT',
    },
    {
      label: 'Agent',
      value: 'AGENT',
    },
    {
      label: 'Partner',
      value: 'PARTNER',
    },
    {
      label: 'Hub',
      value: 'HUB',
    },
    {
      label: 'Line Haul',
      value: 'LINEHAUL',
    },
    {
      label: 'HQ',
      value: 'HQ',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <div className="row">
              <Flex wrap w={1}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-4">Tanggal</label>
                    <div className="col-md-8">
                      <Calendar
                        name="date"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.date}
                      />
                      <div className="mt-2 mb-2 text-center">s/d</div>
                      <Calendar
                        name="endDate"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.endDate}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">Kota</label>
                    <div className="col-md-8">
                      <InputText
                        name="city"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.city}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">Province</label>
                    <div className="col-md-8">
                      <InputText
                        name="province"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.province}
                      />
                    </div>
                  </div>
                </Flex>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-4">Layanan</label>
                    <div className="col-md-8">
                      <Dropdown
                        options={LAYANAN_OPTIONS}
                        onChange={formikProps.handleChange('layanan')}
                        value={formikProps.values.layanan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">Gerai</label>
                    <div className="col-md-8">
                      <InputText
                        name="trackingSite"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.trackingSite}
                      />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label className="col-md-4">Tipe Gerai</label>
                    <div className="col-md-8">
                      <Dropdown
                        options={this.optionSiteType}
                        onChange={formikProps.handleChange('siteType')}
                        value={formikProps.values.siteType}
                      />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label className="col-md-4">COD</label>
                    <div className="col-md-8">
                      <Dropdown
                        options={this.optionsCod}
                        onChange={formikProps.handleChange('cod')}
                        value={formikProps.values.cod}
                      />
                    </div>
                  </div>
                </Flex>
              </Flex>
              <div className="row">
                <div className="p-col-12" style={{ padding: '1em' }}>
                  <div className="form-group">
                    <label className="col-md-6">Tipe Gerai :</label>
                  </div>
                </div>
                <div className="p-col-12" style={{ padding: '.5em' }}>
                  <div className="col-md-2 pb-3">
                    <label htmlFor="cb1" className="p-checkbox-label">Last Mile</label>
                    <Checkbox
                      className="pl-5"
                      inputId="cb1"
                      onChange={formikProps.handleChange('isLastMile')}
                      value={formikProps.values.isLastMile}
                      checked={formikProps.values.isLastMile}>
                    </Checkbox>
                  </div>
                  <div className="col-md-2 pb-3">
                    <label htmlFor="cb2" className="p-checkbox-label">Hub</label>
                    <Checkbox
                      className="pl-5"
                      inputId="cb2"
                      onChange={formikProps.handleChange('isHub')}
                      value={formikProps.values.isHub}
                      checked={formikProps.values.isHub}>
                    </Checkbox>
                  </div>
                  <div className="col-md-2 pb-3">
                    <label htmlFor="cb3" className="p-checkbox-label">PUDO</label>
                    <Checkbox
                      className="pl-5"
                      inputId="cb3"
                      onChange={formikProps.handleChange('isPudo')}
                      value={formikProps.values.isPudo}
                      checked={formikProps.values.isPudo}>
                    </Checkbox>
                  </div>
                  <div className="col-md-2 pb-3">
                    <label htmlFor="cb4" className="p-checkbox-label">Line Haul</label>
                    <Checkbox
                      className="pl-5"
                      inputId="cb4"
                      onChange={formikProps.handleChange('isLineHaul')}
                      value={formikProps.values.isLineHaul}
                      checked={formikProps.values.isLineHaul}>
                    </Checkbox>
                  </div>
                  <div className="col-md-2 pb-3">
                    <label htmlFor="cb5" className="p-checkbox-label">Other</label>
                    <Checkbox
                      className="pl-5"
                      inputId="cb5"
                      onChange={formikProps.handleChange('otherFunctionType')}
                      value={formikProps.values.otherFunctionType}
                      checked={formikProps.values.otherFunctionType}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
