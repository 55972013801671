import { observer } from 'mobx-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'reflexbox';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { DeliveryPerformanceByDestinationFilterContainer } from './DeliveryPerformanceByDestinationFilterContainer';
import _ from 'lodash';
import moment from 'moment';
import { IDeliveryPerformanceByDestinationFilterContainerValue } from './IDeliveryPerformanceByDestinationFilterContainerValue';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { _isComputed } from 'mobx/lib/internal';
import { Growl } from 'primereact/growl';

@observer
export class DeliveryPerformanceByDestinationCapacityContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  tableExportData;
  formFilters: IDeliveryPerformanceByDestinationFilterContainerValue;
  dataSource = new DataSource<any>();
  refFilter: RefObject<DeliveryPerformanceByDestinationFilterContainer> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      expandedRows: null,
      footerTotal: {}
    }

    this.exportCsv = this.exportCsv.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
  }

  loadData() {
    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.date = moment(this.formFilters.date).format('YYYY-MM-DD');
    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('pod/delivery-performance-by-destination', this.formFilters)
      .subscribe(
        response => {
          this.processData(response);
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        },
      );
  }

  processData(data) {
    this.data = data;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);

    this.calculateTotals();
  }

  calculateTotals() {
    const footerTotal = {
      totalManifest: _.sumBy(this.data, function (o: any) { return o.totalManifest; }),
      totalManifestWeight: _.sumBy(this.data, function (o: any) { return o.totalManifestWeight; }),
      totalDelivered: _.sumBy(this.data, function (o: any) { return o.totalDelivered; }),
      totalDeliveredWeight: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight; }),
      totalDelivered0: _.sumBy(this.data, function (o: any) { return o.totalDelivered0; }),
      totalDeliveredWeight0: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight0; }),
      totalDelivered1: _.sumBy(this.data, function (o: any) { return o.totalDelivered1; }),
      totalDeliveredWeight1: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight1; }),
      totalDelivered2: _.sumBy(this.data, function (o: any) { return o.totalDelivered2; }),
      totalDeliveredWeight2: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight2; }),
      totalDelivered3: _.sumBy(this.data, function (o: any) { return o.totalDelivered3; }),
      totalDeliveredWeight3: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight3; }),
      totalDelivered4: _.sumBy(this.data, function (o: any) { return o.totalDelivered4; }),
      totalDeliveredWeight4: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight4; }),
      totalDelivered5: _.sumBy(this.data, function (o: any) { return o.totalDelivered5; }),
      totalDeliveredWeight5: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight5; }),
      totalDelivered6: _.sumBy(this.data, function (o: any) { return o.totalDelivered6; }),
      totalDeliveredWeight6: _.sumBy(this.data, function (o: any) { return o.totalDeliveredWeight6; }),
      totalInProcess: _.sumBy(this.data, function (o: any) { return o.totalInProcess; }),
      totalControllableProblem: _.sumBy(this.data, function (o: any) { return o.totalControllableProblem; }),
      totalUncontrollableProblem: _.sumBy(this.data, function (o: any) { return o.totalUncontrollableProblem; }),
      totalUndelivered: _.sumBy(this.data, function (o: any) { return o.totalUndelivered; }),
      totalOverSlaInternal: _.sumBy(this.data, function (o: any) { return o.totalOverSlaInternal; }),
      totalOverSlaExternal: _.sumBy(this.data, function (o: any) { return o.totalOverSlaExternal; }),
    }

    this.setState({ footerTotal: footerTotal });
  }

  async exportCsv() {
    await this.tableExportData.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  columnPercentBody = (rowData, column: ColumnProps) => {
    return (
      <div>{rowData[column.field!]}%</div>
    );
  }

  render() {
    let headerGroup = <ColumnGroup>
      <Row>
        <Column header="Province"
          field="province"
          rowSpan={2}
          filter={true}
          filterMatchMode="contains"
          sortable={true} />
        <Column header="Kota"
          field="city"
          rowSpan={2}
          filter={true}
          filterMatchMode="contains"
          sortable={true} />
        <Column header="Kecamatan"
          field="subdistrict"
          rowSpan={2}
          filter={true}
          filterMatchMode="contains"
          sortable={true} />
        <Column header="Manifest" colSpan={3} />
        <Column header="Delivered" colSpan={4} />
        <Column header="DLV+0" colSpan={4} />
        <Column header="DLV+1" colSpan={4} />
        <Column header="DLV+2" colSpan={4} />
        <Column header="DLV+3" colSpan={4} />
        <Column header="DLV+4" colSpan={4} />
        <Column header="DLV+5" colSpan={4} />
        <Column header="DLV++" colSpan={4} />
        <Column header="In Process" colSpan={2} />
        <Column header="Problem" colSpan={2} />
        <Column header="Undelivered" colSpan={2} />
        <Column header="Over SLA (Internal)" colSpan={2} />
        <Column header="Over SLA (External)" colSpan={2} />
      </Row>
      <Row>
        <Column header="Jumlah" field="totalManifest" sortable={true} />
        <Column header="Berat" field="totalManifestWeight" sortable={true} />
        <Column header="Berat (%)" field="totalManifestWeightPerc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeightPerc" sortable={true} />
        <Column header="vs Manifest (%)" field="totalDeliveredPerc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered0" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight0" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight0Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered0Perc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered1" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight1" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight1Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered1Perc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered2" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight2" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight2Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered2Perc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered3" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight3" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight3Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered3Perc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered4" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight4" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight4Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered4Perc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered5" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight5" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight5Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered5Perc" sortable={true} />

        <Column header="Jumlah" field="totalDelivered6" sortable={true} />
        <Column header="Berat" field="totalDeliveredWeight6" sortable={true} />
        <Column header="Berat (%)" field="totalDeliveredWeight6Perc" sortable={true} />
        <Column header="DLV (%)" field="totalDelivered6Perc" sortable={true} />

        <Column header="Jumlah" field="totalInProcess" sortable={true} />
        <Column header="vs Manifest (%)" field="totalInProcessPerc" sortable={true} />

        <Column header="Controllable" field="totalControllableProblem" sortable={true} />
        <Column header="Uncontrollable" field="totalUncontrollableProblem" sortable={true} />

        <Column header="Jumlah" field="totalUndelivered" sortable={true} />
        <Column header="vs Manifest (%)" field="totalUndeliveredPerc" sortable={true} />

        <Column header="Jumlah" field="totalOverSlaInternal" sortable={true} />
        <Column header="vs Manifest (%)" field="totalOverSlaInternalPerc" sortable={true} />

        <Column header="Jumlah" field="totalOverSlaExternal" sortable={true} />
        <Column header="vs Manifest (%)" field="totalOverSlaExternalPerc" sortable={true} />
      </Row>
    </ColumnGroup>;

    let footerGroup = <ColumnGroup>
      <Row>
        <Column footer={"Totals:" + (this.dataSource.total).toLocaleString()} colSpan={3} />
        <Column footer={this.state.footerTotal.totalManifest ? this.state.footerTotal.totalManifest.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalManifestWeight ? this.state.footerTotal.totalManifestWeight.toLocaleString() : ""} />
        <Column footer="" />

        <Column footer={this.state.footerTotal.totalDelivered ? this.state.footerTotal.totalDelivered.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight ? this.state.footerTotal.totalDeliveredWeight.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered0 ? this.state.footerTotal.totalDelivered0.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight0 ? this.state.footerTotal.totalDeliveredWeight0.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered1 ? this.state.footerTotal.totalDelivered1.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight1 ? this.state.footerTotal.totalDeliveredWeight1.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered2 ? this.state.footerTotal.totalDelivered2.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight2 ? this.state.footerTotal.totalDeliveredWeight2.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered3 ? this.state.footerTotal.totalDelivered3.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight3 ? this.state.footerTotal.totalDelivered3.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered4 ? this.state.footerTotal.totalDelivered4.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight4 ? this.state.footerTotal.totalDeliveredWeight4.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered5 ? this.state.footerTotal.totalDelivered5.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight5 ? this.state.footerTotal.totalDeliveredWeight5.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalDelivered6 ? this.state.footerTotal.totalDelivered6.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalDeliveredWeight6 ? this.state.footerTotal.totalDeliveredWeight6.toLocaleString() : ""} />
        <Column footer="" colSpan={2} />

        <Column footer={this.state.footerTotal.totalInProcess ? this.state.footerTotal.totalInProcess.toLocaleString() : ""} />
        <Column footer="" />

        <Column footer={this.state.footerTotal.totalControllableProblem ? this.state.footerTotal.totalControllableProblem.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalUncontrollableProblem ? this.state.footerTotal.totalUncontrollableProblem.toLocaleString() : ""} />

        <Column footer={this.state.footerTotal.totalUndelivered ? this.state.footerTotal.totalUndelivered.toLocaleString() : ""} />
        <Column footer="" />

        <Column footer={this.state.footerTotal.totalOverSlaInternal ? this.state.footerTotal.totalOverSlaInternal.toLocaleString() : ""} />
        <Column footer="" />

        <Column footer={this.state.footerTotal.totalOverSlaExternal ? this.state.footerTotal.totalOverSlaExternal.toLocaleString() : ""} />
        <Column footer="" />
      </Row>
    </ColumnGroup>;

    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showExportDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showExportDialog: false })}>
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">

          <Flex className="w-full" column>
            <DeliveryPerformanceByDestinationFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.exportCsv}></Button>
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="365px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => { this.tableExportData = el; }}
                headerColumnGroup={headerGroup}
                footerColumnGroup={footerGroup}>
                {/* onRowToggle={(e) => this.onRowToggle(e)}
                // onRowToggle={(e) => this.setState({expandedRows:e.data})}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"> */}
                <Column
                  field="province"
                  header="Province"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px' }}
                />
                <Column
                  field="city"
                  header="Kota"
                  style={{ width: '200px' }}
                />
                <Column
                  field="subdistrict"
                  header="Kecamatan"
                  style={{ width: '200px' }}
                />
                <Column header="Manifest Jumlah" field="totalManifest" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="Manifest Berat" field="totalManifestWeight" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="Manifest Berat (%)" field="totalManifestWeightPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV Jumlah" field="totalDelivered" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV Berat" field="totalDeliveredWeight" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV Berat (%)" field="totalDeliveredWeightPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV vs Manifest (%)" field="totalDeliveredPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV+0 Jumlah" field="totalDelivered0" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+0 Berat" field="totalDeliveredWeight0" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+0 Berat (%)" field="totalDeliveredWeight0Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+0 (%)" field="totalDelivered0Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV+1 Jumlah" field="totalDelivered1" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+1 Berat" field="totalDeliveredWeight1" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+1 Berat (%)" field="totalDeliveredWeight1Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+1 (%)" field="totalDelivered1Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV+2 Jumlah" field="totalDelivered2" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+2 Berat" field="totalDeliveredWeight2" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+2 Berat (%)" field="totalDeliveredWeight2Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+2 (%)" field="totalDelivered2Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV+3 Jumlah" field="totalDelivered3" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+3 Berat" field="totalDeliveredWeight3" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+3 Berat (%)" field="totalDeliveredWeight3Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+3 (%)" field="totalDelivered3Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV+4 Jumlah" field="totalDelivered4" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+4 Berat" field="totalDeliveredWeight4" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+4 Berat (%)" field="totalDeliveredWeight4Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+4 (%)" field="totalDelivered4Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="Jumlah" field="totalDelivered5" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="Berat" field="totalDeliveredWeight5" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="Berat (%)" field="totalDeliveredWeight5Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV (%)" field="totalDelivered5Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="DLV+5 Jumlah" field="totalDelivered6" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+5 Berat" field="totalDeliveredWeight6" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+5 Berat (%)" field="totalDeliveredWeight6Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="DLV+5 (%)" field="totalDelivered6Perc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="InProcess Jumlah" field="totalInProcess" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="InProcess vs Manifest (%)" field="totalInProcessPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="Problem Controllable" field="totalControllableProblem" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="Problem Uncontrollable" field="totalUncontrollableProblem" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="UNDLV Jumlah" field="totalUndelivered" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="UNDLV vs Manifest (%)" field="totalUndeliveredPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="OverSLA (Internal) Jumlah" field="totalOverSlaInternal" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="vs Manifest (%)" field="totalOverSlaInternalPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />

                <Column header="OverSLA (External) Jumlah" field="totalOverSlaExternal" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
                <Column header="OverSLA (External) vs Manifest (%)" field="totalOverSlaExternalPerc" sortable={true} style={{ width: '120px', textAlign: 'right' }} />
              </DataTable>

              {/* <Paginator
                totalRecords={this.dataSource.total}
                first={this.dataSource.offset}
                rows={this.dataSource.rows}
                rowsPerPageOptions={this.dataSource.rowsOptions}
                onPageChange={e => this.dataSource.fromPageState(e)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
