import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from "reflexbox";

import { DataSource } from "../../../models/DataSource";
import { DataTableFilterService } from "../../../services/DataTableFilterService";
import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { ProduktivitasSummaryFilterContainer } from "./ProduktivitasSummaryFilterContainer";
import _ from "lodash";
import moment from "moment";
import { IProduktivitasSummaryFilterContainerValue } from "./IProduktivitasSummaryFilterContainerValue";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Growl } from "primereact/growl";
import { TrackingSiteEditFormModal } from "../../trackingSite/TrackingSiteEditFormModal";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { IMonitoringPodKepalaKurirDetailCourierContainerQuery } from "../../monitoringPod/MonitoringPodKepalaKurirDetailCourierContainer";
import { IMonitoringPodFilterContainerValueNew } from '../IMonitoringPodFilterContainerValueNew';
import { IMonitoringPodDetailContainerQueryNew } from "../IMonitoringPodDetailContainerQueryNew"
import { toJS } from "mobx";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import AppDateRangePicker from '../../../utils/AppDateRangePicker';
import EmployeeDropdown from "../../../utils/dropdown/EmployeeDropdown";
@observer
export class UpdateToleransiContainer extends React.Component<
any,
any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  tableExportData;
  formFilters: IProduktivitasSummaryFilterContainerValue;
  formFiltersDetail: IMonitoringPodFilterContainerValueNew;
  dataSource = new DataSource<any>();
  refFilter: RefObject<
    ProduktivitasSummaryFilterContainer
    > = React.createRef();
  optionsToleransi = [
    {
      label: '',
      value: '0',
    },
      {
        label: 'ALIHDAYA (OUTSOURCHING)',
        value: '1',
      },
      {
        label: 'MUTASI',
        value: '2',
      },
      {
        label: 'RESIGN / CUT',
        value: '3',
      },
      {
        label: "SAKIT",
        value: "4",
      },
      {
        label: "BARU JOIN",
        value: "5",
      }
    ];

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      expandedRows: null,
      displayUpdateDialog: false,
      selectedTrackingSiteId: null,
      footerTotal: {},
      nikSigesit: '',
      nameSigesit: '',
      nikPalkur:'',
      namePalkur: '',
      toleransi: '',
      periodeDate: new Date(),
      idUpdate: '',
      branchName: '',
      siteCode:'',
    };
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
  }

  onUpdateClick(data) {
    this.setState({
      displayUpdateDialog: true,
      selectedTrackingSiteId: data["trackingSiteId"],
    });
  }

  columnPercentBody = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ];
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[0];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[1];
    } else if (colValue > 90 && colValue <= 110) {
      backgroundSet = background[2];
    } else if (colValue > 110 && colValue <= 120) {
      backgroundSet = background[3];
    } else {
      backgroundSet = background[4];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnPercentBodyOps = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ]
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[4];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[3];
    } else if (colValue > 90 && colValue <= 105) {
      backgroundSet = background[2];
    } else if (colValue > 105 && colValue <= 110) {
      backgroundSet = background[1];
    } else {
      backgroundSet = background[0];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnActionTemplate(rowData) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onUpdateClick(rowData)}
        ></Button>
      </div>
    );
  }

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });
    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
      this.growl.show(msg);
    }
  }

 


  loadCourierData(podData) {
    const formFilters = Object.assign({}, this.formFilters, { siteCode: podData.trackingSiteCode });
    const datasource = podData.courierDataSource;
    return new Promise((resolve, reject) => {
      datasource.loading = true;
      this.monitoringRestService
        .post('capacity/pod/namePalkur/coverage', formFilters)
        .subscribe(
          response => {
            
            datasource.setData(response);
            datasource.setTotal(response.length);
            podData.courierDataLoaded = true;
            podData.courierDataAwbCount = _.sumBy(response, function (o: any) { return o.ant; });
            podData.courierDataAwbDlvCount = _.sumBy(response, function (o: any) { return o.dlv; });
            podData.courierDataAwbProblemCount = _.sumBy(response, function (o: any) { return o.problem; });
            podData.courierDataAwbNoRepCount = _.sumBy(response, function (o: any) { return o.norep; });
            podData.dataCoverage = response
            resolve(response);
          },
          err => {
            reject(err);
          },
          () => {
            datasource.loading = false;
          },
        );
    });
  }

  async onRowToggle(e) {
    // this.setState({ expandedRows: e.data });
    // console.log(this.data)
    const promises: any[] = [];
    if (e.data) {
      for (var key in e.data) {
        // check also if property is not inherited from prototype
        if (e.data.hasOwnProperty(key)) {
          var value = e.data[key];
          if (value) {
            const podData = _.find(this.data, (d) => d.trackingSite === key);
            // console.log(podData)
            if (podData && !podData.courierDataLoaded) {
              promises.push(this.loadCourierData(podData));
              // await this.loadCourierData(podData);
            }
          }
        }
      }
    }

    await Promise.all(promises);
    this.setState({ expandedRows: e.data });
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";

    return (
      <Link
        to={{ 
          pathname: '/admin/monitoring/pod-new/detail',
          search: qs.stringify({
            field: 'inbound',
            label: 'Paket Masuk',
            filters: this.formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringPodDetailContainerQueryNew),
        }}
        target="_blank">
        {fmtValue}
      </Link>
    );
  }
  inputData = () => { 
    const { nikSigesit, nameSigesit, nikPalkur, namePalkur, toleransi, periodeDate, branchName,siteCode, idUpdate } = this.state

    const notification:any[]= [];
    // if (nikSigesit === '' && nameSigesit === '') {
    //   notification.push('NIK, Nama Sigesit');
    // }
    // if (nikPalkur === '' && namePalkur === '') {
    //   notification.push('NIK, Nama Palkur');
    // }
    if (!nikSigesit) {
      notification.push('Nik Sigesit');
    }
    if (!toleransi) {
      notification.push('Kategori Toleransi');
    }
    
    

    const datePeriode = moment(periodeDate).format("YYYY-MM-DD")
   
    if (notification.length >= 1) {
      let msg = { severity: 'error', summary: _.join(notification, ', ') + ' harus di isi' };
      this.growl.show(msg);
      return;
    }
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
      const branchCode = localStorage.getItem("branchCode")
      ? JSON.parse(localStorage.userData)
      : null;
    const userId = String(userData.user_id)
    
    const params = {
      date: datePeriode,
      userIdUpdate: userId,
      catToleransi: toleransi,
      sigesitName: nameSigesit,
      sigesitNik: nikSigesit.sigesitNik,
      branchName: branchName,
      siteCode: siteCode,
      palkurNik: nikPalkur,
      palkurName: namePalkur,
    }

  
    this.monitoringRestService
      .post("productivities/insert-toleransi", params)
      .subscribe(
        (response) => {
          let msg = { severity: 'success', summary: 'Data berhasil di Update' };
          this.growl.show(msg);
          
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  getResponseData = (d) => { 
    // console.log(d.data.data);
    
    const result = d.data.data.filter(datas => { 
      // console.log(datas);
     return datas.sigesitNik === this.state.nikSigesit
    })
    
  }
  render() {
    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer={"Total Good:" + this.dataSource.total.toLocaleString()}
          />
           <Column
            footer={"Total Bood:" + this.dataSource.total.toLocaleString()}
          />
          <Column
            footer={"Total Warning:" + this.dataSource.total.toLocaleString()}
          />
          <Column footer="" colSpan={17} />
        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Update Toleransi</h1> 
            <br />
            <div>
              <h2> <b>Data Sigesit</b> </h2>
              <Flex wrap w={1}>
                <Flex column style={{ marginTop:"5px",width: "360px"}}>
                    <div className="p-col-4" style={{ width: "92%"}}>
                      <label htmlFor="dlvOpsProbationCapacity">
                      NIK Sigesit
                    </label>
                    <EmployeeDropdown
                      style={{ width: "100%" }}
                      name='employeee'
                      onChange={async (e) => {
                        
                        this.setState({
                          nikSigesit: e,
                          nameSigesit: e ? e.sigesitName:'',
                          nikPalkur: e ? e.palkurNik:'',
                          namePalkur: e ? e.palkurName : '',
                          idUpdate: e ? e.palkurName : '',
                          branchName: e ? e.branchName : '',
                          siteCode: e ? e.siteCode : '',
                        })
                      }}
                      placeholder={"Pilih Sigesit"}
                      value={this.state.nikSigesit}
                      datas={this.getResponseData}/>
                    {/* <InputText
                      style={{ width: "100%" }}
                        id="dlvOpsProbationCapacity"
                      onChange={(e: any) => {
                          this.setState({
                            nikSigesit:e.target.value
                          })
                        }}
                        value={this.state.nikSigesit}
                      /> */}
                    </div>
                      
                </Flex>
                <Flex column style={{ width: "360px"}}>
                  <div className="p-col-4" style={{marginTop:"5px", width: "91%" }}>
                      <label htmlFor="dlvOpsDriverCapacity">
                        Nama Sigesit
                      </label>
                    <InputText
                      style={{ width: "100%", marginTop: "5px", paddingBottom:'10px' }}
                      id="dlvOpsDriverCapacity"
                      onChange={(e: any) => {
                        this.setState({
                          nameSigesit: e.target.value
                        })
                      }}
                      value={this.state.nameSigesit}
                      disabled={true}
                      />
                    </div>
                </Flex>
              </Flex>
            </div>
            <br />
            <div>
              <h2> <b>Data Palkur</b> </h2>
              <Flex wrap w={1}>
                <Flex column style={{ marginTop:"5px",width: "360px"}}>
                    <div className="p-col-4" style={{ width: "92%" }}>
                      <label htmlFor="dlvOpsProbationCapacity">
                      NIK Palkur
                      </label>
                    <InputText
                      style={{ width: "100%" }}
                        id="dlvOpsProbationCapacity"
                        onChange={(e: any) => {
                          this.setState({
                            nikPalkur:e.target.value
                          })
                        }}
                      value={this.state.nikPalkur}
                      disabled={true}
                      />
                    </div>
                </Flex>
                <Flex column style={{ width: "360px"}}>
                    <div className="p-col-4" style={{ width: "91%", marginTop:"5px" }}>
                      <label htmlFor="dlvOpsProbationCapacity">
                       Nama Palkur
                      </label>
                    <InputText
                      style={{ width: "100%" }}
                        id="dlvOpsProbationCapacity"
                        onChange={(e: any) => {
                          this.setState({
                            namePalkur:e.target.value
                          })
                        }}
                      value={this.state.namePalkur}
                      disabled={true}
                      />
                    </div>
                </Flex>
              </Flex>
            </div>
            <br />
            <div>
              <Flex wrap w={1}>
                <Flex column style={{ marginTop:"5px",width: "360px"}}>
                    <div className="p-col-4" style={{ width: "92%" }}>
                      <label htmlFor="dlvOpsProbationCapacity">
                      Kategori Toleransi
                    </label>
                    <Dropdown
                          options={this.optionsToleransi}
                      onChange={e => { 
                        this.setState({
                          toleransi: e.value
                        })
                      }}
                          value={this.state.toleransi}
                      placeholder='Pilih Kategori'
                      style={{ width: "100%" }}
                        />
                    </div>
                </Flex>
                <Flex column style={{ width: "300px"}}>
                  <div className="p-col-4" style={{width:"100%", marginTop:'5px'}} >
                      <label htmlFor="dlvOpsProbationCapacity">
                       Periode
                    </label>
                      <AppDateRangePicker
                      name="periodeDate"
                      onChange={async e => {
                        await this.setState({
                          periodeDate: e.target.value
                        })
                      }}
                      value={this.state.periodeDate}
                      // className={style.sizingPicker}
                      maxSelection={[0, 'd']}
                      showButtonBar={true}
                      clearButtonStyleClass="p-button-danger"
                      notRange={true}
                      inputStyle={{width:'100%'}}
                        />
                    </div>
                </Flex>
              </Flex>
            </div>
            
          
            <br />
            <Flex style={{ marginLeft: "5px"}}>
              <Button
                className="p-button-success mr-2"
                label="Submit"
                onClick={() => this.inputData()}
              />
            </Flex>
          </Flex>
        </Flex>
      </div>
    );
  }
}
