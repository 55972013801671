
export const permissions = [
  {
    key: "monitoring_pod",
    data: {
      menu: "Monitoring POD",
    },
  },
  {
    key: "monitoring_fm",
    data: {
      menu: "Monitoring FM",
    },
  },
  {
    key: "audit_tracking_cod",
    data: {
      menu: "Audit Tracking COD",
    },
  },
  {
    key: "check_history_resi",
    data: {
      menu: "Check History Resi",
    },
  },
  {
    key: "monitoring_urgent",
    data: {
      menu: "Monitoring Urgent",
    },
  },
  {
    key: "masa_ro_broken",
    data: {
      menu: "Masa RO & Broken",
    },
    children: [
      {
        key: "summary_masa_ro_last_mile",
        data: {
          menu: "Summary Masa RO Last Mile",
        },
      },
      {
        key: "breakdown_masa_ro_last_mile",
        data: {
          menu: "Breakdown Masa RO Last Mile",
        },
      },
      {
        key: "summary_paket_broken",
        data: {
          menu: "Summary Paket Broken",
        },
      },
      {
        key: "breakdown_paket_broken",
        data: {
          menu: "Breakdown Paket Broken",
        },
      },
    ],
  },
  {
    key: "monitoring_kepala_kurir",
    data: {
      menu: "Monitoring Kepala Kurir",
    },
    children: [
      {
        key: "monpod_kepala_kurir",
        data: {
          menu: "Monitoring POD Kepala Kurir",
        },
      },
      {
        key: "metabase_over_sla",
        data: {
          menu: "[Metabase] Over SLA",
        },
      },
    ],
  },
  {
    key: "lpb",
    data: {
      menu: "Daftar Resi (LPB)",
    },
  },
  {
    key: "awb_detail",
    data: {
      menu: "Detail Resi Sensitive",
    },
    children: [
      {
        key: "awb_detail_partner",
        data: {
          menu: "View Partner",
        },
      },
      {
        key: "awb_detail_pengirim",
        data: {
          menu: "View Pengirim",
        },
      },
      {
        key: "awb_detail_reseller",
        data: {
          menu: "View Reseller",
        },
      },
      {
        key: "awb_detail_nohpReseller",
        data: {
          menu: "View Telp Reseller",
        },
      },
      {
        key: "awb_detail_nohpPenerima",
        data: {
          menu: "View Telp Penerima",
        },
      },
      {
        key: "awb_detail_img_awb",
        data: {
          menu: "View Gambar",
        },
      },
      {
        key: "awb_detail_keterangan",
        data: {
          menu: "View Keterangan",
        },
      },
      {
        key: "awb_detail_prtParcelContent",
        data: {
          menu: "View Isi Barang",
        },
      },
      {
        key: "awb_detail_prtParcelValue",
        data: {
          menu: "View Parcel Value",
        },
      },
      {
        key: "awb_detail_asuransiNilai",
        data: {
          menu: "View Nilai Barang (Asuransi)",
        },
      },
      {
        key: "awb_detail_asuransiBiaya",
        data: {
          menu: "View Biaya Asuransi",
        },
      },
      {
        key: "awb_detail_harga",
        data: {
          menu: "View Harga",
        },
      },
      {
        key: "awb_detail_asuransiPackingBiaya",
        data: {
          menu: "View Packing & Asuransi",
        },
      },
      {
        key: "awb_detail_biaya",
        data: {
          menu: "View Total Ongkir",
        },
      },
      {
        key: "awb_detail_totalbiaya",
        data: {
          menu: "View Grand Total",
        },
      },
      {
        key: "awb_detail_priceAfterDisc",
        data: {
          menu: "View Price After Discount",
        },
      },
    ]
  },
  {
    key: "pengantaran",
    data: {
      menu: "Pengantaran",
    },
    children: [
      {
        key: "pengantaran_process",
        data: {
          menu: "Dalam Pengantaran",
        },
      },
      {
        key: "pengantaran_not_dlv",
        data: {
          menu: "Resi Belum Terkirim",
        },
      },
      {
        key: "pengantaran_volume_representative",
        data: {
          menu: "Volume per Perwakilan Tujuan",
        },
      },
      {
        key: "pengantaran_volume_district",
        data: {
          menu: "Volume per Kecamatan Tujuan",
        },
      },
    ],
  },
  {
    key: "retur",
    data: {
      menu: "Retur",
    },
    children: [
      {
        key: "retur_in_process",
        data: {
          menu: "Resi Retur Dalam Proses New",
        },
      },
    ],
  },
  {
    key: "problems",
    data: {
      menu: "Problems",
    },
    children: [
      {
        key: "problem_over_sla",
        data: {
          menu: "Over SLA",
        },
      },
      {
        key: "problem_resi_xxx",
        data: {
          menu: "Resi XXX",
        },
      },
    ],
  },
  {
    key: "analytics",
    data: {
      menu: "Analytics",
    },
  },
  {
    key: "masterdata",
    data: {
      menu: "Master Data",
    },
    children: [
      {
        key: "masterdata_gerai",
        data: {
          menu: "Master Gerai",
        },
        children: [
          {
            key: "masterdata_gerai_upload",
            data: {
              menu: "Upload Data Master Gerai",
            },
          },
        ],
      },
      {
        key: "awb_status",
        data: {
          menu: "AWB Status",
        },
      },
    ],
  },
  {
    key: "procedures",
    data: {
      menu: "Prosedur dan Ketentuan",
    },
  },
  {
    key: "attendance_driver",
    data: {
      menu: "Attendance Driver",
    },
  },
  {
    key: "summary_monitoring_sds",
    data: {
      menu: "Summary Monitoring SDS",
    },
  },
  {
    key: "monitoring_line_haul",
    data: {
      menu: "Monitoring Line Haul",
    },
  },
  {
    key: "settings",
    data: {
      menu: "Pengaturan",
    },
    children: [
      {
        key: "settings_role_access",
        data: {
          menu: "Role Akses",
        },
      },
    ],
  },
];
