import { observer } from 'mobx-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { Flex } from 'reflexbox';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import _ from 'lodash';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Growl } from 'primereact/growl';

@observer
export class DeliveryCapacityPerwakilanContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data; growl;
  tableExportData;
  dataSource = new DataSource<any>();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      footerTotal: {}
    }

    this.exportCsv = this.exportCsv.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
  }

  loadData() {
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('capacity/pod/perwakilan')
      .subscribe(
        response => {
          this.processData(response);
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        },
      );
  }

  processData(data) {
    this.data = data;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);
    this.calculateTotals();
  }

  calculateTotals() {
    const footerTotal = {
      total: _.sumBy(this.data, function (o: any) { return o.total; }),
      totalNotInLastMile: _.sumBy(this.data, function (o: any) { return o.notInLastMile; }),
      totalInLastMile: _.sumBy(this.data, function (o: any) { return o.inLastMile; }),
      totalIsDelivering: _.sumBy(this.data, function (o: any) { return o.isDelivering; }),
      dlvCapacity: _.sumBy(this.data, function (o: any) { return o.dlvCapacity; }),
      dlvOpsCapacity: _.sumBy(this.data, function (o: any) { return o.dlvOpsCapacity; }),
    }

    console.log(footerTotal);

    this.setState({ footerTotal: footerTotal });
  }

  async exportCsv() {
    await this.tableExportData.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  columnNumberFormat = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = (colValue) ? rowData[column.field!].toLocaleString() : '-';
    return <span>{fmtValue}</span>
  }

  columnPercentBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = (colValue) ? rowData[column.field!].toLocaleString() : '-';
    return <span>{fmtValue}%</span>
  }

  render() {

    let footerGroup = <ColumnGroup>
      <Row>
        <Column footer={"Totals:" + this.dataSource.total ? this.dataSource.total.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.total ? this.state.footerTotal.total.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalNotInLastMile ? this.state.footerTotal.totalNotInLastMile.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalInLastMile ? this.state.footerTotal.totalInLastMile.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.totalIsDelivering ? this.state.footerTotal.totalIsDelivering.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.dlvCapacity ? this.state.footerTotal.dlvCapacity.toLocaleString() : ""} />
        <Column footer={this.state.footerTotal.dlvOpsCapacity ? this.state.footerTotal.dlvOpsCapacity.toLocaleString() : ""} />
        <Column footer="" colSpan={4} />
      </Row>
    </ColumnGroup>;

    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showExportDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showExportDialog: false })}>
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">

          <Flex className="w-full" column>
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.exportCsv}></Button>
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="365px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => { this.tableExportData = el; }}
                footerColumnGroup={footerGroup}>
                {/* onRowToggle={(e) => this.onRowToggle(e)}
                // onRowToggle={(e) => this.setState({expandedRows:e.data})}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"> */}

                {/* [
                  {
                    "perwakilan": "BPN",
                    "total": 3378,
                    "inLastMile": 0,
                    "isDelivering": 867,
                    "dlvCapacity": 900,
                    "dlvOpsCapacity": 0,
                    "employeeTotal": 0,
                    "employeeAntar": 0,
                    "employeeAntarCod": 0,
                    "employeeAntarFreelance": 0,
                    "employeeAntarDriver": 0
                  },
                ] */}

                <Column
                  field="perwakilan"
                  header="Perwakilan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px' }}
                />
                <Column
                  field="total"
                  header="Total Barang"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnNumberFormat}
                />
                <Column
                  field="notInLastMile"
                  header="Belum Di Last Mile"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnNumberFormat}
                />
                <Column
                  field="inLastMile"
                  header="Di Last Mile"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnNumberFormat}
                />
                <Column
                  field="isDelivering"
                  header="Sedang Antar"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnNumberFormat}
                />
                <Column
                  field="dlvCapacity"
                  header="Dlv. Cap"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnNumberFormat}
                />
                <Column
                  field="dlvOpsCapacity"
                  header="Dlv. Ops Cap"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnNumberFormat}
                />
                <Column
                  field="dlvCapacityNotInLastMilePerc"
                  header="Dlv. Ops Cap % (Belum Di Last Mile)"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnPercentBody}
                />
                <Column
                  field="dlvOpsCapacityNotInLastMilePerc"
                  header="Dlv. Ops Cap % (Belum Di Last Mile)"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnPercentBody}
                />

                <Column
                  field="dlvCapacityInLastMilePerc"
                  header="Dlv. Ops Cap % (Di Last Mile)"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnPercentBody}
                />
                <Column
                  field="dlvOpsCapacityInLastMilePerc"
                  header="Dlv. Ops Cap % (Di Last Mile)"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnPercentBody}
                />

                <Column
                  field="dlvCapacityPerc"
                  header="Dlv. Ops Cap %"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnPercentBody}
                />
                <Column
                  field="dlvOpsCapacityPerc"
                  header="Dlv. Ops Cap %"
                  sortable={true}
                  style={{ width: '150px', textAlign: 'right' }}
                  body={this.columnPercentBody}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
