import { observer } from 'mobx-react';
import React, { RefObject } from 'react';

import qs from 'qs';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { TabView, TabPanel } from 'primereact/tabview';
import { AnalyticCapacityByPeriodContainer } from './AnalyticCapacityByPeriodContainer';
import { AnalyticOverloadContainer } from './AnalyticOverloadContainer';
import { AnalyticUnderloadContainer } from './AnalyticUnderloadContainer';
import { AnalyticComparationContainer } from './AnalyticComparationContainer';

@observer
export class AnalyticCapacityContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  refCapacityByPeriod: RefObject<AnalyticCapacityByPeriodContainer> = React.createRef();
  refOverload: RefObject<AnalyticOverloadContainer> = React.createRef();
  refUnderload: RefObject<AnalyticUnderloadContainer> = React.createRef();
  refComparation: RefObject<AnalyticComparationContainer> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 0,
    };
  }

  componentDidMount() {
    const query = (this.props.history.location.search)
      ? qs.parse(
        this.props.history.location.search.substr(1),
      ) as any
      : {} as any;

    if (query) {
      if (query.activeTabIndex) {
        this.setState({ activeTabIndex: parseInt(`${query.activeTabIndex}`) });
      }

      // if (query.filters) {        
      //     const asyncUpdateFormValuesAndLoad = async () => {
      //       await this.refFilter.current!.setFormValues(query.filters);            
      //       this.loadData();
      //     };
      //     asyncUpdateFormValuesAndLoad();        
      // }
    }
  }

  loadData() {
  }

  render() {
    return (
      <div>
        <div>
          <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
            <TabPanel header="Analisa Kapasitas">
              <AnalyticCapacityByPeriodContainer ref={this.refCapacityByPeriod} />
            </TabPanel>
            <TabPanel header="Analisa Overload">
              <AnalyticOverloadContainer ref={this.refOverload} />
            </TabPanel>
            <TabPanel header="Analisa Underload">
              <AnalyticUnderloadContainer ref={this.refUnderload} />
            </TabPanel>
            <TabPanel header="Perbandingan FM LM">
              <AnalyticComparationContainer ref={this.refComparation} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}
