import React, { PureComponent } from "react";
import AsyncPaginate from "react-select-async-paginate";
import _ from "lodash";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {DependencyContainer} from "../../../utils/DependencyInjection";
import { MonitoringRestService } from '../../../services/MonitoringRestService';

const AsyncDropdownStyle = {
  marginTop: "5px",
  fontSize: "14px",
};

@observer
class AsyncDropdown extends PureComponent<any, any> {
  @observable autoFocus = false;
  asyncPaginate;
  @observable data;
  @observable more;
  @observable filters: any[] = [];

  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  constructor(props: any) {
    super(props);
    this.state = {
      limit: 10,
      page: 1,
      filters: {},
      sortBy: "",
      sortDir: "",
      url: "",
      placeholder:
        props.placeholder !== undefined ? props.placeholder : "Pilih",
      template: "",
      selectAllLabel: "Semua",
      key: "",
      selectAll: false,
      className: "",
      loadMore: true,
    };

    this.asyncPaginate = React.createRef();
    this.loadOptions = this.loadOptions.bind(this);
  }
  setCustomFilters = (filters: any): void => {
    this.filters = filters;
  };

  componentDidUpdate(): void {
    this.filters = this.props.filters;
    this.loadData()
  }

  loadData = () => {
    this.loadOptions("", [], {page: 1, state: this.state, name: ""});
    
  };

  async loadOptions(search, prevOptions, {page, state, name}) {
    const optionValue: any = [];
    const primaryKey = state.key;
    state.filters.value = search;

    const data = {
      limit: state.limit,
      page,
      search,
    };
    let hasMore;

    const regexTemplate = /{{(.+?)}}/g;
    const templateKey = state.template.match(regexTemplate);
    const keyList: any = [];
    templateKey.map((val, index) => {
      keyList.push(val.replace("}}", "").replace("{{", ""));
    });

    if (page === 1 && state.selectAll) {
      optionValue.push({
        label: state.selectAllLabel,
        value: 0,
      });
    }

    const config: any = {};

    const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
      if (userData) {
        config.headers = {
          Authorization:
            `Bearer ${userData.bearer_token}`
        };
      }

    await this.monitoringRestService.axios
    .get(state.url, { params: data, ...config })
    .then(response => {
        this.data = response;
        if (state.loadMore) {
            hasMore= response.data.pagination.totalData > prevOptions.length + data.limit
        } else {
            hasMore= false
        }

        response.data.data.map((val) => {
            let label = state.template;
            templateKey.map((item2, key) => {
            label = label.replace(item2, val[keyList[key]]);
            });
            optionValue.push({
            label: label,
            value: val[primaryKey],
            });
        });
    }).catch((error) => {
        console.log(error)
    })
        
    return {
      options: optionValue,
      hasMore,
      additional: {
        page: page + 1,
        state,
        name,
      },
    };
  }

  focus = () => {
    // this.asyncPaginate.current.focus();
  };

  render() {
    const {onChange, id} = this.props;
    const { className } = this.state;
    return (
      <div style={AsyncDropdownStyle} className='w-100'>
        <AsyncPaginate
          {...this.props}
          debounceTimeout={800}
          loadOptions={this.loadOptions}
          onChange={onChange}
          isSearchable={true}
          ref={this.asyncPaginate}
          isClearable={true}
          id={id !== "undefined" ? id : ""}
          isLoading={true}
          // cacheOptions
          value={this.props.value}
          className={className}
          placeholder={this.state.placeholder}
          additional={{
            name: "",
            page: 1,
            state: this.state,
          }}
        />
      </div>
    );
  }
}

export default AsyncDropdown;
