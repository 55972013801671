import { observer } from 'mobx-react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import React from 'react';
import { RouterProps } from 'react-router';
import { Flex } from 'reflexbox';

import { DataSource } from '../../../models/DataSource';
import { MonitoringRestService } from '../../../services/MonitoringRestService';
import { DependencyContainer } from '../../../utils/DependencyInjection';
import { permissions } from './permissions' 
import DropdownRole from './../../../components/dropdown/DropdownRole' 
import { Growl } from 'primereact/growl';

@observer
export class UserPermissionContainer extends React.Component<RouterProps, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  data; growl;
  dataSource = new DataSource<any>();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedMenus: [],
      expandedKeys: {},
      allPermissionKeys: [],
      role: null,
    }
  }

  getExpandedPermissions(permissionData) {
    let _expandedKeys: any = {}
    permissionData.map((permission) => {
      if (permission.children && permission.children.length > 0) {
        _expandedKeys[permission.key] = true
        const childExpandedKeys = this.getExpandedPermissions(permission.children)
        _expandedKeys = {..._expandedKeys, ...childExpandedKeys}
      }
    })
    return _expandedKeys
  }

  getAllPermissionsKey(permissionData) {
    let permissionKeys: any = []
    permissionData.forEach((permission) => {
      let permissionKey = [permission.key];
      if (permission.children && permission.children.length > 0) {
        const childPermissionKeys = this.getAllPermissionsKey(permission.children)
        permissionKey = [...permissionKey, ...childPermissionKeys]
      }
      permissionKeys = permissionKeys.concat(permissionKey)
    })
    return permissionKeys
  }

  initPermissions() {
    this.dataSource.setData(permissions);
    const _expandedKeys = this.getExpandedPermissions(permissions)
    const _allPermissionKeys = this.getAllPermissionsKey(permissions)
    this.setState({
      expandedKeys: _expandedKeys,
      allPermissionKeys: _allPermissionKeys
    })
  }

  componentDidMount() {
    this.initPermissions()
  }
  
  loadData(filter: any = null) {
    this.setState({ selectedMenus: [] })
    if (filter) {
      this.dataSource.loading = true;
      const filterRoleId = filter.value
      this.monitoringRestService.post('/role-permission/list', {
        role_id: String(filterRoleId),
      })
      .subscribe(
        response => {
          const accessMenu = response.menu.filter((menu: { is_access: boolean, menu: string }) => menu.is_access)
          const rolePermissions = accessMenu.map((menu: { is_access: boolean, menu: string }) => menu.menu)

          const selected = {};
          rolePermissions.forEach((permission) => {
            selected[permission] = {checked: true, partialChecked: false}
          })

          this.setState({
            selectedMenus: selected,
          })

        },
        err => {
          console.log(err)
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        },
      );
    }
  }

  handleChangeSelectedMenu(e) {
    this.setState({ selectedMenus: e.value })
  }
  handleToggle(e) {
    this.setState({ expandedKeys: e.value})
  }

  save() {
    if (this.state.role) {
      const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

      this.setState({ loading: true })
      const rolesAccessPermissions: any[] = [];
      this.state.allPermissionKeys.forEach((permissionkey) => {
        rolesAccessPermissions.push({
          menu: permissionkey,
          parent_menu: "",
          is_access: Object.keys(this.state.selectedMenus).includes(permissionkey),
        });
      })
      const payload = {
        role_id: String(this.state.role.value),
        role_name: this.state.role.label,
        menu: rolesAccessPermissions,
        user_id: String(userData.user_id)
      }

      this.monitoringRestService.post('/role-permission/list/update', payload)
      .subscribe(
        response => {
          if (response) {
            let msg = { severity: 'success', summary: 'Success Message', detail: 'Data berhasil tersimpan' };
            this.growl.show(msg);
          }
        },
        err => {
          console.log(err)
          this.setState({ loading: false })
        },
        () => {
          this.setState({ loading: false })
        },
      );
    } else {
      let msg = { severity: 'info', summary: 'Info', detail: 'Pilih Role terlebih dahulu' };
      this.growl.show(msg);
    }
  } 

  render() {
    return (
      <div>
        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />
        <Flex className="card w-full">
          <Flex className="w-full" column>
            <div className="form-group">
              <label className="col-md-2" style={{marginTop: "10px"}}>Role</label>
              <div className="col-md-4">
                <DropdownRole
                  name="role"
                  onChange={(value: any) => {
                    this.setState({ role: value });
                    this.loadData(value)
                  }}
                  value={this.state.role}
                />
              </div>
            </div>
          </Flex>
        </Flex>

        <div className="card">
          <TreeTable
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: '100%' }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            selectionMode="checkbox"
            selectionKeys={this.state.selectedMenus}
            onSelectionChange={e => this.handleChangeSelectedMenu(e)}
            onToggle={e => this.handleToggle(e)}
            expandedKeys={this.state.expandedKeys}
          >
            <Column
              header="Navigasi"
              sortable={false}
              expander
              style={{ width: '150px' }}
            />
            <Column
              field="menu"
              header="Menu"
              filter={true}
              filterMatchMode="contains"
              sortable={false}
            />
          </TreeTable>
          <br />
          <Button
            label="Simpan"
            disabled={this.state.loading}
            onClick={() => this.save()}
          />
        </div>
      </div>
    );
  }
}
