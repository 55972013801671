import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Flex } from 'reflexbox';
import { IDeliveryPerformanceByDestinationFilterContainerValue } from './IDeliveryPerformanceByDestinationFilterContainerValue';
import { Panel } from 'primereact/panel';
import { LAYANAN_OPTIONS } from "../../constants";

export class DeliveryPerformanceByDestinationFilterContainer extends React.Component<any, any> {
  initialFormValues: IDeliveryPerformanceByDestinationFilterContainerValue = {
    layanan: '',
    cod: '',
    date: new Date(),
    subdistrict: '',
    city: '',
    province: '',
    pengirim: '',
    partner: '',
  };
  refFormik: RefObject<Formik> = React.createRef();

  optionsCod = [
    {
      label: 'ALL',
      value: '',
    },
    {
      label: 'COD',
      value: 'Y',
    },
    {
      label: 'NON COD',
      value: 'N',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <Flex wrap w={1}>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Tanggal</label>
                  <div className="col-md-8">
                    <Calendar
                      name="date"
                      readOnlyInput={true}
                      dateFormat="dd-mm-yy"
                      showWeek={true}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.date}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Kecamatan</label>
                  <div className="col-md-8">
                    <InputText
                      name="subdistrict"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.subdistrict}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Kota</label>
                  <div className="col-md-8">
                    <InputText
                      name="city"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.city}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Province</label>
                  <div className="col-md-8">
                    <InputText
                      name="province"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.province}
                    />
                  </div>
                </div>
              </Flex>

              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Layanan</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={LAYANAN_OPTIONS}
                      onChange={formikProps.handleChange('layanan')}
                      value={formikProps.values.layanan}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">COD</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionsCod}
                      onChange={formikProps.handleChange('cod')}
                      value={formikProps.values.cod}
                    />
                  </div>
                </div>
              </Flex>

              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Pengirim</label>
                  <div className="col-md-8">
                    <InputText
                      name="pengirim"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.pengirim}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Partner</label>
                  <div className="col-md-8">
                    <InputText
                      name="partner"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.partner}
                    />
                  </div>
                </div>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
