import React, { PureComponent } from "react";
import AsyncPaginate from "react-select-async-paginate";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {DependencyContainer} from "../../utils/DependencyInjection";
import { MonitoringRestService } from '../../services/MonitoringRestService';

const AsyncDropdownStyle = {
  fontSize: "14px",
  width: "200px",
};

@observer
class AsyncDropDown extends PureComponent<any, any> {
  @observable autoFocus = false;
  asyncPaginate;
  @observable more;
  @observable filters: any[] = [];
  apiService = DependencyContainer.get(MonitoringRestService)
  

  constructor(props: any) {
    super(props);
    this.state = {
      limit: 10,
      page: 1,
      filters: {},
      sortBy: "",
      sortDir: "",
      method: "GET",
      headers: {},
      url: "",
      getResponseData: function (response) {
        return response;
      },
      placeholder:
        props.placeholder !== undefined ? props.placeholder : "Pilih",
      template: "",
      selectAllLabel: "Semua",
      key: "",
      selectAll: false,
      className: "",
      loadMore: true,
      minimumKeyword: props.minimumKeyword ? props.minimumKeyword : 0,
    };
    this.asyncPaginate = React.createRef();
    this.loadOptions = this.loadOptions.bind(this);
  }
  setCustomFilters = (filters: any): void => {
    this.filters = filters;
  };

  componentDidUpdate(): void {
    this.filters = this.props.filters;
  }

  loadData = () => {
    this.loadOptions("", [], {page: 1, state: this.state, name: ""});
  };

  async loadOptions(search, prevOptions, {page, state, name}) {
    const optionValue: any = [];
    const primaryKey = state.key;
    state.filters.value = search;

    const data = {
      limit: state.limit,
      page,
      search,
    };
    let hasMore;
    if (search.length >= this.state.minimumKeyword) {
      const regexTemplate = /{{(.+?)}}/g;
      const templateKey = state.template.match(regexTemplate);
      const keyList: any = [];
      templateKey.map((val, index) => {
        keyList.push(val.replace("}}", "").replace("{{", ""));
      });

      if (page === 1 && state.selectAll) {
        optionValue.push({
          label: state.selectAllLabel,
          value: 0,
        });
      }

      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
        
      let requestConfig: any = {
        url: state.url,
        method: state.method,
        headers: {
          "Content-Type": "application/json",
          ...state.headers
        }
      }

      if (userData) {
        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization:
            `Bearer ${userData.bearer_token}`
        };
      }

      if (state.method === "GET") {
        requestConfig.params = data
      } else {
        requestConfig.data = data
      }

      await this.apiService.axios
      .request(requestConfig)
      .then(res => {
        const response = state.getResponseData(res)
        if (state.loadMore) {
            hasMore = response.data.length > 0 && response.pagination.totalData > prevOptions.length + data.limit
        } else {
            hasMore= false
        }

        response.data.map((val) => {
          let label = state.template;
          templateKey.map((item2, key) => {
          label = label.replace(item2, val[keyList[key]]);
          });
          optionValue.push({
          label: label,
          value: val[primaryKey],
          });
        });
      }).catch((error) => {
          console.log(error)
      })
    }
    return {
      options: optionValue,
      hasMore,
      additional: {
        page: page + 1,
        state,
        name,
      },
    };
  }

  focus = () => {
    // this.asyncPaginate.current.focus();
  };

  noOptionsMessage = (data) => {
    if (data.inputValue.length < this.state.minimumKeyword) {
      return `Minimum ${this.state.minimumKeyword} karakter`
    }
    return "No options"
  };

  loadingMessage = (data) => {
    if (data.inputValue.length < this.state.minimumKeyword) {
      return `Minimum ${this.state.minimumKeyword} karakter`
    }
    return "Loading..."
  };

  render() {
    const {onChange, id} = this.props;
    const { className } = this.state;
    return (
      <div style={AsyncDropdownStyle} className='w-150'>
        <AsyncPaginate
          {...this.props}
          debounceTimeout={800}
          loadOptions={this.loadOptions}
          noOptionsMessage={this.noOptionsMessage}
          loadingMessage={this.loadingMessage}
          onChange={onChange}
          isSearchable={true}
          ref={this.asyncPaginate}
          isClearable={true}
          id={id !== "undefined" ? id : ""}
          isLoading={false}
          // cacheOptions
          value={this.props.value}
          className={className}
          placeholder={this.state.placeholder}
          additional={{
            name: "",
            page: 1,
            state: this.state,
          }}
        />
      </div>
    );
  }
}

export default AsyncDropDown;
