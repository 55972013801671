import { observer } from 'mobx-react';
import moment from 'moment';
import { Button } from 'primereact/button';
import React, { RefObject } from 'react';
import { Flex } from 'reflexbox';
import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AntNotDlvTrackingSiteContainer } from './AntNotDlvTrackingSiteContainer';
import { AntNotDlvTrackingSiteDetailContainer } from './AntNotDlvTrackingSiteDetailContainer';

@observer
export class AntNotDlvContainer extends React.Component {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dataSource = new DataSource<any>();
  refTrackingSite: RefObject<AntNotDlvTrackingSiteContainer> = React.createRef();
  refTrackingSiteDetail: RefObject<AntNotDlvTrackingSiteDetailContainer> = React.createRef();

  constructor(props) {
    super(props);
    this.handleTrackingSiteSelect = this.handleTrackingSiteSelect.bind(this);
}

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.loadData();
    // });

    this.refTrackingSite.current!.setParentRef({ handleTrackingSiteSelect: this.handleTrackingSiteSelect });
  }

  loadData() {
    this.refTrackingSite.current!.loadData();
    this.refTrackingSiteDetail.current!.clearData();
  }

  handleTrackingSiteSelect(trackingSiteId, trackingSiteName, period) {
    if (this.refTrackingSiteDetail && this.refTrackingSiteDetail.current) {
      this.refTrackingSiteDetail.current!.loadData(trackingSiteId, trackingSiteName, period);
    }
  }

  render() {
    return (
      <div>
        <Flex className="card w-full">
          <Flex column w={1}>
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <AntNotDlvTrackingSiteContainer ref={this.refTrackingSite} />
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <AntNotDlvTrackingSiteDetailContainer ref={this.refTrackingSiteDetail} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
