import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { InputText } from 'primereact/inputtext';
import _ from 'lodash';
import moment from "moment";
import { DataSource } from '../../models/DataSource';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnProps } from 'primereact/column';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

@observer
export class AntNotDlvTrackingSiteContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  growl;
  data;
  @observable total;
  parentRef;

  tableData;
  dataSource = new DataSource<any>();

  constructor(props, context) {
    super(props, context);
    this.exportData = this.exportData.bind(this);
  }

  public async loadData() {
    this.dataSource.loading = true;
    const params = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    }
    this.monitoringRestService
      .post('monitoring-pod/sf/ant-not-dlv', params)
      .subscribe(
        response => {
          const mappedData = response.map((d) => {
            return {
              ...d,
              trackingSiteProvince: d.PROVINCE_NAME,
              trackingSiteCity: d.CITY_NAME,
              trackingSite: d.BRANCH_NAME,
              countYesterday: d.YESTERDAY,
              trackingSiteId: d.BRANCH_ID,
              countToday: d.TODAY,
              count: d.COUNT,
            }
          })
          this.data = mappedData;
          this.total = _.sumBy(this.data, function (o: any) { return o.count; });
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        null,
        () => {
          this.dataSource.loading = false;
        },
      );
  }

  public setParentRef(parentRef) {
    this.parentRef = parentRef;
  }

  public async onClickTrackingSite(trackingSiteId, trackingSiteName, period) {
    this.parentRef.handleTrackingSiteSelect(trackingSiteId, trackingSiteName, period);
  }

  async exportData() {
    await this.tableData.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  render() {
    var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.exportData}></Button></div>;
    const footer = 'Total Gerai: ' + this.dataSource.total;

    return (
      <div>
        <div className="card">
          <div className='row'>
            <div className='col-md-8'><b><h3><b>Gerai</b></h3></b></div>
            <div className='col-md-4 pt-10 text-right'><b>Total Resi: </b>{this.total}</div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="550px"
                style={{ width: '100%' }}
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                header={header} 
                ref={(el) => { this.tableData = el; }}
                footer={footer}>
                <Column
                  field="trackingSiteProvince"
                  header="Province"
                  sortable={true}
                  filter={true}
                  style={{ width: '120px' }}
                />
                <Column
                  field="trackingSiteCity"
                  header="Kota"
                  sortable={true}
                  filter={true}
                  style={{ width: '120px' }}
                />
                <Column
                  field="trackingSite"
                  header="Gerai"
                  sortable={true}
                  filter={true}
                  style={{ width: '120px' }}
                />
                <Column
                  field="countYesterday"
                  header="Kemarin"
                  sortable={true}
                  filter={true}
                  style={{ width: '60px' }}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";

                    return (
                      <a onClick={() => this.onClickTrackingSite(rowData['trackingSiteId'], rowData['trackingSite'], 'yesterday')}>{fmtValue}</a>
                    )
                  }}
                />
                <Column
                  field="countToday"
                  header="Hari Ini"
                  sortable={true}
                  filter={true}
                  style={{ width: '60px' }}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";

                    return (
                      <a onClick={() => this.onClickTrackingSite(rowData['trackingSiteId'], rowData['trackingSite'], 'today')}>{fmtValue}</a>
                    )
                  }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
