import { Formik } from "formik";
import { observable } from "mobx";
import { observer } from "mobx-react";
import qs from "qs";
import React, { RefObject } from "react";
import { Link } from "react-router-dom";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import _ from "lodash";
import { DateHelper } from "../../utils/DateHelper";
import Loader from "react-loader-spinner";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

@observer
export class OverSlaSnowflakeSummaryByTujuanContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  refFormik: RefObject<Formik> = React.createRef();

  @observable data;
  rawData;
  filterParams;
  perwakilanNode: any = '';
  constructor(props) {
    super(props);

    this.state = {
      loadOverSlaData: false,
      overslaData: []

    };

    this.columnDescriptionTemplate = this.columnDescriptionTemplate.bind(this);
    this.columnCountTemplate = this.columnCountTemplate.bind(this);
  }

  public async loadData(filterParams) {
    this.data = [];
    this.filterParams = filterParams;
    const fParams = Object.assign({}, filterParams);
    this.setState({ loadOverSlaData: "load" });

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    fParams.select = "REPRESENTATIVE_CODE";
    this.monitoringRestService.post("sf/oversla/summary", fParams).subscribe(
      response => {
        const mappedData = response.map((d) => {
          const mappedList = d.VALUE.map((v) => {
            return {
              key: v.KEY,
              doc_count: v.DOC_COUNT,
            }
          })
          return {
            key: d.KEY,
            doc_count: d.TOTAL,
            list: mappedList,
          }
        })
        this.rawData = mappedData;

        this.processData(this.rawData);
        // this.data = response;
      },
      () => {
        this.setState({ loadOverSlaData: false });
      },
      () => {
        this.setState({ loadOverSlaData: true });
      }
    );
  }

  processData(rawData) {
    let newData: any = [];
    let keyCounter: number = 0;

    let groupData: any;
    groupData = _.groupBy(rawData, "key");
    Object.keys(groupData).forEach((gKey: any) => {
      let gData: any;
      gData = groupData[gKey];

      let dataList: any;
      dataList = {
        key: keyCounter++,
        data: {
          description: gKey,
          count: _.sumBy(gData, "doc_count"),
          isMaster: true
        }
      };

      if (gData[0].list) {
        dataList.children = gData[0].list.map(m => {
          return {
            key: keyCounter++,
            data: {
              description: m.key,
              count: m.doc_count,
              isMaster: false
            }
          };
        });
      }

      newData.push(dataList);
    });
    this.data = newData;
    this.setState({ overslaData: newData });
  }

  columnDescriptionTemplate(node, column) {
    return (
      <span>
        {node.data.isMaster ? (
          <span>
            <b>{node.data.description}</b>
          </span>
        ) : (
            <span>{node.data.description}</span>
          )}
      </span>
    );
  }

  columnCountTemplate(node, column) {

    node.data.isMaster ?
      this.perwakilanNode = node.data.description : null
    return (
      <span>
        {node.data.isMaster ? (

          <span>
            <b>
              <Link
                to={{
                  pathname: "/admin/monitoring/oversla-sf",
                  search: qs.stringify({
                    filters: Object.assign({}, this.filterParams, {
                      perwakilan: node.data.description
                    }),
                    activeTabIndex: 1
                  })
                }}
                target="_blank"
              >
                {node.data.count.toLocaleString()}

              </Link>
            </b>
          </span>
        ) : (
            <span>
              <Link
                to={{
                  pathname: "/admin/monitoring/oversla-sf",
                  search: qs.stringify({
                    filters: Object.assign({}, this.filterParams, {
                      trackingSite: node.data.description,
                      perwakilan: this.perwakilanNode
                    }),
                    activeTabIndex: 1
                  })
                }}
                target="_blank"
              >
                {node.data.count.toLocaleString()}
              </Link>
            </span>
          )}
      </span>
    );
  }

  renderTable() {
    return (
      <TreeTable value={this.state.overslaData}>
        <Column
          field="description"
          header="Description"
          body={this.columnDescriptionTemplate}
          sortable
          expander
        ></Column>
        <Column
          field="doc_count"
          header="Total"
          body={this.columnCountTemplate}
          style={{ width: "65px", textAlign: "left" }}
          sortable
        ></Column>
      </TreeTable>
    );
  }

  render() {
    return (
      <div>
        {this.data && this.data.length > 0 ? (
          <div className="mt-5">
            <p
              className="font-bold"
              style={{ fontSize: "18px", margin: "10px 0" }}
            >
              Total Resi:{" "}
              {(this.rawData ? _.sumBy(this.rawData, "doc_count") : 0).toLocaleString()}
            </p>
            <hr />
          </div>
        ) : (
            ""
          )}
        {this.data && this.data.length > 0 ? (
          <div>{this.renderTable()}</div>
        ) : (
            <div>
              {this.state.loadOverSlaData == "load" ? (
                <Loader type="ThreeDots" color="#c8c8c8" height="50" width="50" />
              ) : (
                  <p>Tidak ada data</p>
                )}
            </div>
          )}
      </div>
    );
  }
}
