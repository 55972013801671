import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {AccessHelper} from "../../utils/AccessHelper";

export class LayoutAdminSubMenu extends React.Component<any, any> {
  userData;

  static defaultProps = {
    className: null,
    items: null,
    onMenuItemClick: null,
    root: false,
  };

  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    onMenuItemClick: PropTypes.func,
    root: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { activeIndex: null };
    this.userData = (localStorage.getItem('userData')) ? JSON.parse(localStorage.userData) : null;
  }

  onMenuItemClick(event, item, index) {
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    if (index === this.state.activeIndex) this.setState({ activeIndex: null });
    else this.setState({ activeIndex: index });

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item,
      });
    }
  }

  renderLinkContent(item) {
    const submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon" />;
    const badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

    return (
      <React.Fragment>
        <i className={item.icon} />
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  }

  renderTreeLink(content, item, index) {
    return (
      <a
          href = {item.href}
          onClick= {(e) => this.onMenuItemClick(e, item, index) }
          target={(item.newtab) ? '_blank' : item.target} >
          {content}
      </a>
    )
  }

  renderSingleLink(content, item) {
    return (
      <NavLink
          activeClassName="active-route"
          to= {item.href}
          target={(item.newtab) ? '_blank' : item.target} >
          {content}
      </NavLink>
    )
  }

  renderLink(item, i) {
    const content = this.renderLinkContent(item);

    const permitted = item.auth ? AccessHelper.isPermitted(item.name) : true;
    if (permitted) {
      // Check if link item target to other website or not
      if (item.externalLink) {
        return (
          <a
            href={item.href}
            target={(item.newtab) ? '_blank' : item.target}
          >
            {content}
          </a>
        );
      } else {
        return ((item.items && item.items.length) ? this.renderTreeLink(content, item, i) : this.renderSingleLink(content, item) );
      }
    }
  }

  render() {
    const items =
      this.props.items &&
      this.props.items.map((item, i) => {
        const active = this.state.activeIndex === i;
        const styleClass = classNames(item.badgeStyleClass, {
          'active-menuitem': active && !item.to,
        });

        return (
          <li className={styleClass} key={i}>
            {item.items && this.props.root === true && <div className="arrow" />}
            {this.renderLink(item, i)}
            <LayoutAdminSubMenu items={item.items} onMenuItemClick={this.props.onMenuItemClick} />
          </li>
        );
      });

    return items ? <ul className={this.props.className}>{items}</ul> : null;
  }
}

export class LayoutAdminMenu extends React.Component<any, any> {
  static defaultProps = {
    model: null,
    onMenuItemClick: null,
  };

  static propTypes = {
    model: PropTypes.array,
    onMenuItemClick: PropTypes.func,
  };

  render() {
    return (
      <div className="menu">
        <LayoutAdminSubMenu
          items={this.props.model}
          className="layout-main-menu"
          onMenuItemClick={this.props.onMenuItemClick}
          root={true}
        />
      </div>
    );
  }
}
