import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from "reflexbox";

import { DataSource } from "../../../models/DataSource";
import { DataTableFilterService } from "../../../services/DataTableFilterService";
import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { ProduktivitasSummaryFilterContainer } from "./ProduktivitasSummaryFilterContainer";
import _, { upperCase } from "lodash";
import moment from "moment";
import { IProduktivitasSummaryFilterContainerValue } from "./IProduktivitasSummaryFilterContainerValue";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Growl } from "primereact/growl";
import { TrackingSiteEditFormModal } from "../../trackingSite/TrackingSiteEditFormModal";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { IMonitoringPodKepalaKurirDetailCourierContainerQuery } from "../../monitoringPod/MonitoringPodKepalaKurirDetailCourierContainer";
import { IMonitoringPodFilterContainerValueNew } from '../IMonitoringPodFilterContainerValueNew';
import { IMonitoringPodDetailContainerQueryNew } from "../IMonitoringPodDetailContainerQueryNew"
import { toJS } from "mobx";
@observer
export class ProduktivitasSummaryContainer extends React.Component<
any,
any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  tableExportData;
  formFilters: IProduktivitasSummaryFilterContainerValue;
  formFiltersDetail: IMonitoringPodFilterContainerValueNew;
  dataSource = new DataSource<any>();
  refFilter: RefObject<
    ProduktivitasSummaryFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      expandedRows: null,
      displayUpdateDialog: false,
      selectedTrackingSiteId: null,
      footerTotal: {},
      userData: '',
      roleProduktivitas:[
        "Ops - Kepala Kurir SDS First Mile",
        "Ops - Kepala Kurir SDS Last Mile",
        "Ops - Kepala Sigesit",
        "Ops - Koordinator Regional",
        "Root Business Process",
        "Root Cost Control",
      ]
    };

    this.columnActionTemplate = this.columnActionTemplate.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
    let userData = ''
    if (localStorage.getItem('userData')) {
      userData = JSON.parse(localStorage.userData)
    }
    this.setState({
      userData,
    })
  }

  async loadData() {
    let formValue = this.refFilter.current!.formValues 
    this.formFilters = this.refFilter.current!.formValues;    

    this.formFilters['province'] = formValue.province ? formValue.province.value:"";
    this.formFilters['city'] = formValue.city ? formValue.city.value: formValue.city1['value']?formValue.city1['value']:'';
    this.formFilters['branchCode'] = formValue.branchCode ? formValue.branchCode.value : "";
    
    // const sDateMoment = await moment(new Date(this.formFilters.startDate), 'yyyy-mm-dd');
    // const eDateMoment = await moment(new Date(this.formFilters.endDate), 'yyyy-mm-dd');
    // const getDif = await eDateMoment.diff(sDateMoment, 'days')

    // if (getDif > 14) {
    //   alert('Range hari tidak boleh lebih dari 14 hari, Silahkan pilih range hari dan coba lagi!')
    // }

    this.formFilters.startDate = moment(this.formFilters.periodeDate[0]).format("YYYY-MM-DD");
    if (this.formFilters.periodeDate[1] !== null) {
      this.formFilters.endDate = moment(this.formFilters.periodeDate[1]).format("YYYY-MM-DD");
    } else {
      this.formFilters.endDate = moment(this.formFilters.periodeDate[0]).format("YYYY-MM-DD");
    }

    const dateToleransi:any = []
    
    if (this.formFilters.dateToleransiFirst !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransiFirst).format("YYYY-MM-DD"))
      console.log(dateToleransi)
    }
    if (this.formFilters.dateToleransi2 !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransi2).format("YYYY-MM-DD"))
    }
    if (this.formFilters.dateToleransi3 !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransi3).format("YYYY-MM-DD"))
    }
    if (this.formFilters.dateToleransi4 !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransi4).format("YYYY-MM-DD"))
    }
    if (this.formFilters.dateToleransi5 !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransi5).format("YYYY-MM-DD"))
    }
    if (this.formFilters.dateToleransi6 !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransi6).format("YYYY-MM-DD"))
    }
    if (this.formFilters.dateToleransiLast !== '') {
      dateToleransi.push(moment(this.formFilters.dateToleransiLast).format("YYYY-MM-DD"))
    }
    
    
    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    const { startDate, endDate, province, city, justifikasi, branchCode, city1, province1, siteCode, dateToleransiFirst, dateToleransiLast } = this.formFilters
    
    if (localStorage.getItem('userData')) {
      var userData = JSON.parse(localStorage.userData)
      var branchLocal = JSON.parse(localStorage.branchCode)
      var rolePermission =  this.state.roleProduktivitas.filter(role => { 
        return role === userData.role_name
      })
    }
    
    let str = siteCode ? String(siteCode['data']['value']) : ''
    const params = {
      siteCode: rolePermission.length === 1? String(branchLocal): str.trim(),
      startDate,
      endDate,
      toleransiDate: dateToleransi,
      province: province? province.toUpperCase() : province1 ? province1['value'] : '',
      city: city?city:city1?city1['value']:'',
      justifikasi,
      roleName: this.state.userData ? this.state.userData.role_name:''
    }

    this.monitoringRestService
      .post("productivities/list-palkur", params)
      .subscribe(
        (response) => {
          this.processData(response);
        },
        (e) => {
          
          let msg = {
            severity: "error",
            summary: e.response.data['message'],
          };
          this.growl.show(msg);
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  loadRowData(trackingSiteId): Observable<any> {
    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.startDate = moment(this.formFilters.startDate).format("YYYY-MM-DD");

    const filters = Object.assign({}, this.formFilters, {
      trackingSiteId: trackingSiteId,
    });

    return this.monitoringRestService.post("capacity/pod/branch", filters).pipe(
      tap((response) => {
        if (response && response.length && response.length > 0) {
          const r = response[0];
          let dIdx = this.data.findIndex(
            (x) => x.trackingSiteId === trackingSiteId
          );
          if (dIdx >= 0) {
            const d = this.data[dIdx];
            this.data[dIdx] = Object.assign({}, d, r);
            this.processData(this.data);
          }
        }
      })
    );
  }

  processData(data) {
    for (const dt of data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource()
    }

    this.data = data;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

 

  onUpdateClick(data) {
    this.setState({
      displayUpdateDialog: true,
      selectedTrackingSiteId: data["trackingSiteId"],
    });
  }

  async exportCsv() {
    await this.tableExportData.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  columnPercentBody = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ];
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[0];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[1];
    } else if (colValue > 90 && colValue <= 110) {
      backgroundSet = background[2];
    } else if (colValue > 110 && colValue <= 120) {
      backgroundSet = background[3];
    } else {
      backgroundSet = background[4];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnPercentBodyOps = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ]
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[4];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[3];
    } else if (colValue > 90 && colValue <= 105) {
      backgroundSet = background[2];
    } else if (colValue > 105 && colValue <= 110) {
      backgroundSet = background[1];
    } else {
      backgroundSet = background[0];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnActionTemplate(rowData) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onUpdateClick(rowData)}
        ></Button>
      </div>
    );
  }

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });
    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
      this.growl.show(msg);
    }
  }

  columnCourierDetailLinkBody = (rowData, column: ColumnProps, podData) => {
    
    const formFilters = Object.assign({}, this.formFilters, { siteCode: podData.trackingSiteCode });
    return (
      <Link
        to={{
          pathname: '/admin/monitoring-pod-new/detail',
          search: qs.stringify({
            courierId: rowData['courierId'],
            courierName: rowData['courierName'],
            courierNik: rowData['courierNik'],
            branchName: podData.trackingSite,
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: podData.trackingSiteCode,
            destination:rowData.destination,
          } as IMonitoringPodKepalaKurirDetailCourierContainerQuery),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }

  rowExpansionTemplate(data) {
    const podData = (_.find(this.data, (d: any) => { return d.trackingSiteCode === data.trackingSiteCode }));
    console.log(podData);
    let totalPaketMasuk = 0
    let totalResi = 0
    let totalCoverage = 0
    if (podData.dataCoverage) {
      podData.dataCoverage.map(data => {
        totalPaketMasuk += Number(data.paketMasuk)
        totalResi += Number(data.totalResi)
      })
      totalCoverage = podData.dataCoverage.length
    }
    
    
    const datasource = podData.courierDataSource;
    // var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = <div style={{ textAlign: 'left' }}>
      <Button style={{ width: '80px' }} type="button" icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : "pi pi-external-link"} iconPos="left" label="CSV" onClick={() => this.exportCourierCsv(podData)}></Button>
      {/* <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}>Total Kurir: {(datasource) ? datasource.total : 0}  |  Total antar: {podData.courierDataAwbCount}  |  Total Terkirim: {podData.courierDataAwbDlvCount}  |  Total Problem: {podData.courierDataAwbProblemCount}  |  Total NoRep: {podData.courierDataAwbNoRepCount}</span></div>; */}
      <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}> Total Kecamatan: {totalCoverage}  |  Total Paket Masuk: {totalPaketMasuk}  |  Total Resi: {totalResi}</span></div>;
    return (
      <div className="p-grid p-fluid" style={{ padding: '1em 1em 1em 1em' }}>
        <div className="p-col-12 p-md-12">
          <DataTable
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="200px"
            className="mt-4"
            value={datasource.data}
            loading={datasource.loading}
            footer={footer}
            ref={(el) => { podData.exportTableData = el; }}>
            <Column
              field="siteCode"
              header="Kode Cabang"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="branchName"
              header="Nama Cabang"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '200px' }}
            />
            <Column
              field="tujuanKota"
              header="Kota/Kab"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="destination"
              header="Kode Tujuan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="tujuanKecamatan"
              header="Kecamatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="isCovered"
              header="Coverage Kecamatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="paketMasuk"
              header="Paket Masuk"
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="totalResi"
              header="Total Resi"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
          </DataTable>
        </div>
      </div>
    );
  }

  loadCourierData(podData) {
    const formFilters = Object.assign({}, this.formFilters, { siteCode: podData.trackingSiteCode });
    const datasource = podData.courierDataSource;
    return new Promise((resolve, reject) => {
      datasource.loading = true;
      this.monitoringRestService
        .post('capacity/pod/branch/coverage', formFilters)
        .subscribe(
          response => {
            
            datasource.setData(response);
            datasource.setTotal(response.length);
            podData.courierDataLoaded = true;
            podData.courierDataAwbCount = _.sumBy(response, function (o: any) { return o.ant; });
            podData.courierDataAwbDlvCount = _.sumBy(response, function (o: any) { return o.dlv; });
            podData.courierDataAwbProblemCount = _.sumBy(response, function (o: any) { return o.problem; });
            podData.courierDataAwbNoRepCount = _.sumBy(response, function (o: any) { return o.norep; });
            podData.dataCoverage = response
            resolve(response);
          },
          err => {
            reject(err);
          },
          () => {
            datasource.loading = false;
          },
        );
    });
  }

  async onRowToggle(e) {
    // this.setState({ expandedRows: e.data });
    // console.log(this.data)
    const promises: any[] = [];
    if (e.data) {
      for (var key in e.data) {
        // check also if property is not inherited from prototype
        if (e.data.hasOwnProperty(key)) {
          var value = e.data[key];
          if (value) {
            const podData = _.find(this.data, (d) => d.trackingSite === key);
            // console.log(podData)
            if (podData && !podData.courierDataLoaded) {
              promises.push(this.loadCourierData(podData));
              // await this.loadCourierData(podData);
            }
          }
        }
      }
    }

    await Promise.all(promises);
    this.setState({ expandedRows: e.data });
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";

    return fmtValue
  }


  render() {
    let totalGood = 0
    let totalBad = 0
    let totalWarning = 0
    if (this.dataSource) {
      this.dataSource.data.map((d, i) => {
        
        if (upperCase(d.justifikasi) === "BAD") {
          totalBad++
        } else if (upperCase(d.justifikasi) === "GOOD") { 
          totalGood++
        } else if (upperCase(d.justifikasi) === "WARNING") { 
          totalWarning++
        }       
       })
    }
    
    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer={"Total Good:" + totalGood}
          />
           <Column
            footer={"Total Bad:" + totalBad}
          />
          <Column
            footer={"Total Warning:" + totalWarning}
          />
          <Column footer="" colSpan={17} />
        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Produktivitas</h1>
            <ProduktivitasSummaryFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                style={{ marginLeft:"1%"}}
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="CSV"
                onClick={this.exportCsv}
              ></Button>
              {/* <Button
                style={{ marginLeft:"1%"}}
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="Upload Data"
                onClick={this.exportCsv}
              ></Button> */}
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={"Tidak ada data untuk ditampilkan."}
                scrollable={true}
                scrollHeight="450px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => {
                  this.tableExportData = el;
                }}
                footerColumnGroup={footerGroup}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.onRowToggle(e)}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"
              >
                <Column
                  field="perwakilan"
                  header="Perwakilan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="branchname"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="palkurname"
                  header="Nama Palkur"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="korwil"
                  header="Korwil"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                  body={this.columnDetailLinkBody}
                />
                <Column
                  field="totalVolume"
                  header="Total Dlv"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "200px" }}
                />
                <Column
                  field="avgVolPerDay"
                  header="AVG Dlv / Day"
                  filter={false}
                  sortable={false}
                  filterMatchMode="contains"
                  style={{ width: "200px" }}
                />
                
                <Column
                  field="targetPembawaan"
                  header="Target Pembawaan"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "200px" }}
                />
                <Column
                  field="avgSigesitPerDay"
                  header="AVG DLV Day / Sigesit"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "120px", textAlign: "center" }}
                />
                <Column
                  field="avgSigesitPerWeek"
                  header="AVG DLV Week / Sigesit"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "150px", textAlign: "center" }}
                />
                <Column
                  field="actualSigesit"
                  header="Aktual Jumlah Sigesit"
                  style={{ width: "200px" }}
                />
                <Column
                  field="siGesitPassed"
                  header="Total Sigesit Capai Target"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "170px" }}
                />
                <Column
                  field="siGesitFailed"
                  header="Total Sigesit Tidak Capai Target"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "170px" }}
                />
                 
                <Column
                  field="alihDaya"
                  header="Alih Daya (OS)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "150px" }}
                />
                <Column
                  field="mutasi"
                  header="Mutasi"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "120px", textAlign: "center" }}
                />
                <Column
                  field="resign"
                  header="Resign / Cut"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "150px" }}
                />
                <Column
                  field="newMember"
                  header="Baru Join"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "120px", textAlign: "center" }}
                />
                <Column
                  field="sick"
                  header="Sakit"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "center" }}
                />
                <Column
                  field="recomEfisiensi"
                  header="Rekomendasi Efisiensi"
                  style={{ width: "130px", textAlign: "center" }}
                />
                <Column
                  field="coverKecamatan"
                  header="Cover Kecamatan"
                  style={{ width: "130px", textAlign: "center" }}
                />
                <Column
                  field="areaKecamatan"
                  header="Area Kecamatan"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "130px", textAlign: "center" }}
                />
                <Column
                  field="target"
                  header="% Pencapaian Target"
                  style={{ width: "130px", textAlign: "center" }}
                  // body={this.columnPercentBody}
                />
                <Column
                  field="justifikasi"
                  header="Justifikasi"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "120px", textAlign: "center" }}
                />
                <Column
                  field="sanksi"
                  header="Sanksi"
                  style={{ width: "130px", textAlign: "center" }}
                />
                 <Column
                  field="totalSigesitIdeal"
                  header="Jumlah Sigesit Ideal"
                  style={{ width: "200px", textAlign: "center" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
