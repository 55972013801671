import * as Sentry from "@sentry/react";

const sentryInit = () => {
  const NODE_ENV = process.env.NODE_ENV;
  const IS_PRODUCTION = NODE_ENV === 'production'
  const SENTRY_DSN = "http://314848041e938c2c645dd7481233f01e@188.166.185.19:9000/4";

  Sentry.init({
    dsn: SENTRY_DSN || "",
    environment: NODE_ENV,
    debug: !IS_PRODUCTION,
  });
};

export default sentryInit;
