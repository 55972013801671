import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Box, Flex } from 'reflexbox';
import { Panel } from 'primereact/panel';
import { LAYANAN_OPTIONS } from "../../constants";

export interface IResiXxxFilterContainerValue {
  startDate?: Date;
  endDate?: Date;
  customer?: string;
  partner?: string;
  serviceType?: any;
  trackingSite?: any;
  city?: any;
}

export class ResiXxxKibanaFilterContainer extends React.Component<any, any> {
  initialFormValues: IResiXxxFilterContainerValue = {
    startDate: undefined,
    endDate: undefined,
    customer: '',
    partner: '',
    serviceType: '',
    trackingSite: '',
    city: '',
  };
  refFormik: RefObject<Formik> = React.createRef();

  optionYN = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Ya',
      value: 'Y',
    },
    {
      label: 'Tidak',
      value: 'N',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = (this.refFormik.current) ? this.refFormik.current.state.values : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <Flex w={1}>
              <Box w={450}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Periode</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange('startDate')}
                          value={formikProps.values.startDate}
                          showButtonBar={true}
                        />
                        <div className="mt-2 mb-2 text-center" style={{ width: '172px' }}>s/d</div>
                        <Calendar
                          name="endDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          minDate={formikProps.values.startDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange('endDate')}
                          value={formikProps.values.endDate}
                          showButtonBar={true}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Customer</label>
                    <div className="col-md-7">
                      <InputText
                        name="customer"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.customer}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Partner</label>
                    <div className="col-md-7">
                      <InputText
                        name="partner"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.partner}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
              <Box>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Layanan</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={LAYANAN_OPTIONS}
                        onChange={formikProps.handleChange('serviceType')}
                        value={formikProps.values.serviceType}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Gerai</label>
                    <div className="col-md-7">
                      <InputText
                        name="trackingSite"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.trackingSite}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Kota</label>
                    <div className="col-md-7">
                      <InputText
                        name="city"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.city}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
