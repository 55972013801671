import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Box, Flex } from 'reflexbox';
import { Panel } from 'primereact/panel';

export interface IManifestPerwakilanListFilterContainerValue {
  date?: Date;  
  [key: string]: any;
}

export class ManifestPerwakilanListFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: IManifestPerwakilanListFilterContainerValue = {
    date: new Date(),
  };

  refFormik: RefObject<Formik> = React.createRef();  

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = (this.refFormik.current) ? this.refFormik.current.state.values : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <Flex wrap w={1}>
              <Box w={350}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Tanggal</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="date"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange('date')}
                          value={formikProps.values.date}
                        // showButtonBar={true}
                        />
                      </Flex>
                    </div>
                  </div>                  
                </Flex>
              </Box>                            
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}