import Axios from 'axios';

import { HttpClientAxiosService } from './HttpClientAxiosService';

export class HttpClientAxiosInterceptedService extends HttpClientAxiosService {
  constructor(baseURL: string) {
    super(Axios.create());
    this.axios.defaults.baseURL = baseURL;
    this.axios.defaults.headers = {
      "x-api-key": "ca9c18687c39bf847d7404ab4e7f618a",
    }
  }
}
