import classNames from 'classnames';
import React from 'react';
import { Redirect, NavLink } from 'react-router-dom';

export class LayoutAdminInlineProfile extends React.Component<any, any> {
  userData;

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      redirect: false,
    };
    this.onClick = this.onClick.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.userData = (localStorage.getItem('userData')) ? JSON.parse(localStorage.userData) : null;
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  logout() {
    localStorage.setItem('userData', '');
    localStorage.setItem('branchCode', '');

    localStorage.clear();
    this.setState({redirect: true});
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={'/'}/>);
    }

    return (
      <div className="profile">
        <div>
          <img src="assets/layout/images/avatar_4.png" alt="" style={{margin: '10px auto'}}/>
        </div>
        <button className="p-link profile-link" onClick={this.onClick}>
          <span className="username">{this.userData!.first_name}</span>
          <i className="pi pi-fw pi-cog" />
        </button>
        <ul className={classNames({ 'profile-expanded': this.state.expanded })}>
          {/* <li>
            <button className="p-link">
              <i className="pi pi-fw pi-user" />
              <span>Account</span>
            </button>
          </li> */}
          <li>
            <NavLink to={'/role'}>
              <i className="pi pi-fw pi-user" />
              <span>{this.userData!.role_name}</span>
            </NavLink>
          </li>
          <li>
            <button className="p-link" onClick={this.logout}>
              <i className="pi pi-fw pi-power-off" onClick={this.logout}/>
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
