import React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { OverSlaSummaryContainer } from "../containers/oversla/OverSlaSummaryContainer";
import { MonitoringPodKepalaKurirContainer } from "../containers/monitoringPod/MonitoringPodKepalaKurirContainer";
import { MonitoringPodKepalaKurirDetailCourierContainer } from "../containers/monitoringPod/MonitoringPodKepalaKurirDetailCourierContainer";
import { MonitoringPodNewDetail } from "../containers/monitoringPod/MonitoringPodNewDetail";
import { MonitoringPodContainer } from "../containers/monitoringPod/MonitoringPodContainer";
import { MonitoringPodDetailContainer } from "../containers/monitoringPod/MonitoringPodDetailContainer";

import { CheckAwbHistoryContainer } from "../containers/check-awb-history/CheckAwbHistoryContainer";

import { ResiXxxContainer } from "../containers/resi-xxx/ResiXxxContainer";
import { ResiXxxKibanaContainer } from "../containers/resi-xxx-kibana/ResiXxxKibanaContainer";

import { ReturnInProgressContainer } from "../containers/return-in-progress/ReturnInProgressContainer";
import { ReturnInProgressKibanaContainer } from "../containers/return-in-progress-kibana/ReturnInProgressKibanaContainer";
import { ReturnInProgressSnowflakeContainer } from "../containers/return-in-progress-snowflake/ReturnInProgressSnowflakeContainer";

import { LayoutAdmin } from "../layouts/admin/LayoutAdmin";
import { AntNotDlvContainer } from "../containers/ant-not-dlv/AntNotDlvContainer";
import { AntNotDlvContainerRedshift } from "../containers/ant-not-dlv/redshift/AntNotDlvContainer";
import { OverSlaContainer } from "../containers/oversla/OverSlaContainer";
import { OverSlaKibanaContainer } from "../containers/oversla-kibana/OverSlaKibanaContainer";
import { OverSlaSnowflakeContainer } from "../containers/oversla-snowflake/OverSlaSnowflakeContainer";

import { AwbImageContainer } from "../containers/awb/AwbImageContainer";
import { AwbListContainer } from "../containers/awb-list/AwbListContainer";
import { UndeliveredContainer } from "../containers/undelivered/UndeliveredContainer";
import { UndeliveredKibanaContainer } from "../containers/undelivered-kibana/UndeliveredKibanaContainer";
import { MonitoringLineHaulMetabaseContainer } from "../containers/monitoringLineHaul/monitoringLineHaulMetabase/MonitoringLineHaulMetabaseContainer";
import { IdleawbContainer } from "../containers/idleawb/IdleawbContainer";
import { DeliveryPerformanceByDestinationCapacityContainer } from "../containers/deliveryPerformance/DeliveryPerformanceByDestinationContainer";
import { DeliveryCapacitySummaryContainer } from "../containers/capacity/DeliveryCapacitySummary";
import { AuditTrackingCodSummaryContainer } from "../containers/auditCod/AuditTrackingCodSummaryContainer";
import { AuditTrackingCodDetailCourierContainer } from "../containers/auditCod/AuditTrackingCodDetailCourierContainer";
import { ProduktivitasSummaryContainer } from "../containers/monitoringPodNew/produktivitas/ProduktivitasSummaryContainer";
import { OverSlaMetaBaseSummaryContainer } from "../containers/monitoringPodNew/overSlaMetaBase/OverSlaMetaBaseSummaryContainer";
import { ProductivitasMetaBaseSummaryContainer } from "../containers/monitoringPodNew/productivitasMetaBase/ProduktivitasSummaryContainer";
import { UpdateToleransiContainer } from "../containers/monitoringPodNew/updateToleransi/UpdateToleransiContainer";

import { TrackingSiteContainer } from "../containers/trackingSite/TrackingSiteContainer";
import { DeliveryCapacityPerwakilanContainer } from "../containers/capacity/DeliveryCapacityPerwakilan";
// import { ReportMonitoringPopContainer } from "../containers/pickup/reports/ReportMonitoringPopContainer";
// import { ReportOntimePerformancePickupContainer } from "../containers/pickup/reports/ReportOntimePerformancePickupContainer";
// import { ReportKpiPerformancePickupContainer } from "../containers/pickup/reports/ReportKpiPerformancePickupContainer";
import { CourierDeliverySummaryContainer } from "../containers/monitoringPod/courierDeliverySummary/CourierDeliverySummaryContainer";
import { AwbListKibanaContainer } from "../containers/awb-list-kibana/AwbListKibanaContainer";
import { AwbListSnowflakeContainer } from "../containers/awb-list-snowflake/AwbListSnowflakeContainer";
import { IdleAwbKibanaContainer } from "../containers/idleawb-kibana/IdleAwbKibanaContainer";
import { RedirectMonitoringPodKepalaKurirContainerSnowflake } from "../containers/monitoringPodNew/snowflake/RedirectMonitoringPodKepalaKurirContainerSnowflake";
// import { MonitoringPodKepalaKurirContainerNew } from "../containers/monitoringPodNew/MonitoringPodKepalaKurirContainer";
import { MonitoringPodKepalaKurirContainerSnowflake } from "../containers/monitoringPodNew/snowflake/MonitoringPodKepalaKurirContainerSnowflake";
import { MonitoringPodKepalaKurirDetailCourierContainerNew } from "../containers/monitoringPodNew/MonitoringPodKepalaKurirDetailCourierContainer";
import { MonitoringPodKepalaKurirDetailCourierContainerSnowflake } from "../containers/monitoringPodNew/snowflake/MonitoringPodKepalaKurirDetailCourierContainerSnowflake";
import { MonitoringPodDetailContainerNew } from "../containers/monitoringPodNew/MonitoringPodDetailContainerNew";
import { MonitoringPodDetailContainerSnowflake } from "../containers/monitoringPodNew/snowflake/MonitoringPodDetailContainerSnowflake";
import { RedirectMonitoringPodContainerSnowflake } from "../containers/monitoringPodNew/snowflake/RedirectMonitoringPodContainerSnowflake";
// import { MonitoringPodContainerNew } from "../containers/monitoringPodNew/MonitoringPodContainerNew";
import { MonitoringPodContainerSnowflake } from "../containers/monitoringPodNew/snowflake/MonitoringPodContainerSnowflake";
import { AnalyticCapacityContainer } from "../containers/analytic-capacity/AnalyticCapacityContainer";
import { ManifestPerwakilanListContainer } from "../containers/manifest-perwakilan/ManifestPerwakilanListContainer";
import { ManifestDestinationDistrictListContainer } from "../containers/manifest-perwakilan-dest/ManifestDestinationDistrictListContainer";
import { TargetPembawaanContainer } from "../containers/monitoringPodNew/targetPembawaan/TargetPembawaanContainer";
import { MonitoringUrgentContainer } from "../containers/monitoringUrgent/MonitoringUrgentContainer";
import { BreakdownROLastMileContainer } from "../containers/monitoringROBroken/BreakdownROLastMileContainer";
import { MonitoringBrokenContainer } from "../containers/monitoringROBroken/MonitoringBrokenContainer";
import { SummaryROLastMileContainer } from "../containers/monitoringROBroken/SummaryROLastMileContainer";
import { MonitoringROBrokenContainer } from "../containers/monitoringROBroken/MonitoringROBrokenContainer";
import { AwbStatusContainer } from "../containers/awbStatus/AwbStatusContainer";
import { MonitoringFMContainer } from "../containers/monitoringFM/MonitoringFMContainer";
import { MonitoringFMDetailContainer } from "../containers/monitoringFM/MonitoringFMDetailContainer";
import { UserPermissionContainer } from "../containers/settings/userPermission/UserPermissionContainer";
import {AccessHelper} from "../utils/AccessHelper";
import { MonitoringRestService } from '../services/MonitoringRestService';
import {DependencyContainer} from "../utils/DependencyInjection";

export class MonitoringRouter extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  constructor(props) {
    super(props);

    this.state = {
      permissionInitiated: false,
    };
  }

  appRoutes = [
    // {
    //   path: "/admin/monitoring/pod",
    //   component: MonitoringPodContainer,
    //   permissionName: "monitoring_pod",
    //   layout: true,
    // },
    // {
    //   path: "/admin/monitoring/pod/detail",
    //   component: MonitoringPodDetailContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    // {
    //   path: "/admin/monitoring-pod-palkur",
    //   component: MonitoringPodKepalaKurirContainer,
    //   permissionName: "monitoring_kepala_kurir",
    //   layout: true,
    // },
    // {
    //   path: "/admin/monitoring-pod-palkur/courier/detail",
    //   component: MonitoringPodKepalaKurirDetailCourierContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring-pod-new/detail",
      component: MonitoringPodNewDetail,
      permissionName: "",
      layout: true,
    },
    // {
    //   path: "/admin/monitoring/pod-new",
    //   component: MonitoringPodContainerNew,
    //   permissionName: "monitoring_pod",
    //   auth: true,
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/pod-new",
      component: RedirectMonitoringPodContainerSnowflake,
      permissionName: "monitoring_pod",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/pod-sf",
      component: MonitoringPodContainerSnowflake,
      permissionName: "monitoring_pod",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/pod-new/detail",
      component: MonitoringPodDetailContainerNew,
      permissionName: "",
      layout: true,
    },
    {
      path: "/admin/monitoring/pod-sf/detail",
      component: MonitoringPodDetailContainerSnowflake,
      permissionName: "",
      layout: true,
    },
    // {
    //   path: "/admin/monitoring-pod-new-palkur",
    //   component: MonitoringPodKepalaKurirContainerNew,
    //   permissionName: "monitoring_kepala_kurir",
    //   auth: true,
    //   layout: true,
    // },
    {
      path: "/admin/monitoring-pod-new-palkur",
      component: RedirectMonitoringPodKepalaKurirContainerSnowflake,
      permissionName: "monitoring_kepala_kurir",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-sf-palkur",
      component: MonitoringPodKepalaKurirContainerSnowflake,
      permissionName: "monitoring_kepala_kurir",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-fm",
      component: MonitoringFMContainer,
      permissionName: "monitoring_fm",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-fm/detail",
      component: MonitoringFMDetailContainer,
      permissionName: "monitoring_fm",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/check-history-resi",
      component: CheckAwbHistoryContainer,
      permissionName: "check_history_resi",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/auditor-tracking-cod",
      component: AuditTrackingCodSummaryContainer,
      permissionName: "audit_tracking_cod",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-tracking-cod/detail",
      component: AuditTrackingCodDetailCourierContainer,
      permissionName: "audit_tracking_cod",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-palkur/produktivitas",
      component: ProduktivitasSummaryContainer,
      permissionName: "produktivitas",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-palkur/over-sla",
      component: OverSlaMetaBaseSummaryContainer,
      permissionName: "metabase_over_sla",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-palkur/update-toleransi",
      component: UpdateToleransiContainer,
      permissionName: "produktivitas_toleransi",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-palkur/target-pembawaan",
      component: TargetPembawaanContainer,
      permissionName: "productivitas_carrier_target",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-new-palkur/courier/detail",
      component: MonitoringPodKepalaKurirDetailCourierContainerNew,
      permissionName: "",
      layout: true,
    },
    {
      path: "/admin/monitoring-pod-new-palkur/courier-sf/detail",
      component: MonitoringPodKepalaKurirDetailCourierContainerSnowflake,
      permissionName: "",
      layout: true,
    },
    // {
    //   path: "/admin/monitoring/awb-list",
    //   component: AwbListContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/awb-list-kibana",
      // component: () => <Redirect to={'/admin/monitoring/awb-list-sf'} />,
      component: AwbListKibanaContainer,
      permissionName: "lpb",
      auth: true,
      layout: true,
    },
    // {
    //   path: "/admin/monitoring/awb-list-sf",
    //   component: AwbListSnowflakeContainer,
    //   permissionName: "lpb",
    //   auth: true,
    //   layout: true,
    //   tag: ""
    // },
    // {
    //   path: "/admin/monitoring/undelivered",
    //   component: UndeliveredContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/undelivered-kibana",
      component: UndeliveredKibanaContainer,
      permissionName: "pengantaran_not_dlv",
      auth: true,
      layout: true,
    },
    // {
    //   path: "/admin/monitoring/idleawb",
    //   component: IdleawbContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/idleawb-Kibana",
      component: IdleAwbKibanaContainer,
      permissionName: "problem_resi_tak_bergerak",
      auth: true,
      layout: true,
    },
    // {
    //   path: "/admin/monitoring/oversla",
    //   component: OverSlaContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/oversla-kibana",
      component: () => <Redirect to={'/admin/monitoring/oversla-sf'} />,
      permissionName: "problem_over_sla",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/oversla-sf",
      component: OverSlaSnowflakeContainer,
      permissionName: "problem_over_sla",
      auth: true,
      layout: true,
      tag: "",
    },
    {
      path: "/admin/monitoring/ant-not-dlv",
      component: AntNotDlvContainer,
      permissionName: "pengantaran_process",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/manifest-perwakilan",
      component: ManifestPerwakilanListContainer,
      permissionName: "pengantaran_volume_representative",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/manifest-perwakilan-dest-district",
      component: ManifestDestinationDistrictListContainer,
      permissionName: "pengantaran_volume_district",
      auth: true,
      layout: true,
    },
    // {
    //   path: "/admin/monitoring/return-in-progress",
    //   component: ReturnInProgressContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/return-in-progress-kibana",
      component: () => <Redirect to={'/admin/monitoring/return-in-progress-sf'} />,
      // component: ReturnInProgressKibanaContainer,
      permissionName: "retur_in_process",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/return-in-progress-sf",
      component: ReturnInProgressSnowflakeContainer,
      permissionName: "retur_in_process",
      auth: true,
      layout: true,
      tag: ""
    },
    // {
    //   path: "/admin/monitoring/resi-xxx",
    //   component: ResiXxxContainer,
    //   permissionName: "",
    //   layout: true,
    // },
    {
      path: "/admin/monitoring/resi-xxx-kibana",
      component: ResiXxxKibanaContainer,
      permissionName: "problem_resi_xxx",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/pod/delivery-performance-by-destination",
      component: DeliveryPerformanceByDestinationCapacityContainer,
      permissionName: "pengantaran_peformance_dlv",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/pod/delivery-capacity-perwakilan",
      component: DeliveryCapacityPerwakilanContainer,
      permissionName: "pengantaran_representative",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/pod/delivery-capacity",
      component: DeliveryCapacitySummaryContainer,
      permissionName: "pengantaran_capacity",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/pod/courier-delivery-summary",
      component: CourierDeliverySummaryContainer,
      permissionName: "pengantaran_summary_dlv",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring/analytic-capacity",
      component: AnalyticCapacityContainer,
      permissionName: "pengantaran_analysis",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/masterdata/trackingsites",
      component: TrackingSiteContainer,
      permissionName: "masterdata_gerai",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/masterdata/awb-status",
      component: AwbStatusContainer,
      permissionName: "awb_status",
      auth: true,
      layout: true,
    },
    {
      path: "/monitor/trackingNote/showImage/:awb",
      component: AwbImageContainer,
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-line-haul-metabase",
      component: MonitoringLineHaulMetabaseContainer,
      permissionName: "monitoring_line_haul",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-urgent",
      component: MonitoringUrgentContainer,
      permissionName: "monitoring_urgent",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/breakdown-masa-ro-last-mile",
      component: BreakdownROLastMileContainer,
      permissionName: "breakdown_masa_ro_last_mile",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-paket-broken",
      component: MonitoringBrokenContainer,
      permissionName: "breakdown_paket_broken",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/summary-masa-ro-last-mile",
      component: SummaryROLastMileContainer,
      permissionName: "summary_masa_ro_last_mile",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/monitoring-masa-ro-broken",
      component: MonitoringROBrokenContainer,
      permissionName: "summary_paket_broken",
      auth: true,
      layout: true,
    },
    {
      path: "/admin/settings/user-permission",
      component: UserPermissionContainer,
      permissionName: "settings_role_access",
      auth: true,
      layout: true,
    },
  ]

  getRolePermissions() {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
    this.monitoringRestService.post('/role-permission/list', {
      role_id: String(userData.role_id),
    })
    .subscribe(
      response => {
        const accessMenu = response.menu.filter((menu: { is_access: boolean, menu: string }) => menu.is_access)
        const permissions = accessMenu.map((menu: { is_access: boolean, menu: string }) => menu.menu)

        userData.role_permissions = permissions;
        localStorage.setItem("userData", JSON.stringify(userData));
      },
      err => {
        console.log(err)
        this.setState({ permissionInitiated: true })
      },
      () => {
        this.setState({ permissionInitiated: true })
      },
    );
  }
    
    componentDidMount() {
      const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
      if (userData) {
        this.getRolePermissions()
      }
    }

  render() {
    return (
      <Switch>
        {this.appRoutes.map((route, i) => {
          return (
            <Route
              key={`route_${i}`}
              exact
              path={route.path}
              render={(props) => {
                const permitted = route.auth ? AccessHelper.isPermitted(route.permissionName) : true;
                return (
                  permitted ? (                
                    route.layout ? (
                      <LayoutAdmin {...(route.tag && { tag: route.tag })}>
                        <route.component {...props} />
                      </LayoutAdmin>
                      ) : (
                        <route.component {...props} />
                      )
                    ) : (
                      <Redirect to={'/'}/>
                    )
                )
              }}
            />
          )
        })}      
      </Switch>
    );
  }
};

