import DropdownPaginateEmployee from "./DropdownPaginateEmployee";

class EmployeeDropdown extends DropdownPaginateEmployee {
  constructor(props: any) {
    super(props);

    const myState = {
      key: props.keyData !== undefined ? props.keyData : "employeeName",
      limit: 10,
      page: 1,
      sortBy: props.sortBy !== undefined ? props.sortBy : "",
      sortDir: props.sortDir !== undefined ? props.sortDir : "asc",
      filters: props.filters !== undefined ? props.filters : {},
      url: "productivities/list-filter-sigesit",
      placeholder:
        props.placeholder !== undefined ? props.placeholder : "Pilih Sigesit",
      template: props.template !== undefined ? props.template : "{{value}}",
      selectAll: props.selectAll !== undefined ? props.selectAll : false,
      selectAllLabel:
        props.selectAllLabel !== undefined ? props.selectAllLabel : "Semua",
    };

    Object.assign(this.state, myState);
  }
}

export default EmployeeDropdown;
