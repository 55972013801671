import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { RefObject } from 'react';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import _ from 'lodash';
import { Chart } from 'primereact/chart';
import { Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Flex } from 'reflexbox';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataSource } from '../../models/DataSource';
import { TabView, TabPanel } from 'primereact/components/tabview/TabView';
import { DateHelper } from '../../utils/DateHelper';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';

export interface IAnalyticOverloadContainerValue {
  startDate?: Date;
  endDate?: Date;
  function?: 'lm' | 'fm' | 'transit';
  type?: 'over-branch' | 'over-mp';
  city?: string;
  provice?: string;
  [key: string]: any;
}

@observer
export class AnalyticOverloadContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  dateRange: any = null;
  initialFormValues: IAnalyticOverloadContainerValue = {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    function: 'lm',
    type: 'over-branch',
    city: '',
    province: ''
  };

  data;
  dt;
  overloadChartData;
  @observable overloadChartOptions: any = {
    maintainAspectRatio: false,
  };
  growl;

  dataSource = new DataSource<any>();

  refFormik: RefObject<Formik> = React.createRef();

  optionType = [
    {
      label: 'Overload Tempat',
      value: 'over-branch'
    }, {
      label: 'Overload MP',
      value: 'over-mp'
    }
  ];

  optionFunction = [
    {
      label: 'First Mile',
      value: 'fm'
    },
    {
      label: 'Last Mile',
      value: 'lm'
    },
    {
      label: 'Transit',
      value: 'transit'
    }
  ];

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 0,
      showLoading: false,
      showDialog: false,
    };

    this.export = this.export.bind(this);
  }

  public async loadData() {
    const filterParams = this.refFormik.current!.state.values;
    const fParams = Object.assign({}, filterParams);
    this.setState({ showLoading: true, showDialog: true });

    if (fParams.date) fParams.date = DateHelper.formatLocalDateForQueryParam(fParams.date);

    this.resetData();
    this.dataSource.loading = true;

    const url = filterParams.type === 'over-branch' ? 'capacity/report/branch-overload' : 'capacity/report/branch-mp-overload';

    this.monitoringRestService
      .post(url, fParams)
      .subscribe(
        response => {
          this.data = response && response.length ? response.slice(0, 10) : [];
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
          this.generateOverloadChartData();
        },
        error => {
          this.dataSource.loading = false;
          this.setState({ showLoading: false, showDialog: false });
        },
        () => {
          this.dataSource.loading = false;
          this.setState({ showLoading: false, showDialog: false });
        },
      );
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  generateOverloadChartData() {
    const isBranch = this.refFormik.current!.state.values.type === 'over-branch';
    
    const dataBranch = _.map(this.data, function (d) { return d.name });
    const dataCapacity = _.map(this.data, function (d) { return isBranch ? d.branchCapacity : d.opsCapacity });
    const dataActualVolume = _.map(this.data, function (d) { return d.actualVolume });
    const dataoverloadPercentage = _.map(this.data, function (d) { return d.overloadPercentage });

    this.overloadChartData = {
      labels: dataBranch,
      datasets: [
        {
          type: 'line',
          label: '% Overload',
          borderColor: '#42A5F5',
          borderWidth: 2,
          fill: false,
          data: dataoverloadPercentage,
          yAxisID: 'y-axis-2'
        }, {
          type: 'bar',
          label: 'Capacity',
          data: dataCapacity,
          fill: true,
          backgroundColor: '#3498db',
          yAxisID: 'y-axis-1'
        },
        {
          type: 'bar',
          label: 'Volume',
          data: dataActualVolume,
          fill: true,
          backgroundColor: '#fab561',
          yAxisID: 'y-axis-1'
        },
      ]
    };

    this.overloadChartOptions = {
      maintainAspectRatio: false,
      hoverMode: 'index',
      stacked: false,
      scales: {
        yAxes: [{
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
        }, {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
        }]
        
      }
    }
  }

  resetData() {
    this.overloadChartData = {};
    this.overloadChartOptions = {
      maintainAspectRatio: false,
    };
    this.dataSource.setData([]);
    this.dataSource.setTotal(0);
  }

  export() {
    this.dt.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  render() {
    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showDialog: false })}>
          Sedang memuat data, mohon jangan tinggalkan halaman ini
        </Dialog>
        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />

        <div className="row">
          <div className="card col-md-12">
            <div>
              <Formik
                ref={this.refFormik}
                enableReinitialize
                initialValues={this.initialFormValues}
                onSubmit={() => { }}>
                {formikProps => (
                  <Flex
                    wrap
                    w={1}>
                    <Flex column>
                      <div className="form-group">
                        <label className="col-md-4">Periode</label>
                        <div className="col-md-8">
                          <Flex column>
                            <Calendar
                              name="startDate"
                              readOnlyInput={true}
                              dateFormat="dd-mm-yy"
                              showWeek={true}
                              maxDate={formikProps.values.endDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange('startDate')}
                              value={formikProps.values.startDate}
                            />
                            <div className="mt-2 mb-2 text-center">s/d</div>
                            <Calendar
                              name="endDate"
                              readOnlyInput={true}
                              dateFormat="dd-mm-yy"
                              showWeek={true}
                              minDate={formikProps.values.startDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange('endDate')}
                              value={formikProps.values.endDate}
                            />
                          </Flex>
                        </div>
                      </div>
                    </Flex>
                    <Flex column>
                      <div className="form-group">
                        <label className="col-md-4">Fungsi</label>
                        <div className="col-md-8">
                          <Dropdown
                            options={this.optionFunction}
                            onChange={formikProps.handleChange('function')}
                            value={formikProps.values.function}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4">Type</label>
                        <div className="col-md-8">
                          <Dropdown
                            options={this.optionType}
                            onChange={formikProps.handleChange('type')}
                            value={formikProps.values.type}
                          />
                        </div>
                      </div>
                    </Flex>
                    <Flex column>
                      <div className="form-group">
                        <label className="col-md-4">Provinsi</label>
                        <div className="col-md-8">
                          <InputText
                            name="province"
                            type="text"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.province}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4">Kota</label>
                        <div className="col-md-8">
                          <InputText
                            name="city"
                            type="text"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.city}
                          />
                        </div>
                      </div>
                    </Flex>
                  </Flex>
                )}
              </Formik>
            </div>
            <div>
              <div>
                <Button
                  className="p-button-success mr-2"
                  label="Tampilkan"
                  icon={(this.state.showLoading) ? "pi pi-spin pi-spinner" : ""}
                  disabled={(this.state.showLoading)}
                  onClick={() => this.loadData()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
              <TabPanel header="Chart">
                <h3>Top 10 Branch Overload</h3>
                <Chart type="bar" data={this.overloadChartData} options={this.overloadChartOptions} height="600px"/>
                <br />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    );
  }
}
