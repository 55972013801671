import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import React, { RefObject } from 'react';
import { IMonitoringFMFilterContainerValue } from './IMonitoringFMFilterContainerValue';
import { Panel } from 'primereact/panel';
import DropdownBranch from "./../../components/dropdown/DropdownBranch";
import DropdownCity from "./../../components/dropdown/DropdownCity";

export class MonitoringFMFilterContainer extends React.Component<any, any> {
  initialFormValues: IMonitoringFMFilterContainerValue = {
    date: new Date(),
    city: null,
    branch: null,
  };
  refFormik: RefObject<Formik> = React.createRef();


  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };

  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 p-4">
              <div className="flex items-center">
                <label className="flex-none w-48">Tanggal</label>
                <Calendar
                  className="flex-1"
                  name="date"
                  readOnlyInput={true}
                  dateFormat="yy-mm-dd"
                  showWeek={true}
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  value={formikProps.values.date}
                />
              </div>
              <div className="flex items-center">
                <label className="flex-none w-48">Kota</label>
                <div className="flex-1">
                  <DropdownCity
                    name='city'
                    onChange={formikProps.handleChange("city")}
                    value={formikProps.values.city}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <label className="flex-none w-48">Cabang</label>
                <div className="flex-1">
                  <DropdownBranch
                    name='branch'
                    onChange={formikProps.handleChange("branch")}
                    value={formikProps.values.branch}
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
