import "../../css/login.css";

import Crypto from "crypto";
import {Button} from "primereact/button";
import React from "react";
import {Redirect} from "react-router-dom";
import {Flex} from "reflexbox";

import {MonitoringRestService} from "../../services/MonitoringRestService";
import {DependencyContainer} from "../../utils/DependencyInjection";

interface ILoginState {
  userName: string;
  password: string;
  [key: string]: any;
}
export class LoginContainer extends React.Component<{}, ILoginState> {
  loginData;
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      redirect: false,
      loading: false,
    };

    this.handleUser = this.handleUser.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUser(e) {
    this.setState({userName: e.target.value});
  }

  handlePassword(e) {
    this.setState({password: e.target.value});
  }

  handleSubmit() {
    if (this.state.userName.length > 0 && this.state.password.length > 0) {
      this.login();
    } else {
      return alert("Login gagal, username dan password tidak boleh kosong!");
    }
  }

  login() {
    this.setState({loading: true});

    if (this.state.userName === "*branchmode*") {
      localStorage.setItem("userData", JSON.stringify(this.loginData));
      localStorage.removeItem("branchCode");
      this.setState({redirect: true});
      this.setState({loading: false});
      return;
    }

    const hashPassword = Crypto.createHash("md5")
      .update(this.state.password)
      .digest("hex");

    const loginParams = Object.assign({
      userName: this.state.userName,
      password: hashPassword,
    });

    const setFailedLogin = () => {
      alert("Login gagal, username dan password salah!");
      this.setState({loading: false});
    };

    this.monitoringRestService.post("auth/login-jwt", loginParams).subscribe(
      (response) => {
        console.log(response)
        this.loginData = response;
      },
      (error) => {
        alert("Login gagal, username dan password salah!");
        this.setState({loading: false});
      },
      () => {
        try {
          if (
            !this.loginData ||
            !this.loginData.login_time ||
            !this.loginData.hash_token
          ) {
            setFailedLogin();
            return;
          }

          const hashValue = `${hashPassword}:${this.loginData.login_time}:${this.state.userName}:sicepat`;
          const hashSecret = Crypto.createHash("md5")
            .update(hashValue)
            .digest("hex");

          if (hashSecret !== this.loginData.hash_token) {
            setFailedLogin();
            return;
          }

          console.log(hashSecret);

          if (this.loginData.user_id === 1) {
            this.loginData.role_name = "Superadmin";
          }

          localStorage.setItem("userData", JSON.stringify(this.loginData));
          localStorage.removeItem("branchCode");
          this.setState({redirect: true});
        } finally {
          this.setState({loading: false});
        }
      },
    );
  }

  render() {
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    if (this.state.redirect || localStorage.getItem("userData")) { 
        return <Redirect to={"/role"} />;
        // return <Redirect to={"/admin/dashboard"} />;
    }

    return (
      <div className='login-container'>
        <div className='login-box'>
          <img className='login-logo' src='/assets/logo-sicepat.png' />
          <Flex column className='login-form'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                name='userName'
                placeholder='Username'
                value={this.state.userName}
                onChange={this.handleUser}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                name='password'
                placeholder='Password'
                value={this.state.password}
                onChange={this.handlePassword}
              />
            </div>
            <div className='text-center'>
              <Button
                className='p-button-danger login-btn'
                label='Masuk'
                icon={this.state.loading ? "pi pi-spin pi-spinner" : ""}
                disabled={this.state.loading}
                onClick={this.handleSubmit}
              />
            </div>
          </Flex>
        </div>
      </div>
    );
  }
}
