import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";
import { TabView, TabPanel } from 'primereact/tabview';

import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringUrgentFilterContainer } from "./MonitoringUrgentFilterContainer";
import moment from "moment";
import { IMonitoringUrgentFilterContainerValue } from "./IMonitoringUrgentFilterContainerValue";
import { Growl } from "primereact/growl";

@observer
export class MonitoringUrgentContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl;
  formFilters: IMonitoringUrgentFilterContainerValue;
  refFilter: RefObject<
  MonitoringUrgentFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      dashboardId: 2808,
      showNote: false,
      dashboards: [
        {
          label: "Total Urgent",
          value: 2808,
          url: "",
          token: "",
        },
        {
          label: "Total Urgent 1",
          value: 2809,
          url: "",
          token: "",
        },
        {
          label: "Total Urgent 2",
          value: 2810,
          url: "",
          token: "",
        },
        {
          label: "Total Urgent 3",
          value: 2811,
          url: "",
          token: "",
        },
      ],
      isHeadQuarter: localStorage.getItem("branchCode") === "3601001"
    };
  }

  
  
  
  
  componentDidMount() {
    this.loadData();
  }
  
  onFilterData() {
    console.log("isHQ", this.state.isHeadQuarter)
    const dashboardList = this.state.dashboards.map((data) => {
      return {
        ...data,
        url: "",
        token: "",
      }
    })
    this.setState({
      dashboards: dashboardList
    })
    this.loadData();
  }

  async loadData(dashboardId = this.state.dashboardId) {
    const formValue = this.refFilter.current!.formValues
    let startDate;
    let endDate;
    let branchCode;
    let partner;
    let layanan;
    if (formValue) {
      startDate = formValue.periodeDate[0];
      endDate = formValue.periodeDate[1] ? formValue.periodeDate[1] : formValue.periodeDate[0];
      if (!this.state.isHeadQuarter) {
        branchCode = [localStorage.getItem("branchCode")]
      } else {
        branchCode = formValue.branchCode ? formValue.branchCode.map((data) => data.value) : [];
      }
      partner = formValue.partner ? formValue.partner.map((data) => data.value) : [];
      layanan = formValue.service ? formValue.service.map((data) => data.value) : [];
    }
    const params = {
      ...(startDate ? { start_date: moment(startDate).format("YYYY-MM-DD") } : {}),
      ...(endDate ? { end_date: moment(endDate).format("YYYY-MM-DD") } : {}),
      branch_code: branchCode,
      partner: partner,
      layanan: layanan,
      status_sla: formValue.statusSla,
    }

    this.monitoringRestService
    .post(`embed-post/${dashboardId}`, params)
    .subscribe(
      (response) => {
        const dashboardList = this.state.dashboards.map((dashboard) => {
          if (dashboard.value === dashboardId) {
            return {
              ...dashboard,
              url: response.url,
              token: response.token,
            }
          } else {
            return dashboard
          }
        })
        this.setState({
          dashboards: dashboardList
        })
      },
      (e) => {
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
      },
      () => {}
    );
  }

  onChangeDashboard = (value) => {
    const selectedDashboard = this.state.dashboards[value.index];
    this.setState({
      activeIndex: value.index,
      dashboardId: selectedDashboard.value
    });
    if (selectedDashboard.url === "" || selectedDashboard.token === "") {
      this.loadData(selectedDashboard.value);
    }
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Monitoring Paket Urgent</h1>
            <MonitoringUrgentFilterContainer ref={this.refFilter} isHeadQuerter={this.state.isHeadQuarter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.onFilterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onChangeDashboard(e)} renderActiveOnly={false}>
            {this.state.dashboards.map((dashboard) => (
              <TabPanel key={dashboard.value} header={dashboard.label}>
                <>
                  {dashboard.url && dashboard.token && (
                    <iframe
                      src={dashboard.url + dashboard.token}
                      width={"100%"}
                      height={750}
                      allowTransparency
                    />
                  )}
                </>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    );
  }
}
