import { snakeCase } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import qs from 'qs';
import React, { RefObject } from 'react';
import { RouterProps } from 'react-router';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { observable, toJS } from 'mobx';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { Button } from 'primereact/button';
import { IAuditTrackingFilterContainerValue } from './IAuditTrackingCodFilterContainerValue';
// import { flex } from '../../utils/Css';

export interface IAuditTrackingCodDetailCourierContainerQuery {
  startDate: string,
  endDate: string,
  title: string,
  status: string,
  nostt: string,
  branch_code: string,
  branch_name:string
}

@observer
export class AuditTrackingCodDetailCourierContainer extends React.Component<RouterProps> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable data;
  tableExportData;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  pageTitle1: string;
  pageTitle2: string;
  queryParameters: IAuditTrackingCodDetailCourierContainerQuery;
  
  constructor(props, context) {
    super(props, context);
    this.export = this.export.bind(this);
  }

  componentWillMount() {
    this.queryParameters = qs.parse(
      this.props.history.location.search.substr(1),
    ) as any;
    

    // this.pageTitle1 = `${this.queryParameters.courierName} - ${this.queryParameters.label}`;

    // const pageTitle2Elements: string[] = [];
    // if (this.queryParameters.filters.date) {
    //   const formattedDate = moment(this.queryParameters.filters.date).format(
    //     'DD-MM-YYYY',
    //   );
    //   pageTitle2Elements.push(formattedDate);
    // }

    // this.pageTitle2 = pageTitle2Elements.join('/');

    this.loadData();
  }

  loadData() {
    this.dataSource.loading = true;
    console.log('aaa')
    this.monitoringRestService
      .get(`https://spartan1.sicepat.com/monitoring-tracking-cod/detail/sf?nostt=${this.queryParameters.nostt}&status=${this.queryParameters.status}`)
      .subscribe(
        response => {
          this.data = response;
          const resultData = this.data.map((d) => {
            return {
              tanggal_status: d.TANGGAL_STATUS,
              tglfoto: d.TGLFOTO,
              nostt: d.NOSTT,
              codnilai: d.CODNILAI,
              awb_status_name: d.AWB_STATUS_NAME,
              history_date: d.HISTORY_DATE,
              awb_note: d.AWB_NOTE,
              branch_name: d.BRANCH_NAME,
              branch_code: d.BRANCH_CODE,
              reason_name: d.REASON_NAME,
              note_internal: d.NOTE_INTERNAL,
              nik_kurir: d.NIK_KURIR,
              nama_kurir: d.NAMA_KURIR,
            }
          });
          this.dataSource.setData(resultData);
          this.dataSource.setTotal(resultData.length);
        },
        null,
        () => {
          this.dataSource.loading = false;
        },
      );

  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  // columnAwbNumber = (rowData, column: ColumnProps) => {
    
  //   let number = Number(column.rowIndex) +1
  //   return number
    
  // }

  export() {
    this.tableExportData.exportCSV();
  }

  render() {
    var header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = 'Total data: ' + this.dataSource.total;
    
    return (
      <div className="card">
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <h3>
          {this.pageTitle1}
        </h3>
        <hr />
        <div className="row">
          <label className="col-md-2 control-label">Tracking Site | {this.queryParameters.title}</label>
          {/* <div className="col-md-10">{this.queryParameters.branchName}</div> */}
        </div>
        <DataTable
          emptyMessage={'Tidak ada data untuk ditampilkan.'}
          scrollable={true}
          scrollHeight="400px"
          className="mt-6"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          header={header} ref={(el) => { this.tableExportData = el; }}
          footer={footer}>
          
          <Column
            field="history_date"
            header="Tanggal"
            sortable={true}
            style={{ width: '180px' }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              if (value) {
                value = moment(value).utc().format('DD-MM-YYYY HH:mm');
              }

              return value;
            }}
          />
          <Column
            field="note_internal"
            header="Remark"
            sortable={false}
          style={{ width: '120px' }}
          />
            <Column
            field="nik_kurir"
            header="NIK Kurir"
            sortable={true}
            style={{ width: '120px' }}
          />
            <Column
            field="nama_kurir"
            header="Nama Kurir"
            sortable={true}
            style={{ width: '120px' }}
          />
        </DataTable>
      </div>
    );
  }
}
