import React, {Component, PureComponent} from "react";
import AsyncPaginate from "react-select-async-paginate";
import _ from "lodash";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {DependencyContainer} from "../../../utils/DependencyInjection";
import {AttendanceRestApi} from "../../../services/AttendanceRestApi";

const DropdownPaginateProvinceStyle = {
  marginTop: "5px",
  fontSize: "14px",
};

@observer
class DropdownPaginateProvince extends PureComponent<any, any> {
  @observable autoFocus = false;
  asyncPaginate;
  @observable data;
  @observable more;
  @observable filters: any[] = [];

  attendanceRestApi = DependencyContainer.get(AttendanceRestApi);

  constructor(props: any) {
    super(props);
    this.state = {
      limit: 10,
      page: 1,
      filters: {},
      sortBy: "",
      sortDir: "",
      url: "",
      placeholder:
        props.placeholder !== undefined ? props.placeholder : "Pilih",
      template: "",
      selectAllLabel: "Semua",
      key: "",
      selectAll: false,
      className: "",
    };

    this.asyncPaginate = React.createRef();
    this.loadOptions = this.loadOptions.bind(this);
  }
  setCustomFilters = (filters: any): void => {
    // this.setState({
    //     filters:filters
    // })
    this.filters = filters;
  };

  componentDidUpdate(): void {
    //   this.setState({
    //     filters:this.props.filters
    // })
    this.filters = this.props.filters;
  }

  loadData = () => {
    this.loadOptions("", [], {page: 1, state: this.state, name: ""});
    // this.forceUpdate();
  };

  async loadOptions(search, prevOptions, {page, state, name}) {
    const optionValue: any = [];
    const primaryKey = state.key;
    state.filters.value = search;
    let branch = "";
    let sortByName = "";
    if (this.props.name === "province1") {
      sortByName = "province";
    } else if (this.props.name === "branchCode") {
      sortByName = "siteCode";
    } else if (this.props.name === "city1") {
      sortByName = "city";
    } else {
      sortByName = this.props.name;
    }

    if (this.props.branchName["data"]) {
      branch = this.props.branchName["data"]["show"];
    }
    const data = {
      limit: state.limit,
      page,
      sortBy: sortByName,
      sortDir: state.sortDir,
      province: this.props.province
        ? this.props.province.value
        : this.props.filters.parameter === "province"
        ? state.filters.value
        : "",
      name: branch
        ? branch
        : this.props.branchName
        ? this.props.branchName["label"]
        : "",
      city: this.props.city
        ? this.props.city.value
        : this.props.filters.parameter === "city"
        ? state.filters.value
        : "",
      siteCode:
        this.props.name === "branchCode"
          ? this.props.filters.value
          : this.props.name === "siteCode"
          ? search
          : "",
    };
    let hasMore;

    const regexTemplate = /{{(.+?)}}/g;
    const templateKey = state.template.match(regexTemplate);
    const keyList: any = [];
    templateKey.map((val, index) => {
      keyList.push(val.replace("}}", "").replace("{{", ""));
    });

    if (page === 1 && state.selectAll) {
      optionValue.push({
        label: state.selectAllLabel,
        value: 0,
      });
    }

    const options: any = {};
    const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
      if (userData) {
        options.headers = {
          Authorization:
            `Bearer ${userData.bearer_token}`,
            "x-permission-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlSWQiOiIxIiwiYnJhbmNoSWQiOiIxMjEiLCJpYXQiOjE1NzM5MjQ4NzN9.KaAaArPO2deT7pUEYqnt54ZBq5opc-R8Th6bOWJN7ZM",
        };
      }
    // const headers = {
    //   Authorization:
    //     "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IndlYiIsInVzZXJJZCI6IjE1IiwidXNlcm5hbWUiOiJhZHJ5IiwiZW1haWwiOiJhZHJ5QHNpY2VwYXQuY29tIiwiZGlzcGxheU5hbWUiOiJBZHJ5IEFuZHJlYXMgUHJhdGhhbWEiLCJlbXBsb3llZUlkIjoiMjk4MSIsImlhdCI6MTU3MzkyNDg3MCwiZXhwIjoxNTc0MDExMjcwfQ.QTCK1f2UwmA4BZ56Po4UgxiuB3MXHaPBAMTe_WFYemA",
    //   "x-permission-token":
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlSWQiOiIxIiwiYnJhbmNoSWQiOiIxMjEiLCJpYXQiOjE1NzM5MjQ4NzN9.KaAaArPO2deT7pUEYqnt54ZBq5opc-R8Th6bOWJN7ZM",
    // };
    // options.headers = headers;

    await this.attendanceRestApi.axios
      .post(state.url, data, options)
      .then((response) => {
        this.data = response;
        hasMore =
          response.data.paging.totalData > prevOptions.length + data.limit;

        response.data.data.map((val) => {
          let label = state.template;
          templateKey.map((item2, key) => {
            label = label.replace(item2, val[keyList[key]]);
          });
          optionValue.push({
            label,
            value: label,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });

    //  let myFirstPromise = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     // console.log(this.data);

    //     resolve(this.data); // Yay! Everything went well!
    //   }, 50);
    // });

    // myFirstPromise.then((successMessage) => {
    //   console.log("Yay! " + successMessage);
    // });

    return {
      options: optionValue,
      hasMore,
      additional: {
        page: page + 1,
        state,
        name,
      },
    };
  }

  focus = () => {
    // this.asyncPaginate.current.focus();
  };

  render() {
    const {onChange, id} = this.props;
    const {className} = this.state;
    return (
      <div style={DropdownPaginateProvinceStyle} className='w-100'>
        <AsyncPaginate
          {...this.props}
          loadOptions={this.loadOptions}
          onChange={onChange}
          isSearchable={true}
          ref={this.asyncPaginate}
          isClearable={true}
          id={id !== "undefined" ? id : ""}
          isLoading={true}
          // cacheOptions
          value={this.props.value}
          className={className}
          placeholder={this.state.placeholder}
          additional={{
            name: "",
            page: 1,
            state: this.state,
          }}
        />
      </div>
    );
  }
}

export default DropdownPaginateProvince;
