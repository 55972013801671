import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";
import _ from "lodash";

import qs from "qs";
import { DataSource } from "../../../models/DataSource";
import { DataTableFilterService } from "../../../services/DataTableFilterService";
import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import {
  ICourierDeliverySummaryFilterContainerValue,
  CourierDeliverySummaryFilterContainer,
} from "./CourierDeliverySummaryFilterContainer";
import { AwbDetailModalComponent } from "../../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../../utils/DateHelper";

export interface ICourierDeliverySummaryContainerQuery {
  filters: ICourierDeliverySummaryFilterContainerValue;
}

@observer
export class CourierDeliverySummaryContainer extends React.Component<
  RouterProps,
  any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  startDate: moment.Moment;
  endDate: moment.Moment;
  dataSource = new DataSource<any>();
  refFilter: RefObject<CourierDeliverySummaryFilterContainer> =
    React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      startDate: null,
      endDate: null,
      dlvFields: [],
    };

    this.exportCsv = this.exportCsv.bind(this);
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? (qs.parse(this.props.history.location.search.substr(1)) as any)
      : ({} as any);

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }
  }

  processRawData(rawData) {

    if (!rawData || !rawData.length || rawData.length <= 0) {
      return [];
    }

    let datas = _(rawData)
      .groupBy((g) => `${g.branchId}#${g.courierId}`)
      .map((value, key) => {
        const fData = value[0];
        const obj = {
          courierId: fData.courierId,
          courierNik: fData.courierNik,
          courierName: fData.courierName,
          titleName: fData.titleName,
          trackingSiteId: fData.trackingSiteId,
          trackingSiteName: fData.trackingSiteName,
          trackingSiteCity: fData.trackingSiteCity,
          // branchId: fData.branchId,
          // branchName: fData.branchName,
          // branchRegion: fData.branchRegion,
        };

        let runningDate = moment(this.startDate);
        let i = 0;
        let total = 0;
        while (this.endDate.isSameOrAfter(runningDate, "day")) {
          const f = value.find((x) =>
            moment(x.trackingDate).isSame(runningDate, "day")
          );
          const dlv = f ? f.totalDelivered : 0;
          obj[`totalDelivered${i}`] = dlv;
          total = total + dlv;
          runningDate = runningDate.add("day", 1);
          i++;
        }

        obj["totalDelivered"] = total;
        obj["dailyAverage"] = Math.round(total / (i + 1));

        return obj;
      })
      .value();

    console.log(datas);
    datas = _.orderBy(datas, ["courierNik"], ["asc"]);
    return datas;
  }

  loadData(filterParams: any = null) {
    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);
    let startDate;
    let endDate;
    fParams.periodeDate.map((val, key) => {
      if (key === 0) {
        startDate = moment(val).format('YYYY-MM-DD');
      } else {
        endDate = moment(val)
          .add(0, 'd')
          .format('YYYY-MM-DD');
      }
    });
    fParams.startDate = moment(startDate);
    fParams.endDate = endDate === "Invalid date" ?moment(startDate):moment(endDate);
    this.startDate = moment(fParams.startDate);
    this.endDate = moment(fParams.endDate);
    
    this.setState({ startDate: this.startDate, endDate: this.endDate });

    fParams.startDate = DateHelper.formatLocalDateForQueryParam(this.startDate);
    fParams.endDate = DateHelper.formatLocalDateForQueryParam(this.endDate);
    // fParams.branchName = (fParams.branchName) ? fParams.branchName.name! : null;
    // fParams.serviceType = (fParams.serviceType) ? fParams.serviceType.name! : null;

    if (fParams.branchName) { 
      fParams.branchName = fParams.branchName['value']
    }
    this.dataSource.loading = true;
    this.monitoringRestService
      .post("monitoring-pod/courier-delivery-summary", fParams)
      .subscribe(
        (response) => {
          this.data = this.processRawData(response);
          this.populateDataSource();
        },
        (err) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  populateDataSource() {
    if (this.data && this.data.length) {
      this.dataSource.setData(this.data);
      this.dataSource.setTotal(this.data.length);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  exportCsv() {
    this.dt.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    const cols: any[] = [];
    cols.push(
      <Column
        field="courierNik"
        header="NIK"
        filter={true}
        filterMatchMode="contains"
        sortable={true}
        style={{ width: "130px", verticalAlign: "text-top" }}
      />
    );

    cols.push(
      <Column
        field="courierName"
        header="Nama"
        filter={true}
        filterMatchMode="contains"
        sortable={true}
        style={{ width: "145px", verticalAlign: "text-top" }}
      />
    );

    cols.push(
      <Column
        field="titleName"
        header="Jabatan"
        filter={true}
        filterMatchMode="contains"
        sortable={true}
        style={{ width: "145px", verticalAlign: "text-top" }}
      />
    );

    cols.push(
      <Column
        field="trackingSiteName"
        header="Cabang"
        filter={true}
        filterMatchMode="contains"
        sortable={true}
        style={{ width: "145px", verticalAlign: "text-top" }}
      />
    );

    cols.push(
      <Column
        field="trackingSiteCity"
        header="Kota"
        filter={true}
        filterMatchMode="contains"
        sortable={true}
        style={{ width: "145px", verticalAlign: "text-top" }}
      />
    );

    let runningDate = moment(this.state.startDate);
    const endDate = moment(this.state.endDate);
    let i = 0;
    while (endDate.isSameOrAfter(runningDate, "day")) {
      cols.push(
        <Column
          field={`totalDelivered${i}`}
          header={moment(runningDate).format("DD MMM")}
          sortable={true}
          body={(rowData, column: ColumnProps) => {
            const colValue = rowData[column.field!];
            const colInt = colValue ? parseInt(colValue) : 0;
            const fmtValue = colValue ? colInt.toLocaleString() : "-";
            return <span>{fmtValue}</span>;
          }}
          style={{ width: "145px", verticalAlign: "text-top" }}
        />
      );

      runningDate = runningDate.add("day", 1);
      i++;
    }

    cols.push(
      <Column
        field="totalDelivered"
        header="Total"
        sortable={true}
        body={(rowData, column: ColumnProps) => {
          const colValue = rowData[column.field!];
          const colInt = colValue ? parseInt(colValue) : 0;
          const fmtValue = colValue ? colInt.toLocaleString() : "-";
          return <span>{fmtValue}</span>;
        }}
        style={{ width: "145px", verticalAlign: "text-top" }}
      />
    );

    cols.push(
      <Column
        field="dailyAverage"
        header="Average"
        sortable={true}
        body={(rowData, column: ColumnProps) => {
          const colValue = rowData[column.field!];
          const colInt = colValue ? parseInt(colValue) : 0;
          const fmtValue = colValue ? colInt.toLocaleString() : "-";
          return <span>{fmtValue}</span>;
        }}
        style={{ width: "145px", verticalAlign: "text-top" }}
      />
    );

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <CourierDeliverySummaryFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.loadData();
                }}
              />
              <Button
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="CSV"
                onClick={this.exportCsv}
              ></Button>
              {/* <Button
                className="p-button-primary"
                label="Export Excel"
                icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : ""}
                disabled={(this.state.exportExcelLoading)}
                onClick={() => this.downloadCsv()}
              /> */}
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={(el) => (this.dt = el)}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            paginator={true}
            rows={50}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            footer={footer}
          >
            {cols}
          </DataTable>
        </div>
      </div>
    );
  }
}
