import React, {Component} from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";

import {AuthRouter} from "./routers/AuthRouter";
import {DashboardRouter} from "./routers/DashboardRouter";
import {MonitoringRouter} from "./routers/MonitoringRouter";
import sentryInit from "./sentry-config";
import * as Sentry from "@sentry/react";
import packageJson from "../package.json";

class App extends Component {
  render() {
    sentryInit();

    const version = localStorage.getItem("version") || null;
    if (version === null || version < packageJson.version) {
      localStorage.setItem("version", packageJson.version);
      window.location.reload();
    }
    return (
      <Sentry.ErrorBoundary fallback={<div>An error has occured</div>} showDialog={false}>
        <Router>
          <Route exact path='/' render={() => <Redirect to='/auth/login' />} />
          <AuthRouter />
          <DashboardRouter />
          <MonitoringRouter />
        </Router>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
