import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Flex } from 'reflexbox';
import { IMonitoringPodFilterContainerValueSnowflake } from './IMonitoringPodFilterContainerValueSnowflake';
import { Panel } from 'primereact/panel';
import { UserRoleHelper } from '../../../utils/UserRoleHelper';
import { LAYANAN_OPTIONS } from "../../../constants";
import DropdownProvince from "./../../../components/dropdown/DropdownProvince";
import DropdownCity from "./../../../components/dropdown/DropdownCity";
import DropdownPartner from "./../../../components/dropdown/DropdownPartner";
import DropdownRepresentative from "./../../../components/dropdown/DropdownRepresentative";
import DropdownService from "./../../../components/dropdown/DropdownService";

export class MonitoringPodFilterContainerSnowflake extends React.Component<any, any> {
  initialFormValues: IMonitoringPodFilterContainerValueSnowflake = {
    layanan: null,
    cod: '',
    date: new Date(),
    city: null,
    province: null,
    perwakilan: null,
    pengirim: '',
    partner: null,
    packageValue: '',
  };
  refFormik: RefObject<Formik> = React.createRef();

  optionsCod = [
    {
      label: 'ALL',
      value: '',
    },
    {
      label: 'COD',
      value: 'Y',
    },
    {
      label: 'NON COD',
      value: 'N',
    },
  ];

  private userRole;
  private showSensitiveFilter = false;

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };

    this.userRole = UserRoleHelper.getCurrentUserRole();
    this.showSensitiveFilter = UserRoleHelper.hasRole(this.userRole, ["Developer Mode", "Developer", "Control Tower"]);
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 p-4">
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Tanggal</label>
                  <Calendar
                    className="flex-1"
                    name="date"
                    readOnlyInput={true}
                    dateFormat="yy-mm-dd"
                    showWeek={true}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.date}
                  />
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Kota</label>
                  <div className="flex-1">
                    <DropdownCity
                      name='city'
                      onChange={formikProps.handleChange("city")}
                      value={formikProps.values.city}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Province</label>
                  <div className="flex-1">
                    <DropdownProvince
                      name='province'
                      onChange={formikProps.handleChange("province")}
                      value={formikProps.values.province}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Layanan</label>
                  <div className="flex-1">
                    <DropdownService
                      name='layanan'
                      onChange={formikProps.handleChange("layanan")}
                      value={formikProps.values.layanan}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">COD</label>
                  <Dropdown
                    className="flex-1"
                    options={this.optionsCod}
                    onChange={formikProps.handleChange('cod')}
                    value={formikProps.values.cod}
                  />
                </div>
                {this.showSensitiveFilter &&
                  <div className="flex items-center">
                    <label className="flex-none w-48">Parcel Value</label>
                    <InputText
                      className="flex-1"
                      name="packageValue"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.packageValue}
                      />
                  </div>
                }
              </div>

              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Pengirim</label>
                  <InputText
                    className="flex-1"
                    name="pengirim"
                    type="text"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.pengirim}
                  />
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Partner</label>
                  <div className="flex-1">
                    <DropdownPartner
                      name='partner'
                      onChange={formikProps.handleChange("partner")}
                      value={formikProps.values.partner}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Perwakilan</label>
                  <div className="flex-1">
                    <DropdownRepresentative
                      name='perwakilan'
                      onChange={formikProps.handleChange("perwakilan")}
                      value={formikProps.values.perwakilan}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
