import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import React, { RefObject } from 'react';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import _ from 'lodash';
import { Chart } from 'primereact/chart';
import { Formik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Flex } from 'reflexbox';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataSource } from '../../models/DataSource';
import { TabView, TabPanel } from 'primereact/components/tabview/TabView';
import { DateHelper } from '../../utils/DateHelper';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';

export interface IAnalyticComparationContainerValue {
  period: 'daily' | 'weekly' | 'monthly';
  branchName?: string;
  branchCode?: string;
  province?: string;
  city?: string;
  date1: Date;
  date2: Date;
  monthly1?: Date;
  monthly2?: Date;
  [key: string]: any;
}

@observer
export class AnalyticComparationContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  dateRange: any = null;
  initialFormValues: IAnalyticComparationContainerValue = {
    date1: moment().startOf('month').toDate(),
    date2: moment().startOf('month').toDate(),
    monthly1: moment().toDate(),
    monthly2: moment().toDate(),
    period: 'daily',
    branchName: '',
    branchCode: '',
    province: '',
    city: '',
  };
  
  data;
  dt;
  growl;

  dataSource = new DataSource<any>();

  refFormik: RefObject<Formik> = React.createRef();

  optionPeriod = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
  ]

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 0,
      showLoading: false,
      showDialog: false,
      showTable: false,
    };

    this.export = this.export.bind(this);
  }

  public async loadData() {
    const filterParams = this.refFormik.current!.state.values;
    if (filterParams.date1) filterParams.date1 = DateHelper.formatLocalDateForQueryParam(filterParams.date1);
    if (filterParams.date2) filterParams.date2 = DateHelper.formatLocalDateForQueryParam(filterParams.date2);

    const fParams = {
      timePeriod: filterParams.period,
      period: {
        date1: filterParams.period === 'monthly' ? DateHelper.formatLocalDateTime(filterParams.monthly1, 'YYYY-MM') : filterParams.date1,
        date2: filterParams.period === 'monthly' ? DateHelper.formatLocalDateTime(filterParams.monthly2, 'YYYY-MM') : filterParams.date2
      },
      branchName: filterParams.branchName,
      branchCode: filterParams.branchCode,
      province: filterParams.province,
      city: filterParams.city
    }

    this.setState({ showLoading: true, showDialog: true });
    this.resetData();
    this.monitoringRestService
      .post('capacity/report/comparation', fParams)
      .subscribe(
        response => {
          this.data = response;
        },
        error => {
          this.dataSource.loading = false;
          this.setState({ showLoading: false, showDialog: false });
        },
        () => {
          this.dataSource.loading = false;
          this.setState({ showLoading: false, showDialog: false });
        },
      );
  }

  resetData() { }

  calculateDiff(a, b, isPerc = false) {
    const diff = b - a;

    if (isPerc) {
      if (b == 0) return 0.00;
      const perc = diff / b * 100;
      return perc.toFixed(2);
    }

    return diff.toLocaleString();
  }

  export() {
    this.dt.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  render() {
    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showDialog: false })}>
          Sedang memuat data, mohon jangan tinggalkan halaman ini
        </Dialog>
        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />

        <div className="row">
          <div className="card col-md-12">
            <div>
              <Formik
                ref={this.refFormik}
                enableReinitialize
                initialValues={this.initialFormValues}
                onSubmit={() => { }}>
                {formikProps => (
                  <Flex
                    wrap
                    w={1}>
                    <Flex column>
                      <div className="form-group">
                        <label className="col-md-4">Period</label>
                        <div className="col-md-8">
                          <Dropdown
                            options={this.optionPeriod}
                            onChange={formikProps.handleChange('period')}
                            value={formikProps.values.period}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4">Tanggal</label>
                        <div className="col-md-8">
                          <Flex column>
                          {formikProps.values.period === 'monthly' ?
                          (
                            <>
                              <Calendar
                                name="monthly1"
                                dateFormat="mm-yy"
                                showWeek={false}
                                view="month"
                                yearNavigator
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange('monthly1')}
                                value={formikProps.values.monthly1}
                                yearRange="2010:2030"
                              />
                              <div className="mt-2 mb-2 text-center"> Dan </div>
                              <Calendar
                                name="monthly2"
                                dateFormat="mm-yy"
                                showWeek={false}
                                view="month"
                                yearNavigator
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange('monthly2')}
                                value={formikProps.values.monthly2}
                                yearRange="2010:2030"
                              />
                            </>
                          ) : (
                            <>
                              <Calendar
                                name="date1"
                                readOnlyInput={true}
                                dateFormat="dd-mm-yy"
                                showWeek={true}
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange('date1')}
                                value={formikProps.values.date1}
                              />
                              <div className="mt-2 mb-2 text-center"> Dan </div>
                              <Calendar
                                name="date2"
                                readOnlyInput={true}
                                dateFormat="dd-mm-yy"
                                showWeek={true}
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange('date2')}
                                value={formikProps.values.date2}
                              />
                            </>
                          )}
                          </Flex>
                        </div>
                      </div>
                      {formikProps.values.period === 'weekly' && <small className="col-md-12 mb-4">Catatan: tanggal akan dikurangi 7 hari sebagai acuan weekly.</small>}
                    </Flex>
                    <Flex column>
                      <div className="form-group">
                        <label className="col-md-4">Nama Cabang</label>
                        <div className="col-md-8">
                          <InputText
                            name="branchName"
                            type="text"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.branchName}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4">Kode Cabang</label>
                        <div className="col-md-8">
                          <InputText
                            name="branchCode"
                            type="text"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.branchCode}
                          />
                        </div>
                      </div>
                    </Flex>
                    <Flex column>
                      <div className="form-group">
                        <label className="col-md-4">Provinsi</label>
                        <div className="col-md-8">
                          <InputText
                            name="province"
                            type="text"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.province}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="col-md-4">Kota</label>
                        <div className="col-md-8">
                          <InputText
                            name="city"
                            type="text"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.city}
                          />
                        </div>
                      </div>
                    </Flex>
                  </Flex>
                )}
              </Formik>
            </div>
            <div>
              <div>
                <Button
                  className="p-button-success mr-2"
                  label="Tampilkan"
                  icon={(this.state.showLoading) ? "pi pi-spin pi-spinner" : ""}
                  disabled={(this.state.showLoading)}
                  onClick={() => this.loadData()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
              <TabPanel header="Table">
                {
                  this.data && 
                  (
                    <div>
                      <table style={{borderCollapse: 'collapse', width: '100%'}}>
                        <thead>
                          <tr style={{border: '1px solid #000000', backgroundColor: '#dddddd'}}>
                            <th style={{border: '1px solid #000000', padding: '5px'}} colSpan={10}>FM</th>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <th style={{border: '1px solid #000000', padding: '5px', width: '150px'}}>Cabang / Gerai</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Tempat FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Orang FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Vol FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit FM Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver FM Prob</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>MGM FM {this.data.firstMile.dateA.trackingDate}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.branchCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.opsCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.volume.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.sigesitPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.sigesitProbation.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.driverPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateA.driverProbation.toLocaleString()}</td>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>MGM FM {this.data.firstMile.dateB.trackingDate}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.branchCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.opsCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.volume.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.sigesitPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.sigesitProbation.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.driverPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.firstMile.dateB.driverProbation.toLocaleString()}</td>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>SELISIH VOLUME</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.branchCapacity, this.data.firstMile.dateB.branchCapacity)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.opsCapacity, this.data.firstMile.dateB.opsCapacity)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.volume, this.data.firstMile.dateB.volume)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.sigesitPermanent, this.data.firstMile.dateB.sigesitPermanent)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.sigesitProbation, this.data.firstMile.dateB.sigesitProbation)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.driverPermanent, this.data.firstMile.dateB.driverPermanent)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.driverProbation, this.data.firstMile.dateB.driverProbation)}</td>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>SELISIH %</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.branchCapacity, this.data.firstMile.dateB.branchCapacity, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.opsCapacity, this.data.firstMile.dateB.opsCapacity, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.volume, this.data.firstMile.dateB.volume, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.sigesitPermanent, this.data.firstMile.dateB.sigesitPermanent, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.sigesitProbation, this.data.firstMile.dateB.sigesitProbation, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.driverPermanent, this.data.firstMile.dateB.driverPermanent, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.firstMile.dateA.driverProbation, this.data.firstMile.dateB.driverProbation, true)}</td>
                          </tr>
                        </tbody>   
                      </table>
                      <br/>
                      <table style={{borderCollapse: 'collapse', width: '100%'}}>
                        <thead>
                          <tr style={{border: '1px solid #000000', backgroundColor: '#dddddd'}}>
                            <th style={{border: '1px solid #000000', padding: '5px'}} colSpan={14}>LM</th>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <th style={{border: '1px solid #000000', padding: '5px', width: '150px'}}>Cabang / Gerai</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Tempat LM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Orang LM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>VOL.Mon.POD</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit LM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver LM</th>
                            <th style={{ border: '1px solid #000000', padding: '5px' }}>Sigesit COD</th>
                            <th style={{ border: '1px solid #000000', padding: '5px' }}>Sigesit SDS </th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit Cargo</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit LM Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver LM Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>COD Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>SDS Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Cargo Prob</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>MGM LM {this.data.lastMile.dateA.trackingDate}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.branchCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.opsCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.volume.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.sigesitPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.driverPermanent.toLocaleString()}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.data.lastMile.dateA.codPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.sdsPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.cargoPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.sigesitProbation.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.driverProbation.toLocaleString()}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.data.lastMile.dateA.codProbation.toLocaleString()}</td>
                            
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.sdsProbation.toLocaleString()}</td>
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateA.cargoProbation.toLocaleString()}</td>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>MGM LM {this.data.lastMile.dateB.trackingDate}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.branchCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.opsCapacity.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.volume.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.sigesitPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.driverPermanent.toLocaleString()}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.data.lastMile.dateB.codPermanent.toLocaleString()}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.data.lastMile.dateB.sdsPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.cargoPermanent.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.sigesitProbation.toLocaleString()}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.driverProbation.toLocaleString()}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.data.lastMile.dateB.codProbation.toLocaleString()}</td>
                            
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.sdsProbation.toLocaleString()}</td>
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.data.lastMile.dateB.cargoProbation.toLocaleString()}</td>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>SELISIH VOLUME</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.branchCapacity, this.data.lastMile.dateB.branchCapacity)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.opsCapacity, this.data.lastMile.dateB.opsCapacity)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.volume, this.data.lastMile.dateB.volume)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.sigesitPermanent, this.data.lastMile.dateB.sigesitPermanent)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.driverPermanent, this.data.lastMile.dateB.driverPermanent)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.codPermanent, this.data.lastMile.dateB.codPermanent)}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.calculateDiff(this.data.lastMile.dateA.sdsPermanent, this.data.lastMile.dateB.sdsPermanent)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.cargoPermanent,this.data.lastMile.dateB.cargoPermanent)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.sigesitProbation, this.data.lastMile.dateB.sigesitProbation)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.driverProbation, this.data.lastMile.dateB.driverProbation)}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.calculateDiff(this.data.lastMile.dateA.codProbation, this.data.lastMile.dateB.codProbation)}</td>
                            
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.sdsProbation,this.data.lastMile.dateB.sdsProbation)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.cargoProbation,this.data.lastMile.dateB.cargoProbation)}</td>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>SELISIH %</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.branchCapacity, this.data.lastMile.dateB.branchCapacity, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.opsCapacity, this.data.lastMile.dateB.opsCapacity, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.volume, this.data.lastMile.dateB.volume, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.sigesitPermanent, this.data.lastMile.dateB.sigesitPermanent, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.driverPermanent, this.data.lastMile.dateB.driverPermanent, true)}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.calculateDiff(this.data.lastMile.dateA.codPermanent, this.data.lastMile.dateB.codPermanent, true)}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.calculateDiff(this.data.lastMile.dateA.sdsPermanent, this.data.lastMile.dateB.sdsPermanent, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.cargoPermanent,this.data.lastMile.dateB.cargoPermanent,true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.sigesitProbation, this.data.lastMile.dateB.sigesitProbation, true)}</td>
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.driverProbation, this.data.lastMile.dateB.driverProbation, true)}</td>
                            <td style={{ border: '1px solid #000000', padding: '5px' }}>{this.calculateDiff(this.data.lastMile.dateA.codProbation, this.data.lastMile.dateB.codProbation, true)}</td>
                            
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.sdsProbation,this.data.lastMile.dateB.sdsProbation, true)}</td>
                            
                            <td style={{border: '1px solid #000000', padding: '5px'}}>{this.calculateDiff(this.data.lastMile.dateA.cargoProbation,this.data.lastMile.dateB.cargoProbation,true)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }

                {
                  !this.data && (
                    <div>
                      <table style={{borderCollapse: 'collapse', width: '100%'}}>
                        <thead>
                          <tr style={{border: '1px solid #000000', backgroundColor: '#dddddd'}}>
                            <th style={{border: '1px solid #000000', padding: '5px'}} colSpan={10}>FM</th>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Cabang / Gerai</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Tempat FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Orang FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Vol FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit FM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit FM Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver FM</th>
                            <th style={{ border: '1px solid #000000', padding: '5px' }}>Driver FM Prob</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{border: '1px solid #000000', padding: '5px', textAlign: 'center'}} colSpan={8}>
                              <span>Tidak ada data untuk ditampilkan.</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br/>
                      <table style={{borderCollapse: 'collapse', width: '100%'}}>
                        <thead>
                          <tr style={{border: '1px solid #000000', backgroundColor: '#dddddd'}}>
                            <th style={{border: '1px solid #000000', padding: '5px'}} colSpan={14}>LM</th>
                          </tr>
                          <tr style={{border: '1px solid #000000'}}>
                            <th style={{border: '1px solid #000000', padding: '5px', width: '150px'}}>Cabang / Gerai</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Tempat LM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Kap. Orang LM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>VOL.Mon.POD</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit LM</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver LM</th>
                            <th style={{ border: '1px solid #000000', padding: '5px' }}>Sigesit COD</th>
                            <th style={{ border: '1px solid #000000', padding: '5px' }}>Sigesit SDS </th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit Cargo</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Sigesit LM Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Driver LM Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>COD Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>SDS Prob</th>
                            <th style={{border: '1px solid #000000', padding: '5px'}}>Cargo Prob</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{border: '1px solid #000000', padding: '5px', textAlign: 'center'}} colSpan={14}>
                              <span>Tidak ada data untuk ditampilkan.</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    );
  }
}
