import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from "reflexbox";

import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { DeliveryCapacitySummaryFilterContainer } from "./DeliveryCapacitySummaryFilterContainer";
import _ from "lodash";
import moment from "moment";
import { IDeliveryCapacitySummaryFilterContainerValue } from "./IDeliveryCapacitySummaryFilterContainerValue";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Growl } from "primereact/growl";
import { TrackingSiteEditFormModal } from "../trackingSite/TrackingSiteEditFormModal";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { IMonitoringPodKepalaKurirDetailCourierContainerQuery } from "../monitoringPod/MonitoringPodKepalaKurirDetailCourierContainer";
import { IMonitoringPodFilterContainerValueNew } from '../monitoringPodNew/IMonitoringPodFilterContainerValueNew';
import { IMonitoringPodDetailContainerQueryNew } from "../monitoringPodNew/IMonitoringPodDetailContainerQueryNew"
import { toJS } from "mobx";
@observer
export class DeliveryCapacitySummaryContainer extends React.Component<
any,
any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  tableExportData;
  formFilters: IDeliveryCapacitySummaryFilterContainerValue;
  formFiltersDetail: IMonitoringPodFilterContainerValueNew;
  dataSource = new DataSource<any>();
  refFilter: RefObject<
    DeliveryCapacitySummaryFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      expandedRows: null,
      displayUpdateDialog: false,
      selectedTrackingSiteId: null,
      footerTotal: {}
    };

    this.columnActionTemplate = this.columnActionTemplate.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
  }

  async loadData() {
    this.formFilters = this.refFilter.current!.formValues;

    const sDateMoment = await moment(new Date(this.formFilters.date), 'yyyy-mm-dd');
    const eDateMoment = await moment(new Date(this.formFilters.endDate), 'yyyy-mm-dd');
    const getDif = await eDateMoment.diff(sDateMoment, 'days')

    if (getDif > 29) {
      alert('Range hari tidak boleh lebih dari 30 hari, Silahkan pilih range hari dan coba lagi!')
    }

    this.formFilters.date = moment(this.formFilters.date).format("YYYY-MM-DD");
    this.formFilters.endDate = moment(this.formFilters.endDate).format("YYYY-MM-DD");
    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    this.monitoringRestService
      .post("capacity/pod/branch", this.formFilters)
      .subscribe(
        (response) => {
          this.processData(response);
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  loadRowData(trackingSiteId): Observable<any> {
    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.date = moment(this.formFilters.date).format("YYYY-MM-DD");

    const filters = Object.assign({}, this.formFilters, {
      trackingSiteId: trackingSiteId,
    });

    return this.monitoringRestService.post("capacity/pod/branch", filters).pipe(
      tap((response) => {
        if (response && response.length && response.length > 0) {
          const r = response[0];
          let dIdx = this.data.findIndex(
            (x) => x.trackingSiteId === trackingSiteId
          );
          if (dIdx >= 0) {
            const d = this.data[dIdx];
            this.data[dIdx] = Object.assign({}, d, r);
            this.processData(this.data);
          }
        }
      })
    );
  }

  processData(data) {
    for (const dt of data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource()
    }

    this.data = data;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);
    this.calculateTotals();
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

  calculateTotals() {
    const footerTotal = {
      dlvBranchCapacity: _.sumBy(this.data, function (o: any) {
        return o.dlvBranchCapacity;
      }),
      dlvOpsCapacityBase: _.sumBy(this.data, function (o: any) {
        return o.dlvOpsCapacityBase;
      }),
      dlvOpsCapacity: _.sumBy(this.data, function (o: any) {
        return o.dlvOpsCapacity;
      }),
      dlvOpsCapacityByCheckin: _.sumBy(this.data, function (o: any) {
        return o.dlvOpsCapacityByCheckin;
      }),
      AVGInbound: _.sumBy(this.data, function (o: any) {
        return o.AVGInbound;
      }),
      AVGTotalInbound: _.sumBy(this.data, function (o: any) {
        return o.AVGTotalInbound;
      }),
      AVGTotalTobeDelivered: _.sumBy(this.data, function (o: any) {
        return o.AVGTotalTobeDelivered;
      }),
      AVGTotalDelivering: _.sumBy(this.data, function (o: any) {
        return o.AVGTotalDelivering;
      }),
      AVGTotalDelivered: _.sumBy(this.data, function (o: any) {
        return o.AVGTotalDelivered;
      }),
      employeeAntarPermanent: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarPermanent;
      }),
      employeeAntarPermanentCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarPermanentCheckin;
      }),
      employeeAntarProbation: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarProbation;
      }),
      employeeAntarProbationCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarProbationCheckin;
      }),

      employeeAntarDriverPermanent: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarDriverPermanent;
      }),
      employeeAntarDriverPermanentCheckin: _.sumBy(this.data, function (
        o: any
      ) {
        return o.employeeAntarDriverPermanentCheckin;
      }),
      employeeAntarDriverProbation: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarDriverProbation;
      }),
      employeeAntarDriverProbationCheckin: _.sumBy(this.data, function (
        o: any
      ) {
        return o.employeeAntarDriverProbationCheckin;
      }),

      employeeAntarCodPermanent: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCodPermanent;
      }),
      employeeAntarCodPermanentCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCodPermanentCheckin;
      }),
      employeeAntarCodProbation: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCodProbation;
      }),
      employeeAntarCodProbationCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCodProbationCheckin;
      }),

      employeeAntarSdsPermanent: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarSdsPermanent;
      }),
      employeeAntarSdsPermanentCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarSdsPermanentCheckin;
      }),
      employeeAntarSdsProbation: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarSdsProbation;
      }),
      employeeAntarSdsProbationCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarSdsProbationCheckin;
      }),

      employeeAntarCargoPermanent: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCargoPermanent;
      }),
      employeeAntarCargoPermanentCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCargoPermanentCheckin;
      }),
      employeeAntarCargoProbation: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCargoProbation;
      }),
      employeeAntarCargoProbationCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarCargoProbationCheckin;
      }),

      employeeAntarFreelance: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarFreelance;
      }),
      employeeAntarFreelanceCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeAntarFreelanceCheckin;
      }),

      employeeTotal: _.sumBy(this.data, function (o: any) {
        return o.employeeTotal;
      }),
      employeeTotalCheckin: _.sumBy(this.data, function (o: any) {
        return o.employeeTotalCheckin;
      }),
    };

    this.setState({ footerTotal: footerTotal });
  }

  onUpdateClick(data) {
    this.setState({
      displayUpdateDialog: true,
      selectedTrackingSiteId: data["trackingSiteId"],
    });
  }

  onUpdateSuccess(): Observable<any> {
    return Observable.create((observer) => {
      const trackingSiteId = this.state.selectedTrackingSiteId;
      this.loadRowData(trackingSiteId)
        .subscribe(
          () => {
            this.setState({
              displayUpdateDialog: false,
              selectedTrackingSiteId: null,
            });
            observer.next();
          },
          (error) => {
            observer.error(error);
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  onCancelUpdate() {
    this.setState({
      displayUpdateDialog: false,
      selectedTrackingSiteId: null,
    });
  }

  async exportCsv() {
    await this.tableExportData.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  columnPercentBody = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ];
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[0];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[1];
    } else if (colValue > 90 && colValue <= 110) {
      backgroundSet = background[2];
    } else if (colValue > 110 && colValue <= 120) {
      backgroundSet = background[3];
    } else {
      backgroundSet = background[4];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnPercentBodyOps = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ]
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[4];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[3];
    } else if (colValue > 90 && colValue <= 105) {
      backgroundSet = background[2];
    } else if (colValue > 105 && colValue <= 110) {
      backgroundSet = background[1];
    } else {
      backgroundSet = background[0];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnActionTemplate(rowData) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onUpdateClick(rowData)}
        ></Button>
      </div>
    );
  }

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });
    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
      this.growl.show(msg);
    }
  }

  columnCourierDetailLinkBody = (rowData, column: ColumnProps, podData) => {
    
    const formFilters = Object.assign({}, this.formFilters, { siteCode: podData.trackingSiteCode });
    return (
      <Link
        to={{
          pathname: '/admin/monitoring-pod-new/detail',
          search: qs.stringify({
            courierId: rowData['courierId'],
            courierName: rowData['courierName'],
            courierNik: rowData['courierNik'],
            branchName: podData.trackingSite,
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: podData.trackingSiteCode,
            destination:rowData.destination,
          } as IMonitoringPodKepalaKurirDetailCourierContainerQuery),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }

  rowExpansionTemplate(data) {
    const podData = (_.find(this.data, (d: any) => { return d.trackingSiteCode === data.trackingSiteCode }));
    console.log(podData);
    let totalPaketMasuk = 0
    let totalResi = 0
    let totalCoverage = 0
    if (podData.dataCoverage) {
      podData.dataCoverage.map(data => {
        totalPaketMasuk += Number(data.paketMasuk)
        totalResi += Number(data.totalResi)
      })
      totalCoverage = podData.dataCoverage.length
    }
    
    
    const datasource = podData.courierDataSource;
    // var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = <div style={{ textAlign: 'left' }}>
      <Button style={{ width: '80px' }} type="button" icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : "pi pi-external-link"} iconPos="left" label="CSV" onClick={() => this.exportCourierCsv(podData)}></Button>
      {/* <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}>Total Kurir: {(datasource) ? datasource.total : 0}  |  Total antar: {podData.courierDataAwbCount}  |  Total Terkirim: {podData.courierDataAwbDlvCount}  |  Total Problem: {podData.courierDataAwbProblemCount}  |  Total NoRep: {podData.courierDataAwbNoRepCount}</span></div>; */}
      <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}> Total Kecamatan: {totalCoverage}  |  Total Paket Masuk: {totalPaketMasuk}  |  Total Resi: {totalResi}</span></div>;
    return (
      <div className="p-grid p-fluid" style={{ padding: '1em 1em 1em 1em' }}>
        <div className="p-col-12 p-md-12">
          <DataTable
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="200px"
            className="mt-4"
            value={datasource.data}
            loading={datasource.loading}
            footer={footer}
            ref={(el) => { podData.exportTableData = el; }}>
            <Column
              field="siteCode"
              header="Kode Cabang"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="branchName"
              header="Nama Cabang"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '200px' }}
            />
            <Column
              field="tujuanKota"
              header="Kota/Kab"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="destination"
              header="Kode Tujuan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="tujuanKecamatan"
              header="Kecamatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="isCovered"
              header="Coverage Kecamatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="paketMasuk"
              header="Paket Masuk"
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="totalResi"
              header="Total Resi"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            {/* <Column
              field="problem"
              header="Problem"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="norep"
              header="Non Report"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="dlvPerc"
              header="%DLV"
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="podPerc"
              header="%POD"
              sortable={true}
              style={{ width: '60px' }}
            /> */}
          </DataTable>
        </div>
      </div>
    );
  }

  loadCourierData(podData) {
    const formFilters = Object.assign({}, this.formFilters, { siteCode: podData.trackingSiteCode });
    const datasource = podData.courierDataSource;
    return new Promise((resolve, reject) => {
      this.dataSource.loading = true;
      this.monitoringRestService
        .post('capacity/pod/branch/coverage', formFilters)
        .subscribe(
          response => {
            
            datasource.setData(response);
            datasource.setTotal(response.length);
            podData.courierDataLoaded = true;
            podData.courierDataAwbCount = _.sumBy(response, function (o: any) { return o.ant; });
            podData.courierDataAwbDlvCount = _.sumBy(response, function (o: any) { return o.dlv; });
            podData.courierDataAwbProblemCount = _.sumBy(response, function (o: any) { return o.problem; });
            podData.courierDataAwbNoRepCount = _.sumBy(response, function (o: any) { return o.norep; });
            podData.dataCoverage = response
            resolve(response);
          },
          err => {
            reject(err);
            this.dataSource.loading = false;
          },
          () => {
            this.dataSource.loading = false;
          },
        );
    });
  }

  async onRowToggle(e) {
    // this.setState({ expandedRows: e.data });
    // console.log(this.data)
    const promises: any[] = [];
    if (e.data) {
      for (var key in e.data) {
        // check also if property is not inherited from prototype
        if (e.data.hasOwnProperty(key)) {
          var value = e.data[key];
          if (value) {
            const podData = _.find(this.data, (d) => d.trackingSite === key);
            // console.log(podData)
            if (podData && !podData.courierDataLoaded) {
              promises.push(this.loadCourierData(podData));
              // await this.loadCourierData(podData);
            }
          }
        }
      }
    }

    await Promise.all(promises);
    this.setState({ expandedRows: e.data });
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";

    return (
      <Link
        to={{ 
          pathname: '/admin/monitoring/pod-new/detail',
          search: qs.stringify({
            field: 'inbound',
            label: 'Paket Masuk',
            filters: this.formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringPodDetailContainerQueryNew),
        }}
        target="_blank">
        {fmtValue}
      </Link>
    );
  }


  render() {
    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            header={"Totals:" + this.dataSource.total.toLocaleString()}
            colSpan={9}
          />
          <Column
            header={
              this.state.footerTotal.dlvBranchCapacity
                ? this.state.footerTotal.dlvBranchCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.dlvOpsCapacity
                ? this.state.footerTotal.dlvOpsCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.dlvOpsCapacityByCheckin
                ? this.state.footerTotal.dlvOpsCapacityByCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.AVGInbound
                ? this.state.footerTotal.AVGInbound.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.AVGTotalInbound
                ? this.state.footerTotal.AVGTotalInbound.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.AVGTotalTobeDelivered
                ? this.state.footerTotal.AVGTotalTobeDelivered.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.AVGTotalDelivering
                ? this.state.footerTotal.AVGTotalDelivering.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.AVGTotalDelivered
                ? this.state.footerTotal.AVGTotalDelivered.toLocaleString()
                : ""
            }
          />
          <Column header="" colSpan={8} />
          <Column
            header={
              this.state.footerTotal.employeeTotal
                ? this.state.footerTotal.employeeTotal.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeTotalCheckin
                ? this.state.footerTotal.employeeTotalCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarPermanent
                ? this.state.footerTotal.employeeAntarPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarPermanentCheckin
                ? this.state.footerTotal.employeeAntarPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarProbation
                ? this.state.footerTotal.employeeAntarProbation.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarProbationCheckin
                ? this.state.footerTotal.employeeAntarProbationCheckin.toLocaleString()
                : ""
            }
          />

          <Column
            header={
              this.state.footerTotal.employeeAntarDriverPermanent
                ? this.state.footerTotal.employeeAntarDriverPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarDriverPermanentCheckin
                ? this.state.footerTotal.employeeAntarDriverPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarDriverProbation
                ? this.state.footerTotal.employeeAntarDriverProbation.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarDriverProbationCheckin
                ? this.state.footerTotal.employeeAntarDriverProbationCheckin.toLocaleString()
                : ""
            }
          />

          <Column
            header={
              this.state.footerTotal.employeeAntarCodPermanent
                ? this.state.footerTotal.employeeAntarCodPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarCodPermanentCheckin
                ? this.state.footerTotal.employeeAntarCodPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarCodProbation
                ? this.state.footerTotal.employeeAntarCodProbation.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarCodProbationCheckin
                ? this.state.footerTotal.employeeAntarCodProbationCheckin.toLocaleString()
                : ""
            }
          />

<Column
            header={
              this.state.footerTotal.employeeAntarSdsPermanent
                ? this.state.footerTotal.employeeAntarSdsPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarSdsPermanentCheckin
                ? this.state.footerTotal.employeeAntarSdsPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarSdsProbation
                ? this.state.footerTotal.employeeAntarSdsProbation.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarSdsProbationCheckin
                ? this.state.footerTotal.employeeAntarSdsProbationCheckin.toLocaleString()
                : ""
            }
          />

<Column
            header={
              this.state.footerTotal.employeeAntarCargoPermanent
                ? this.state.footerTotal.employeeAntarCargoPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarCargoPermanentCheckin
                ? this.state.footerTotal.employeeAntarCargoPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarCargoProbation
                ? this.state.footerTotal.employeeAntarCargoProbation.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarCargoProbationCheckin
                ? this.state.footerTotal.employeeAntarCargoProbationCheckin.toLocaleString()
                : ""
            }
          />

          <Column
            header={
              this.state.footerTotal.employeeAntarFreelance
                ? this.state.footerTotal.employeeAntarFreelance.toLocaleString()
                : ""
            }
          />
          <Column
            header={
              this.state.footerTotal.employeeAntarFreelanceCheckin
                ? this.state.footerTotal.employeeAntarFreelanceCheckin.toLocaleString()
                : ""
            }
          />
        </Row>
        <Row>
          <Column header="" rowSpan={2} />
          <Column header="" rowSpan={2} />
          <Column
            header="Province"
            field="province"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Kota"
            field="city"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Kecamatan"
            field="district"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Cabang"
            field="trackingSite"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Kode Cabang"
            field="trackingSiteCode"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Tipe Cabang"
            field="siteType"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Active"
            field="isActiveLabel"
            rowSpan={2}
            filter={false}
            filterMatchMode="contains"
            sortable={true}
          />

          <Column
            header="DLV Cap."
            field="dlvBranchCapacity"
            rowSpan={2}
            sortable={true}
          />
          <Column header="DLV OPS Cap." colSpan={2} />
          {/* <Column
            header="Kapasitas Orang."
            field="dlvOpsCapacity"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Kapasitas Orang (Absen)"
            field="dlvOpsCapacityByCheckin"
            rowSpan={2}
            sortable={true}
          /> */}
          <Column
            header="Paket Masuk"
            field="AVGInbound"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Total Resi"
            field="AVGTotalInbound"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Harus Diantar"
            field="AVGTotalTobeDelivered"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Sedang Antar"
            field="AVGTotalDelivering"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="DLV"
            field="AVGTotalDelivered"
            rowSpan={2}
            sortable={true}
          />

          <Column header="Over/Under Cap (Total Resi)" colSpan={2} />
          <Column header="Over/Under Cap (Paket Masuk)" colSpan={2} />
          <Column header="Over/Under Ops" colSpan={2} />
          <Column header="Over/Under Ops (Masuk)" colSpan={2} />
          <Column header="Total SiGesit" colSpan={2} />

          <Column header="SiGesit Reguler" colSpan={4} />
          <Column header="SiGesit Driver" colSpan={4} />
          <Column header="SiGesit COD" colSpan={4} />
          <Column header="SiGesit SDS" colSpan={4} />
          <Column header="SiGesit CARGO" colSpan={4} />
          <Column header="SiGesit Freelance" colSpan={2} />
        </Row>
        <Row>
          <Column
            field="dlvOpsCapacity"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />

          <Column
            field="dlvOpsCapacityByCheckin"
            header="Masuk"
            rowSpan={2}
            sortable={true}
          />

          {/* <Column
            header="Kapasitas Orang."
            field="dlvOpsCapacity"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Kapasitas Orang (Absen)"
            field="dlvOpsCapacityByCheckin"
            rowSpan={2}
            sortable={true}
          /> */}

          <Column
            field="dlvBranchCapacityOverUnder"
            header="Total"
            sortable={true}
          />
          <Column
            field="dlvBranchCapacityOverUnderPerc"
            header="%"
            sortable={true}
          />
          <Column
            field="dlvBranchInCapacityOverUnder"
            header="Total"
            sortable={true}
          />
          <Column
            field="dlvBranchInCapacityOverUnderPerc"
            header="%"
            sortable={true}
          />

          <Column
            field="dlvOpsCapacityOverUnder"
            header="Total"
            sortable={true}
          />
          <Column
            field="dlvOpsCapacityOverUnderPerc"
            header="%"
            sortable={true}
          />

          <Column
            field="dlvOpsCapacityOverUnderByCheckin"
            header="Total"
            sortable={true}
          />
          <Column
            field="dlvOpsCapacityOverUnderPercByCheckin"
            header="%"
            sortable={true}
          />

          <Column
            field="employeeTotal"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeTotalCheckin"
            header="Masuk"
            sortable={true}
          />
          <Column
            field="employeeAntarPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarPermanentCheckin"
            header="Masuk"
            sortable={true}
          />
          <Column
            field="employeeAntarProbation"
            header="Terdaftar (Probation)"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarProbationCheckin"
            header="Masuk (Probation)"
            sortable={true}
          />

          <Column
            field="employeeAntarDriverPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarDriverPermanentCheckin"
            header="Masuk"
            sortable={true}
          />
          <Column
            field="employeeAntarDriverProbation"
            header="Terdaftar (Probation)"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarDriverProbationCheckin"
            header="Masuk (Probation)"
            sortable={true}
          />

          <Column
            field="employeeAntarCodPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarCodPermanentCheckin"
            header="Masuk"
            sortable={true}
          />
          <Column
            field="employeeAntarCodProbation"
            header="Terdaftar (Probation)"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarCodProbationCheckin"
            header="Masuk (Probation)"
            sortable={true}
          />

<Column
            field="employeeAntarSdsPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarSdsPermanentCheckin"
            header="Masuk"
            sortable={true}
          />
          <Column
            field="employeeAntarSdsProbation"
            header="Terdaftar (Probation)"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarSdsProbationCheckin"
            header="Masuk (Probation)"
            sortable={true}
          />

<Column
            field="employeeAntarCargoPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarCargoPermanentCheckin"
            header="Masuk"
            sortable={true}
          />
          <Column
            field="employeeAntarCargoProbation"
            header="Terdaftar (Probation)"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarCargoProbationCheckin"
            header="Masuk (Probation)"
            sortable={true}
          />

          <Column
            field="employeeAntarFreelance"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarFreelanceCheckin"
            header="Masuk"
            sortable={true}
          />
        </Row>
      </ColumnGroup>
    );

    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer={"Totals:" + this.dataSource.total.toLocaleString()}
            colSpan={7}
          />
          <Column
            footer={
              this.state.footerTotal.dlvBranchCapacity
                ? this.state.footerTotal.dlvBranchCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCapacity
                ? this.state.footerTotal.dlvOpsCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCapacityByCheckin
                ? this.state.footerTotal.dlvOpsCapacityByCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.totalInbound
                ? this.state.footerTotal.totalInbound.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.totalTobeDelivered
                ? this.state.footerTotal.totalTobeDelivered.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.totalDelivering
                ? this.state.footerTotal.totalDelivering.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.totalDelivered
                ? this.state.footerTotal.totalDelivered.toLocaleString()
                : ""
            }
          />
          <Column footer="" colSpan={6} />
          <Column
            footer={
              this.state.footerTotal.employeeTotal
                ? this.state.footerTotal.employeeTotal.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeTotalCheckin
                ? this.state.footerTotal.employeeTotalCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarPermanent
                ? this.state.footerTotal.employeeAntarPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarPermanentCheckin
                ? this.state.footerTotal.employeeAntarPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarProbation
                ? this.state.footerTotal.employeeAntarProbation.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarProbationCheckin
                ? this.state.footerTotal.employeeAntarProbationCheckin.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.employeeAntarDriverPermanent
                ? this.state.footerTotal.employeeAntarDriverPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarDriverPermanentCheckin
                ? this.state.footerTotal.employeeAntarDriverPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarDriverProbation
                ? this.state.footerTotal.employeeAntarDriverProbation.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarDriverProbationCheckin
                ? this.state.footerTotal.employeeAntarDriverProbationCheckin.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.employeeAntarCodPermanent
                ? this.state.footerTotal.employeeAntarCodPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarCodPermanentCheckin
                ? this.state.footerTotal.employeeAntarCodPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarCodProbation
                ? this.state.footerTotal.employeeAntarCodProbation.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarCodProbationCheckin
                ? this.state.footerTotal.employeeAntarCodProbationCheckin.toLocaleString()
                : ""
            }
          />

<Column
            footer={
              this.state.footerTotal.employeeAntarSdsPermanent
                ? this.state.footerTotal.employeeAntarSdsPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarSdsPermanentCheckin
                ? this.state.footerTotal.employeeAntarSdsPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarSdsProbation
                ? this.state.footerTotal.employeeAntarSdsProbation.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarSdsProbationCheckin
                ? this.state.footerTotal.employeeAntarSdsProbationCheckin.toLocaleString()
                : ""
            }
          />

<Column
            footer={
              this.state.footerTotal.employeeAntarCargoPermanent
                ? this.state.footerTotal.employeeAntarCargoPermanent.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarCargoPermanentCheckin
                ? this.state.footerTotal.employeeAntarCargoPermanentCheckin.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarCargoProbation
                ? this.state.footerTotal.employeeAntarCargoProbation.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarCargoProbationCheckin
                ? this.state.footerTotal.employeeAntarCargoProbationCheckin.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.employeeAntarFreelance
                ? this.state.footerTotal.employeeAntarFreelance.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.employeeAntarFreelanceCheckin
                ? this.state.footerTotal.employeeAntarFreelanceCheckin.toLocaleString()
                : ""
            }
          />
        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <DeliveryCapacitySummaryFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="CSV"
                onClick={this.exportCsv}
              ></Button>
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={"Tidak ada data untuk ditampilkan."}
                scrollable={true}
                scrollHeight="450px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => {
                  this.tableExportData = el;
                }}
                headerColumnGroup={headerGroup}
                // footerColumnGroup={footerGroup}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.onRowToggle(e)}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"
              >
                {/* onRowToggle={(e) => this.onRowToggle(e)}
                // onRowToggle={(e) => this.setState({expandedRows:e.data})}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"> */}
                <Column
                  expander={true}
                  // frozen={true}
                  style={{ width: '50px', height: '40px' }} />
                <Column
                  body={this.columnActionTemplate}
                  style={{ textAlign: "center", width: "5em" }}
                />
                <Column
                  field="province"
                  header="Province"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="city"
                  header="Kota"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="district"
                  header="Kecamatan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="trackingSite"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                  body={this.columnDetailLinkBody}
                />
                <Column
                  field="trackingSiteCode"
                  header="Kode Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="siteType"
                  header="Tipe"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="isActiveLabel"
                  header="Active"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="dlvBranchCapacity"
                  header="DLV Cap."
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="dlvOpsCapacity"
                  header="DLV OPS Cap. (Terdaftar)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="dlvOpsCapacityByCheckin"
                  header="DLV OPS Cap. (Masuk)"
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="AVGInbound"
                  header="Paket Masuk"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="AVGTotalInbound"
                  header="Total Resi"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="AVGTotalTobeDelivered"
                  header="Harus Antar"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="AVGTotalDelivering"
                  header="Sedang Antar"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="AVGTotalDelivered"
                  header="DLV"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "120px", textAlign: "right" }}
                />
                <Column
                  field="dlvBranchCapacityOverUnder"
                  header="Over/Under Cap (Total Resi)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="dlvBranchCapacityOverUnderPerc"
                  header="Over/Under Over/Under (%)"
                  sortable={true}
                  style={{ width: "80px", textAlign: "right" }}
                  body={this.columnPercentBody}
                />
                <Column
                  field="dlvBranchInCapacityOverUnder"
                  header="Over/Under Cap (Paket Masuk)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="dlvBranchInCapacityOverUnderPerc"
                  header="Over/UnderCap (Paket Masuk) (%)"
                  sortable={true}
                  style={{ width: "80px", textAlign: "right" }}
                  body={this.columnPercentBody}
                />
                <Column
                  field="dlvOpsCapacityOverUnder"
                  header="Over/Under Ops"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="dlvOpsCapacityOverUnderPerc"
                  header="Over/Under Ops (%)"
                  sortable={true}
                  style={{ width: "80px", textAlign: "right" }}
                  body={this.columnPercentBodyOps}
                />
                <Column
                  field="dlvOpsCapacityOverUnderByCheckin"
                  header="Over/Under Ops (Masuk)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="dlvOpsCapacityOverUnderPercByCheckin"
                  header="Over/Under Ops (%) (Masuk)"
                  sortable={true}
                  style={{ width: "80px", textAlign: "right" }}
                  body={this.columnPercentBodyOps}
                />
                <Column
                  field="employeeTotal"
                  header="Total SiGesit (Aktif)"
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeTotalCheckin"
                  header="Total SiGesit (Masuk)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarPermanent"
                  header="SiGesit Reguler (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarPermanentCheckin"
                  header="SiGesit Reguler (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarProbation"
                  header="SiGesit Reguler Probation (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarProbationCheckin"
                  header="SiGesit Reguler Probation (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />

                <Column
                  field="employeeAntarDriverPermanent"
                  header="SiGesit Driver (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarDriverPermanentCheckin"
                  header="SiGesit Driver (Masuk)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarDriverProbation"
                  header="SiGesit Driver Probation (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarDriverProbationCheckin"
                  header="SiGesit Driver Probation (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />

                <Column
                  field="employeeAntarCodPermanent"
                  header="SiGesit COD (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarCodPermanentCheckin"
                  header="SiGesit COD (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarCodProbation"
                  header="SiGesit COD Probation (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarCodProbationCheckin"
                  header="SiGesit COD Probation (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />



<Column
                  field="employeeAntarSdsPermanent"
                  header="SiGesit Sds (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarSdsPermanentCheckin"
                  header="SiGesit Sds (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarSdsProbation"
                  header="SiGesit Sds Probation (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarSdsProbationCheckin"
                  header="SiGesit Sds Probation (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />


<Column
                  field="employeeAntarCargoPermanent"
                  header="SiGesit Cargo (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarCargoPermanentCheckin"
                  header="SiGesit Cargo (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarCargoProbation"
                  header="SiGesit Cargo Probation (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarCargoProbationCheckin"
                  header="SiGesit Cargo Probation (Masuk)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />

                <Column
                  field="employeeAntarFreelance"
                  header="SiGesit Freelance (Terdaftar)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "100px", textAlign: "right" }}
                />
                <Column
                  field="employeeAntarFreelanceCheckin"
                  header="SiGesit Freelance (Masuk)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "100px", textAlign: "right" }}
                />
              </DataTable>

              <TrackingSiteEditFormModal
                trackingSiteId={this.state.selectedTrackingSiteId}
                isOpen={this.state.displayUpdateDialog}
                onSuccess={() => this.onUpdateSuccess()}
                onClose={() => this.onCancelUpdate()}
              />
              <br />
              <div className="row">
                <div className="col-md-4">
                  <table>
                    <tr style={{ height: '40px' }}>
                      <td colSpan={2}>
                        <b> Keterangan Warna Cap Vs Paket </b>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#FFE598', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp;&#60; 80% (kurang barang) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#C5E0B3', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp; 81-90 % (Mendekati ideal) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#70AD47', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp; 91-110% (Ideal) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#ED7D31', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp; 111-120% (mendekati Overload barang) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#FF0000', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp;&#62; 121% (Overload barang) </span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-4">
                  <table>
                    <tr style={{ height: '40px' }}>
                      <td colSpan={2}>
                        <b> Keterangan Warna Ops Vs Paket </b>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#FF0000', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp;&#60; 80% (kurang barang)</span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#ED7D31', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp; 81-90 % (Mendekati ideal) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#70AD47', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp; 91-105% (Ideal) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#C5E0B3', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp; 106-110% (mendekati Overload barang) </span>
                      </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                      <td style={{ background: '#FFE598', width: '25px' }}>
                      </td>
                      <td>
                        <span>&nbsp;&nbsp;&nbsp;&#62; 111% (Overload barang) </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  }
}
