import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React from "react";

import { DataSource } from "../../models/DataSource";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";

interface Props {
  type: string;
}

@observer
export class LpbSnowflakeExportContainer extends React.Component<Props, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();

  constructor(props) {
    super(props);
    this.state = {
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
    this.loadData();
  }

  getColumnFieldNames(columnField) {
    const columnFieldNames = {
      awbNumber: "AWB_NUMBER",
      awbDate: "AWB_DATE",
    }
    return columnFieldNames[columnField] ? columnFieldNames[columnField] : "";
  }

  loadData() {
    this.dataSource.loading = true;

    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };

    const params = {
      ...pagingParams,
      employee_id: userData.employee_id,
      report_type: this.props.type
    }
    this.monitoringRestService.post("sf/lpb/download-list", params).subscribe(
      (response) => {
        this.data = {
          data: response.data,
          count: response.pagination.totalData,
          limit: response.pagination.limit,
          page: response.pagination.page,
        };
        this.populateDataSource();
      },
      (err) => {
        let msg = {
          severity: "error",
          summary: "Terjadi kesalahan",
          detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
        };
        this.growl.show(msg);
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      console.log(this.data.data, "data in data");
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }
    await this.loadData();
  }

  columnStatus = (rowData, column: ColumnProps) => {
    switch (rowData[column.field!]) {
      case 1:
          return 'MENGANTRI';
      case 2:
          return 'PROSES';
      case 3:
          return 'SELESAI';
      case 4:
          return 'GAGAL';
      case 5:
          return 'BATAL';
      default:
          return 'N/A';
    }
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .utc()
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  columnDownload = (rowData, column: ColumnProps) => {
    return rowData[column.field!] ? (
      <a href={rowData[column.field!][0]}>
        Download
      </a>
    ) : "";
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div className="card">
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <div className="text-right">
          <Button
            className="p-button-success"
            icon="pi pi-refresh"
            label="Refresh"
            disabled={this.dataSource.loading}
            onClick={() => this.loadData()}
          />
        </div>
        <DataTable
          ref={(el) => (this.dt = el)}
          emptyMessage={"Tidak ada data untuk ditampilkan."}
          scrollable={true}
          scrollHeight="550px"
          style={{ width: "100%" }}
          className="mt-4"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          sortField={this.state.sortField}
          sortOrder={this.state.sortOrder}
          onSort={(e) => {
            this.onSorting(e);
          }}
          footer={footer}
        >
          <Column
            field="file_name"
            header="Nama File"
            style={{ width: "120px", "vertical-align": "text-top" }}
          />
          <Column
            field="status"
            header="Status"
            body={this.columnStatus}
            style={{ width: "90px", "vertical-align": "text-top" }}
          />
          <Column
            field="created_date"
            header="Tanggal Request"
            body={this.columnDateFormat}
            sortable={true}
            style={{ width: "100px", "vertical-align": "text-top" }}
          />
          <Column
            field="list_url"
            header=""
            body={this.columnDownload}
            style={{ width: "90px", "vertical-align": "text-top" }}
          />
        </DataTable>

        <Paginator
          totalRecords={this.dataSource.total}
          first={this.dataSource.offset}
          rows={this.dataSource.rows}
          rowsPerPageOptions={this.dataSource.rowsOptions}
          onPageChange={(e) => this.dataSource.fromPageState(e)}
        />
      </div>
       
    );
  }
}
