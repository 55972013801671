import { IObservableArray, observable } from 'mobx';
import { PageState } from 'primereact/paginator';
import { Subject } from 'rxjs';

export class DataSource<T = any> {
  public data: IObservableArray<T> = observable([]);
  @observable public total: number = 0;
  @observable public page: number = 1;
  @observable public rows: number = 50;
  @observable public loading: boolean = false;
  public rowsOptions: IObservableArray<number> = observable([10, 20, 50, 100]);

  @observable public offset: number = 0;
  @observable public limit: number = this.rows;

  onPageChange: Subject<number> = new Subject();

  constructor(data?: T[], rows?: number, rowsOptions?: number[]) {
    if (data && data.length) {
      this.data.replace(data);
      this.total = this.data.length;
    }

    if (rows) {
      this.rows = rows;
      this.limit = rows;
    }

    if (rowsOptions) {
      this.rowsOptions.replace(rowsOptions);
    }
  }

  calculatePaginationData() {
    const page = this.page >= 1 ? this.page : 1;
    const rows = this.rows;
    const offset = (page - 1) * rows;
    const limit = page * rows;

    this.offset = offset;
    this.limit = limit;

    this.onPageChange.next(page);
  }

  fromPageState(state: PageState) {
    this.page = state.page + 1;
    this.rows = state.rows;

    this.calculatePaginationData();
  }

  setData(newData: T[]) {
    this.data.replace(newData);
  }

  setTotal(total: number) {
    this.total = total;
  }

  setPage(page: number){
    this.page = page;

    this.calculatePaginationData();
  }
}
