import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Growl } from 'primereact/growl';
import React from "react";

import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

interface Props {
  data: any;
  isShow: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

@observer
export class AwbStatusUpdateModal extends React.Component<Props, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;
  userData;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.setState({ awbStatus: nextProps.data });
  }

  handleChange(prop, value) {
    let awbStatus = { ...this.state.awbStatus };
    awbStatus[prop] = value;
    this.setState({ awbStatus: awbStatus });
  }

  submit() {
    this.setState({ isLoading: true });
    try {
      const {
        awb_status_id,
        awb_status_name,
        is_calc_fm,
        is_calc_fm_in,
        is_calc_fm_prev,
        is_calc_fm_nob,
        is_calc_lm,
        is_calc_lm_prev,
        is_calc_lm_problem,
        is_calc_lm_nob,
      } = this.state.awbStatus
      
      const param = [{
        awb_status_id: Number(awb_status_id),
        awb_status_name: awb_status_name,
        is_calc_fm: Number(is_calc_fm),
        is_calc_fm_in: Number(is_calc_fm_in),
        is_calc_fm_prev: Number(is_calc_fm_prev),
        is_calc_fm_nob: Number(is_calc_fm_nob),
        is_calc_lm: Number(is_calc_lm),
        is_calc_lm_prev: Number(is_calc_lm_prev),
        is_calc_lm_problem: Number(is_calc_lm_problem),
        is_calc_lm_nob: Number(is_calc_lm_nob),
        user_id_updated: Number(this.userData.user_id),
      }]

      this.monitoringRestService
        .post("awb-status/update", param)
        .subscribe(
          () => {
            this.props.onClose();
            this.props.onSuccess();
          },
          () => {
            let msg = {
              severity: "error",
              summary: "Terjadi kesalahan",
              detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
            };
            this.growl.show(msg);
          },
        )
        .add(() => {
          this.setState({ isLoading: false });
        });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (
      !this.props.isShow ||
      !this.props.data ||
      !this.state.awbStatus
    ) {
      return null;
    }

    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cancel" icon="pi pi-times" onClick={this.props.onClose} />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={this.submit}
          disabled={this.state.isLoading}
        />
      </div>
    );

    return (
      <div>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />
        <Dialog
          visible={this.props.isShow}
          contentStyle={{ maxHeight: "500px", overflowY: "auto" }}
          header="Ubah AWB Status"
          modal={true}
          footer={dialogFooter}
          onHide={this.props.onClose}
          >
          <div className="p-grid p-fluid">
            <div className="p-col-4">
              <label htmlFor="awb_status_name">Nama</label>
            </div>
            <div className="p-col-8">
              <InputText
                id="awb_status_name"
                value={this.state.awbStatus.awb_status_name}
                disabled
              />
            </div>

            <div className="p-col-4">
              <label htmlFor="is_calc_fm">Kalkulasi FM</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_fm}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_fm", e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="p-col-4">
              <label htmlFor="is_calc_fm_in">Kalkulasi FM Paket Masuk</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_fm_in}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_fm_in", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="p-col-4">
              <label htmlFor="is_calc_fm_nob">Kalkulasi FM NOB</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_fm_nob}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_fm_nob", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="p-col-4">
              <label htmlFor="is_calc_fm_prev">Kalkulasi FM Sisa Kemarin</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_fm_prev}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_fm_prev", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="p-col-4">
              <label htmlFor="is_calc_lm">Kalkulasi LM</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_lm}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_lm", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="p-col-4">
              <label htmlFor="is_calc_lm_problem">Kalkulasi LM Problem</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_lm_problem}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_lm_problem", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="p-col-4">
              <label htmlFor="is_calc_lm_nob">Kalkulasi LM NOB</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_lm_nob}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_lm_nob", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="p-col-4">
              <label htmlFor="is_calc_lm_prev">Kalkulasi LM Sisa Kemarin</label>
            </div>
            <div className="p-col-8">
              <div>
                <InputSwitch
                  checked={this.state.awbStatus.is_calc_lm_prev}
                  onChange={(e: any) => {
                    this.handleChange("is_calc_lm_prev", e.target.value);
                  }}
                />
              </div>
            </div>

          </div>
        </Dialog>
      </div>
    );
  }
}
