import { snakeCase } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import qs from 'qs';
import React, { RefObject } from 'react';
import { RouterProps } from 'react-router';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { observable } from 'mobx';
import { Button } from 'primereact/button';
import { IMonitoringPodDetailContainerQueryNew } from './IMonitoringPodDetailContainerQueryNew';

@observer
export class MonitoringPodDetailContainerNew extends React.Component<RouterProps> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  @observable data;
  tableExportData;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  pageTitle1: string;
  pageTitle2: string;
  queryParameters: IMonitoringPodDetailContainerQueryNew;

  constructor(props, context) {
    super(props, context);
    this.export = this.export.bind(this);
  }

  componentWillMount() {
    this.queryParameters = qs.parse(
      this.props.history.location.search.substr(1),
    ) as any;

    this.pageTitle1 = `${this.queryParameters.trackingSite} | ${this.queryParameters.label}`;

    const pageTitle2Elements: string[] = [];
    if (this.queryParameters.filters.date) {

      const formattedDate = moment(this.queryParameters.filters.date).format(
        'DD-MM-YYYY',
      );
      const formattedEndDate = moment(this.queryParameters.filters.endDate).format(
        'DD-MM-YYYY',
      );
      
      if (formattedDate === formattedEndDate ) {
        pageTitle2Elements.push(formattedDate);
      } else {
        pageTitle2Elements.push(formattedDate + ' s/d ' + formattedEndDate);
      }
    }
    if (this.queryParameters.filters.city) {
      pageTitle2Elements.push(this.queryParameters.filters.city);
    }
    if (this.queryParameters.filters.layanan) {
      pageTitle2Elements.push(this.queryParameters.filters.layanan);
    }
    if (this.queryParameters.filters.cod) {
      pageTitle2Elements.push(this.queryParameters.filters.cod);
    }
    this.pageTitle2 = pageTitle2Elements.join('/');

    this.loadData();
  }

  get queryParametersTypeId() {
    switch (this.queryParameters.field) {
      case 'prevUndelivered':
        return 'prev_problem';
      case 'deliveryProblem':
        return 'problem';
      case 'returned':
        return 'return';
      default:
        return snakeCase(this.queryParameters.field).toLowerCase();
    }
  }

  loadData() {
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('monitoring-pod-new/improve/detail', {
        date: this.queryParameters.filters.date,
        endDate: this.queryParameters.filters.endDate,
        siteCode: this.queryParameters.trackingSiteCode,
        cod: this.queryParameters.filters.cod,
        layanan: this.queryParameters.filters.layanan,
        pengirim: this.queryParameters.filters.pengirim,
        partner: this.queryParameters.filters.partner,
        packageValue: this.queryParameters.filters.packageValue,
        detailType: this.queryParametersTypeId,
      })
      .subscribe(
        response => {
          this.data = response;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
    );
  }

  export() {
    this.tableExportData.exportCSV();
  }

  notedStatus = (rowData, column: ColumnProps) => {
    console.log(rowData)
    console.log(column.field)
      let tgl = rowData['statusDate'];
      if (tgl) {
        tgl = moment(tgl).utc().format('DD-MM-YYYY HH:mm');
      } else {
        tgl = "-"
      }

      let note = rowData['statusNote'];
      if (!note) { note = "-"; }

      return <div><div><b>Tanggal:</b> {tgl}</div><div><p><b>Note: </b>{note}</p></div></div>
    }

  render() {
    var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div className="card">
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <h3>
          {this.pageTitle1} - {this.pageTitle2}
        </h3>

        <DataTable
          emptyMessage={'Tidak ada data untuk ditampilkan.'}
          scrollable={true}
          scrollHeight="550px"
          className="mt-6"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
          footer={footer}
          header={header} ref={(el) => { this.tableExportData = el; }}>
          <Column
            field="receiptNumber"
            header="Resi"
            filter={true}
            sortable={true}
            style={{ width: '130px', "vertical-align": 'text-top' }}
            body={this.columnAwbNumber}
          />
          <Column
            field="transactionDate"
            header="Tanggal"
            sortable={true}
            style={{ width: '145px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              if (value) {
                const d = moment(value);
                if (d.isValid()) {
                  value = d.utc().format('DD-MM-YYYY HH:mm');
                } else {
                  value = "-"
                }
              }

              return value;
            }}
          />
          <Column
            field="origin"
            header="Asal"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '120px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let originCode = rowData[column.field!];
              let originKota = rowData['asalKota'];
              let originKecamatan = rowData['asalKecamatan'];

              return <div><div><b>{originCode}</b></div><div><small>{originKota} ({originKecamatan})</small></div></div>
            }}
          />
          <Column
            field="destination"
            header="Tujuan"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '120px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let destinationCode = rowData[column.field!];
              let destinationKota = rowData['tujuanKota'];

              return <div><div><b>{destinationCode}</b></div><div><small>{destinationKota}</small></div></div>
            }}
          />
          <Column
            field="tujuanKecamatan"
            header="Tujuan Kecamatan"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '150px', "vertical-align": 'text-top' }}
          />
          <Column
            field="pengirim"
            header="Pengirim"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '180px', "vertical-align": 'text-top' }}
          />
          <Column
            field="recipient"
            header="Penerima"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '180px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              const phone = rowData['recipientPhone'];

              if (phone) {
                return <div><div>{value}</div><div><small style={{ wordWrap: "break-word"}}>{phone}</small></div></div>
              } else {
                return value;
              }
            }}
          />
          <Column
            field="perwakilan"
            header="Perwakilan"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="layanan"
            header="Layanan"
            sortable={true}
            filter={true}
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="codValue"
            header="Nilai COD"
            sortable={true}
            style={{ width: '100px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              const colValue = rowData[column.field!];
              const fmtValue = (colValue) ? rowData[column.field!].toLocaleString() : '-';
              return <span>{fmtValue}</span>
            }}
          />
          <Column
            field="beratasli"
            header="Berat Asli"
            sortable={true}
            filter={true}
            style={{ width: '100px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              const colValue = rowData[column.field!];
              return <span>{colValue} kg</span>
            }}
          />
          <Column
            field="leadTime"
            header="Usia Paket"
            sortable={true}
            filter={true}
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="overSLADaysInternal"
            header="Over SLA"
            sortable={true}
            filter={true}
            style={{ width: '120px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              const tglOverSla = moment(rowData['slaMaxDateTimeInternal']);
              let sTglOverSla: string = '-';
              if (tglOverSla.isValid()) {
                sTglOverSla = tglOverSla.utc().format('DD-MM-YYYY');
              }
              const overSlaDays = rowData[column.field!];

              return <div><div><b>{overSlaDays} hari</b></div><div><small>{sTglOverSla}</small></div></div>
            }}
          />
          <Column
            field="statusTrackingType"
            header="Status Sebelumnya"
            sortable={true}
            filter={true}
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="statusDate"
            header="Info Status Sebelumnya"
            sortable={false}
            style={{ width: '250px', "vertical-align": 'text-top', }}
            body={this.notedStatus}
          />
          <Column
            field="statusNote"
            header="Info Status Sebelumnya"
            sortable={false}
            style={{ width: '250px', "vertical-align": 'text-top',display:'none' }}
            body={this.notedStatus}
          />
          <Column
            field="lastStatusTrackingType"
            header="Status Akhir"
            sortable={true}
            filter={true}
            style={{ width: '150px', "vertical-align": 'text-top' }}
          />
          <Column
            field="lastStatusDate"
            header="Info Status Akhir"
            sortable={false}
            style={{ width: '250px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let tgl = rowData[column.field!];
              let trackingSite = rowData['lastStatusTrackingSite'];
              if (tgl) {
                tgl = moment(tgl).utc().format('DD-MM-YYYY HH:mm');
              } else {
                tgl = "-"
              }

              let note = rowData['lastStatusNote'];
              if (!note) { note = "-"; }

              return <div><div><b>Cabang:</b> {trackingSite}</div><div><b>Tanggal:</b> {tgl}</div><div><b>Note: </b><small>{note}</small></div></div>
            }}
          />
          <Column
            field="lastStatusTrackingSite"
            header="Info Status Akhir"
            sortable={false}
            style={{ width: '250px', "vertical-align": 'text-top',display:'none' }}
            body={this.notedStatus}
          />
          <Column
            field="lastStatusNote"
            header="Info Status Akhir"
            sortable={false}
            style={{ width: '250px', "vertical-align": 'text-top',display:'none' }}
            body={this.notedStatus}
          />
        </DataTable>
      </div>
    );
  }
}
