import { observer } from "mobx-react";
import { observable } from "mobx";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Growl } from "primereact/growl";
import { Card } from "primereact/card";
import qs from "qs";
import React, { RefObject } from "react";
import { Link } from "react-router-dom";
import { Flex } from "reflexbox";

import { DataSource } from "../../../models/DataSource";
import { DataTableFilterService } from "../../../services/DataTableFilterService";
import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { IMonitoringPodKepalaKurirDetailCourierContainerQueryNew } from "./../MonitoringPodKepalaKurirDetailCourierContainer";
import { MonitoringPodKepalaKurirFilterContainerSnowflake } from "./MonitoringPodKepalaKurirFilterContainerSnowflake";
import { IMonitoringPodFilterContainerValueSnowflake } from "./IMonitoringPodFilterContainerValueSnowflake";
import { IMonitoringPodDetailContainerQueryNew } from "./../IMonitoringPodDetailContainerQueryNew";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { tap, merge } from "rxjs/operators";
import _ from "lodash";
import moment from "moment";
import { Observable, forkJoin } from "rxjs";
import { TabView, TabPanel } from "primereact/tabview";
// import { AwbDetailModalComponent } from './AwbDetailModalComponentSnowflake';
import { AwbDetailModalComponent } from './../../awb/AwbDetailModalComponent';


@observer
export class MonitoringPodKepalaKurirContainerSnowflake extends React.Component<
  any,
  any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  podData;
  growl: any;
  courierData;
  detailBestData;
  detailCodData;
  detailOverslaData;
  detailWillOverslaData;
  detailAntNotPodData;
  courBranchName;
  podTableExportData;
  detailCourierTableExportData;
  detailAwbTableExportData;
  formFilters: IMonitoringPodFilterContainerValueSnowflake;
  dataSource = new DataSource<any>();
  detailCourierDataSource = new DataSource<any>();
  detailBestDataSource = new DataSource<any>();
  detailCodDataSource = new DataSource<any>();
  detailOverslaDataSource = new DataSource<any>();
  detailWillOverslaDataSource = new DataSource<any>();
  detailAntNotPodDataSource = new DataSource<any>();
  @observable loadingAwbDetail: boolean = false;
  @observable countBest: number = -1;
  @observable countOversla: number = -1;
  @observable countWillOversla: number = -1;
  @observable countAntNotPod: number = -1;

  refFilter: RefObject<MonitoringPodKepalaKurirFilterContainerSnowflake> =
    React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.exportPodTable = this.exportPodTable.bind(this);
    this.exportDetailCourierTable = this.exportDetailCourierTable.bind(this);
    this.exportDetailAwbTable = this.exportDetailAwbTable.bind(this);
    this.onClickExportPdf = this.onClickExportPdf.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);

    this.state = {
      courierDataAwbCount: 0,
      courierDataAwbDlvCount: 0,
      courierDataAwbProblemCount: 0,
      courierDataAwbNoRepCount: 0,
      exportPdfLoading: false,
      showExportDialog: false,
      exportPdfNote: "",
    };
  }

  componentDidMount() {}

  async loadData() {
    this.countBest = -1;
    this.countOversla = -1;
    this.countWillOversla = -1;
    this.countAntNotPod = -1;

    this.dataSource.setData([]);
    this.dataSource.setTotal(0);

    this.detailCourierDataSource.setData([]);
    this.detailCourierDataSource.setTotal(0);

    this.detailBestDataSource.setData([]);
    this.detailBestDataSource.setTotal(0);

    this.detailCodDataSource.setData([]);
    this.detailCodDataSource.setTotal(0);

    this.detailOverslaDataSource.setData([]);
    this.detailOverslaDataSource.setTotal(0);

    this.detailWillOverslaDataSource.setData([]);
    this.detailWillOverslaDataSource.setTotal(0);

    this.detailAntNotPodDataSource.setData([]);
    this.detailAntNotPodDataSource.setTotal(0);

    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.date = moment(this.formFilters.date).format("YYYY-MM-DD");
    this.formFilters.siteCode = localStorage.getItem("branchCode")
      ? localStorage.getItem("branchCode")
      : "1303002";

    // await Promise.all([
    //   this.loadPodData(),
    //   this.loadDetailCourierData(),
    //   this.loadDetailBestData(),
    //   this.loadDetailCodData(),
    //   this.loadDetailOverslaData(),
    //   this.loadDetailWillOverslaData(),
    //   this.loadDetailAntNotPodData(),
    // ]);

    await this.loadPodData();
    await this.loadDetailCourierData();
  }

  loadPodData() {
    return new Promise((resolve, reject) => {
      this.formFilters.endDate = this.formFilters.date;
      let filterCod;
      if (this.formFilters.cod === "Y") {
        filterCod = true
      } else if (this.formFilters.cod === "N") {
        filterCod = false
      } else {
        filterCod = null
      }
      const mappedParams = {
        date: this.formFilters.date,
        layanan: this.formFilters.layanan ? this.formFilters.layanan.value : "",
        is_cod: filterCod,
        pengirim: this.formFilters.pengirim,
        partner: this.formFilters.partner ? this.formFilters.partner.value : "",
        branch_code: this.formFilters.siteCode,
        sort_by: "city_name",
        include_child_branches: true,
      }
      this.dataSource.loading = true;
      this.monitoringRestService.post("monitoring-pod/sf", mappedParams).subscribe(
        (response) => {
          const mappedData = response.map((d) => {
            return {
              ...d,
              trackingSiteCity:	d.CITY_NAME,
              trackingSiteId:	d.BRANCH_ID,
              trackingSite:	d.BRANCH_NAME,
              trackingSiteCode:	d.BRANCH_CODE,
              perwakilan:	d.REPRESENTATIVE_CODE,
              prevOpenStatus:	d.PREV_PROCESS,
              inbound:	d.IN_PROCESS,
              totalInbound:	d.TOTAL,
              tobeDelivered:	d.TOBE_DELIVERED,
              deliveryProcessed:	d.DELIVERY_PROCESSED,
              delivering:	d.DELIVERING,
              delivered:	d.DELIVERED,
              deliveryProblem:	d.DELIVERY_PROBLEM,
              crissCross:	d.CRISS_CROSS,
              returned:	d.RETURN_PROCESS,
              outboundTransit:	d.OUT_TRANSIT,
              outbound3Pl:	d.OUT_THP,
              lostBroken:	d.LOST_BROKEN,
              ots:	d.OTS,
              stuck:	d.STUCK,
              stockOpname:	d.STOCK_OPNAME,
              doneho:	d.DONEHO,
              notho:	d.NOTHO,
              processedDlvPerc:	d.DLV_PERC,
              oversla:	d.OVER_SLA,
              overslaPerc:	d.OVER_SLA_PERC,
              notOutbound:	d.STUCK,
              processed:	d.PROCESSED,
              processedPerc:	d.PROCESSED_PERC,
            }
          })
          this.podData = mappedData;
          this.courBranchName =
            mappedData && mappedData.length && mappedData.length > 0
              ? mappedData[0].trackingSiteCity +
                " (" +
                mappedData[0].trackingSite +
                ") "
              : "";
          console.log(this.courBranchName);
          this.populatePodDataSource();
          resolve(true);
        },
        (err) => {
          this.dataSource.loading = false;
          reject(err);
        },
        () => {
          this.dataSource.loading = false;
        }
      );
    });
  }

  populatePodDataSource() {
    if (this.podData && this.podData.length) {
      this.dataSource.setData(this.podData);
      this.dataSource.setTotal(this.podData.length);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  loadDetailCourierData() {
    const formFilters = this.formFilters;
    let filterCod;
    if (formFilters.cod === "Y") {
      filterCod = true
    } else if (formFilters.cod === "N") {
      filterCod = false
    } else {
      filterCod = null
    }
    const mappedParams = {
      date: formFilters.date,
      layanan: formFilters.layanan ? formFilters.layanan.value : "",
      is_cod: filterCod,
      pengirim: formFilters.pengirim,
      partner: formFilters.partner ? formFilters.partner.value : "",
      branch_code: formFilters.siteCode,
      sort_by: "employee_name_driver",
      include_child_branches: true,
    }
    return new Promise((resolve, reject) => {
      this.detailCourierDataSource.loading = true;
      this.monitoringRestService
        .post("monitoring-pod/sf/courier", mappedParams)
        .subscribe(
          (response) => {
            const mappedData = response.map((d) => {
              return {
                ...d,
                courierNik: d.EMPLOYEE_NIK_DRIVER,
                courierName: d.EMPLOYEE_NAME_DRIVER,
                titleName: d.EMPLOYEE_ROLE_NAME,
                ant: d.TOTAL,
                dlv: d.DELIVERED,
                problem: d.PROBLEM,
                doneho: d.DONEHO,
                notho: d.NOTHO,
                norep: d.NOREP,
                dlvPerc: d.DLV_PERC,
                podPerc: d.POD_PERC,
                trackingSiteId: d.BRANCH_ID,
                trackingSite: d.BRANCH_NAME,
                courierId: d.EMPLOYEE_ID_DRIVER,
                trackingSiteCode: formFilters.siteCode,
              }
            })
            this.courierData = mappedData;
            const courierDataAwbCount = _.sumBy(mappedData, function (o: any) {
              return o.ant;
            });
            const courierDataAwbDlvCount = _.sumBy(mappedData, function (o: any) {
              return o.dlv;
            });
            const courierDataAwbProblemCount = _.sumBy(
              mappedData,
              function (o: any) {
                return o.problem;
              }
            );
            const courierDataAwbNoRepCount = _.sumBy(
              mappedData,
              function (o: any) {
                return o.norep;
              }
            );
            this.setState({
              courierDataAwbCount: courierDataAwbCount,
              courierDataAwbDlvCount: courierDataAwbDlvCount,
              courierDataAwbProblemCount: courierDataAwbProblemCount,
              courierDataAwbNoRepCount: courierDataAwbNoRepCount,
            });

            if (this.courierData && this.courierData.length) {
              this.detailCourierDataSource.setData(this.courierData);
              this.detailCourierDataSource.setTotal(this.courierData.length);
            } else {
              this.detailCourierDataSource.setData([]);
              this.detailCourierDataSource.setTotal(0);
            }

            resolve(true);
          },
          (err) => {
            this.detailCourierDataSource.loading = false;
            reject(err);
          },
          () => {
            this.detailCourierDataSource.loading = false;
          }
        );
    });
  }

  async loadDetailExtra(dataSource, url) {
    if (dataSource.loading || this.loadingAwbDetail) {
      return;
    }

    dataSource.loading = true;
    this.loadingAwbDetail = true;
    try {
      const fFilters = Object.assign({}, this.formFilters, {
        includeChildBranches: true,
      });

      const result = await new Promise((resolve, reject) => {
        this.monitoringRestService
          .post(`monitoring-pod-new/${url}`, fFilters)
          .subscribe(
            (response) => {
              if (response && response.length) {
                dataSource.setData(response);
                dataSource.setTotal(response.length);
              } else {
                dataSource.setData([]);
                dataSource.setTotal(0);
              }

              resolve(response);
            },
            (err) => {
              reject(err);
            }
          );
      });

      return result;
    } finally {
      dataSource.loading = false;
      this.loadingAwbDetail = false;
    }
  }

  async loadDetailData(detailType, detailData, detailDataSource, setCount?) {    
    if (detailDataSource.loading || this.loadingAwbDetail) {
      return;
    }
    
    return new Promise(async (resolve, reject) => {
      detailDataSource.loading = true;
      this.loadingAwbDetail = true;
      if (!this.podData || !this.podData.length || this.podData.length <= 0) {
        await this.loadData();
      }
      const mappedParams = {
        date: this.formFilters.date,
        branch_code: this.formFilters.siteCode,
        sort_by: "awb_number",
        detail_type: detailType,
        include_child_branches: true,
      }
      this.monitoringRestService
        .post("monitoring-pod/sf/head-courier", mappedParams)
        .subscribe(
          (response) => {
            const mappedData = response.map((d) => {
              return {
                ...d,
                trackingSite: d.LAST_BRANCH_NAME,
                receiptNumber: d.AWB_NUMBER,
                transactionDate: d.AWB_DATE,
                origin: d.FROM_DISTRICT_CODE,
                asalKota: d.FROM_CITY_NAME,
                asalKecamatan: d.FROM_DISTRICT_NAME,
                destination: d.TO_DISTRICT_CODE,
                tujuanKota: d.TO_CITY_NAME,
                tujuanKecamatan: d.TO_DISTRICT_NAME,
                recipient: d.CONSIGNEE_NAME,
                recipientPhone: d.CONSIGNEE_PHONE,
                layanan: d.LAYANAN,
                codValue: d.COD_VALUE,
                leadTIme: d.USIA_PAKET,
                overSLADaysInternal: d.OVER_SLA_DAYS,
                slaMaxDateTimeInternal: d.SLA_MAX_DATE,
                statusTrackingType: d.LAST_AWB_STATUS_NAME,
                statusDate: d.LAST_DATE_TIME,
                statusNote: d.LAST_NOTE_INTERNAL,
                lastStatusTrackingType: d.LAST_POD_AWB_STATUS_NAME,
                lastStatusDate: d.LAST_POD_DATE_TIME,
                lastStatusTrackingSite: d.LAST_POD_BRANCH_NAME,
                lastStatusNote: d.LAST_POD_NOTE_INTERNAL,
              }
            })
            detailData = mappedData;

            if (detailData && detailData.length) {
              if (setCount) {
                setCount(detailData.length)
              }
              detailDataSource.setData(detailData);
              detailDataSource.setTotal(detailData.length);
            } else {
              if (setCount) {
                setCount(0)
              }
              detailDataSource.setData([]);
              detailDataSource.setTotal(0);
            }

            resolve(true);
          },
          (err) => {
            detailDataSource.loading = false;
            this.loadingAwbDetail = false;
            reject(err);
          },
          () => {
            detailDataSource.loading = false;
            this.loadingAwbDetail = false;
          }
        );
    });
  }

  async loadDetailBestData() {
    this.loadDetailData("best", this.detailBestData, this.detailBestDataSource, (total) => this.countBest = total)
  }

  async loadDetailCodData() {
    this.loadDetailData("cod", this.detailCodData, this.detailCodDataSource)
  }

  async loadDetailOverslaData() {
    this.loadDetailData("oversla", this.detailOverslaData, this.detailOverslaDataSource, (total) => this.countOversla = total)
  }

  async loadDetailWillOverslaData() {
    this.loadDetailData("will_oversla", this.detailWillOverslaData, this.detailWillOverslaDataSource, (total) => this.countWillOversla = total)
  }

  async loadDetailAntNotPodData() {
    this.loadDetailData("ant_not_pod", this.detailAntNotPodData, this.detailAntNotPodDataSource, (total) => this.countAntNotPod = total)
  }

  exportPodTable() {
    this.podTableExportData.exportCSV();
    this.showGrowlSuccess();
  }

  exportDetailCourierTable() {
    this.detailCourierTableExportData.exportCSV();
    this.showGrowlSuccess();
  }

  exportDetailAwbTable() {
    this.detailAwbTableExportData.exportCSV();
    this.showGrowlSuccess();
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";
    const formFilters = {
      ...this.formFilters,
      layanan: this.formFilters.layanan ? this.formFilters.layanan.value : "",
      partner: this.formFilters.partner ? this.formFilters.partner.value : "",
    };
    return (
      <Link
        to={{
          pathname: "/admin/monitoring/pod-sf/detail",
          search: qs.stringify({
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringPodDetailContainerQueryNew),
        }}
        target="_blank"
      >
        {fmtValue}
      </Link>
    );
  };

  columnCourierDetailLinkBody = (rowData, column: ColumnProps) => {
    const formFilters = {
      ...this.formFilters,
      layanan: this.formFilters.layanan ? this.formFilters.layanan.value : "",
      partner: this.formFilters.partner ? this.formFilters.partner.value : "",
    };
    return (
      <Link
        to={{
          pathname: "/admin/monitoring-pod-new-palkur/courier-sf/detail",
          search: qs.stringify({
            courierId: rowData["courierId"],
            courierName: rowData["courierName"],
            courierNik: rowData["courierNik"],
            branchName: this.courBranchName,
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringPodKepalaKurirDetailCourierContainerQueryNew),
        }}
        target="_blank"
      >
        {rowData[column.field!]}
      </Link>
    );
  };

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  handleNoteChange = (e) => {
    e.preventDefault();
    this.setState({ exportPdfNote: e.target.value });
  };

  showGrowlSuccess() {
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  showGrowlFail() {
    let msg = {
      severity: "error",
      summary: "Error Message",
      detail: "Export failed",
    };
    this.growl.show(msg);
  }

  async processExportPdf() {
    const pdf = new jsPDF("p", "pt");

    this.setState({
      showExportDialog: true,
      exportPdfLoading: true,
    });

    try {
      if (!this.podData || !this.podData.length || this.podData.length <= 0) {
        await this.loadData();
      }

      if (!this.podData || !this.podData.length || this.podData.length <= 0) {
        return;
      }

      const siteCode = this.formFilters.siteCode.toUpperCase();
      const date = this.formFilters.date;
      const pod = _.find(
        this.podData,
        (x) => x.trackingSiteCode.toUpperCase() === siteCode
      );
      const transitPods = _.filter(
        this.podData,
        (x) => x.trackingSiteCode.toUpperCase() !== siteCode
      );
      console.log(transitPods);
      const couriers = this.courierData;

      const hasTransits =
        transitPods && transitPods.length && transitPods.length > 0;

      var courierColumns = [
        { title: "Cabang", dataKey: "trackingSite" },
        { title: "Nama", dataKey: "courierName" },
        { title: "Jabatan", dataKey: "titleName" },
        { title: "ANT", dataKey: "ant" },
        { title: "DLV", dataKey: "dlv" },
        { title: "PROBLEM", dataKey: "problem" },
        { title: "NON.REP", dataKey: "norep" },
      ];

      if (pod) {
        let html =
          "<p>===============================================================</p>";
        html +=
          "<p>   Laporan Gerai Antar: <b>" + pod["trackingSite"] + "</b></p>";
        html += "<p>   Tanggal Laporan: <b>" + date + "</b></p>";
        html +=
          "<p>________________________________________________________________________</p>";
        html +=
          "<p> Jumlah Barang Masuk               : <b>" +
          pod.inbound +
          "</b></p>";
        // html += "<p> Sisa Barang Kemarin               : <b>" + (pod.prevOpenStatus + pod.prevUndelivered) + "</b></p>";
        html +=
          "<p> Sisa Barang Kemarin               : <b>" +
          pod.prevOpenStatus +
          "</b></p>";
        html +=
          "<p> Total Barang Hari ini             : <b>" +
          pod.totalInbound +
          "</b></p>";
        // html += "<p> Total Yang Diantar                : <b>" + pod.processed + "</b></p>";
        html +=
          "<p> Total Yang Diantar                : <b>" +
          pod.deliveryProcessed +
          "</b></p>";
        html +=
          "<p> Total Yang Tidak Diantar          : <b>" +
          pod.notOutbound +
          "</b></p>";
        html +=
          "<p> Total Paket yang Delivered        : <b>" +
          pod.delivered +
          "</b></p>";
        html +=
          "<p> Total Barang Bermasalah           : <b>" +
          pod.deliveryProblem +
          "</b></p>";
        html +=
          "<p> Total Barang Retur                : <b>" +
          pod.returned +
          "</b></p>";
        // html += "<p> Total Barang Outbound to Transit  : <b>" + pod.outboundTransit + "</b></p>";
        html +=
          "<p> Total Barang Outbound to Transit  : <b>" + pod.ots + "</b></p>";
        html +=
          "<p> Persentase Delivered              : <b>" +
          pod.processedDlvPerc +
          "% </b></p>";
        html +=
          "<p> Persentase Antar (status POD)     : <b>" +
          pod.processedPerc +
          "% </b></p>";
        html +=
          "<p>_______________________________________________________________________</p>";

        if (hasTransits) {
          const transitDelivered = _.sumBy(transitPods, (x) => x.delivered);
          const transitDeliveryProcessed = _.sumBy(
            transitPods,
            (x) => x.deliveryProcessed
          );
          const transitProcessed = _.sumBy(transitPods, (x) => x.processed);
          const transitTotalInbound = _.sumBy(
            transitPods,
            (x) => x.totalInbound
          );
          const transitProcessedDlvPerc =
            transitDeliveryProcessed && transitDeliveryProcessed > 0
              ? ((transitDelivered / transitDeliveryProcessed) * 100).toFixed(2)
              : 0;
          const transitProcessedPerc =
            transitTotalInbound && transitTotalInbound > 0
              ? ((transitProcessed / transitTotalInbound) * 100).toFixed(2)
              : 0;

          html += "<p></p>";
          html += "<p>   Laporan Gerai Antar Luar Kota (Transit)</p>";
          html +=
            "<p>________________________________________________________________________</p>";
          html +=
            "<p> Jumlah Barang Masuk               : <b>" +
            _.sumBy(transitPods, (x) => x.inbound) +
            "</b></p>";
          // html += "<p> Sisa Barang Kemarin               : <b>" + _.sumBy(transitPods, (x) => x.prevOpenStatus + x.prevUndelivered) + "</b></p>";
          html +=
            "<p> Sisa Barang Kemarin               : <b>" +
            _.sumBy(transitPods, (x) => x.prevOpenStatus) +
            "</b></p>";
          html +=
            "<p> Total Barang Hari ini             : <b>" +
            _.sumBy(transitPods, (x) => x.totalInbound) +
            "</b></p>";
          // html += "<p> Total Yang Diantar                : <b>" + _.sumBy(transitPods, (x) => x.processed) + "</b></p>";
          html +=
            "<p> Total Yang Diantar                : <b>" +
            _.sumBy(transitPods, (x) => x.deliveryProcessed) +
            "</b></p>";
          html +=
            "<p> Total Yang Tidak Diantar          : <b>" +
            _.sumBy(transitPods, (x) => x.notOutbound) +
            "</b></p>";
          html +=
            "<p> Total Paket yang Delivered        : <b>" +
            _.sumBy(transitPods, (x) => x.delivered) +
            "</b></p>";
          html +=
            "<p> Total Barang Bermasalah           : <b>" +
            _.sumBy(transitPods, (x) => x.deliveryProblem) +
            "</b></p>";
          html +=
            "<p> Total Barang Retur                : <b>" +
            _.sumBy(transitPods, (x) => x.returned) +
            "</b></p>";
          // html += "<p> Total Barang Outbound to Transit  : <b>" + _.sumBy(transitPods, (x) => x.outboundTransit) + "</b></p>";
          html +=
            "<p> Total Barang Outbound to Transit  : <b>" +
            _.sumBy(transitPods, (x) => x.ots) +
            "</b></p>";
          html +=
            "<p> Persentase Delivered              : <b>" +
            transitProcessedDlvPerc +
            "% </b></p>";
          html +=
            "<p> Persentase Antar (status POD)     : <b>" +
            transitProcessedPerc +
            "% </b></p>";
          html +=
            "<p>_______________________________________________________________________</p>";
        }

        let note = "<p><b>Catatan:</b></p>";
        note += "<p>" + this.state.exportPdfNote + "</p>";
        note +=
          "<p>_______________________________________________________________________</p>";

        html += note;

        html += "<br /><p><b>Laporan Kurir Antar</b></p>";

        // get pdf width
        var width = pdf.internal.pageSize.getWidth();

        // 40 start x, 15 start y
        pdf.fromHTML(html, 40, 15, {
          width: width - 80,
        });

        // get width of note
        const textWidth = pdf.getTextWidth(this.state.exportPdfNote);

        // Calculate note height, (width - 80) => width of page with padding left & right, 12 => font size (12pt)
        const plusHeight =
          textWidth > width - 80
            ? Math.floor(textWidth / (width - 80)) * 12
            : textWidth > 0
            ? 12
            : 0;

        pdf.autoTable(courierColumns, couriers, {
          startY: (hasTransits ? 960 : 480) + plusHeight + 12, // 12 random margin
          theme: "plain",
        });

        pdf.save("PodPalkurPdf");
        console.log("end pdf");
      }
    } finally {
      this.setState({
        showExportDialog: false,
        exportPdfLoading: false,
        exportPdfNote: "",
      });

      this.showGrowlSuccess();
    }
  }

  onClickExportPdf() {
    this.processExportPdf().then();
  }

  renderBranchData() {
    const branchFooter = `Total Gerai: ${this.dataSource.total}`;
    return (
      <DataTable
        emptyMessage={"Tidak ada data untuk ditampilkan."}
        scrollable={true}
        scrollHeight="200px"
        className="mt-4"
        value={this.dataSource.data}
        loading={this.dataSource.loading}
        footer={branchFooter}
        // header={podheader}
        ref={(el) => {
          this.podTableExportData = el;
        }}
      >
        <Column
          field="trackingSiteCity"
          header="Kota"
          filter={true}
          filterMatchMode="contains"
          sortable={true}
          // frozen={true}
          style={{ width: "200px" }}
        />
        <Column
          field="trackingSite"
          header="Cabang"
          filter={true}
          filterMatchMode="contains"
          sortable={true}
          // frozen={true}
          style={{ width: "200px" }}
        />
        <Column
          field="prevOpenStatus"
          header="Sisa Kemarin"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="inbound"
          header="Masuk"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="totalInbound"
          header="Total Resi"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="tobeDelivered"
          header="Harus Diantar"
          body={this.columnDetailLinkBody}
          sortable={true}
          style={{ width: "90px", height: "40px" }}
        />
        <Column
          field="deliveryProcessed"
          header="Total Antar"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="delivering"
          header="Sedang Antar"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="delivered"
          header="DLV"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="deliveryProblem"
          header="Prob."
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="crissCross"
          header="CC"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="returned"
          header="Retur"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="outboundTransit"
          header="Keluar Transit"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="outbound3Pl"
          header="Keluar 3PL"
          body={this.columnDetailLinkBody}
          sortable={false}
          style={{ width: "80px" }}
        />
        <Column
          field="lostBroken"
          header="Hilang / Rusak"
          sortable={false}
          body={this.columnDetailLinkBody}
          style={{ width: "80px" }}
        />
        <Column
          field="ots"
          header="OTS"
          sortable={false}
          body={this.columnDetailLinkBody}
          style={{ width: "80px" }}
        />
        <Column
          field="stuck"
          header="NOB"
          body={this.columnDetailLinkBody}
          style={{ width: "90px", height: "40px" }}
        />
        {/* <Column
          field="tobeDeliveredDlvPerc"
          header="%TARGET"
          sortable={true}
          style={{ width: "90px" }}
        /> */}
        <Column
          field="processedDlvPerc"
          header="%DLV"
          sortable={true}
          style={{ width: "90px" }}
        />
        <Column
          field="oversla"
          header="OverSLA"
          body={this.columnDetailLinkBody}
          sortable={true}
          style={{ width: "90px", height: "40px" }}
        />
        <Column
          field="overslaPerc"
          header="%OverSLA"
          sortable={true}
          style={{ width: "90px", height: "40px" }}
        />
      </DataTable>
    );
  }

  renderDetailCourierData() {
    const courierFooter = `Total Kurir: ${this.detailCourierDataSource.total}  |  Total Antar: ${this.state.courierDataAwbCount}  |  Total Terkirim: ${this.state.courierDataAwbDlvCount}  |  Total Problem: ${this.state.courierDataAwbProblemCount}  |  Total NoRep: ${this.state.courierDataAwbNoRepCount}`;
    return (
      <DataTable
        emptyMessage={"Tidak ada data untuk ditampilkan."}
        scrollable={true}
        scrollHeight="250px"
        className="mt-4"
        value={this.detailCourierDataSource.data}
        loading={this.detailCourierDataSource.loading}
        footer={courierFooter}
        // header={courierheader}
        ref={(el) => {
          this.detailCourierTableExportData = el;
        }}
      >
        <Column
          field="trackingSite"
          header="Cabang"
          filter={true}
          filterMatchMode="contains"
          sortable={true}
          // frozen={true}
          style={{ width: "150px" }}
        />
        <Column
          field="courierNik"
          header="NIK"
          filter={true}
          filterMatchMode="contains"
          sortable={true}
          style={{ width: "100px" }}
        />
        <Column
          field="courierName"
          header="Nama"
          filter={true}
          filterMatchMode="contains"
          sortable={true}
          style={{ width: "200px" }}
        />
        <Column
          field="titleName"
          header="Jabatan"
          filter={true}
          filterMatchMode="contains"
          sortable={true}
          style={{ width: "200px" }}
        />
        <Column
          field="ant"
          header="Total Paket"
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="dlv"
          header="Delivered"
          body={this.columnCourierDetailLinkBody}
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="problem"
          header="Problem"
          body={this.columnCourierDetailLinkBody}
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="doneho"
          header="Sudah SO"
          body={this.columnCourierDetailLinkBody}
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="notho"
          header="Belum SO"
          body={this.columnCourierDetailLinkBody}
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="norep"
          header="Non Report"
          body={this.columnCourierDetailLinkBody}
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="dlvPerc"
          header="%DLV"
          sortable={true}
          style={{ width: "80px" }}
        />
        <Column
          field="podPerc"
          header="%POD"
          sortable={true}
          style={{ width: "80px" }}
        />
      </DataTable>
    );
  }

  renderDetailAwb(datasource, loadCallback) {
    var header = (
      <div style={{ textAlign: "left" }}>
        <Flex>
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            label="Tampilkan"
            onClick={() => loadCallback()}
          ></Button>
          <Button
            type="button"
            icon="pi pi-external-link"
            iconPos="left"
            label="CSV"
            style={{ marginLeft: "10px" }}
            onClick={this.exportDetailAwbTable}
          ></Button>
        </Flex>
      </div>
    );
    const footer = `Total: ${datasource.total}`;
    return (
      <div>
        <DataTable
          emptyMessage={"Tidak ada data untuk ditampilkan."}
          scrollable={true}
          scrollHeight="550px"
          className="mt-6"
          value={datasource.data}
          loading={datasource.loading}
          paginator={true}
          rows={50}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          footer={footer}
          header={header}
          ref={(el) => {
            this.detailAwbTableExportData = el;
          }}
        >
          <Column
            field="trackingSite"
            header="Cabang"
            filter={true}
            filterMatchMode="contains"
            sortable={true}
            // frozen={true}
            style={{ width: "200px", "vertical-align": "text-top" }}
          />
          <Column
            field="receiptNumber"
            header="Resi"
            filter={true}
            sortable={true}
            style={{ width: "130px", "vertical-align": "text-top" }}
            body={this.columnAwbNumber}
          />
          <Column
            field="transactionDate"
            header="Tanggal"
            sortable={true}
            style={{ width: "145px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              if (value) {
                const d = moment(value);
                if (d.isValid()) {
                  value = d.format("DD-MM-YYYY HH:mm");
                } else {
                  value = "-";
                }
              }

              return value;
            }}
          />
          <Column
            field="origin"
            header="Asal"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: "120px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              let originCode = rowData[column.field!];
              let originKota = rowData["asalKota"];
              let originKecamatan = rowData["asalKecamatan"];

              return (
                <div>
                  <div>
                    <b>{originCode}</b>
                  </div>
                  <div>
                    <small>
                      {originKota} ({originKecamatan})
                    </small>
                  </div>
                </div>
              );
            }}
          />
          <Column
            field="destination"
            header="Tujuan"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: "120px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              let destinationCode = rowData[column.field!];
              let destinationKota = rowData["tujuanKota"];
              let destinationKecamatan = rowData["tujuanKecamatan"];

              return (
                <div>
                  <div>
                    <b>{destinationCode}</b>
                  </div>
                  <div>
                    <small>
                      {destinationKota} ({destinationKecamatan})
                    </small>
                  </div>
                </div>
              );
            }}
          />
          <Column
            field="recipient"
            header="Penerima"
            sortable={true}
            filter={true}
            filterMatchMode="contains"
            style={{ width: "180px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              const phone = rowData["recipientPhone"];

              if (phone) {
                return (
                  <div>
                    <div>{value}</div>
                    <div>
                      <small>{phone}</small>
                    </div>
                  </div>
                );
              } else {
                return value;
              }
            }}
          />
          <Column
            field="layanan"
            header="Layanan"
            sortable={true}
            filter={true}
            style={{ width: "100px", "vertical-align": "text-top" }}
          />
          <Column
            field="codValue"
            header="Nilai COD"
            sortable={true}
            style={{ width: "100px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              const colValue = rowData[column.field!];
              const fmtValue = colValue
                ? rowData[column.field!].toLocaleString()
                : "-";
              return <span>{fmtValue}</span>;
            }}
          />
          <Column
            field="leadTime"
            header="Usia Paket"
            sortable={true}
            filter={true}
            style={{ width: "100px", "vertical-align": "text-top" }}
          />
          <Column
            field="overSLADaysInternal"
            header="Over SLA"
            sortable={true}
            filter={true}
            style={{ width: "120px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              const tglOverSla = moment(rowData["slaMaxDateTimeInternal"]);
              let sTglOverSla: string = "-";
              if (tglOverSla.isValid()) {
                sTglOverSla = tglOverSla.format("DD-MM-YYYY");
              }
              const overSlaDays = rowData[column.field!];

              return (
                <div>
                  <div>
                    <b>{overSlaDays} hari</b>
                  </div>
                  <div>
                    <small>{sTglOverSla}</small>
                  </div>
                </div>
              );
            }}
          />
          <Column
            field="statusTrackingType"
            header="Status Akhir Gerai"
            sortable={true}
            filter={true}
            style={{ width: "100px", "vertical-align": "text-top" }}
          />
          <Column
            field="statusDate"
            header="Info Status Akhir Gerai"
            sortable={false}
            style={{ width: "250px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              let tgl = rowData[column.field!];
              if (tgl) {
                tgl = moment(tgl).format("DD-MM-YYYY HH:mm");
              } else {
                tgl = "-";
              }

              let note = rowData["statusNote"];
              if (!note) {
                note = "-";
              }

              return (
                <div>
                  <div>
                    <b>Tanggal:</b> {tgl}
                  </div>
                  <div>
                    <small>
                      <b>Note: </b>
                      {note}
                    </small>
                  </div>
                </div>
              );
            }}
          />
          <Column
            field="lastStatusTrackingType"
            header="Status Terkini"
            sortable={true}
            filter={true}
            style={{ width: "100px", "vertical-align": "text-top" }}
          />
          <Column
            field="lastStatusDate"
            header="Info Status Terkini"
            sortable={false}
            style={{ width: "250px", "vertical-align": "text-top" }}
            body={(rowData, column: ColumnProps) => {
              let tgl = rowData[column.field!];
              let trackingSite = rowData["lastStatusTrackingSite"];
              if (tgl) {
                tgl = moment(tgl).format("DD-MM-YYYY HH:mm");
              } else {
                tgl = "-";
              }

              let note = rowData["lastStatusNote"];
              if (!note) {
                note = "-";
              }

              return (
                <div>
                  <div>
                    <b>Cabang:</b> {trackingSite}
                  </div>
                  <div>
                    <b>Tanggal:</b> {tgl}
                  </div>
                  <div>
                    <b>Note: </b>
                    <small>{note}</small>
                  </div>
                </div>
              );
            }}
          />
        </DataTable>
      </div>
    );
  }

  renderDetailBestData() {
    return this.renderDetailAwb(this.detailBestDataSource, () =>
      this.loadDetailBestData()
    );
  }

  renderDetailCodData() {
    return this.renderDetailAwb(this.detailCodDataSource, () =>
      this.loadDetailCodData()
    );
  }

  renderDetailOverslaData() {
    return this.renderDetailAwb(this.detailOverslaDataSource, () =>
      this.loadDetailOverslaData()
    );
  }

  renderDetailWillOverslaData() {
    return this.renderDetailAwb(this.detailWillOverslaDataSource, () =>
      this.loadDetailWillOverslaData()
    );
  }

  renderDetailAntNotPodData() {
    return this.renderDetailAwb(this.detailAntNotPodDataSource, () =>
      this.loadDetailAntNotPodData()
    );
  }

  render() {
    const dialogFooter = (
      <div>
        <Button
          label="Export"
          onClick={this.onClickExportPdf}
          className="p-button-success"
          icon={this.state.exportPdfLoading ? "pi pi-spin pi-spinner" : ""}
          disabled={this.state.exportPdfLoading}
        />
      </div>
    );

    return (
      <div>
        <Dialog
          header="Export PDF"
          footer={dialogFooter}
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          <h4>Catatan</h4>
          <InputTextarea
            style={{ width: "100%" }}
            rows={5}
            value={this.state.exportPdfNote}
            onChange={this.handleNoteChange}
          />
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <MonitoringPodKepalaKurirFilterContainerSnowflake ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                className="p-button-primary"
                label="Export PDF"
                onClick={() => {
                  this.setState({ showExportDialog: true });
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="row mb-10">
          <div className="col-md-3 text-center">
            <Card title="BEST BELUM TERKIRIM">
              <h3>{this.countBest >= 0 ? this.countBest : "-"}</h3>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                style={{ marginTop: "6px" }}
                disabled={this.loadingAwbDetail}
                onClick={() => this.loadDetailBestData()}
              />
            </Card>
          </div>
          <div className="col-md-3 text-center">
            <Card title="SUDAH OVERSLA">
              <h3>{this.countOversla >= 0 ? this.countOversla : "-"}</h3>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                style={{ marginTop: "6px" }}
                disabled={this.loadingAwbDetail}
                onClick={() => this.loadDetailOverslaData()}
              />
            </Card>
          </div>
          <div className="col-md-3 text-center">
            <Card title="AKAN OVERSLA">
              <h3>
                {this.countWillOversla >= 0 ? this.countWillOversla : "-"}
              </h3>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                style={{ marginTop: "6px" }}
                disabled={this.loadingAwbDetail}
                onClick={() => this.loadDetailWillOverslaData()}
              />
            </Card>
          </div>
          <div className="col-md-3 text-center">
            <Card title="RESI KEMARIN BELUM POD">
              <h3>{this.countAntNotPod >= 0 ? this.countAntNotPod : "-"}</h3>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                style={{ marginTop: "6px" }}
                disabled={this.loadingAwbDetail}
                onClick={() => this.loadDetailAntNotPodData()}
              />
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <TabView>
                <TabPanel header="Cabang">
                  <div>
                    {this.renderBranchData()}
                    <br />
                    <h3>
                      <b>Kurir</b>
                    </h3>
                    {this.renderDetailCourierData()}
                  </div>
                </TabPanel>
                <TabPanel header="Resi Best">
                  <div>{this.renderDetailBestData()}</div>
                </TabPanel>
                <TabPanel header="Resi COD">
                  <div>{this.renderDetailCodData()}</div>
                </TabPanel>
                <TabPanel header="Resi Over SLA">
                  <div>{this.renderDetailOverslaData()}</div>
                </TabPanel>
                <TabPanel header="Resi Over SLA Besok">
                  <div>{this.renderDetailWillOverslaData()}</div>
                </TabPanel>
                <TabPanel header="Resi Kemarin belum POD">
                  <div>{this.renderDetailAntNotPodData()}</div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
