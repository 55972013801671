import React, { RefObject } from "react";
import { observer } from "mobx-react";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { DataTableFilterService } from "../../../services/DataTableFilterService";
import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DataSource } from "../../../models/DataSource";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Formik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Growl } from "primereact/growl";
import { Flex } from "reflexbox";

@observer
export class TargetPembawaanContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  carryTargetData;
  userData;
  growl;
  dataSource = new DataSource<any>();
  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      showDialogCarryTarget: false,
      showDialogConfirm: false,
      detailDataCarryTarget: {},
    }

    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
  }

  loadData (){
    this.dataSource.loading = true
    this.monitoringRestService.get("productivities/list-carry-target").subscribe(
      (response) => {
        this.dataSource.setData(response);
        this.dataSource.setTotal(response.length);
      },
      () => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    )
  }

  columnActionTemplate = (rowData, column: ColumnProps) => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onEditClick(rowData)}
        ></Button>
      </div>
    );
  }

  onEditClick = (rowData) => {
    this.setState({detailDataCarryTarget : {
      branchName: rowData["branchName"],
      siteCode: rowData["siteCode"],
      carryTarget: rowData["carryTarget"],
      districtCoverage: rowData["districtCoverage"],
      district: rowData["district"],
      id: rowData["_id"],
      userUpdate: rowData["userUpdate"]
    },
    showDialogCarryTarget: true,});
  }

  updateProperty(property, value) {
    let detailDataCarryTargetNew = this.state.detailDataCarryTarget;
    detailDataCarryTargetNew[property] = value;
    this.setState({ detailDataCarryTarget : detailDataCarryTargetNew })
  }

  onSaveClick = () => {
    this.setState({ showDialogConfirm: true })
  }

  onYesClick = () => {
    const { id, carryTarget, district, districtCoverage } = this.state.detailDataCarryTarget;
    const params = {
      _id:  id,
      carryTarget: carryTarget,
      district: district,
      districtCoverage: districtCoverage,
      userUpdate: this.userData.username,
    };

    this.monitoringRestService
      .post("productivities/update-carry-target", params)
      .subscribe(
        (response) => {
          try {
            this.setState({
              showDialogCarryTarget: false,
              showDialogConfirm: false,
            })
            this.loadData();
            let msg = {
              severity: "success",
              summary: "Success Message",
              detail: "Sukses Update Data",
            };
            this.growl.show(msg);
          } catch (error) {
            console.log(error)
          }
        },
        error => {
          this.setState({
            showDialogCarryTarget: false,
            showDialogConfirm: false,
          });
          let msg = {
            severity: "error",
            summary: "Error Message",
            detail: "Gagal Update Data",
          };
          this.growl.show(msg);
        },
        () => {
          this.setState({
            showDialogCarryTarget: false,
            showDialogConfirm: false,
          });
        },
      );
  };

  render() {
    const dialogFooter = (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => this.setState({showDialogCarryTarget: false})}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={() => this.onSaveClick()}
        />
      </div>
    )
    
    const dialogFooterConfirm = (
      <div>
        <Button
          label="No"
          onClick={() => this.setState({showDialogConfirm: false})}
        />
        <Button
          label="Yes"
          onClick={() => this.onYesClick()}
        />
      </div>
    )

    return (
      <div>
        <Dialog
          header="Confirm Dialog"
          footer={dialogFooterConfirm}
          visible={this.state.showDialogConfirm}
          onHide={() => this.setState({ showDialogConfirm: false})}
          modal={true}
        >
          Apakah anda yakin ingin mengganti data tersebut?
        </Dialog>
        <Dialog
          header="Ubah Target Pembawaan dan Cover Kecamatan"
          footer={dialogFooter}
          visible={this.state.showDialogCarryTarget}
          onHide={() => this.setState({ showDialogCarryTarget: false })}
          modal={true}
          style={{width: "40vw"}}
        >
          <div className="p-grid p-fluid">
            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label className="p-col-4">Cabang</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                value={this.state.detailDataCarryTarget.branchName}
                disabled
              />
            </div>
            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label className="p-col-4">Kode Cabang</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                value={this.state.detailDataCarryTarget.siteCode}
                disabled
              />
            </div>
            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label className="p-col-4">Target Pembawaan</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="carryTarget"
                type="number"
                onChange={(e: any) => {
                  this.updateProperty("carryTarget", e.target.value);
                }}
                value={this.state.detailDataCarryTarget.carryTarget}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label className="p-col-4">Cover Kecamatan</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                value={this.state.detailDataCarryTarget.districtCoverage}
                type="number"
                onChange={(e: any) => {
                  this.updateProperty("districtCoverage", e.target.value);
                }}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label className="p-col-4">Area Kecamatan</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputTextarea
                value={this.state.detailDataCarryTarget.district}
                type="text"
                onChange={(e: any) => {
                  this.updateProperty("district", e.target.value);
                }}
              />
            </div>
          </div>
        </Dialog>
        
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <h1>Update Target Pembawaan</h1>

        <Flex className="card w-full" style={{ marginTop: "10px" }}>
          <Button
            className="p-button-success mr-2"
            label="Tampilkan"
            onClick={() => this.loadData()}
          />
        </Flex>

        <div className="p-grid">
          <div className="p-col-2">
            <div className={`card`}>
              <p className="text-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total Gerai
                </span>
              </p>
              <p className="text-center" style={{ marginBottom: "0px" }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {this.dataSource.total}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage="Tidak ada data untuk ditampilkan."
                scrollable={true}
                scrollHeight="365px"
                className="mt-4"
                paginator={true}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                value={this.dataSource.data}
                loading={this.dataSource.loading}
              >
                <Column
                  body={this.columnActionTemplate}
                  style={{ textAlign: "center", width: "5em" }}
                />
                <Column
                  field="branchName"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="siteCode"
                  header="Kode Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="carryTarget"
                  header="Target Pembawaan"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "100px", textAlign: "center" }}
                />
                <Column
                  field="districtCoverage"
                  header="Cover Kecamatan"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "100px", textAlign: "center" }}
                  
                />
                <Column
                  field="district"
                  header="Area Kecamatan"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "250px", textAlign: "center", overflow:'auto' }}
                />
                <Column
                  field="userUpdate"
                  header="Update by User"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "100px", textAlign: "center" }}
                />
                <Column
                  field="updatedAt"
                  header="Update Date Time"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "200px" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    )
  }
}