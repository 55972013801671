import PropTypes from 'prop-types';
import React, { RefObject } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import '../../css/CustomAdmin.css';
import { AwbDetailModalComponent } from '../../containers/awb/AwbDetailModalComponent';
// import { ConfrimDetailModalComponent } from '../../containers/confrim/ConfrimDetailModalComponent';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import DropdownBranch from "./../../components/dropdown/DropdownBranchSpartan";
import { AutoComplete } from 'primereact/autocomplete';
import { Growl } from 'primereact/growl';
import _ from 'lodash';

export class LayoutAdminTopbar extends React.Component<any, any> {
  restService = DependencyContainer.get(MonitoringRestService);
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  // refConfrimDetailModal: RefObject<ConfrimDetailModalComponent> = React.createRef();

  trackingSites;
  userData;
  growl;
  adminRoles: string[] = ['Superadmin', 'Developer', 'Control Tower','Root IT','Audit Internal', 'Developer Mode','Root Business Process'];

  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.userData = (localStorage.getItem('userData')) ? JSON.parse(localStorage.userData) : null;
    const allowChangeTrackingSite = _.includes(this.adminRoles, this.userData.role_name);
    const allowSearchAwb = !(this.userData.role_name == 'Admin Hub');
    this.state = {
      awbNumber: '',
      confrim:'',
      selectedTrackingSite: null,
      allowChangeTrackingSite: allowChangeTrackingSite,
      allowSearchAwb: allowSearchAwb,
    };
  }

  componentDidMount() {}

  handleTrackingAwbChange = (e) => {
    this.setState({ awbNumber: e.target.value });
  }
  handleTrackingConfrimChange = (e) => {
    this.setState({ confrim: e.target.value });
  }

  onClickAwbNumber() {
    this.refAwbDetailModal.current!.handleShow(this.state.awbNumber);
  }
  // onClickNoConfrim() {
  //   this.refConfrimDetailModal.current!.handleShow(this.state.confrim);
  // }

  onChangeTrackingSite(selectedTrackingSite = null) {
    const ts = (selectedTrackingSite) ? selectedTrackingSite : this.state.selectedTrackingSite;
    if (ts && ts.value) {
      localStorage.setItem('branchCode', ts.value);
      let msg = { severity: 'success', summary: '', detail: 'Cabang telah diubah.' };
      this.growl.show(msg);
    }
    
    this.setState({ selectedTrackingSite: null });
  }

  render() {
    return (
      <div>
        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />

        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        {/* <ConfrimDetailModalComponent ref={this.refConfrimDetailModal} /> */}

        <div className="layout-topbar clearfix">
          <button
            className="p-link layout-menu-button"
            onClick={this.props.onToggleMenu}>
            <span className="pi pi-bars" />
          </button>

          {this.state.allowSearchAwb ?
            (
              <span className="awb-topbar-search">
                <div className="p-inputgroup">
                  <InputText type="text" placeholder="Search Awb" className="awb-topbar-search-input" onChange={this.handleTrackingAwbChange} />
                  <Button icon="pi pi-search" className="awb-topbar-search-btn" onClick={() => this.onClickAwbNumber()} />
                </div>
              </span>
            ) : ''
          }
          {/* {this.state.allowSearchAwb ?
            (
              <span className="awb-topbar-search">
                <div className="p-inputgroup">
                  <InputText type="text" placeholder="Search Konfirmasi" className="awb-topbar-search-input" onChange={this.handleTrackingConfrimChange} />
                  <Button icon="pi pi-search" className="awb-topbar-search-btn" onClick={() => this.onClickNoConfrim()} />
                </div>
              </span>
            ) : ''
          } */}

          {this.state.allowChangeTrackingSite ? (
              <span className="awb-topbar-search">
              <div className="p-inputgroup">
                <DropdownBranch
                  name='branch'
                  onChange={e => this.onChangeTrackingSite(e)}
                  value={this.state.selectedTrackingSite} 
                />
              </div>
            </span>
          ) : (
              <span></span>
            )}          

          {/* <div className="layout-topbar-icons">
            <button className="p-link">
              <span className="layout-topbar-item-text">Settings</span>
              <span className="layout-topbar-icon pi pi-cog" />
            </button>
            <button className="p-link">
              <span className="layout-topbar-item-text">User</span>
              <span className="layout-topbar-icon pi pi-user" />
            </button>
          </div> */}
        </div>
      </div>
    );
  }
}
