import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from "reflexbox";

import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AuditTrackingSummaryFilterContainer  } from "./AuditTrackingSummaryFilterContainer";
import _, { upperCase } from "lodash";
import moment from "moment";
import { IAuditTrackingCodFilterContainerValue } from "./IAuditTrackingCodFilterContainerValue";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Growl } from "primereact/growl";
import { TrackingSiteEditFormModal } from "../trackingSite/TrackingSiteEditFormModal";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { IAuditTrackingCodDetailCourierContainerQuery } from "./AuditTrackingCodDetailCourierContainer";

import { toJS } from "mobx";
@observer

export class AuditTrackingCodSummaryContainer extends React.Component<
any,
any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  data;
  growl;
  tableExportData;
  formFilters: IAuditTrackingCodFilterContainerValue;
  // formFiltersDetail: IMonitoringPodFilterContainerValueNew;
  dataSource = new DataSource<any>();
  refFilter: RefObject<
  AuditTrackingSummaryFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      expandedRows: null,
      displayUpdateDialog: false,
      selectedTrackingSiteId: null,
      footerTotal: {},
      userData: '',
      roleProduktivitas:[
        "Ops - Kepala Kurir SDS First Mile",
        "Ops - Kepala Kurir SDS Last Mile",
        "Ops - Kepala Sigesit",
        "Ops - Koordinator Regional",
        "Root Business Process",
        "Root Cost Control",
        "Root IT"
      ]
    };

    this.columnActionTemplate = this.columnActionTemplate.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
    let userData = ''
    if (localStorage.getItem('userData')) {
      userData = JSON.parse(localStorage.userData)
    }
    this.setState({
      userData,
    })
  }

  async loadData() {
    
    let formValue = this.refFilter.current!.formValues 
    this.formFilters = this.refFilter.current!.formValues;
    
    this.formFilters.startDate = moment(this.formFilters.periodeDate[0]).format("YYYY-MM-DD");
    if (this.formFilters.periodeDate[1] !== null) {
      this.formFilters.endDate = moment(this.formFilters.periodeDate[1]).format("YYYY-MM-DD");
    } else {
      this.formFilters.endDate = moment(this.formFilters.periodeDate[0]).format("YYYY-MM-DD");
    }

    const dateToleransi:any = []
    
    
    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    const { startDate, endDate, siteCode} = this.formFilters
    
    if (localStorage.getItem('userData')) {
      var userData = JSON.parse(localStorage.userData)
      var branchLocal = JSON.parse(localStorage.branchCode)
      var rolePermission =  this.state.roleProduktivitas.filter(role => { 
        return role === userData.role_name
      })
    }
    console.log(this.formFilters)
    let url = `monitoring-tracking-cod/sf?startDate=${this.formFilters['startDate']}&endDate=${this.formFilters['endDate']}`;
    
    if (this.formFilters['cabang']) {
      url =`monitoring-tracking-cod/sf?startDate=${this.formFilters['startDate']}&endDate=${this.formFilters['endDate']}&branch_name=${this.formFilters['cabang']}`
    }
    if (this.formFilters['awbNumber']) {
      url =`monitoring-tracking-cod/sf?startDate=${this.formFilters['startDate']}&endDate=${this.formFilters['endDate']}&nostt=${this.formFilters['awbNumber']}`
    }
    if (this.formFilters['cabang']&&this.formFilters['awbNumber']) {
      url =`monitoring-tracking-cod/sf?startDate=${this.formFilters['startDate']}&endDate=${this.formFilters['endDate']}&branch_name=${this.formFilters['cabang']}&nostt=${this.formFilters['awbNumber']}`
    }
  
    this.monitoringRestService
      .get(url)
      .subscribe(
        (response) => {
          this.processData(response);
        },
        (e) => {
          
         
          let msg = {
            severity: "error",
            summary: e.response.data['message'],
          };
          this.growl.show(msg);
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  loadRowData(trackingSiteId): Observable<any> {
    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.startDate = moment(this.formFilters.startDate).format("YYYY-MM-DD");

    const filters = Object.assign({}, this.formFilters, {
      trackingSiteId: trackingSiteId,
    });

    return this.monitoringRestService.post("capacity/pod/branch", filters).pipe(
      tap((response) => {
        if (response && response.length && response.length > 0) {
          const r = response[0];
          let dIdx = this.data.findIndex(
            (x) => x.trackingSiteId === trackingSiteId
          );
          if (dIdx >= 0) {
            const d = this.data[dIdx];
            this.data[dIdx] = Object.assign({}, d, r);
            this.processData(this.data);
          }
        }
      })
    );
  }

  processData(data) {
    for (const dt of data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource()
    }

    const resultData = data.map((d) => {
      return {
        branch_code: d.BRANCH_CODE,
        branch_name: d.BRANCH_NAME,
        codnilai: d.CODNILAI,
        isau: d.ISAU,
        isba: d.ISBA,
        ishold: d.ISHOLD,
        isnth: d.ISNTH,
        nostt: d.NOSTT,
        tglfoto: d.TGLFOTO,
      }
    });
    this.data = resultData;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

 

  onUpdateClick(data) {
    this.setState({
      displayUpdateDialog: true,
      selectedTrackingSiteId: data["trackingSiteId"],
    });
  }

  async exportCsv() {
    await this.tableExportData.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  columnPercentBody = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ];
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[0];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[1];
    } else if (colValue > 90 && colValue <= 110) {
      backgroundSet = background[2];
    } else if (colValue > 110 && colValue <= 120) {
      backgroundSet = background[3];
    } else {
      backgroundSet = background[4];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnPercentBodyOps = (rowData, column: ColumnProps) => {
    const background = [
      '#FFE598',
      '#C5E0B3',
      '#70AD47',
      '#ED7D31',
      '#FF0000'
    ]
    const colValue = rowData[column.field!];
    let backgroundSet = '';
    if (colValue < 80) {
      backgroundSet = background[4];
    } else if (colValue > 80 && colValue <= 90) {
      backgroundSet = background[3];
    } else if (colValue > 90 && colValue <= 105) {
      backgroundSet = background[2];
    } else if (colValue > 105 && colValue <= 110) {
      backgroundSet = background[1];
    } else {
      backgroundSet = background[0];
    }
    return <div style={{ background: backgroundSet }}>{rowData[column.field!]}%</div>;
  };

  columnActionTemplate(rowData) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onUpdateClick(rowData)}
        ></Button>
      </div>
    );
  }

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });
    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
      this.growl.show(msg);
    }
  }

  columnCourierDetailLinkBody = (rowData, column: ColumnProps, podData) => {
    const data = this.refFilter.current!.formValues 
    console.log(data)
    
    return (
      <Link
        to={{
          pathname: '/admin/monitoring-tracking-cod/detail',
          search: qs.stringify({
            startDate: data['startDate'],
            endDate:data['endDate']
          } as IAuditTrackingCodDetailCourierContainerQuery),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }
  columnAU = (rowData, column: ColumnProps, podData) => {
    const data = this.refFilter.current!.formValues 
    if (rowData.isau == 0) {
      return 0
    }
    
    return (
      <Link
      to={{
        pathname: '/admin/monitoring-tracking-cod/detail',
        search: qs.stringify({
          startDate: data['startDate'],
          endDate: data['endDate'],
          title: 'Antar Ulang (AU)',
          status: 'isau',
          nostt: rowData.nostt,
          branch_code: rowData.branch_code,
          branch_name:rowData.branch_name
        } as IAuditTrackingCodDetailCourierContainerQuery),
      }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }
  columnHold = (rowData, column: ColumnProps, podData) => {
    const data = this.refFilter.current!.formValues 
    if (rowData.ishold == 0) {
      return 0
    }
    
    return (
      <Link
      to={{
        pathname: '/admin/monitoring-tracking-cod/detail',
        search: qs.stringify({
          startDate: data['startDate'],
          endDate: data['endDate'],
          title: 'Hold Pending',
          status: 'ishold',
          nostt: rowData.nostt,
          branch_code: rowData.branch_code,
          branch_name:rowData.branch_name
        } as IAuditTrackingCodDetailCourierContainerQuery),
      }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }
  columnNth = (rowData, column: ColumnProps, podData) => {
    const data = this.refFilter.current!.formValues 
    if (rowData.isnth == 0) {
      return 0
    }
    
    return (
      <Link
        to={{
          pathname: '/admin/monitoring-tracking-cod/detail',
          search: qs.stringify({
            startDate: data['startDate'],
            endDate: data['endDate'],
            title: 'Not at Home',
            status: 'isnth',
            nostt: rowData.nostt,
            branch_code: rowData.branch_code,
            branch_name:rowData.branch_name
          } as IAuditTrackingCodDetailCourierContainerQuery),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }
  columnBA = (rowData, column: ColumnProps, podData) => {
    const data = this.refFilter.current!.formValues 
    if (rowData.isba == 0) {
      return 0
    }
    
    return (
      <Link
      to={{
        pathname: '/admin/monitoring-tracking-cod/detail',
        search: qs.stringify({
          startDate: data['startDate'],
          endDate: data['endDate'],
          title: 'Bad Address',
          status: 'isba',
          nostt: rowData.nostt,
          branch_code: rowData.branch_code,
          branch_name:rowData.branch_name
        } as IAuditTrackingCodDetailCourierContainerQuery),
      }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }

  rowExpansionTemplate(data) {
    const podData = (_.find(this.data, (d: any) => { return d.trackingSiteCode === data.trackingSiteCode }));
    console.log(podData);
    let totalPaketMasuk = 0
    let totalResi = 0
    let totalCoverage = 0
    if (podData.dataCoverage) {
      podData.dataCoverage.map(data => {
        totalPaketMasuk += Number(data.paketMasuk)
        totalResi += Number(data.totalResi)
      })
      totalCoverage = podData.dataCoverage.length
    }
    
    
    const datasource = podData.courierDataSource;
    // var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = <div style={{ textAlign: 'left' }}>
      <Button style={{ width: '80px' }} type="button" icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : "pi pi-external-link"} iconPos="left" label="CSV" onClick={() => this.exportCourierCsv(podData)}></Button>
      {/* <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}>Total Kurir: {(datasource) ? datasource.total : 0}  |  Total antar: {podData.courierDataAwbCount}  |  Total Terkirim: {podData.courierDataAwbDlvCount}  |  Total Problem: {podData.courierDataAwbProblemCount}  |  Total NoRep: {podData.courierDataAwbNoRepCount}</span></div>; */}
      <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}> Total Kecamatan: {totalCoverage}  |  Total Paket Masuk: {totalPaketMasuk}  |  Total Resi: {totalResi}</span></div>;
    return (
      <div className="p-grid p-fluid" style={{ padding: '1em 1em 1em 1em' }}>
        <div className="p-col-12 p-md-12">
          <DataTable
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="200px"
            className="mt-4"
            value={datasource.data}
            loading={datasource.loading}
            footer={footer}
            ref={(el) => { podData.exportTableData = el; }}>
            <Column
              field="siteCode"
              header="Kode Cabang"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="branchName"
              header="Nama Cabang"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '200px' }}
            />
            <Column
              field="tujuanKota"
              header="Kota/Kab"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="destination"
              header="Kode Tujuan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="tujuanKecamatan"
              header="Kecamatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="isCovered"
              header="Coverage Kecamatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="paketMasuk"
              header="Paket Masuk"
              sortable={true}
              style={{ width: '60px' }}
            />
            {/* <Column
              field="totalResi"
              header="Total Resi"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            /> */}
          </DataTable>
        </div>
      </div>
    );
  }

  loadCourierData(podData) {
    const formFilters = Object.assign({}, this.formFilters, { siteCode: podData.trackingSiteCode });
    const datasource = podData.courierDataSource;
    return new Promise((resolve, reject) => {
      datasource.loading = true;
      this.monitoringRestService
        .post('capacity/pod/branch/coverage', formFilters)
        .subscribe(
          response => {
            
            datasource.setData(response);
            datasource.setTotal(response.length);
            podData.courierDataLoaded = true;
            podData.courierDataAwbCount = _.sumBy(response, function (o: any) { return o.ant; });
            podData.courierDataAwbDlvCount = _.sumBy(response, function (o: any) { return o.dlv; });
            podData.courierDataAwbProblemCount = _.sumBy(response, function (o: any) { return o.problem; });
            podData.courierDataAwbNoRepCount = _.sumBy(response, function (o: any) { return o.norep; });
            podData.dataCoverage = response
            resolve(response);
          },
          err => {
            reject(err);
          },
          () => {
            datasource.loading = false;
          },
        );
    });
  }

  async onRowToggle(e) {
    // this.setState({ expandedRows: e.data });
    // console.log(this.data)
    const promises: any[] = [];
    if (e.data) {
      for (var key in e.data) {
        // check also if property is not inherited from prototype
        if (e.data.hasOwnProperty(key)) {
          var value = e.data[key];
          if (value) {
            const podData = _.find(this.data, (d) => d.trackingSite === key);
            // console.log(podData)
            if (podData && !podData.courierDataLoaded) {
              promises.push(this.loadCourierData(podData));
              // await this.loadCourierData(podData);
            }
          }
        }
      }
    }

    await Promise.all(promises);
    this.setState({ expandedRows: e.data });
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";

    return fmtValue
  }


  render() {
    let totalGood = 0
    let totalBad = 0
    let totalWarning = 0
    if (this.dataSource) {
      this.dataSource.data.map((d, i) => {
        
        if (upperCase(d.justifikasi) === "BAD") {
          totalBad++
        } else if (upperCase(d.justifikasi) === "GOOD") { 
          totalGood++
        } else if (upperCase(d.justifikasi) === "WARNING") { 
          totalWarning++
        }       
       })
    }

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Audit Tracking COD</h1>
            <AuditTrackingSummaryFilterContainer  ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                style={{ marginLeft:"1%"}}
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="CSV"
                onClick={this.exportCsv}
              ></Button>
              {/* <Button
                style={{ marginLeft:"1%"}}
                type="button"
                icon="pi pi-external-link"
                iconPos="left"
                label="Upload Data"
                onClick={this.exportCsv}
              ></Button> */}
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={"Tidak ada data untuk ditampilkan."}
                scrollable={true}
                scrollHeight="450px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => {
                  this.tableExportData = el;
                }}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.onRowToggle(e)}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"
              >
                <Column
                  field="branch_code"
                  header="Kode Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="branch_name"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="nostt"
                  header="No Resi"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                />
                <Column
                  field="tglfoto"
                  header="Tanggal Manifest"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: "200px" }}
                  body={(rowData, column: ColumnProps) => {
                    return rowData[column.field!] ? (
                      <div className='text-center'>
                        {moment(rowData[column.field!]).format(
                          "DD - MMM - YYYY",
                        )}
                      </div>
                    ) : (
                      ""
                    );
                  }}
                />
                <Column
                  field="isnth"
                  header="Not at Home"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "200px" }}
                  body={this.columnNth}
                />
                <Column
                  field="isau"
                  header="Antar Ulang"
                  filter={false}
                  sortable={false}
                  filterMatchMode="contains"
                  style={{ width: "200px" }}
                  body={this.columnAU}
                />
                
                <Column
                  field="ishold"
                  header="Hold Pending"
                  filter={false}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "200px" }}
                  body={this.columnHold}
                />
                <Column
                  field="isba"
                  header="Bad Address"
                  style={{ width: "200px" }}
                  body={this.columnBA}
                />
                <Column
                  field="codnilai"
                  header="Nilai COD value"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "170px" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
