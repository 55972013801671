import {observable} from "mobx";
import {observer} from "mobx-react";
import React, {RefObject} from "react";
import {DependencyContainer} from "../../utils/DependencyInjection";
import {MonitoringRestService} from "../../services/MonitoringRestService";
import _ from "lodash";
import {Chart} from "primereact/chart";
import {Formik} from "formik";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Flex} from "reflexbox";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import moment from "moment";
import {DataSource} from "../../models/DataSource";
import {DataTable} from "primereact/datatable";
import {Column, ColumnProps} from "primereact/column";
import {TabView, TabPanel} from "primereact/components/tabview/TabView";
import {DateHelper} from "../../utils/DateHelper";
import {Dialog} from "primereact/dialog";
import {Growl} from "primereact/growl";
import BranchDropdown from "../attendance-driver/dropdown/BranchDropdown";
import SiteDropdown from "../attendance-driver/dropdown/SiteDropdown";
import BranchCodeDropdown from "../attendance-driver/dropdown/BranchCodeDropdown";
import CityDropdown from "../attendance-driver/dropdown/CityDropdown";
import CityDropdownNew from "../attendance-driver/dropdown/CityDropdownNew";
import ProvinceDropdown from "../attendance-driver/dropdown/ProvinceDropdown";
import ProvinceDropdownNew from "../attendance-driver/dropdown/ProvinceDropdownNew";
import BranchNameDropdown from "../attendance-driver/dropdown/BranchNameDropdown";
export interface IAnalyticCapacityByPeriodContainerValue {
  startDate?: Date;
  endDate?: Date;
  name?: string;
  branchName?: string;
  siteCode?: string;
  branchCode?: string;
  province?: string;
  province1?: string;
  city?: string;
  city1?: string;
  [key: string]: any;
}

@observer
export class AnalyticCapacityByPeriodContainer extends React.Component<
  any,
  any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  cityDropdownRef: RefObject<CityDropdown>;
  dateRange: any = null;
  initialFormValues: IAnalyticCapacityByPeriodContainerValue = {
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    name: "",
    siteCode: "",
    branchCode: "",
    city: "",
    province: "",
  };

  data;
  dt;
  growl;

  dataSource = new DataSource<any>();

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 0,
      showLoading: false,
      showDialog: false,
      city: "",
      province: "",
      isRenderCity: false,
      isRenderCode: false,
      isRenderProvince: false,
      isRenderBranch: false,
    };
    this.export = this.export.bind(this);
    this.cityDropdownRef = React.createRef();
  }

  public async loadData() {
    const filterParams = this.refFormik.current!.state.values;
    const fParams = Object.assign({}, filterParams);
    this.setState({showLoading: true, showDialog: true});

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate,
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate,
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate,
      );
    if (fParams.name && fParams.name.label) {
      fParams.name = fParams.name.label;
    }
    if (fParams.branchCode && fParams.branchCode.label) {
      fParams.siteCode = fParams.branchCode.label;
      fParams.branchCode = "";
    }
    if (fParams.siteCode && fParams.siteCode.label) {
      fParams.siteCode = fParams.siteCode.label;
    }
    if (fParams.city && fParams.city.label) {
      fParams.city = fParams.city.label;
    }
    if (fParams.province && fParams.province.label) {
      fParams.province = fParams.province.label;
    }
    const params = {
      startDate: fParams.startDate,
      endDate: fParams.endDate,
      branchName: fParams.name,
      branchCode: fParams.siteCode,
      city: fParams.city,
      province: fParams.province,
    };

    this.resetData();
    this.dataSource.loading = true;
    this.monitoringRestService
      .post("capacity/report/summary", params)
      .subscribe(
        (response) => {
          this.data = response;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        (error) => {
          this.dataSource.loading = false;
          this.setState({showLoading: false, showDialog: false});
        },
        () => {
          this.dataSource.loading = false;
          this.setState({showLoading: false, showDialog: false});
        },
      );
  }

  resetData() {
    this.dataSource.setData([]);
    this.dataSource.setTotal(0);
  }

  export() {
    this.dt.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }
  onChangeCity = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("city", e);
      await this.setState({
        city: e,
      });
    } else {
      if (this.state.isRenderProvince) {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        await this.setState({
          city: "",
          isRenderProvince: false,
          isRenderCity: false,
        });
      } else {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        await this.setState({
          city: "",
          isRenderProvince: true,
          isRenderCity: true,
        });
      }

      formikProps.setFieldValue("city", "");
      await this.setState({
        city: "",
      });
    }
  };
  onChangeProvince = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("province", e);
      formikProps.setFieldValue("province1", e);
      await this.setState({
        province: e,
      });
    } else {
      if (this.state.isRenderCity) {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        await this.setState({
          province: "",
          isRenderProvince: false,
          isRenderCity: false,
        });
      } else {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        await this.setState({
          province: "",
          isRenderProvince: true,
          isRenderCity: true,
        });
      }
    }
  };
  onChangeBranch = async (formikProps, e) => {
    // console.log(e);

    if (e) {
      formikProps.setFieldValue("name", e);
      formikProps.setFieldValue("branchName", e);
    } else {
      if (this.state.isRenderCode) {
        formikProps.setFieldValue("name", "");
        formikProps.setFieldValue("branchName", "");
        formikProps.setFieldValue("siteCode", "");
        formikProps.setFieldValue("branchCode", "");
        await this.setState({
          isRenderCode: false,
          isRenderBranch: false,
        });
      } else {
        formikProps.setFieldValue("name", "");
        formikProps.setFieldValue("branchName", "");
        formikProps.setFieldValue("siteCode", "");
        formikProps.setFieldValue("branchCode", "");
        await this.setState({
          isRenderBranch: true,
          isRenderCode: true,
        });
      }
    }
  };
  onChangeCodeBranch = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("siteCode", e);
      formikProps.setFieldValue("branchCode", e);
    } else {
      if (this.state.isRenderBranch) {
        formikProps.setFieldValue("name", "");
        formikProps.setFieldValue("branchName", "");
        formikProps.setFieldValue("siteCode", "");
        formikProps.setFieldValue("branchCode", "");
        await this.setState({
          isRenderBranch: false,
          isRenderCode: false,
        });
      } else {
        formikProps.setFieldValue("name", "");
        formikProps.setFieldValue("branchName", "");
        formikProps.setFieldValue("siteCode", "");
        formikProps.setFieldValue("branchCode", "");
        await this.setState({
          isRenderBranch: true,
          isRenderCode: true,
        });
      }
    }
  };
  render() {
    var header = (
      <div style={{textAlign: "left"}}>
        <Button
          type='button'
          icon='pi pi-external-link'
          iconPos='left'
          label='CSV'
          onClick={this.export}
        ></Button>
      </div>
    );

    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column header='Tanggal' />
          <Column header='Kap. Tempat LM' />
          <Column header='Kap. Orang LM' />
          <Column header='Barang Masuk' />
          <Column header='Total Resi' />
          <Column header='Harus Antar' />
          <Column header='Kap. Tempat FM' />
          <Column header='Kap. Orang FM' />
          <Column header='Vol FM' />
        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        <Dialog
          header='Informasi'
          visible={this.state.showDialog}
          style={{width: "40vw"}}
          modal={true}
          onHide={() => this.setState({showDialog: false})}
        >
          Sedang memuat data, mohon jangan tinggalkan halaman ini
        </Dialog>
        <Growl ref={(el) => (this.growl = el)} style={{marginTop: "75px"}} />

        <div className='row'>
          <div className='card col-md-12'>
            <div>
              <Formik
                ref={this.refFormik}
                enableReinitialize
                initialValues={this.initialFormValues}
                onSubmit={() => {}}
              >
                {(formikProps) => (
                  <Flex wrap w={1}>
                    <Flex column>
                      <div className='form-group'>
                        <label className='col-md-4'>Periode</label>
                        <div className='col-md-8'>
                          <Flex column>
                            <Calendar
                              name='startDate'
                              readOnlyInput={true}
                              dateFormat='dd-mm-yy'
                              showWeek={true}
                              maxDate={formikProps.values.endDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange("startDate")}
                              value={formikProps.values.startDate}
                            />
                            <div className='mt-2 mb-2 text-center'>s/d</div>
                            <Calendar
                              name='endDate'
                              readOnlyInput={true}
                              dateFormat='dd-mm-yy'
                              showWeek={true}
                              minDate={formikProps.values.startDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange("endDate")}
                              value={formikProps.values.endDate}
                            />
                          </Flex>
                        </div>
                      </div>
                    </Flex>
                    <Flex style={{width: "350px"}} column>
                      <div className='form-group'>
                        <label className='col-md-4'>Nama Cabang</label>
                        <div className='col-md-8'>
                          {this.state.isRenderCode ? (
                            <BranchDropdown
                              name='branchName'
                              onChange={async (e) => {
                                // formikProps.setFieldValue("province", e);
                                this.onChangeBranch(formikProps, e);
                              }}
                              placeholder={"Pilih Nama Cabang"}
                              filters={{
                                parameter: "name",
                                value: formikProps.values.branchName,
                              }}
                              siteCode={formikProps.values.siteCode}
                              value={formikProps.values.branchName}
                            />
                          ) : (
                            <BranchNameDropdown
                              name='name'
                              onChange={async (e) => {
                                // formikProps.setFieldValue("province", e);
                                this.onChangeBranch(formikProps, e);
                              }}
                              placeholder={"Pilih Nama Cabang"}
                              filters={{
                                parameter: "name",
                                value: formikProps.values.name,
                              }}
                              siteCode={formikProps.values.siteCode}
                              value={formikProps.values.name}
                            />
                          )}
                          {/* <InputText
                            name='province'
                            type='text'
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.province}
                          /> */}
                        </div>
                      </div>

                      <div className='form-group'>
                        <label className='col-md-4'>Kode Cabang</label>
                        <div className='col-md-8'>
                          {this.state.isRenderCode ? (
                            <BranchCodeDropdown
                              name='branchCode'
                              onChange={(e) => {
                                this.onChangeCodeBranch(formikProps, e);
                              }}
                              placeholder={"Pilih Kode Cabang"}
                              filters={{
                                parameter: "siteCode",
                                value: formikProps.values.branchCode,
                              }}
                              branchName={formikProps.values.name}
                              value={formikProps.values.branchCode}
                            />
                          ) : (
                            <SiteDropdown
                              name='siteCode'
                              onChange={(e) => {
                                this.onChangeCodeBranch(formikProps, e);
                              }}
                              placeholder={"Pilih Kode Cabang"}
                              filters={{
                                parameter: "siteCode",
                                value: formikProps.values.siteCode,
                              }}
                              branchName={formikProps.values.name}
                              value={formikProps.values.siteCode}
                            />
                          )}
                        </div>
                      </div>
                    </Flex>
                    <Flex style={{width: "350px"}} column>
                      <div className='form-group'>
                        <label className='col-md-4'>Provinsi</label>
                        <div className='col-md-8'>
                          {this.state.isRenderProvince ? (
                            <ProvinceDropdown
                              name='province'
                              onChange={async (e) => {
                                // formikProps.setFieldValue("province", e);
                                this.onChangeProvince(formikProps, e);
                              }}
                              placeholder={"Pilih Province"}
                              filters={{
                                parameter: "province",
                                value: formikProps.values.province,
                              }}
                              // ref={this.cityDropdownRef}
                              branchName={formikProps.values.name}
                              city={formikProps.values.city}
                              value={formikProps.values.province}
                            />
                          ) : (
                            <ProvinceDropdownNew
                              name='province1'
                              onChange={async (e) => {
                                // formikProps.setFieldValue("province", e);
                                this.onChangeProvince(formikProps, e);
                              }}
                              placeholder={"Pilih Province"}
                              filters={{
                                parameter: "province",
                                value: formikProps.values.province1,
                              }}
                              // ref={this.cityDropdownRef}
                              branchName={formikProps.values.name}
                              city={formikProps.values.city}
                              value={formikProps.values.province1}
                            />
                          )}
                          {/* <InputText
                            name='province'
                            type='text'
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.province}
                          /> */}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-md-4'>Kota</label>
                        <div className='col-md-8'>
                          {this.state.isRenderCity ? (
                            <CityDropdownNew
                              name='city1'
                              onChange={async (e) => {
                                this.onChangeCity(formikProps, e);
                              }}
                              placeholder={"Pilih Kota"}
                              filters={{
                                parameter: "city",
                                value: formikProps.values.city1,
                              }}
                              branchName={formikProps.values.name}
                              province={formikProps.values.province}
                              value={formikProps.values.city1}
                            />
                          ) : (
                            <CityDropdown
                              name='city'
                              onChange={async (e) => {
                                this.onChangeCity(formikProps, e);
                              }}
                              placeholder={"Pilih Kota "}
                              filters={{
                                parameter: "city",
                                value: formikProps.values.city,
                              }}
                              branchName={formikProps.values.name}
                              province={formikProps.values.province}
                              value={formikProps.values.city}
                            />
                          )}
                        </div>
                      </div>
                    </Flex>
                  </Flex>
                )}
              </Formik>
            </div>
            <div>
              <div>
                <Button
                  className='p-button-success mr-2'
                  label='Tampilkan'
                  icon={this.state.showLoading ? "pi pi-spin pi-spinner" : ""}
                  disabled={this.state.showLoading}
                  onClick={() => this.loadData()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <TabView
              activeIndex={this.state.activeTabIndex}
              onTabChange={(e) => this.setState({activeTabIndex: e.index})}
            >
              <TabPanel header='Table'>
                <DataTable
                  emptyMessage={"Tidak ada data untuk ditampilkan."}
                  scrollable={true}
                  scrollHeight='550px'
                  style={{width: "100%"}}
                  className='mt-4'
                  value={this.dataSource.data}
                  loading={this.dataSource.loading}
                  header={header}
                  ref={(el) => {
                    this.dt = el;
                  }}
                  headerColumnGroup={headerGroup}
                >
                  <Column
                    field='date'
                    header='Tanggal'
                    sortable={false}
                    style={{width: "120px"}}
                    body={(rowData, column: ColumnProps) => {
                      return rowData[column.field!] ? (
                        <div className='text-center'>
                          {moment(rowData[column.field!]).format(
                            "dddd, DD - MMM - YYYY",
                          )}
                        </div>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Column
                    field='dlvBranchCapacityLM'
                    header='Kap. Tempat LM'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='dlvOpsCapacityLM'
                    header='Kap. Orang LM'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='inboundLM'
                    header='Barang Masuk'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='totalInboundLM'
                    header='Total Resi'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='totalTobeDeliveredLM'
                    header='Harus Antar'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='dlvBranchCapacityFM'
                    header='Kap. Tempat FM'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='dlvOpsCapacityFM'
                    header='Kap. Orang FM'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                  <Column
                    field='actualVolumeFM'
                    header='Vol FM'
                    sortable={false}
                    style={{width: "85px"}}
                    body={(rowData, column: ColumnProps) => {
                      return (
                        <span>{rowData[column.field!].toLocaleString()}</span>
                      );
                    }}
                  />
                </DataTable>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    );
  }
}
