import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, CancelTokenStatic } from 'axios';
import { Observable } from 'rxjs';

export class HttpClientAxiosService {
  public cancelToken: CancelTokenStatic = Axios.CancelToken;
  props: any;

  constructor(public axios: AxiosInstance) { }

  public post<T = any>(url: string = '', data?: any, config: AxiosRequestConfig = {}): Observable<T> {
    return Observable.create((observer) => {
      const axiosCancel = this.cancelToken.source();
      config.cancelToken = axiosCancel.token;
      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
      if (userData) {
        config.headers = {
          Authorization:
            `Bearer ${userData.bearer_token}`
        };
      }

      (this.axios.post(url, data, config) as AxiosPromise)
        .catch((error) => {
          observer.error(error)
          if (error !== undefined) {
            if (error["response"] !== undefined) {
              if (error["response"].status === 401) {
                localStorage.removeItem("branchCode");
                localStorage.removeItem("userData");
                window.location.href = "/auth/login";
                return
              }
            }
          }

        })
        .then((response) => {
          if (response) {
            observer.next(response.data);
          }
          observer.complete();
        });

      return () => {
        axiosCancel.cancel();
        observer.complete();
      };
    });
  }

  public put<T = any>(url: string = '', data?: any, config: AxiosRequestConfig = {}): Observable<T> {
    return Observable.create((observer) => {
      const axiosCancel = this.cancelToken.source();
      config.cancelToken = axiosCancel.token;
      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
      if (userData) {
        config.headers = {
          Authorization:
            `Bearer ${userData.bearer_token}`
        };
      }

      (this.axios.put(url, data, config) as AxiosPromise)
        .catch((error) => {
          observer.error(error)
          if (error !== undefined) {
            if (error["response"] !== undefined) {
              if (error["response"].status === 401) {
                localStorage.removeItem("branchCode");
                localStorage.removeItem("userData");
                window.location.href = "/auth/login";
                return
              }
            }
          }


        })
        .then((response) => {
          if (response) {
            observer.next(response.data);
          }
          observer.complete();
        });

      return () => {
        axiosCancel.cancel();
        observer.complete();
      };
    });
  }

  public get<T = any>(url: string = '', config: AxiosRequestConfig = {}): Observable<T> {
    return Observable.create((observer) => {
      const axiosCancel = this.cancelToken.source();
      config.cancelToken = axiosCancel.token;
      const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
      if (userData) {
        config.headers = {
          Authorization:
            `Bearer ${userData.bearer_token}`
        };
      }

      (this.axios.get(url, config) as AxiosPromise)
        .catch((error) => {
          observer.error(error)


          if (error !== undefined) {
            if (error["response"] !== undefined) {
              if (error["response"].status === 401) {
                localStorage.removeItem("branchCode");
                localStorage.removeItem("userData");
                window.location.href = "/auth/login";
                return
              }
            }
          }
        })
        .then((response) => {
          if (response) {
            observer.next(response.data);
          }
          observer.complete();
        });

      return () => {
        axiosCancel.cancel();
        observer.complete();
      };
    });
  }

  public delete<T = any>(url: string = '', config: AxiosRequestConfig = {}): Observable<T> {
    return Observable.create((observer) => {
      const axiosCancel = this.cancelToken.source();
      config.cancelToken = axiosCancel.token;

      (this.axios.delete(url, config) as AxiosPromise)
        .catch((error) => {
          observer.error(error)
          if (error !== undefined) {
            if (error["response"] !== undefined) {
              if (error["response"].status === 401) {
                localStorage.removeItem("branchCode");
                localStorage.removeItem("userData");
                window.location.href = "/auth/login";
                return
              }
            }
          }

        })
        .then((response) => {
          if (response) {
            observer.next(response.data);
          }
          observer.complete();
        });

      return () => {
        axiosCancel.cancel();
        observer.complete();
      };
    });
  }
}
