import { observer } from 'mobx-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';

import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'reflexbox';

import { DataSource } from '../../../models/DataSource';
import { DataTableFilterService } from '../../../services/DataTableFilterService';
import { MonitoringRestService } from '../../../services/MonitoringRestService';
import { DependencyContainer } from '../../../utils/DependencyInjection';
import { MonitoringPodFilterContainerSnowflake } from './MonitoringPodFilterContainerSnowflake';
import _ from 'lodash';
import moment from 'moment';
import { IMonitoringPodFilterContainerValueSnowflake } from './IMonitoringPodFilterContainerValueSnowflake';
import { IMonitoringPodDetailContainerQueryNew } from './../IMonitoringPodDetailContainerQueryNew';
import { IMonitoringPodKepalaKurirDetailCourierContainerQueryNew } from './../MonitoringPodKepalaKurirDetailCourierContainer';

@observer
export class MonitoringPodContainerSnowflake extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  data;
  tableExportData;
  courierTableExportData;
  formFilters: IMonitoringPodFilterContainerValueSnowflake;
  dataSource = new DataSource<any>();
  refFilter: RefObject<MonitoringPodFilterContainerSnowflake> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      expandedRows: null,
    }

    this.exportCsv = this.exportCsv.bind(this);
    this.onRowToggle = this.onRowToggle.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
  }

  loadData() {
    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.date = moment(this.formFilters.date).format('YYYY-MM-DD');
    this.formFilters.endDate = moment(this.formFilters.date).format('YYYY-MM-DD');
    let filterCod;
    if (this.formFilters.cod === "Y") {
      filterCod = true
    } else if (this.formFilters.cod === "N") {
      filterCod = false
    } else {
      filterCod = null
    }
    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    const mappedParams = {
      date: this.formFilters.date,
      city_name: this.formFilters.city ? this.formFilters.city.value : "",
      province_name: this.formFilters.province ? this.formFilters.province.value : "",
      layanan: this.formFilters.layanan ? this.formFilters.layanan.value : "",
      is_cod: filterCod,
      pengirim: this.formFilters.pengirim,
      partner: this.formFilters.partner ? this.formFilters.partner.value : "",
      representative_code: this.formFilters.perwakilan ? this.formFilters.perwakilan.value : "",
      sort_by: "city_name",
    }
    this.monitoringRestService
      .post('monitoring-pod/sf', mappedParams)
      .subscribe(
        response => {
          this.processData(response);
          // this.data = response;
          // this.dataSource.setData(this.data);
          // this.dataSource.setTotal(this.data.length);
          // this.populateDataSource();
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  processData(data) {
    for (const dt of data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource()
    }

    const mappedData = data.map((d) => {
      return {
        ...d,
        trackingSiteCity:	d.CITY_NAME,
        trackingSiteId:	d.BRANCH_ID,
        trackingSite:	d.BRANCH_NAME,
        trackingSiteCode:	d.BRANCH_CODE,
        perwakilan:	d.REPRESENTATIVE_CODE,
        prevOpenStatus:	d.PREV_PROCESS,
        inbound:	d.IN_PROCESS,
        totalInbound:	d.TOTAL,
        tobeDelivered:	d.TOBE_DELIVERED,
        deliveryProcessed:	d.DELIVERY_PROCESSED,
        delivering:	d.DELIVERING,
        delivered:	d.DELIVERED,
        deliveryProblem:	d.DELIVERY_PROBLEM,
        crissCross:	d.CRISS_CROSS,
        returned:	d.RETURN_PROCESS,
        outboundTransit:	d.OUT_TRANSIT,
        outbound3Pl:	d.OUT_THP,
        lostBroken:	d.LOST_BROKEN,
        ots:	d.OTS,
        stuck:	d.STUCK,
        moreso:	d.MORESO,
        doneho:	d.DONEHO,
        notho:	d.NOTHO,
        processedDlvPerc:	d.DLV_PERC,
      }
    })
        
    this.data = mappedData;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

  loadCourierData(podData) {
    const formFilters = this.formFilters;
    let filterCod;
    if (formFilters.cod === "Y") {
      filterCod = true
    } else if (formFilters.cod === "N") {
      filterCod = false
    } else {
      filterCod = null
    }
    const mappedParams = {
      date: formFilters.date,
      city_name: formFilters.city ? formFilters.city.value : "",
      province_name: formFilters.province ? formFilters.province.value : "",
      layanan: formFilters.layanan ? formFilters.layanan.value : "",
      is_cod: filterCod,
      pengirim: formFilters.pengirim,
      partner: formFilters.partner ? formFilters.partner.value : "",
      representative_code: formFilters.perwakilan ? formFilters.perwakilan.value : "",
      branch_code: podData.trackingSiteCode,
      sort_by: "employee_name_driver",
    }
    const datasource = podData.courierDataSource;
    return new Promise((resolve, reject) => {
      this.dataSource.loading = true;
      this.monitoringRestService
        .post('monitoring-pod/sf/courier', mappedParams)
        .subscribe(
          response => {
            const mappedData = response.map((d) => {
              return {
                ...d,
                courierNik: d.EMPLOYEE_NIK_DRIVER,
                courierName: d.EMPLOYEE_NAME_DRIVER,
                titleName: d.EMPLOYEE_ROLE_NAME,
                ant: d.TOTAL,
                dlv: d.DELIVERED,
                problem: d.PROBLEM,
                doneho: d.DONEHO,
                notho: d.NOTHO,
                norep: d.NOREP,
                dlvPerc: d.DLV_PERC,
                podPerc: d.POD_PERC,
                trackingSiteId: d.BRANCH_ID,
                courierId: d.EMPLOYEE_ID_DRIVER,
              }
            })
            datasource.setData(mappedData);
            datasource.setTotal(mappedData.length);
            podData.courierDataLoaded = true;
            podData.courierDataAwbCount = _.sumBy(mappedData, function (o: any) { return o.ant; });
            podData.courierDataAwbDlvCount = _.sumBy(mappedData, function (o: any) { return o.dlv; });
            podData.courierDataAwbProblemCount = _.sumBy(mappedData, function (o: any) { return o.problem; });
            podData.courierDataAwbNoRepCount = _.sumBy(mappedData, function (o: any) { return o.norep; });
            resolve(mappedData);
          },
          err => {
            reject(err);
            this.dataSource.loading = false;
          },
          () => {
            this.dataSource.loading = false;
          },
        );
    });
  }

  async exportCsv() {
    this.setState({ exportExcelLoading: true });
    await this.tableExportData.exportCSV();
    this.setState({ exportExcelLoading: false });
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });
    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
      this.growl.show(msg);
    }
  }

  async onRowToggle(e) {
    // this.setState({ expandedRows: e.data });

    const promises: any[] = [];
    if (e.data) {
      for (var key in e.data) {
        // check also if property is not inherited from prototype
        if (e.data.hasOwnProperty(key)) {
          var value = e.data[key];
          if (value) {
            const podData = _.find(this.data, (d) => d.trackingSiteCode === key);
            if (podData && !podData.courierDataLoaded) {
              promises.push(this.loadCourierData(podData));
              // await this.loadCourierData(podData);
            }
          }
        }
      }
    }

    await Promise.all(promises);
    this.setState({ expandedRows: e.data });
  }

  columnCourierDetailLinkBody = (rowData, column: ColumnProps, podData) => {
    const formFilters = {
      ...this.formFilters,
      city: this.formFilters.city ? this.formFilters.city.value : "",
      province: this.formFilters.province ? this.formFilters.province.value : "",
      layanan: this.formFilters.layanan ? this.formFilters.layanan.value : "",
      partner: this.formFilters.partner ? this.formFilters.partner.value : "",
      perwakilan: this.formFilters.perwakilan ? this.formFilters.perwakilan.value : "",
    };
    return (
      <Link
        to={{
          pathname: '/admin/monitoring-pod-new-palkur/courier-sf/detail',
          search: qs.stringify({
            courierId: rowData['courierId'],
            courierName: rowData['courierName'],
            courierNik: rowData['courierNik'],
            branchName: podData.trackingSite,
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: podData.trackingSiteCode,
          } as IMonitoringPodKepalaKurirDetailCourierContainerQueryNew),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  }

  rowExpansionTemplate(data) {
    const podData = (_.find(this.data, (d: any) => { return d.trackingSiteCode === data.trackingSiteCode }));
    const datasource = podData.courierDataSource;
    // var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = <div style={{ textAlign: 'left' }}>
      <Button style={{ width: '80px' }} type="button" icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : "pi pi-external-link"} iconPos="left" label="CSV" onClick={() => this.exportCourierCsv(podData)}></Button>
      <span style={{ textAlign: 'left', marginLeft: '8px', marginTop: '6px' }}>Total Kurir: {(datasource) ? datasource.total : 0}  |  Total antar: {podData.courierDataAwbCount}  |  Total Terkirim: {podData.courierDataAwbDlvCount}  |  Total Problem: {podData.courierDataAwbProblemCount}  |  Total NoRep: {podData.courierDataAwbNoRepCount}</span></div>;

    return (
      <div className="p-grid p-fluid" style={{ padding: '1em 1em 1em 1em' }}>
        <div className="p-col-12 p-md-12">
          <DataTable
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="200px"
            className="mt-4"
            value={datasource.data}
            loading={datasource.loading}
            footer={footer}
            ref={(el) => { podData.exportTableData = el; }}>
            <Column
              field="courierNik"
              header="NIK"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '120px' }}
            />
            <Column
              field="courierName"
              header="Nama"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="titleName"
              header="Jabatan"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: '150px' }}
            />
            <Column
              field="ant"
              header="Total Paket"
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="dlv"
              header="Delivered"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="problem"
              header="Problem"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="doneho"
              header="Sudah SO"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="notho"
              header="Belum SO"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="norep"
              header="Non Report"
              body={(rowData, column: ColumnProps) => this.columnCourierDetailLinkBody(rowData, column, data)}
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="dlvPerc"
              header="%DLV"
              sortable={true}
              style={{ width: '60px' }}
            />
            <Column
              field="podPerc"
              header="%POD"
              sortable={true}
              style={{ width: '60px' }}
            />
          </DataTable>
        </div>
      </div>
    );
  }

  // exportExcel() {
  //   this.setState({ exportExcelLoading: true, showExportDialog: true });
  //   this.formFilters = this.refFilter.current!.formValues;
  //   this.formFilters.date = moment(this.formFilters.date).format('YYYY-MM-DD');

  //   this.monitoringRestService
  //     .post('pod-tracking-site', this.formFilters)
  //     .subscribe(
  //       response => {
  //         if (response) {
  //           this.dataExcel = response.map(rec => {
  //             return {
  //               'Cabang': rec.trackingSite,
  //               'Kota': rec.trackingSiteCity,
  //               'Sisa Kemarin': rec.prevOpenStatus,
  //               'Masuk': rec.inbound,
  //               'Total Resi': rec.totalInbound,
  //               'Total Antar': rec.deliveryProcessed,
  //               'Sedang Antar': rec.delivering,
  //               'DLV': rec.delivered,
  //               'Prob.': rec.deliveryProblem,
  //               'CC': rec.crissCross,
  //               'Retur': rec.returned,
  //               'Keluar Transit': rec.outboundTransit,
  //               'Keluar 3PL': rec.outbound3Pl,
  //               'Hilang/ Rusak': rec.lostBroken,
  //               'Not Outbound': rec.unprocessed,
  //               '%DLV': rec.processedDlvPerc,
  //               '%POD': rec.processedPerc,
  //             }
  //           });
  //         }
  //       },
  //       () => {
  //         this.setState({ exportExcelLoading: false, showExportDialog: false });
  //       },
  //       () => {
  //         let wb = xlsx.utils.book_new();
  //         wb.Props = {
  //           Title: "",
  //           Subject: "",
  //           Author: "",
  //           CreatedDate: new Date(),
  //         };
  //         wb.SheetNames.push("POD");
  //         let ws_data = this.dataExcel;
  //         let ws = xlsx.utils.json_to_sheet(ws_data);
  //         wb.Sheets["POD"] = ws;

  //         xlsx.writeFile(wb, "MonitorPOD.xlsx");

  //         this.setState({ exportExcelLoading: false, showExportDialog: false });
  //       },
  //     );
  // }

  // populateDataSource() {
  //   if (this.data && this.data.length) {
  //     // const currentData = this.applySearch(this.data).slice(
  //     //   this.dataSource.offset,
  //     //   this.dataSource.limit,
  //     // );

  //     // this.dataSource.setData(currentData);
  //     // this.dataSource.setTotal(this.data.length);

  //     this.dataSource.setData(this.data);
  //     this.dataSource.setTotal(this.data.length);
  //   }
  // }

  // onSearchTrackingSite(e) {
  //   let state = this.state;
  //   state.search.searchTrackingSite = e.target.value;
  //   this.setState(state);    
  //   this.populateDataSource();
  // }

  // onSearchCity(e) {
  //   let state = this.state;
  //   state.search.searchCity = e.target.value;
  //   this.setState(state);
  //   this.populateDataSource();
  // }

  // applySearch(data): any[] {
  //   if (!data || !data.length) return [];

  //   const searchTrackingSite = (!StringHelper.isStringEmpty(this.state.search.searchTrackingSite)) ? this.state.search.searchTrackingSite.trim().toLowerCase() : null;
  //   const searchCity = (!StringHelper.isStringEmpty(this.state.search.searchCity)) ? this.state.search.searchCity.trim().toLowerCase() : null;

  //   if (!searchTrackingSite && !searchCity) return data;

  //   console.log(searchTrackingSite);
  //   console.log(searchCity);
  //   const searchData = _.filter(data, function (d) {
  //     const dTrackingSite = (!StringHelper.isStringEmpty(d.trackingSite)) ? d.trackingSite.trim().toLowerCase() : null;
  //     const dCity = (!StringHelper.isStringEmpty(d.trackingSiteCity)) ? d.trackingSiteCity.trim().toLowerCase() : null;
  //     return (!searchTrackingSite || (dTrackingSite && dTrackingSite.indexOf(searchTrackingSite) >= 0)) && (!searchCity || (dCity && dCity.indexOf(searchCity) >= 0));
  //   });

  //   return (searchData as any);
  // }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";
    const formFilters = {
      ...this.formFilters,
      city: this.formFilters.city ? this.formFilters.city.value : "",
      province: this.formFilters.province ? this.formFilters.province.value : "",
      layanan: this.formFilters.layanan ? this.formFilters.layanan.value : "",
      partner: this.formFilters.partner ? this.formFilters.partner.value : "",
      perwakilan: this.formFilters.perwakilan ? this.formFilters.perwakilan.value : "",
    };
    
    return (
      <Link
        to={{
          pathname: '/admin/monitoring/pod-sf/detail',
          search: qs.stringify({
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringPodDetailContainerQueryNew),
        }}
        target="_blank">
        {fmtValue}
      </Link>
    );
  }

  render() {
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showExportDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showExportDialog: false })}>
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">

          <Flex className="w-full" column>
            <MonitoringPodFilterContainerSnowflake ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button type="button" icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : "pi pi-external-link"} iconPos="left" label="CSV" onClick={this.exportCsv}></Button>
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="1000px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => { this.tableExportData = el; }}
                footer={footer}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.onRowToggle(e)}
                // onRowToggle={(e) => this.setState({expandedRows:e.data})}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSiteCode">
                <Column
                  expander={true}
                  // frozen={true}
                  style={{ width: '50px', height: '40px' }} />
                <Column
                  field="trackingSiteCity"
                  header="Kota"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  // frozen={true}
                  style={{ width: '200px', height: '40px' }}
                // body={(rowData, column: ColumnProps) => {
                //   let rawValue = rowData[column.field!];
                //   let value = (rawValue && rawValue.length > 30) ? `${rawValue.substring(0, 30)}...` : rawValue;
                //   return <div><a data-tip={rawValue}>{value}</a><ReactTooltip place="top" /></div>
                // }}
                />
                <Column
                  field="trackingSite"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  // frozen={true}
                  style={{ width: '200px', height: '40px' }}
                // body={(rowData, column: ColumnProps) => {
                //   let rawValue = rowData[column.field!];
                //   let value = (rawValue && rawValue.length > 30) ? `${rawValue.substring(0, 30)}...` : rawValue;
                //   return <div><a data-tip={rawValue}>{value}</a><ReactTooltip place="top" /></div>
                // }}
                />
                <Column
                  field="trackingSiteCode"
                  header="Kode Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  // frozen={true}
                  style={{ width: '200px', height: '40px' }}
                // body={(rowData, column: ColumnProps) => {
                //   let rawValue = rowData[column.field!];
                //   let value = (rawValue && rawValue.length > 30) ? `${rawValue.substring(0, 30)}...` : rawValue;
                //   return <div><a data-tip={rawValue}>{value}</a><ReactTooltip place="top" /></div>
                // }}
                />
                <Column
                  field="perwakilan"
                  header="Perwakilan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  // frozen={true}
                  style={{ width: '200px', height: '40px' }}
                // body={(rowData, column: ColumnProps) => {
                //   let rawValue = rowData[column.field!];
                //   let value = (rawValue && rawValue.length > 30) ? `${rawValue.substring(0, 30)}...` : rawValue;
                //   return <div><a data-tip={rawValue}>{value}</a><ReactTooltip place="top" /></div>
                // }}
                />
                <Column
                  field="prevOpenStatus"
                  header="Sisa Kemarin"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="inbound"
                  header="Masuk"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="totalInbound"
                  header="Total Resi"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="tobeDelivered"
                  header="Harus Diantar"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="deliveryProcessed"
                  header="Total Antar"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="delivering"
                  header="Sedang Antar"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="delivered"
                  header="DLV"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="deliveryProblem"
                  header="Prob."
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="crissCross"
                  header="CC"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="returned"
                  header="Retur"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="outboundTransit"
                  header="Keluar Transit"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="outbound3Pl"
                  header="Keluar 3PL"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="lostBroken"
                  header="Hilang / Rusak"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="ots"
                  header="OTS"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="stuck"
                  header="NOB"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="doneho"
                  header="Sudah SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px' }}
                />
                <Column
                  field="notho"
                  header="Belum SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px' }}
                />
                <Column
                  field="moreso"
                  header="Lebih Paket"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="processedDlvPerc"
                  header="%DLV"
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                {/* <Column
                  field="oversla"
                  header="OverSLA"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="overslaPerc"
                  header="%OverSLA"
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                /> */}
              </DataTable>

              {/* <Paginator
                totalRecords={this.dataSource.total}
                first={this.dataSource.offset}
                rows={this.dataSource.rows}
                rowsPerPageOptions={this.dataSource.rowsOptions}
                onPageChange={e => this.dataSource.fromPageState(e)}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
