import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";
import { TabView, TabPanel } from 'primereact/tabview';

import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { OverSlaMetaBaseSummaryFilterContainer } from "./OverSlaMetaBaseSummaryFilterContainer";
import moment from "moment";
import { IOverSlaMetaBaseSummaryFilterContainerValue } from "./IOverSlaMetaBaseSummaryFilterContainerValue";
import { Growl } from "primereact/growl";

@observer
export class OverSlaMetaBaseSummaryContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl;
  formFilters: IOverSlaMetaBaseSummaryFilterContainerValue;
  refFilter: RefObject<
    OverSlaMetaBaseSummaryFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      dashboardId: 2579,
      showNote: false,
      dashboards: [
        {
          label: "Home",
          value: 2579,
          url: "",
          token: "",
        },
        {
          label: "(Akan OverSLA)",
          value: 2580,
          url: "",
          token: "",
        },
        {
          label: "(Akan OverSLA BEST)",
          value: 2593,
          url: "",
          token: "",
        },
        {
          label: "(Akan OverSLA COD)",
          value: 2589,
          url: "",
          token: "",
        },
        {
          label: "(Sudah Over SLA)",
          value: 2592,
          url: "",
          token: "",
        },
        {
          label: "(Sudah Over SLA BEST)",
          value: 2586,
          url: "",
          token: "",
        },
        {
          label: "(Sudah Over SLA COD)",
          value: 2594,
          url: "",
          token: "",
        },
        {
          label: "(Potential Claim)",
          value: 2590,
          url: "",
          token: "",
        },
        {
          label: "(Detail Resi Prioritas 1)",
          value: 2588,
          url: "",
          token: "",
        },
        {
          label: "(Detail Resi Prioritas 2)",
          value: 2591,
          url: "",
          token: "",
        },
      ]
    };
  }

  


  componentDidMount() {
    this.loadData();
  }

  onFilterData() {
    const dashboardList = this.state.dashboards.map((data) => {
      return {
        ...data,
        url: "",
        token: "",
      }
    })
    this.setState({
      dashboards: dashboardList
    })
    this.loadData();
  }

  async loadData(dashboardId = this.state.dashboardId) {
    if (localStorage.getItem('branchCode')) {
      const branchCode = localStorage.getItem('branchCode')

      const formValue = this.refFilter.current!.formValues
      let startDate;
      let endDate;
      if (formValue) {
        startDate = formValue.periodeDate[0];
        endDate = formValue.periodeDate[1] ? formValue.periodeDate[1] : formValue.periodeDate[0];
      }
      const params = {
        branch_code: branchCode,
        ...(startDate ? { start_date: moment(startDate).format("YYYY-MM-DD") } : {}),
        ...(endDate ? { end_date: moment(endDate).format("YYYY-MM-DD") } : {}),
      }

      this.monitoringRestService
      .post(`embed-post/${dashboardId}`, params)
      .subscribe(
        (response) => {
          const dashboardList = this.state.dashboards.map((dashboard) => {
            if (dashboard.value === dashboardId) {
              return {
                ...dashboard,
                url: response.url,
                token: response.token,
              }
            } else {
              return dashboard
            }
          })
          this.setState({
            dashboards: dashboardList
          })
        },
        (e) => {
          let msg = {
            severity: "error",
            summary: e.response.data['message'],
          };
          this.growl.show(msg);
        },
        () => {}
      );
    }
  }

  onChangeDashboard = (value) => {
    const selectedDashboard = this.state.dashboards[value.index];
    this.setState({
      activeIndex: value.index,
      dashboardId: selectedDashboard.value
    });
    if (selectedDashboard.url === "" || selectedDashboard.token === "") {
      this.loadData(selectedDashboard.value);
    }
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Over SLA</h1>
            <OverSlaMetaBaseSummaryFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.onFilterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <Panel header="Note" toggleable style={{margin: "0.25em"}} >
            <ul style={{listStyle:"initial", paddingLeft: "1.5em"}}>
              <li>
                <b>Cara Melihat List Resi</b> : Harap klik <b>Tab</b> di bawah ini untuk melihat <b>List Resi</b>
              </li>
              <li>
                Cara <b>Download</b> Data Resi : 
                <ul style={{listStyle:"circle", paddingLeft: "1.5em"}}>
                  <li>
                    Pilih <b>Tab</b> yang akan dilihat
                  </li>
                  <li>
                    Arahkan <b>Cursor/Panah</b> ke tabel
                  </li>
                  <li>
                    <Flex>
                    Klik Icon
                    <svg style={{ color: "#b8bbc3" }} className="Icon Icon-download e621b520 css-4g44w1 e621b521 ml-2" viewBox="0 0 52 32" width="20" height="20" fill="currentcolor" role="img" aria-label="download icon">
                      <path d="M26 0c7.264 0 13.323 5.164 14.704 12.022C47.004 12.384 52 17.609 52 24c0 6.627-5.373 12-12 12H12C5.373 36 0 30.627 0 24c0-6.391 4.997-11.616 11.297-11.98C12.677 5.164 18.736 0 26 0zm0 6a3 3 0 00-3 3v13.649l-5.232-5.511-.13-.128a2.409 2.409 0 00-3.406.128c-.976 1.028-.976 2.696 0 3.724l10 10.533.13.129a2.409 2.409 0 003.406-.129l10-10.533.122-.138c.852-1.034.812-2.602-.122-3.586l-.13-.128a2.409 2.409 0 00-3.406.128L29 22.649V9a3 3 0 00-3-3z"></path>
                    </svg>
                    </Flex>
                  </li>
                </ul>
              </li>
              <li>
                Untuk <b>Kembali</b> ke Halaman Awal, Klik <b>Tab Home</b>  
              </li>
            </ul>
          </Panel>
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onChangeDashboard(e)} renderActiveOnly={false}>
            {this.state.dashboards.map((dashboard) => (
              <TabPanel key={dashboard.value} header={dashboard.label}>
                <>
                  {dashboard.url && dashboard.token && (
                    <iframe
                      src={dashboard.url + dashboard.token}
                      width={"100%"}
                      height={750}
                      allowTransparency
                    />
                  )}
                </>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    );
  }
}
