import { observer } from 'mobx-react';
import moment from "moment";
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';

import React from 'react';
import { Flex } from 'reflexbox';

import { DataSource } from '../../models/DataSource';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbStatusUpdateModal } from './AwbStatusUpdateModal';
import _ from 'lodash';

@observer
export class AwbStatusContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;
  data;
  dataSource = new DataSource<any>();

  constructor(props) {
    super(props);

    this.state = {
      isShowModalUpdate: false,
      selectedAwbStatus: null,
    };

    this.onCloseModalUpdate = this.onCloseModalUpdate.bind(this);
    this.onSuccessUpdate = this.onSuccessUpdate.bind(this);
  }

  loadData() {
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('awb-status/list', {})
      .subscribe(
        response => {
          this.processData(response);
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  syncMasterData() {
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('awb-status/sync', {})
      .subscribe(
        () => {
          this.loadData();
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  processData(data) {
    this.dataSource.setData(data);
    this.dataSource.setTotal(data.length);
  }

  onClickUpdateRow(rowData) {
    this.setState({
      isShowModalUpdate: true,
      selectedAwbStatus: rowData,
    });
  }
  
  onCloseModalUpdate() {
    this.setState({
      isShowModalUpdate: false,
      selectedAwbStatus: null,
    });
  }

  onSuccessUpdate() {
    this.dataSource.loading = true;
    setTimeout(() => {
      this.loadData()
    }, 1000)
  }
  
  columnAction = (rowData, column: ColumnProps) => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onClickUpdateRow(rowData)}
          />
      </div>
    );
  }
  
  columnYesNo = (rowData, column: ColumnProps) => {
    return rowData[column.field!] ? (
      <div style={{ background: "lightGreen", padding: "12px 12px", margin: "-12px -12px", textAlign: "center" }}>Yes</div>
    ) : (
      <div style={{ background: "lightCoral", padding: "12px 12px", margin: "-12px -12px", textAlign: "center" }}>No</div>
    );
  }
  
  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
          .utc()
          .format("DD-MM-YYYY HH:mm")
      : "";
  };

  render() {
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">

          <Flex className="w-full" column>
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                label="Sync Master Data"
                onClick={() => this.syncMasterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="1000px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
                footer={footer}>
                <Column
                  field="awb_status_id"
                  body={this.columnAction}
                  style={{ textAlign: "center", width: "5em" }}
                />
                <Column
                  field="awb_status_name"
                  header="Nama"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="is_calc_fm"
                  header="Kalkulasi FM"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '90px' }}
                />
                <Column
                  field="is_calc_fm_in"
                  header="Kalkulasi FM Paket Masuk"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '130px' }}
                />
                <Column
                  field="is_calc_fm_nob"
                  header="Kalkulasi FM NOB"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '110px' }}
                />
                <Column
                  field="is_calc_fm_prev"
                  header="Kalkulasi FM Sisa Kemarin"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '130px' }}
                />
                <Column
                  field="is_calc_lm"
                  header="Kalkulasi LM"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '90px' }}
                />
                <Column
                  field="is_calc_lm_problem"
                  header="Kalkulasi LM Problem"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '120px' }}
                />
                <Column
                  field="is_calc_lm_nob"
                  header="Kalkulasi LM NOB"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '110px' }}
                />
                <Column
                  field="is_calc_lm_prev"
                  header="Kalkulasi LM Sisa Kemarin"
                  sortable={true}
                  body={this.columnYesNo}
                  style={{ width: '130px' }}
                />
                <Column
                  field="user_id_updated"
                  header="Updated By User"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '160px' }}
                />
                <Column
                  field="updated_date_time"
                  header="Updated Date Time"
                  sortable={true}
                  body={this.columnDateFormat}
                  style={{ width: '140px' }}
                />
              </DataTable>

              <AwbStatusUpdateModal
                data={this.state.selectedAwbStatus}
                isShow={this.state.isShowModalUpdate}
                onSuccess={this.onSuccessUpdate}
                onClose={this.onCloseModalUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
