import { Formik } from 'formik';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { DataSource } from '../../models/DataSource';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
// import { AwbDetailModalComponent } from './../monitoringPodNew/snowflake/AwbDetailModalComponentSnowflake';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

@observer
export class AntNotDlvTrackingSiteDetailContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  refFormik: RefObject<Formik> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  @observable data;
  @observable groupData;
  @observable trackingSiteId;
  @observable trackingSiteName = 'Kurir';

  growl;
  courierTableData;
  courierDetailTableData;
  dataSource = new DataSource<any>();

  constructor(props, context) {
    super(props, context);
    this.state = {
      expandedRows: null,
    };

    this.onRowToggle = this.onRowToggle.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
    this.exportCourierData = this.exportCourierData.bind(this);
    this.exportCourierDetailData = this.exportCourierDetailData.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.populateDataSource();
    // });
  }

  public async loadData(trackingSiteId, trackingSiteName, period) {
    this.trackingSiteId = trackingSiteId;
    this.trackingSiteName = trackingSiteName;

    const mappedParams = {
      date: moment(new Date()).format("YYYY-MM-DD"),
      branch_id: trackingSiteId,
      periode_selection: period,
    }

    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('monitoring-pod/sf/ant-not-dlv-branch', mappedParams)
      .subscribe(
        response => {
          const mappedData = response.map((d) => {
            return {
              ...d,
              courierName: d.EMPLOYEE_NAME_DRIVER,
              receiptNumber: d.AWB_NUMBER,
              lastStatusDateTime: d.LAST_DATE_TIME,
            }
          })
          this.data = mappedData;
          const groupData = _(mappedData)
            .groupBy('courierName')
            .map((value, key) => {
              return {
                courierName: key,
                total: (value as any).length,
                details: value,
                dataSource: this.createDetailDataSource(value)
              }
            }).value();
          console.log(groupData);
          this.groupData = groupData;
          this.dataSource.setData(this.groupData);
          this.dataSource.setTotal(this.groupData.length);
        },
        null,
        () => {
          this.dataSource.loading = false;
        },
      );
  }

  public clearData() {
    this.setState({ expandedRows: null });
    this.trackingSiteId = null;
    this.trackingSiteName = 'Gerai';
    this.data = [];
    this.groupData = [];
    this.dataSource.setData([]);
    this.dataSource.setTotal(0);
  }

  createDetailDataSource(values) {
    const dataSource = new DataSource<any>();
    dataSource.setData(values);
    dataSource.setTotal(values.length);

    return dataSource;
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
    );
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    return (
      (rowData[column.field!] != null) ? moment(new Date(rowData[column.field!])).format('DD-MM-YYYY HH:mm') : ''
    );
  }

  async exportCourierData() {
    await this.courierTableData.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  async exportCourierDetailData() {
    await this.courierDetailTableData.exportCSV();
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  onRowToggle(e) {
    this.setState({ expandedRows: e.data });
    console.log(e.data);
  }

  rowExpansionTemplate(data) {
    const datasource = (_.find(this.groupData, (d: any) => { return d.courierName === data.courierName })).dataSource;
    var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.exportCourierDetailData}></Button></div>;
    const footer = 'Total data: ' + datasource.total;

    return (
      <div className="p-grid p-fluid" style={{ padding: '1em 1em 1em 1em' }}>
        <div className="p-col-12 p-md-12" style={{ textAlign: 'center' }}>
          <DataTable
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="300px"
            style={{ width: '100%' }}
            className="mt-4"
            value={datasource.data}
            loading={datasource.loading}
            footer={footer}
            header={header} ref={(el) => { this.courierDetailTableData = el; }}>
            <Column
              field="courierName"
              header="Kurir"
              sortable={true}
              style={{ width: '130px' }}
            />
            <Column
              field="receiptNumber"
              header="No Resi"
              sortable={false}
              style={{ width: '130px' }}
              body={this.columnAwbNumber}
            />
            <Column
              field="lastStatusDateTime"
              header="Tgl. Antar"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: '145px' }}
            />
          </DataTable>
        </div>
      </div>
    );
  }

  render() {
    var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.exportCourierData}></Button></div>;
    const footer = 'Total Kurir: ' + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <div className="card">
          <div className="row">
            <div className="col-md-12">
              <h3><b>{this.trackingSiteName}</b></h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                style={{ width: '100%' }}
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                footer={footer}
                header={header} ref={(el) => { this.courierTableData = el; }}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.onRowToggle(e)}
                // onRowToggle={(e) => this.setState({expandedRows:e.data})}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="courierName">
                <Column expander={true} style={{ width: '2em' }} />
                <Column
                  field="courierName"
                  header="Kurir"
                  sortable={true}
                  filter={true}
                  style={{ width: '130px' }}
                />
                <Column
                  field="total"
                  header="Total Resi"
                  sortable={false}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: '80px' }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
