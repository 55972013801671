import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { IMonitoringPodFilterContainerValueSnowflake } from './IMonitoringPodFilterContainerValueSnowflake';
import { Panel } from 'primereact/panel';
import DropdownPartner from "./../../../components/dropdown/DropdownPartner";
import DropdownService from "./../../../components/dropdown/DropdownService";


export class MonitoringPodKepalaKurirFilterContainerSnowflake extends React.Component<any, any> {
  initialFormValues: IMonitoringPodFilterContainerValueSnowflake = {
    layanan: null,
    cod: '',
    date: new Date(),
    city: null,
    pengirim: '',
    partner: null,
  };
  refFormik: RefObject<Formik> = React.createRef();

  optionsCod = [
    {
      label: 'ALL',
      value: '',
    },
    {
      label: 'COD',
      value: 'Y',
    },
    {
      label: 'NON COD',
      value: 'N',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 p-4">
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Tanggal</label>
                  <Calendar
                    className="flex-1"
                    name="date"
                    readOnlyInput={true}
                    dateFormat="yy-mm-dd"
                    showWeek={true}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.date}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Layanan</label>
                  <div className="flex-1">
                    <DropdownService
                      name='layanan'
                      onChange={formikProps.handleChange("layanan")}
                      value={formikProps.values.layanan}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">COD</label>
                  <Dropdown
                    className="flex-1"
                    options={this.optionsCod}
                    onChange={formikProps.handleChange('cod')}
                    value={formikProps.values.cod}
                  />
                </div>
              </div>

              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Pengirim</label>
                  <InputText
                    className="flex-1"
                    name="pengirim"
                    type="text"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.pengirim}
                  />
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Partner</label>
                  <div className="flex-1">
                    <DropdownPartner
                      name='partner'
                      onChange={formikProps.handleChange("partner")}
                      value={formikProps.values.partner}
                    />
                  </div>
                </div>
              </div>
            </div>
            // <Flex wrap w={1}>
            //   <Flex column>
            //     <div className="form-group">
            //       <label className="col-md-4">Tanggal</label>
            //       <div className="col-md-8">
            //         <Calendar
            //           name="date"
            //           readOnlyInput={true}
            //           dateFormat="dd-mm-yy"
            //           showWeek={true}
            //           onBlur={formikProps.handleBlur}
            //           onChange={formikProps.handleChange}
            //           value={formikProps.values.date}
            //         />
            //       </div>
            //     </div>
            //   </Flex>

            // </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
