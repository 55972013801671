import { snakeCase } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import qs from 'qs';
import React, { RefObject } from 'react';
import { RouterProps } from 'react-router';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { observable } from 'mobx';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { Button } from 'primereact/button';
import { IMonitoringPodFilterContainerValueNew } from './IMonitoringPodFilterContainerValueNew';
// import { flex } from '../../utils/Css';

export interface IMonitoringPodKepalaKurirDetailCourierContainerQueryNew {
  courierId: string;
  courierName: string;
  courierNik: string;
  branchName: string;
  field: string;
  label: string;
  filters: IMonitoringPodFilterContainerValueNew;
  trackingSiteCode: number;
}

@observer
export class MonitoringPodKepalaKurirDetailCourierContainerNew extends React.Component<RouterProps> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable data;
  tableExportData;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  pageTitle1: string;
  pageTitle2: string;
  queryParameters: IMonitoringPodKepalaKurirDetailCourierContainerQueryNew;
  
  constructor(props, context) {
    super(props, context);
    this.export = this.export.bind(this);
  }

  componentWillMount() {
    this.queryParameters = qs.parse(
      this.props.history.location.search.substr(1),
    ) as any;

    this.pageTitle1 = `${this.queryParameters.courierName} - ${this.queryParameters.label}`;

    const pageTitle2Elements: string[] = [];
    if (this.queryParameters.filters.date) {
      const formattedDate = moment(this.queryParameters.filters.date).format(
        'DD-MM-YYYY',
      );
      pageTitle2Elements.push(formattedDate);
    }

    this.pageTitle2 = pageTitle2Elements.join('/');

    this.loadData();
  }

  loadData() {
    this.dataSource.loading = true;
    this.monitoringRestService
      .post('monitoring-pod-new/courier-detail', {
        date: this.queryParameters.filters.date,
        siteCode: this.queryParameters.trackingSiteCode,
        courierId: this.queryParameters.courierId,
        cod: this.queryParameters.filters.cod,
        layanan: this.queryParameters.filters.layanan,
        detailType: this.queryParameters.field,
      })
      .subscribe(
        response => {
          this.data = response;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        null,
        () => {
          this.dataSource.loading = false;
        },
      );
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
    );
  }

  export() {
    this.tableExportData.exportCSV();
  }

  render() {
    var header = <div style={{textAlign:'left'}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = 'Total data: ' + this.dataSource.total;
    
    return (
      <div className="card">
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <h3>
          {this.pageTitle1}
        </h3>
        <hr />
        <div className="row">
          <label className="col-md-2 control-label">Tracking Site:</label>
          <div className="col-md-10">{this.queryParameters.branchName}</div>
        </div>
        <div className="row">
          <label className="col-md-2 control-label">Date:</label>
          <div className="col-md-10">{this.pageTitle2}</div>
        </div>
        <div className="row">
          <label className="col-md-2 control-label">Courier NIK:</label>
          <div className="col-md-10">{this.queryParameters.courierNik}</div>
        </div>
        <div className="row">
          <label className="col-md-2 control-label">Courier Name:</label>
          <div className="col-md-10">{this.queryParameters.courierName}</div>
        </div>
        <br />

        <DataTable
          emptyMessage={'Tidak ada data untuk ditampilkan.'}
          scrollable={true}
          scrollHeight="400px"
          className="mt-6"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          header={header} ref={(el) => { this.tableExportData = el; }}
          footer={footer}>
          <Column
            field="receiptNumber"
            header="No Resi"
            filter={true}
            sortable={true}
            style={{ width: '130px' }}
            body={this.columnAwbNumber}
          />
          <Column
            field="podDate"
            header="Tanggal POD"
            sortable={true}
            style={{ width: '180px' }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              if (value) {
                value = moment(value).utc().format('DD-MM-YYYY HH:mm');
              }

              return value;
            }}
          />
          <Column
            field="status"
            header="Status"
            sortable={true}
            style={{ width: '120px' }}
          />
          <Column
            field="remark"
            header="Remark"
            sortable={false}
          // style={{ width: '120px' }}
          />
        </DataTable>
      </div>
    );
  }
}
