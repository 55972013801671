import { observer } from "mobx-react";
import React, { RefObject } from "react";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { OverSlaSnowflakeSummaryByTujuanContainer } from "./OverSlaSnowflakeSummaryByTujuanContainer";
import { OverSlaSnowflakeSummaryByPartnerContainer } from "./OverSlaSnowflakeSummaryByPartnerContainer";
import { OverSlaSnowflakeSummaryByLastStatusContainer } from "./OverSlaSnowflakeSummaryByLastStatusContainer";
import { Fieldset } from "primereact/fieldset";
import { OverSlaSnowflakeSummaryByServiceTypeContainer } from "./OverSlaSnowflakeSummaryByServiceTypeContainer";
import { OverSlaSnowflakeSummaryByLastTrackingSiteContainer } from "./OverSlaSnowflakeSummaryByLastTrackingSiteContainer";
import { OverSlaSnowflakeSummaryByStageContainer } from "./OverSlaSnowflakeSummaryByStageContainer";

@observer
export class OverSlaSnowflakeSummaryContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  filterParams;
  refTujuan: RefObject<
    OverSlaSnowflakeSummaryByTujuanContainer
  > = React.createRef();
  refPartner: RefObject<
    OverSlaSnowflakeSummaryByPartnerContainer
  > = React.createRef();
  refLastTrackingSite: RefObject<
    OverSlaSnowflakeSummaryByLastTrackingSiteContainer
  > = React.createRef();
  refLastStatus: RefObject<
    OverSlaSnowflakeSummaryByLastStatusContainer
  > = React.createRef();
  refServiceType: RefObject<
    OverSlaSnowflakeSummaryByServiceTypeContainer
  > = React.createRef();
  refStage: RefObject<OverSlaSnowflakeSummaryByStageContainer> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      tujuanCollapsed: false,
      partnerCollapsed: false,
      lastTrackingSiteCollapsed: false,
      lastStatusCollapsed: false,
      serviceTypeCollapsed: false
    };
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    this.refTujuan.current!.loadData(this.filterParams);
    this.refPartner.current!.loadData(this.filterParams);
    this.refLastTrackingSite.current!.loadData(this.filterParams);
    this.refLastStatus.current!.loadData(this.filterParams);
    this.refServiceType.current!.loadData(this.filterParams);
    this.refStage.current!.loadData(this.filterParams);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <Fieldset
              legend="Perwakilan Tujuan"
              toggleable={true}
              collapsed={this.state.tujuanCollapsed}
              onToggle={e => this.setState({ tujuanCollapsed: e.value })}
            >
              <OverSlaSnowflakeSummaryByTujuanContainer ref={this.refTujuan} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Fieldset
                  legend="Gerai Terakhir"
                  toggleable={true}
                  collapsed={this.state.lastTrackingSiteCollapsed}
                  onToggle={e =>
                    this.setState({ lastTrackingSiteCollapsed: e.value })
                  }
                >
                  <OverSlaSnowflakeSummaryByLastTrackingSiteContainer
                    ref={this.refLastTrackingSite}
                  />
                </Fieldset>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Fieldset
                  legend="Layanan"
                  toggleable={true}
                  collapsed={this.state.serviceTypeCollapsed}
                  onToggle={e =>
                    this.setState({ serviceTypeCollapsed: e.value })
                  }
                >
                  <OverSlaSnowflakeSummaryByServiceTypeContainer
                    ref={this.refServiceType}
                  />
                </Fieldset>
              </div>
              <div className="col-md-12 mt-10">
                <Fieldset
                  legend="Stage"
                  toggleable={true}
                  collapsed={this.state.stageCollapsed}
                  onToggle={e => this.setState({ stageCollapsed: e.value })}
                >
                  <OverSlaSnowflakeSummaryByStageContainer ref={this.refStage} />
                </Fieldset>
              </div>
              <div className="col-md-12 mt-10">
                <Fieldset
                  legend="Status Terakhir"
                  toggleable={true}
                  collapsed={this.state.lastStatusCollapsed}
                  onToggle={e =>
                    this.setState({ lastStatusCollapsed: e.value })
                  }
                >
                  <OverSlaSnowflakeSummaryByLastStatusContainer
                    ref={this.refLastStatus}
                  />
                </Fieldset>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <Fieldset
              legend="Partner"
              toggleable={true}
              collapsed={this.state.partnerCollapsed}
              onToggle={e => this.setState({ partnerCollapsed: e.value })}
            >
              <OverSlaSnowflakeSummaryByPartnerContainer ref={this.refPartner} />
            </Fieldset>
          </div>
        </div>
      </div>
    );
  }
}
