import AsyncDropDown from './AsyncDropDown';

class DropdownRole extends AsyncDropDown {
    
    constructor(props: any) {
        super(props);
        const myState = {
            key: props.key !== undefined ? props.key : 'role_id',
            limit: 10,
            page: 1,
            sortBy: props.sortBy !== undefined ? props.sortBy : 'role_name',
            sortDir: props.sortDir !== undefined ? props.sortDir : 'asc',
            filters: props.filters !== undefined ? props.filters : {},
            isClearable: props.isClearable !== undefined ? props.isClearable : true,
            method: 'POST',
            getResponseData: function (response) {
                const { data } = response;
                return {
                    data: data.datas,
                    pagination: {
                        limit: data.limit,
                        page: data.page,
                        totalData: data.total,
                        totalPage: data.totalPage,
                    }
                }
            },
            url: '/auth/list-role',
            placeholder:
                props.placeholder !== undefined
                    ? props.placeholder
                    : 'Pilih Role',
            template:
                props.template !== undefined
                    ? props.template
                    : '{{role_name}}',
            selectAll: props.selectAll !== undefined ? props.selectAll : false,
            selectAllLabel:
                props.selectAllLabel !== undefined
                    ? props.selectAllLabel
                    : 'Semua',
        };
        Object.assign(this.state, myState);
    }
}

export default DropdownRole;
