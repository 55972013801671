import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";
import * as _ from "lodash";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  IManifestDestinationDistrictListFilterContainerValue,
  ManifestDestinationDistrictListFilterContainer,
} from "./ManifestDestinationDistrictListFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";

export interface IManifestDestinationDistrictListContainerQuery {
  filters: IManifestDestinationDistrictListFilterContainerValue;
}

@observer
export class ManifestDestinationDistrictListContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<ManifestDestinationDistrictListFilterContainer> =
    React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? (qs.parse(this.props.history.location.search.substr(1)) as any)
      : ({} as any);

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
  }

  loadData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
          {},
          this.refFilter.current!.formValues,
          pagingParams,
          sortingParams
        );

    if (fParams.date)
      fParams.date = DateHelper.formatLocalDateForQueryParam(fParams.date);

    this.dataSource.loading = true;
    this.monitoringRestService
      .post("chatbot/manifest-top-perwakilan-dest-district", fParams)
      .subscribe(
        (response) => {
          this.data = response
            ? _.orderBy(response, ["total", "tujuanKota", "tujuanKecamatan"], ["desc", "asc", "asc"])
            : [];
          this.populateDataSource();
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  async exportData(dt) {
    await dt.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.date)
      fParams.date = DateHelper.formatLocalDateForQueryParam(fParams.date);

    this.monitoringRestService
      .post("chatbot/manifest-top-perwakilan-dest-district", fParams)
      .subscribe(
        (response) => {
          if (response && response.path) {
            this.openCsv(response.path);
          }
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Error Message",
            detail: "Export failed",
          };
          this.growl.show(msg);
          this.setState({ exportExcelLoading: false, showExportDialog: false });
        },
        () => {
          let msg = {
            severity: "success",
            summary: "Success Message",
            detail: "Export Success",
          };
          this.growl.show(msg);
          this.setState({ exportExcelLoading: false, showExportDialog: false });
        }
      );
  }

  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.location = url;
  }

  populateDataSource() {
    if (this.data && this.data.length) {
      this.dataSource.setData(this.data);
      this.dataSource.setTotal(this.data.length ? this.data.length : 0);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
          .utc()
          .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  render() {
    const totalVolume = _.sumBy(this.data, (x) => (x as any).total);
    const totalVolumeCod = _.sumBy(this.data, (x) => (x as any).total_cod);
    const footer = `Total Kecamatan: ${this.dataSource.total}. Total Volume: ${totalVolume}. Total Volume COD: ${totalVolumeCod}`;
    var header = (
      <div style={{ textAlign: "left" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="CSV"
          onClick={() => this.exportData(this.dt)}
        ></Button>
      </div>
    );

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <ManifestDestinationDistrictListFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={(el) => {
              this.dt = el;
            }}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            header={header}
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) => {
              this.onSorting(e);
            }}
            // onValueChange={() => this.loadData()}
            footer={footer}
          >
            <Column
              field="tujuanKota"
              header="Kota"
              sortable={true}
              filter={true}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
            <Column
              field="tujuanKecamatan"
              header="Kecamatan"
              sortable={true}
              filter={true}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
            <Column
              field="total"
              header="Volume"
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="total_cod"
              header="Volume COD"
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="total_cod_amount"
              header="Nilai COD"
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
          </DataTable>
        </div>
      </div>
    );
  }
}
