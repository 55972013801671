import Axios from 'axios';

import { HttpClientAxiosService } from './HttpClientAxiosService';

export class HttpClientAxiosInterceptedService extends HttpClientAxiosService {
  constructor(baseURL: string) {
    super(Axios.create());
    this.axios.defaults.baseURL = baseURL;
  }
}
