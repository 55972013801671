import { observer } from 'mobx-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';

import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'reflexbox';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { MonitoringFMFilterContainer } from './MonitoringFMFilterContainer';
import _ from 'lodash';
import moment from 'moment';
import { IMonitoringFMFilterContainerValue } from './IMonitoringFMFilterContainerValue';
import { IMonitoringFMDetailContainerQueryNew } from './IMonitoringFMDetailContainerQueryNew';

@observer
export class MonitoringFMContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  data;
  tableExportData;
  courierTableExportData;
  formFilters: IMonitoringFMFilterContainerValue;
  dataSource = new DataSource<any>();
  refFilter: RefObject<MonitoringFMFilterContainer> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
    }

    this.exportCsv = this.exportCsv.bind(this);
  }

  loadData() {
    this.formFilters = this.refFilter.current!.formValues;
    this.formFilters.date = moment(this.formFilters.date).format('YYYY-MM-DD');
    this.formFilters.endDate = moment(this.formFilters.date).format('YYYY-MM-DD');
    this.dataSource.loading = true;
    const mappedParams = {
      date: this.formFilters.date,
      city_name: this.formFilters.city ? this.formFilters.city.value : "",
      branch_code: this.formFilters.branch ? this.formFilters.branch.value : "",
      sort_by: "city_name",
    }
    this.monitoringRestService
      .post('monitoring-fm/all', mappedParams)
      .subscribe(
        response => {
          this.processData(response);
          // this.data = response;
          // this.dataSource.setData(this.data);
          // this.dataSource.setTotal(this.data.length);
          // this.populateDataSource();
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  processData(data) {
    for (const dt of data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource()
    }

    const mappedData = data.map((d) => {
      return {
        ...d,
        trackingSiteCity: d.CITY_NAME,
        trackingSiteCode: d.BRANCH_CODE,
        trackingSite: d.BRANCH_NAME,
        prevOpenStatus: d.IS_PREV_PROCESS,
        in: d.IS_IN,
        retur: d.IS_RETURN,
        total: d.TOTAL,
        out: d.IS_OUT,
        nob: d.IS_NOB,
        finalStatus: d.IS_FINAL,
        doneho: d.DONE_SO,
        notho: d.NOT_SO,
        moreso: d.MORE_SO,
      }
    })
        
    this.data = mappedData;
    this.dataSource.setData(this.data);
    this.dataSource.setTotal(this.data.length);
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

  async exportCsv() {
    this.setState({ exportExcelLoading: true });
    await this.tableExportData.exportCSV();
    this.setState({ exportExcelLoading: false });
    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
    this.growl.show(msg);
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const fmtValue = colValue ? colValue.toLocaleString() : "-";
    const formFilters = {
      ...this.formFilters,
      city: this.formFilters.city ? this.formFilters.city.value : "",
      branch: this.formFilters.branch ? this.formFilters.branch.value : "",
    };
    
    return (
      <Link
        to={{
          pathname: '/admin/monitoring-fm/detail',
          search: qs.stringify({
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringFMDetailContainerQueryNew),
        }}
        target="_blank">
        {fmtValue}
      </Link>
    );
  }

  render() {
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showExportDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showExportDialog: false })}>
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">

          <Flex className="w-full" column>
            <MonitoringFMFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button type="button" icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : "pi pi-external-link"} iconPos="left" label="CSV" onClick={this.exportCsv}></Button>
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="1000px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => { this.tableExportData = el; }}
                footer={footer}>
                <Column
                  field="trackingSiteCity"
                  header="Kota"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="trackingSiteCode"
                  header="Kode Analytic"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="trackingSite"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="prevOpenStatus"
                  header="Sisa Kemarin"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="in"
                  header="Total Masuk Hari Ini (Pick / Drop / Manifest)"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '150px', height: '40px' }}
                />
                <Column
                  field="retur"
                  header="In Retur"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="total"
                  header="Total Paket"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="out"
                  header="Paket Keluar"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                  <Column
                    field="finalStatus"
                    header="Total Final Status"
                    body={this.columnDetailLinkBody}
                    sortable={true}
                    style={{ width: '100px', height: '40px' }}
                  />
                <Column
                  field="nob"
                  header="NOB"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="notho"
                  header="Belum SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px' }}
                />
                <Column
                  field="doneho"
                  header="Sudah SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px' }}
                />
                <Column
                  field="moreso"
                  header="Lebih SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
