import { reduce } from 'lodash';

import { Injectable } from '../utils/DependencyInjection';

@Injectable()
export class DataTableFilterService {
  convertDataTableFiltersToKeyValue(filters: {
    [name: string]: { value: any; matchMode: string };
  }) {
    return reduce(
      filters,
      (result, filterData, filterName) => {
        result[filterName] = filterData.value;
        return result;
      },
      {},
    );
  }
}
