import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";
import { TabView, TabPanel } from 'primereact/tabview';

import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { MonitoringLineHaulMetabaseFilterContainer } from "./MonitoringLineHaulMetabaseFilterContainer";
import moment from "moment";
import { IMonitoringLineHaulMetabaseFilterContainerValue } from "./IMonitoringLineHaulMetabaseFilterContainerValue";
import { Growl } from "primereact/growl";

@observer
export class MonitoringLineHaulMetabaseContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl;
  formFilters: IMonitoringLineHaulMetabaseFilterContainerValue;
  refFilter: RefObject<
  MonitoringLineHaulMetabaseFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      dashboardId: 2856,
      dashboards: [
        {
          label: "Summary",
          value: 2856,
          url: "",
          token: "",
        },
        {
          label: "Detail",
          value: 2860,
          url: "",
          token: "",
        },
      ],
    };
  }
  
  onFilterData() {
    const dashboardList = this.state.dashboards.map((data) => {
      return {
        ...data,
        url: "",
        token: "",
      }
    })
    this.setState({
      dashboards: dashboardList
    })
    this.loadData();
  }

  async loadData(dashboardId = this.state.dashboardId) {
    const formValue = this.refFilter.current!.formValues
    const params = {
      date: moment(formValue.date).format("YYYY-MM-DD")
    }

    this.monitoringRestService
    .post(`embed-post/${dashboardId}`, params)
    .subscribe(
      (response) => {
        const dashboardList = this.state.dashboards.map((dashboard) => {
          if (dashboard.value === dashboardId) {
            return {
              ...dashboard,
              url: response.url,
              token: response.token,
            }
          } else {
            return dashboard
          }
        })
        this.setState({
          dashboards: dashboardList
        })
      },
      (e) => {
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
      },
      () => {}
    );
  }

  onChangeDashboard = (value) => {
    const selectedDashboard = this.state.dashboards[value.index];
    this.setState({
      activeIndex: value.index,
      dashboardId: selectedDashboard.value
    });
    if (selectedDashboard.url === "" || selectedDashboard.token === "") {
      this.loadData(selectedDashboard.value);
    }
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Monitoring Line Haul</h1>
            <MonitoringLineHaulMetabaseFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.onFilterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onChangeDashboard(e)} renderActiveOnly={false}>
            {this.state.dashboards.map((dashboard) => (
              <TabPanel key={dashboard.value} header={dashboard.label}>
                <>
                  {dashboard.url && dashboard.token && (
                    <iframe
                      src={dashboard.url + dashboard.token}
                      width={"100%"}
                      height={750}
                      allowTransparency
                    />
                  )}
                </>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    );
  }
}
