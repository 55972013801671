import { observer } from 'mobx-react';
import React, { RefObject } from 'react';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { IdleAwbKibanaSummaryByTujuanContainer } from './IdleAwbKibanaSummaryByTujuanContainer';
import { IdleAwbKibanaSummaryByPartnerContainer } from './IdleAwbKibanaSummaryByPartnerContainer';
import { IdleAwbKibanaSummaryByLastStatusContainer } from './IdleAwbKibanaSummaryByLastStatusContainer';
import { Fieldset } from 'primereact/fieldset';
import { IdleAwbKibanaSummaryByLastTrackingSiteContainer } from './IdleAwbKibanaSummaryByLastTrackingSiteContainer';

@observer
export class IdleAwbKibanaSummaryContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  filterParams;
  refTujuan: RefObject<IdleAwbKibanaSummaryByTujuanContainer> = React.createRef();
  refPartner: RefObject<IdleAwbKibanaSummaryByPartnerContainer> = React.createRef();
  refLastTrackingSite: RefObject<IdleAwbKibanaSummaryByLastTrackingSiteContainer> = React.createRef();
  refLastStatus: RefObject<IdleAwbKibanaSummaryByLastStatusContainer> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      tujuanCollapsed: false,
      partnerCollapsed: false,
      lastTrackingSiteCollapsed: false,
      lastStatusCollapsed: false,
    };
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.loadData();
    // });
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    this.refTujuan.current!.loadData(this.filterParams);
    this.refPartner.current!.loadData(this.filterParams);
    this.refLastTrackingSite.current!.loadData(this.filterParams);
    this.refLastStatus.current!.loadData(this.filterParams);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <Fieldset legend="Tujuan" toggleable={true} collapsed={this.state.tujuanCollapsed} onToggle={(e) => this.setState({ tujuanCollapsed: e.value })}>
              <IdleAwbKibanaSummaryByTujuanContainer ref={this.refTujuan} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <Fieldset legend="Gerai Terakhir" toggleable={true} collapsed={this.state.lastTrackingSiteCollapsed} onToggle={(e) => this.setState({ lastTrackingSiteCollapsed: e.value })}>
              <IdleAwbKibanaSummaryByLastTrackingSiteContainer ref={this.refLastTrackingSite} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <Fieldset legend="Status Terakhir" toggleable={true} collapsed={this.state.lastStatusCollapsed} onToggle={(e) => this.setState({ lastStatusCollapsed: e.value })}>
              <IdleAwbKibanaSummaryByLastStatusContainer ref={this.refLastStatus} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <Fieldset legend="Partner" toggleable={true} collapsed={this.state.partnerCollapsed} onToggle={(e) => this.setState({ partnerCollapsed: e.value })}>
              <IdleAwbKibanaSummaryByPartnerContainer ref={this.refPartner} />
            </Fieldset>
          </div>
        </div>
      </div>
    );
  }
}
