import {Formik} from "formik";
import React, {RefObject} from "react";
import {Flex} from "reflexbox";
import {IMonitoringUrgentFilterContainerValue} from "./IMonitoringUrgentFilterContainerValue";
import {Panel} from "primereact/panel";
import { Dropdown } from 'primereact/dropdown';
import AppDateRangePicker from "../../utils/AppDateRangePicker";
import DropdownBranch from "./components/DropdownBranch";
import DropdownPartner from "./components/DropdownPartner";
import DropdownService from "./components/DropdownService";

export class MonitoringUrgentFilterContainer extends React.PureComponent<
  any,
  any
> {
  initialFormValues: IMonitoringUrgentFilterContainerValue = {
    periodeDate: [new Date(), new Date()],
    branchCode: [],
    partner: [],
    service: [],
    statusSla: null,
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionsStatusSla = [
    {
      label: 'Semua',
      value: null,
    },
    {
      label: 'Over SLA',
      value: 'Sudah Over SLA',
    },
    {
      label: 'Belum SLA',
      value: 'Meet SLA',
    },
  ]
  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      isHQ: props.isHeadQuerter,
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel
        header='Filter'
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({filterCollapsed: e.value})}
      >
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => {}}
        >
          {(formikProps) => (
            <div className='row'>
              <Flex wrap w={1}>
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%"}}
                    >
                      Periode
                    </label>
                    <div className='col-md-8'>
                      <AppDateRangePicker
                        name='periodeDate'
                        onChange={formikProps.handleChange("periodeDate")}
                        value={formikProps.values.periodeDate}
                        showButtonBar={true}
                        clearButtonStyleClass='p-button-danger'
                      />
                    </div>
                  </div>
                </Flex>                 
                {this.state.isHQ && (
                  <Flex column style={{marginTop: "5px", width: "375px"}}>
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%"}}
                        >
                        Cabang
                      </label>
                      <div className='col-md-8'>
                        <DropdownBranch
                          name='branchCode'
                          onChange={formikProps.handleChange("branchCode")}
                          isMulti
                          value={formikProps.values.branchCode}
                          />
                      </div>
                    </div>
                  </Flex>                    
                )}   
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%"}}
                    >
                      Partner
                    </label>
                    <div className='col-md-8'>
                      <DropdownPartner
                        name='partner'
                        onChange={formikProps.handleChange("partner")}
                        isMulti
                        value={formikProps.values.partner}
                      />
                    </div>
                  </div>
                </Flex>                    
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%"}}
                    >
                      Layanan
                    </label>
                    <div className='col-md-8'>
                      <DropdownService
                        name='service'
                        onChange={formikProps.handleChange("service")}
                        isMulti
                        value={formikProps.values.service}
                      />
                    </div>
                  </div>
                </Flex>                    
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%"}}
                    >
                      Status SLA
                    </label>
                    <div className='col-md-8'>
                      <Dropdown
                        options={this.optionsStatusSla}
                        onChange={formikProps.handleChange('statusSla')}
                        value={formikProps.values.statusSla}
                      />
                    </div>
                  </div>
                </Flex>                    
              </Flex>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
