import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";

import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { IMonitoringROBrokenFilterContainerValue } from "./IMonitoringROBrokenFilterContainerValue";
import { MonitoringROBrokenFilterContainer } from "./MonitoringROBrokenFilterContainer";

@observer
export class MonitoringROBrokenContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  formFilters: IMonitoringROBrokenFilterContainerValue;
  refFilter: RefObject<
  MonitoringROBrokenFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      url: '',
      token:'',
    };
  }

  async loadData() {
    const formValue = this.refFilter.current!.formValues
    const startDate = formValue.periodeDate[0];
    const endDate = formValue.periodeDate[1] ? formValue.periodeDate[1] : formValue.periodeDate[0];
    const params = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    }

    this.monitoringRestService
    .post("embed-post/2972", params)
    .subscribe(
      (response) => {
        this.setState({
          url: response.url,
          token:response.token,
        })
        
      },
      (e) => {
        
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
      },
      () => {}
    );
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <MonitoringROBrokenFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
            </Flex>
          </Flex>
        </Flex>

        {this.state.url && this.state.token && (
          <div className="card monitor-pod-table-container">
            <div className="row">
              <div className="col-md-12">
                  <iframe
                    src={this.state.url + this.state.token}
                    width={"100%"}
                    height={750}
                    allowTransparency
                  />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
