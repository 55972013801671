import { observer } from "mobx-react";
import moment from "moment";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";

import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { DateHelper } from "../../utils/DateHelper";
import { Growl } from "primereact/growl";

@observer
export class IdleAwbKibanaDetailContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  filterParams;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    this.dataSource.onPageChange.subscribe(() => {
      this.loadData(this.filterParams);
    });
  }

  loadData(filterParams: any) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    this.filterParams = filterParams;
    const fParams = Object.assign(
      {},
      filterParams,
      pagingParams,
      sortingParams
    );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    this.dataSource.loading = true;
    this.monitoringRestService.post("idleawb-kibana/List", fParams).subscribe(
      (response) => {
        this.data = response;
        this.populateDataSource();
      },
      (error) => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  downloadCsv(filterParams: any) {
    this.filterParams = filterParams;
    const fParams = Object.assign({}, filterParams);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    const resPath = "idleawb-kibana/List";

    this.monitoringRestService.post(resPath, this.filterParams).subscribe(
      (response) => {
        if (response) {
          this.openCsv(response);
        }
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed",
        };
        this.growl.show(msg);
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success",
        };
        this.growl.show(msg);
      }
    );
  }

  openCsv(url) {
    const win = window.open(url, "_blank");
    if (win) win.focus();
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
          .utc()
          .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData(this.filterParams);
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />
        <div className="card">
          <DataTable
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) => {
              this.onSorting(e);
            }}
            footer={footer}
          >
            <Column
              field="awbNumber"
              header="No Resi"
              sortable={true}
              style={{ width: "130px", "vertical-align": "text-top" }}
              body={this.columnAwbNumber}
            />
            <Column
              field="awbDate"
              header="Tgl. Transaksi"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="pengirim"
              header="Pengirim"
              sortable={false}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="asal"
              header="Asal"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["asalKota"]} ({rowData["asalKecamatan"]})
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="tujuan"
              header="Tujuan"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["tujuanKota"]} ({rowData["tujuanKecamatan"]})
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="layanan"
              header="Layanan"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="perwakilan"
              header="Perwakilan"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="codNilai"
              header="Nilai COD"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="usiaPaket"
              header="Usia Paket"
              sortable={true}
              style={{ width: "80px", "vertical-align": "text-top" }}
            />
            <Column
              field="overslaDaysInternal"
              header="Over Sla (Internal)"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeInternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.utc().format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : rowData[column.field!];

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />

            <Column
              field="SlaMaxDateTimeInternal"
              header="Tgl. Over Sla (Internal)"
              sortable={false}
              style={{ width: "145px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglValue = moment(rowData[column.field!]);
                let sTglValue: string = "-";
                if (tglValue.isValid()) {
                  sTglValue = tglValue.utc().format("DD-MM-YYYY");
                }

                return (
                  <div>
                    <div>{sTglValue}</div>
                  </div>
                );
              }}
            />

            <Column
              field="overslaDaysExternal"
              header="Over Sla (External)"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeExternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.utc().format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : rowData[column.field!];

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />

            <Column
              field="SlaMaxDateTimeExternal"
              header="Tgl. Over Sla (External)"
              sortable={false}
              style={{ width: "145px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglValue = moment(rowData[column.field!]);
                let sTglValue: string = "-";
                if (tglValue.isValid()) {
                  sTglValue = tglValue.utc().format("DD-MM-YYYY");
                }

                return (
                  <div>
                    <div>{sTglValue}</div>
                  </div>
                );
              }}
            />
            <Column
              field="manifestTrackingSiteName"
              header="Gerai Manifest"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingSiteName"
              header="Gerai Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastTrackingSiteCity"
              header="Kota Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingType"
              header="Last Status"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingDateTime"
              header="Tgl. Last Status"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemTrackingType"
              header="Last Problem"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemDateTime"
              header="Tgl. Last Problem"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
          </DataTable>

          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={(e) => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}
