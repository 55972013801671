import DropdownPaginateProvince from "./DropdownPaginateProvince";

class CityDropdownNew extends DropdownPaginateProvince {
  constructor(props: any) {
    super(props);

    const myState = {
      key: props.keyData !== undefined ? props.keyData : "branchName",
      limit: 10,
      page: 1,
      sortBy: props.sortBy !== undefined ? props.sortBy : "",
      sortDir: props.sortDir !== undefined ? props.sortDir : "asc",
      filters: props.filters !== undefined ? props.filters : {},
      url: props.url ? props.url : "masterdata/trackingsites/list",
      placeholder:
        props.placeholder !== undefined ? props.placeholder : "Pilih Gerai",
      template: props.template !== undefined ? props.template : "{{value}}",
      selectAll: props.selectAll !== undefined ? props.selectAll : false,
      selectAllLabel:
        props.selectAllLabel !== undefined ? props.selectAllLabel : "Semua",
      province:props.province !== undefined ? props.province  : "",
    };

    Object.assign(this.state, myState);
  }
}

export default CityDropdownNew;
