import * as _ from 'lodash';

export abstract class UserRoleHelper {
  public static getCurrentUserRole() {
    const userData = (localStorage.getItem('userData')) ? JSON.parse(localStorage.userData) : null;
    const role = _.get(userData, 'role_name');
    return role;
  }

  public static isSuperAdmin(role) {
    return !!(role != 'Admin Hub' && (role == '' || role == 'Superadmin'));
  }

  public static hasRole(role, roles) {
    if (this.isSuperAdmin(role) || _.includes(roles, role)) {
      return true
    }

    return false;  
  }
}