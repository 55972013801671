export abstract class AccessHelper {

  public static isPermitted(name): boolean {
    let permitted = false;
    if (name) {
      const userData = localStorage.getItem("userData")
          ? JSON.parse(localStorage.userData)
          : null;
      const rolePermission = userData.role_permissions ? userData.role_permissions : [];
      if (rolePermission.includes(name)) {
        permitted = true
      }
    } else {
      permitted = true
    }
    return permitted
  }
}