import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React from "react";

import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { ITrackingSiteFilterContainerValue } from "./TrackingSiteFilterContainer";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Observable, of, throwError } from "rxjs";
import { switchMap, tap, catchError } from "rxjs/operators";
import { css } from "@emotion/core";
import Spinner from "react-bootstrap/Spinner";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";

export interface ITrackingSiteContainerQuery {
  filters: ITrackingSiteFilterContainerValue;
}

interface Props {
  trackingSiteId: string;
  isOpen: boolean;
  onSuccess: () => Observable<any>;
  onClose: () => void;
}

@observer
export class TrackingSiteEditFormModal extends React.Component<Props, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  userData;

  trackingSiteData;

  otherFunctionTypeOptions = ["HQ", "RETUR", "3PL"];

  optionSiteType = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Branch",
      value: "BRANCH",
    },
    {
      label: "Drop Point",
      value: "DROPPOINT",
    },
    {
      label: "Transit",
      value: "TRANSIT",
    },
    {
      label: "Agent",
      value: "AGENT",
    },
    {
      label: "Partner",
      value: "PARTNER",
    },
    {
      label: "Hub",
      value: "HUB",
    },
    {
      label: "Line Haul",
      value: "LINEHAUL",
    },
    {
      label: "HQ",
      value: "HQ",
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      trackingSite: null,
      updating: false,
      otherValue: null,
      isOther: false,
    };

    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

    this.saveUpdate = this.saveUpdate.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.trackingSiteId !== this.props.trackingSiteId) {
      if (nextProps.trackingSiteId) {
        this.monitoringRestService
          .get(`masterdata/trackingsites/${nextProps.trackingSiteId}`)
          .subscribe(
            (response) => {
              response.isFirstMile
                ? (response.isFirstMile = true)
                : (response.isFirstMile = false);
              response.isLastMile
                ? (response.isLastMile = true)
                : (response.isLastMile = false);
              response.isHub
                ? (response.isHub = true)
                : (response.isHub = false);
              response.isPudo
                ? (response.isPudo = true)
                : (response.isPudo = false);
              response.isLineHaul
                ? (response.isLineHaul = true)
                : (response.isLineHaul = false);
              response.isActive
                ? (response.isActive = true)
                : (response.isActive = false);
              if (
                response.otherFunctionType != null &&
                response.otherFunctionType != undefined
              ) {
                this.setState({
                  otherValue: response.otherFunctionType.trim().toUpperCase(),
                  isOther: true,
                });
              } else {
                this.setState({
                  otherValue: null,
                  isOther: false,
                });
              }
              this.setState({ trackingSite: response });
            },
            () => {},
            () => {}
          );
      } else {
        this.setState({ trackingSite: null });
      }

      this.setState({ updating: false });
    }
  }

  updateProperty(property, value) {
    let trackingSite = this.state.trackingSite;
    trackingSite[property] = value;
    this.setState({ trackingSite: trackingSite });
  }

  updatePropertyOther(property, value) {
    let other = this.state.isOther;
    other = value;
    if (value) {
      this.setState({
        otherValue: null,
      });
    }
    this.setState({ isOther: other });
  }

  saveUpdate() {
    if (this.state.updating) return;

    this.setState({ updating: true });
    try {
      const trackingSite = this.state.trackingSite;
      const trackingSiteId = trackingSite.id;
      const saveData = {
        username: this.userData.username,
        trackingSiteId: trackingSiteId,

        name: trackingSite.name ? trackingSite.name : null,

        province: trackingSite.province ? trackingSite.province : null,

        city: trackingSite.city ? trackingSite.city : null,

        district: trackingSite.district ? trackingSite.district : null,

        perwakilan: trackingSite.perwakilan
          ? trackingSite.perwakilan.trim().toUpperCase()
          : null,
        siteType: trackingSite.siteType
          ? trackingSite.siteType.trim().toUpperCase()
          : null,
        note: trackingSite.note ? trackingSite.note.trim().toUpperCase() : null,

        dlvBranchCapacity: trackingSite.dlvBranchCapacity
          ? parseInt(trackingSite.dlvBranchCapacity)
          : null,

        dlvOpsCapacity: trackingSite.dlvOpsCapacity
          ? parseInt(trackingSite.dlvOpsCapacity)
          : null,
        dlvOpsProbationCapacity: trackingSite.dlvOpsProbationCapacity
          ? parseInt(trackingSite.dlvOpsProbationCapacity)
          : null,
        dlvOpsAdjustmentCapacity: trackingSite.dlvOpsAdjustmentCapacity
          ? parseInt(trackingSite.dlvOpsAdjustmentCapacity)
          : null,
        dlvOpsProbationAdjustmentCapacity:
          trackingSite.dlvOpsProbationAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsProbationAdjustmentCapacity)
            : null,

        dlvOpsDriverCapacity: trackingSite.dlvOpsDriverCapacity
          ? parseInt(trackingSite.dlvOpsDriverCapacity)
          : null,
        dlvOpsDriverProbationCapacity:
          trackingSite.dlvOpsDriverProbationCapacity
            ? parseInt(trackingSite.dlvOpsDriverProbationCapacity)
            : null,
        dlvOpsDriverAdjustmentCapacity:
          trackingSite.dlvOpsDriverAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsDriverAdjustmentCapacity)
            : null,
        dlvOpsDriverProbationAdjustmentCapacity:
          trackingSite.dlvOpsDriverProbationAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsDriverProbationAdjustmentCapacity)
            : null,

        dlvOpsCodCapacity: trackingSite.dlvOpsCodCapacity
          ? parseInt(trackingSite.dlvOpsCodCapacity)
          : null,
        dlvOpsCodProbationCapacity: trackingSite.dlvOpsCodProbationCapacity
          ? parseInt(trackingSite.dlvOpsCodProbationCapacity)
          : null,
        dlvOpsCodAdjustmentCapacity: trackingSite.dlvOpsCodAdjustmentCapacity
          ? parseInt(trackingSite.dlvOpsCodAdjustmentCapacity)
          : null,
        dlvOpsCodProbationAdjustmentCapacity:
          trackingSite.dlvOpsCodProbationAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsCodProbationAdjustmentCapacity)
            : null,

        dlvOpsSdsCapacity: trackingSite.dlvOpsSdsCapacity
          ? parseInt(trackingSite.dlvOpsSdsCapacity)
          : null,
        dlvOpsSdsProbationCapacity: trackingSite.dlvOpsSdsProbationCapacity
          ? parseInt(trackingSite.dlvOpsSdsProbationCapacity)
          : null,
        dlvOpsSdsAdjustmentCapacity: trackingSite.dlvOpsSdsAdjustmentCapacity
          ? parseInt(trackingSite.dlvOpsSdsAdjustmentCapacity)
          : null,
        dlvOpsSdsProbationAdjustmentCapacity:
          trackingSite.dlvOpsSdsProbationAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsSdsProbationAdjustmentCapacity)
            : null,

        dlvOpsCargoCapacity: trackingSite.dlvOpsCargoCapacity
          ? parseInt(trackingSite.dlvOpsCargoCapacity)
          : null,
        dlvOpsCargoProbationCapacity: trackingSite.dlvOpsCargoProbationCapacity
          ? parseInt(trackingSite.dlvOpsCargoProbationCapacity)
          : null,
        dlvOpsCargoAdjustmentCapacity:
          trackingSite.dlvOpsCargoAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsCargoAdjustmentCapacity)
            : null,
        dlvOpsCargoProbationAdjustmentCapacity:
          trackingSite.dlvOpsCargoProbationAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsCargoProbationAdjustmentCapacity)
            : null,

        dlvOpsFreelanceCapacity: trackingSite.dlvOpsFreelanceCapacity
          ? parseInt(trackingSite.dlvOpsFreelanceCapacity)
          : null,
        dlvOpsFreelanceAdjustmentCapacity:
          trackingSite.dlvOpsFreelanceAdjustmentCapacity
            ? parseInt(trackingSite.dlvOpsFreelanceAdjustmentCapacity)
            : null,

        otherFunctionType: this.state.otherValue
          ? this.otherFunctionTypeOptions.includes(this.state.otherValue)
            ? this.state.otherValue.trim().toUpperCase()
            : null
          : null,

        isFirstMile: trackingSite.isFirstMile
          ? (trackingSite.isFirstMile = 1)
          : (trackingSite.isFirstMile = 0),
        isLastMile: trackingSite.isLastMile
          ? (trackingSite.isLastMile = 1)
          : (trackingSite.isLastMile = 0),
        isHub: trackingSite.isHub
          ? (trackingSite.isHub = 1)
          : (trackingSite.isHub = 0),
        isPudo: trackingSite.isPudo
          ? (trackingSite.isPudo = 1)
          : (trackingSite.isPudo = 0),
        isLineHaul: trackingSite.isLineHaul
          ? (trackingSite.isLineHaul = 1)
          : (trackingSite.isLineHaul = 0),
        isActive: trackingSite.isActive
          ? (trackingSite.isActive = 1)
          : (trackingSite.isActive = 0),
        publicSiteName: trackingSite.publicSiteName,
      };

      this.monitoringRestService
        .put(`masterdata/trackingsites/${trackingSiteId}`, saveData)
        .pipe(
          switchMap(() => {
            if (this.props.onSuccess) {
              return this.props.onSuccess();
            } else {
              return of();
            }
          }),
          tap(() => {
            this.setState({ updating: false });
          }),
          catchError((error) => {
            this.setState({ updating: false });
            return throwError(error);
          })
        )
        .subscribe();
    } catch (error) {
      this.setState({ updating: false });
    }
  }

  cancelUpdate() {
    if (this.state.updating) return;

    this.setState({
      trackingSite: null,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    if (
      !this.props.isOpen ||
      !this.props.trackingSiteId ||
      !this.state.trackingSite
    ) {
      return null;
    }

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cancel" icon="pi pi-times" onClick={this.cancelUpdate} />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={this.saveUpdate}
          disabled={this.state.updating}
        />
      </div>
    );

    return (
      <div>
        <Dialog
          visible={this.props.isOpen}
          contentStyle={{ maxHeight: "500px", overflowY: "auto" }}
          header="Ubah Info Cabang"
          modal={true}
          footer={dialogFooter}
          onHide={() => this.props.onClose()}
        >
          <div className="p-grid p-fluid">
            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="name">Nama</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="name"
                onChange={(e: any) => {
                  this.updateProperty("name", e.target.value);
                }}
                value={this.state.trackingSite.name}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="province">Provinsi</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="province"
                onChange={(e: any) => {
                  this.updateProperty("province", e.target.value);
                }}
                value={this.state.trackingSite.province}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="city">Kota</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="city"
                onChange={(e: any) => {
                  this.updateProperty("city", e.target.value);
                }}
                value={this.state.trackingSite.city}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="district">Kecamatan</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="district"
                onChange={(e: any) => {
                  this.updateProperty("district", e.target.value);
                }}
                value={this.state.trackingSite.district}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="perwakilan">Perwakilan</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="perwakilan"
                onChange={(e: any) => {
                  this.updateProperty("perwakilan", e.target.value);
                }}
                value={this.state.trackingSite.perwakilan}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="siteType">Tipe</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <Dropdown
                options={this.optionSiteType}
                onChange={(e: any) => {
                  this.updateProperty("siteType", e.target.value);
                }}
                value={this.state.trackingSite.siteType}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="siteType">Fungsi</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <div className="col-md-4 pb-3">
                <Checkbox
                  inputId="cb1"
                  onChange={(e: any) => {
                    this.updateProperty("isFirstMile", e.target.checked);
                  }}
                  value={this.state.trackingSite.isFirstMile}
                  checked={this.state.trackingSite.isFirstMile}
                ></Checkbox>
                <label htmlFor="cb1" className="p-checkbox-label">
                  First Mile
                </label>
              </div>

              <div className="col-md-4 pb-3">
                <Checkbox
                  inputId="cb2"
                  onChange={(e: any) => {
                    this.updateProperty("isPudo", e.target.checked);
                  }}
                  value={this.state.trackingSite.isPudo}
                  checked={this.state.trackingSite.isPudo}
                >
                  PUDO
                </Checkbox>
                <label htmlFor="cb2" className="p-checkbox-label">
                  PUDO
                </label>
              </div>

              <div className="col-md-4 pb-3">
                <Checkbox
                  inputId="cb3"
                  onChange={(e: any) => {
                    this.updateProperty("isHub", e.target.checked);
                  }}
                  value={this.state.trackingSite.isHub}
                  checked={this.state.trackingSite.isHub}
                >
                  HUB
                </Checkbox>
                <label htmlFor="cb3" className="p-checkbox-label">
                  HUB
                </label>
              </div>

              <div className="col-md-4">
                <Checkbox
                  inputId="cb5"
                  onChange={(e: any) => {
                    this.updateProperty("isLastMile", e.target.checked);
                  }}
                  value={this.state.trackingSite.isLastMile}
                  checked={this.state.trackingSite.isLastMile}
                >
                  Last Mile
                </Checkbox>
                <label htmlFor="cb5" className="p-checkbox-label">
                  Last Mile
                </label>
              </div>

              <div className="col-md-4">
                <Checkbox
                  inputId="cb6"
                  onChange={(e: any) => {
                    this.updateProperty("isLineHaul", e.target.checked);
                  }}
                  value={this.state.trackingSite.isLineHaul}
                  checked={this.state.trackingSite.isLineHaul}
                >
                  LineHaul
                </Checkbox>
                <label htmlFor="cb6" className="p-checkbox-label">
                  LineHaul
                </label>
              </div>

              <div className="col-md-4">
                <Checkbox
                  inputId="cb7"
                  onChange={(e: any) => {
                    this.updatePropertyOther("isOther", e.target.checked);
                  }}
                  value={this.state.isOther}
                  checked={this.state.isOther}
                >
                  LineHaul
                </Checkbox>
                <label htmlFor="cb7" className="p-checkbox-label">
                  Other
                </label>
              </div>

              {this.state.isOther && (
                <div className="col-md-12 pt-3">
                  {_.map(this.otherFunctionTypeOptions, (val) => (
                    <div
                      className="col-md-3 pr-3"
                      style={{ marginLeft: "-15px" }}
                    >
                      <RadioButton
                        inputId={val}
                        value={val}
                        name="otherValue"
                        onChange={(e) => this.setState({ otherValue: e.value })}
                        checked={this.state.otherValue === val}
                      />
                      <label
                        htmlFor={val}
                        style={{ paddingLeft: "5px", paddingTop: "5px" }}
                      >
                        {val}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="isActive">Aktif</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <div className="col-md-4 pb-3">
                <Checkbox
                  inputId="cb4"
                  onChange={(e: any) => {
                    this.updateProperty("isActive", e.target.checked);
                  }}
                  value={this.state.trackingSite.isActive}
                  checked={this.state.trackingSite.isActive}
                >
                  Active
                </Checkbox>
                <label htmlFor="cb4" className="p-checkbox-label">
                  Ya
                </label>
              </div>
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="publicSiteName">Nama cabang di website</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="publicSiteName"
                onChange={(e: any) => {
                  this.updateProperty("publicSiteName", e.target.value);
                }}
                value={this.state.trackingSite.publicSiteName}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvBranchCapacity">DLV Cap</label>
            </div>
            <div className="p-col-8" style={{ padding: ".5em" }}>
              <InputText
                id="dlvBranchCapacity"
                onChange={(e: any) => {
                  this.updateProperty("dlvBranchCapacity", e.target.value);
                }}
                value={this.state.trackingSite.dlvBranchCapacity}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsCapacity">DLV Ops Capacity</label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCapacity"
                onChange={(e: any) => {
                  this.updateProperty("dlvOpsCapacity", e.target.value);
                }}
                value={this.state.trackingSite.dlvOpsCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsAdjustmentCapacity}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsProbationCapacity">
                DLV Ops Probation Capacity
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsProbationCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsProbationCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsProbationCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsProbationAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsProbationAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={
                  this.state.trackingSite.dlvOpsProbationAdjustmentCapacity
                }
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsDriverCapacity">
                DLV Ops Capacity (Driver)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsDriverCapacity"
                onChange={(e: any) => {
                  this.updateProperty("dlvOpsDriverCapacity", e.target.value);
                }}
                value={this.state.trackingSite.dlvOpsDriverCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsDriverAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsDriverAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsDriverAdjustmentCapacity}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsDriverProbationCapacity">
                DLV Ops Probation Capacity (Driver)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsDriverProbationCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsDriverProbationCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsDriverProbationCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsDriverProbationAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsDriverProbationAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={
                  this.state.trackingSite
                    .dlvOpsDriverProbationAdjustmentCapacity
                }
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsCodCapacity">DLV Ops Capacity (COD)</label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCodCapacity"
                onChange={(e: any) => {
                  this.updateProperty("dlvOpsCodCapacity", e.target.value);
                }}
                value={this.state.trackingSite.dlvOpsCodCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCodAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsCodAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsCodAdjustmentCapacity}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsCodProbationCapacity">
                DLV Ops Probation Capacity (COD)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCodProbationCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsCodProbationCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsCodProbationCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCodProbationAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsCodProbationAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={
                  this.state.trackingSite.dlvOpsCodProbationAdjustmentCapacity
                }
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsSdsCapacity">DLV Ops Capacity (Sds)</label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsSdsCapacity"
                onChange={(e: any) => {
                  this.updateProperty("dlvOpsSdsCapacity", e.target.value);
                }}
                value={this.state.trackingSite.dlvOpsSdsCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsSdsAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsSdsAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsSdsAdjustmentCapacity}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsSdsProbationCapacity">
                DLV Ops Probation Capacity (Sds)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsSdsProbationCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsSdsProbationCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsSdsProbationCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsSdsProbationAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsSdsProbationAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={
                  this.state.trackingSite.dlvOpsSdsProbationAdjustmentCapacity
                }
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsCargoCapacity">
                DLV Ops Capacity (Cargo)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCargoCapacity"
                onChange={(e: any) => {
                  this.updateProperty("dlvOpsCargoCapacity", e.target.value);
                }}
                value={this.state.trackingSite.dlvOpsCargoCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCargoAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsCargoAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsCargoAdjustmentCapacity}
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsCargoProbationCapacity">
                DLV Ops Probation Capacity (Cargo)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCargoProbationCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsCargoProbationCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsCargoProbationCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsCargoProbationAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsCargoProbationAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={
                  this.state.trackingSite.dlvOpsCargoProbationAdjustmentCapacity
                }
              />
            </div>

            <div className="p-col-4" style={{ padding: ".75em" }}>
              <label htmlFor="dlvOpsFreelanceCapacity">
                DLV Ops Capacity (Freelance)
              </label>
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsFreelanceCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsFreelanceCapacity",
                    e.target.value
                  );
                }}
                value={this.state.trackingSite.dlvOpsFreelanceCapacity}
              />
            </div>
            <div className="p-col-4" style={{ padding: ".5em" }}>
              <InputText
                id="dlvOpsFreelanceAdjustmentCapacity"
                onChange={(e: any) => {
                  this.updateProperty(
                    "dlvOpsFreelanceAdjustmentCapacity",
                    e.target.value
                  );
                }}
                value={
                  this.state.trackingSite.dlvOpsFreelanceAdjustmentCapacity
                }
              />
            </div>

            <div className="p-col-12" style={{ padding: ".75em" }}>
              <label htmlFor="note">Note</label>
            </div>
            <div className="p-col-12" style={{ padding: ".5em" }}>
              <textarea
                id="note"
                rows={5}
                style={{ border: "solid", borderWidth: "1px", width: "500px" }}
                onChange={(e: any) => {
                  this.updateProperty("note", e.target.value);
                }}
                value={this.state.trackingSite.note}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
