import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'primereact/button';
import React from 'react';

@observer
export class DashboardContainer extends React.Component {
  @observable counter = 0;

  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <h1>Dashboard</h1>

            <p>
              Counter: {this.counter}
              <div className="block">
                <Button label="Increase Counter" onClick={() => this.counter += 1}/>
              </div>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
