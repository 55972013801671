import {Formik} from "formik";
import { Calendar } from 'primereact/calendar';
import React, {RefObject} from "react";
import {Flex} from "reflexbox";
import {IMonitoringLineHaulMetabaseFilterContainerValue} from "./IMonitoringLineHaulMetabaseFilterContainerValue";
import {Panel} from "primereact/panel";

export class MonitoringLineHaulMetabaseFilterContainer extends React.PureComponent<
  any,
  any
> {
  initialFormValues: IMonitoringLineHaulMetabaseFilterContainerValue = {
    date: new Date(),
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel
        header='Filter'
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({filterCollapsed: e.value})}
      >
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => {}}
        >
          {(formikProps) => (
            <div className='row'>
              <Flex wrap w={1}>
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%"}}
                    >
                      Tanggal
                    </label>
                    <div className='col-md-8'>
                      <Calendar
                        name="date"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.date}
                      />
                    </div>
                  </div>
                </Flex>                               
              </Flex>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
