import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import { AutoComplete } from "primereact/autocomplete";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { MonitoringRestService } from "../../../services/MonitoringRestService";
import * as _ from "lodash";
import SiteCodeDropdown from "../../attendance-driver/dropdown/SiteCodeDropdown";
import SiteCodeDropdownCopy from "../../attendance-driver/dropdown/SiteCodeDropdownCopy";
import AppDateRangePicker from "../../../utils/AppDateRangePicker";
import { LAYANAN_OPTIONS } from "../../../constants";

export interface ICourierDeliverySummaryFilterContainerValue {
  [key: string]: any;
}

export class CourierDeliverySummaryFilterContainer extends React.Component<
  any,
  any
> {
  dateRange: any = null;
  initialFormValues: ICourierDeliverySummaryFilterContainerValue = {
    periodeDate: [new Date(), new Date()],
    branchName: null,
    serviceType: "",
    isCod: "",
  };
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  listBranch;

  refFormik: RefObject<Formik> = React.createRef();

  optionYN = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Ya",
      value: "Y",
    },
    {
      label: "Tidak",
      value: "N",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      branchSuggestions: null,
      branchCode:'',
    };

    // this.suggestBranch = this.suggestBranch.bind(this);
  }

  componentDidMount() {
    // this.loadBranch();
  }

  // loadBranch() {
  //   this.monitoringRestService
  //     .get("monitoring-pod/epettycash/branch")
  //     .subscribe(
  //       (response) => {
  //         this.listBranch = response;
  //       },
  //       () => {},
  //       () => {}
  //     );
  // }

  // suggestBranch(event) {
  //   if (this.listBranch && this.listBranch.length) {
  //     let results = this.listBranch.filter((branch) => {
  //       return branch.name.toLowerCase().includes(event.query.toLowerCase());
  //     });

  //     this.setState({ branchSuggestions: results });
  //   }
  // }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    console.log(values)
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }
   onChangeCodeBranch = async (formikProps, e) => {
    
    if (e) {
      formikProps.setFieldValue("branchName", e);
      this.setState({
        branchCode:e,
      });
    } else { 
        formikProps.setFieldValue("branchName", "");
        await this.setState({
          isRenderCode: true,
          branchCode:'',
        });
    }
  };

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {(formikProps) => (
            <Flex wrap w={1}>
              <Box w={500}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Periode</label>
                    <div className="col-md-7">
                 
                    <AppDateRangePicker
                          name="periodeDate"
                          onChange={formikProps.handleChange('periodeDate')}
                          value={formikProps.values.periodeDate}
                          // className={style.sizingPicker}
                          maxSelection={[6, 'd']}
                          showButtonBar={true}
                          clearButtonStyleClass="p-button-danger"
                          inputStyle={{width:'94%'}}
                        />
                      {/* <Flex column >
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                        // showButtonBar={true}
                        />
                        <div
                          className="mt-2 mb-2 text-center"
                          style={{ width: "172px" }}
                        >
                          s/d
                        </div>
                        <Calendar
                          name="endDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          minDate={formikProps.values.startDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("endDate")}
                          value={formikProps.values.endDate}
                        // showButtonBar={true}
                        />
                      </Flex> */}
                    </div>
                  </div>
                  <div className="form-group" >
                      <label style={{ marginTop:"3%"}} className="col-md-5">Cabang</label>
                      <div className="col-md-7"><SiteCodeDropdownCopy
                          name='branchName'
                          onChange={(e) => { 
                            this.onChangeCodeBranch(formikProps, e);
                          }}
                          
                          placeholder={"Pilih Cabang"}
                          filters={{
                            parameter: "name",
                            value: formikProps.values.branchCode,
                          }}
                          province={formikProps.values.province}
                          city={formikProps.values.city}
                          branchName={formikProps.values.name}
                          value={this.state.branchCode}
                        />
                      </div>
                    </div>
                  {/* <div className="form-group">
                    <label className="col-md-5">Cabang</label>
                    <div className="col-md-7">
                      <InputText
                        name="branchName"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.branchName}
                      />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label className="col-md-5">Layanan</label>
                    <div className="col-md-7">
                      <Dropdown
                        style={{ width:"100%"}}
                        options={LAYANAN_OPTIONS}
                        onChange={formikProps.handleChange("serviceType")}
                        value={formikProps.values.serviceType}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">COD</label>
                    <div className="col-md-7">
                      <Dropdown
                        style={{ width:"100%"}}
                        options={this.optionYN}
                        onChange={formikProps.handleChange("isCod")}
                        value={formikProps.values.isCod}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
