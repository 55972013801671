import { observer } from "mobx-react";
import React from 'react';

import { DataSource } from "../../models/DataSource";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { Growl } from "primereact/growl";

@observer
export class CheckAwbHistoryContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  growl;
  tableExportData;
  dataSource = new DataSource<any>();

  constructor(props) {
    super(props);
    this.state = {
      url: '',
      token:'',
    };
  }

  componentDidMount() {
    this.monitoringRestService
    .post("embed-post/2978")
    .subscribe(
      (response) => {
        this.setState({
          url: response.url,
          token:response.token,
        })
        
      },
      (e) => {
        
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }


  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <iframe
                src={this.state.url + this.state.token}
                width={"100%"}
                height={750}
                allowTransparency
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
