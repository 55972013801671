import {Formik} from "formik";
import React, {RefObject} from "react";
import {Flex} from "reflexbox";
import {IOverSlaMetaBaseSummaryFilterContainerValue} from "./IOverSlaMetaBaseSummaryFilterContainerValue";
import {Panel} from "primereact/panel";
import AppDateRangePicker from "../../../utils/AppDateRangePicker";

export class OverSlaMetaBaseSummaryFilterContainer extends React.PureComponent<
  any,
  any
> {
  initialFormValues: IOverSlaMetaBaseSummaryFilterContainerValue = {
    periodeDate: [new Date(), new Date()],
    dashboard: "",
    partner: "",
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      userDataDummy:['Superadmin',"Control Tower","Root IT"]
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel
        header='Filter'
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({filterCollapsed: e.value})}
      >
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => {}}
        >
          {(formikProps) => (
            <div className='row'>
              <Flex wrap w={1}>
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%"}}
                    >
                      Tanggal
                    </label>
                    <div className='col-md-8'>
                      <Flex column>
                        <AppDateRangePicker
                          name='periodeDate'
                          onChange={formikProps.handleChange("periodeDate")}
                          value={formikProps.values.periodeDate}
                          showButtonBar={true}
                          clearButtonStyleClass='p-button-danger'
                        />
                      </Flex>
                    </div>
                  </div>
                </Flex>                    
              </Flex>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
