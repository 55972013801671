import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  IAwbListFilterContainerValue,
  AwbListKibanaFilterContainer,
} from "./AwbListKibanaFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { DataDemo } from "../../demo/components/DataDemo";
import { ExportToCsv } from "export-to-csv";

export interface IAwbListContainerQuery {
  filters: IAwbListFilterContainerValue;
}

@observer
export class AwbListKibanaContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<AwbListKibanaFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? (qs.parse(this.props.history.location.search.substr(1)) as any)
      : ({} as any);

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
  }


  loadData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }

    // let filterList = this.filteringReport(fParams);
    // let filterShould = this.filteringShouldReport(fParams);
    // let filterScriptList = this.filteringScriptReport(fParams);
    fParams.format = "list";
    // fParams.filterList = JSON.stringify(filterList);
    // // fParams.filterShould = JSON.stringify(filterShould);
    // fParams.filterScriptList = JSON.stringify(filterScriptList);
    fParams.fParameters = JSON.stringify(fParams);
    this.dataSource.loading = true;
    // console.log(fParams, "f params");
    this.monitoringRestService.post("Monitoring/LPB/list", fParams).subscribe(
      (response) => {
        this.data = response;
        this.populateDataSource();
      },
      (err) => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    // let filterList = this.filteringReport(fParams);
    // let filterScriptList = this.filteringScriptReport(fParams);

    // fParams.filterList = JSON.stringify(filterList);
    // fParams.filterScriptList = JSON.stringify(filterScriptList);

    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("Monitoring/LPB/list", fParams).subscribe(
      (response) => {
        if (response) {
          response.map(r => {
            setTimeout(() => {
              this.openCsv(r);
            }, 1000);
          });
        }
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed",
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success",
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.open(url);
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      console.log(this.data.data, "data in data");
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .utc()
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <AwbListKibanaFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
              <Button
                className="p-button-primary"
                label="Export Excel"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                disabled={this.state.exportExcelLoading}
                onClick={() => this.downloadCsv()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={(el) => (this.dt = el)}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) => {
              this.onSorting(e);
            }}
            // onValueChange={() => this.loadData()}
            footer={footer}
          >
            <Column
              field="awbNumber"
              header="No Resi"
              sortable={true}
              style={{ width: "130px", "vertical-align": "text-top" }}
              body={this.columnAwbNumber}
            />
            <Column
              field="awbDate"
              header="Tgl. Transaksi"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="tglFoto"
              header="Tgl. Foto"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="tglInput"
              header="Tgl. Input"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="tglFoto"
              header="Tgl. Manifest"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="pengirim"
              header="Pengirim"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="partnerName"
              header="Partner Name"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="reseller"
              header="Reseller"
              sortable={false}
              style={{ width: "150px", "vertical-align": "text-top" }}
            />
            <Column
              field="noHpReseller"
              header="Telp Reseller"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
            <Column
              field="asal"
              header="Asal Code"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const asal = rowData[column.field!] ? rowData[column.field!] : "";
                const subData = asal.substring(0, 3)
                return (
                  <div>
                    <div>
                      <b>{subData}</b>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="asal"
              header="Asal"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["asalKota"]} {rowData["asalKecamatan"] ? `(${rowData["asalKecamatan"]})` : ""}
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="perwakilan"
              header="Perwakilan"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="tujuan"
              header="Tujuan Code"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tujuan = rowData[column.field!] ? rowData[column.field!] : ""
                const subData = tujuan.substring(0, 3);
                return (
                  <div>
                    <div>
                      <b>{subData}</b>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="tujuan"
              header="Tujuan"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div>
                    <div>
                      <b>{rowData[column.field!]}</b>
                    </div>
                    <div>
                      <small>
                        <span>
                          {rowData["tujuanKota"]} {rowData["tujuanKecamatan"] ? `(${rowData["tujuanKecamatan"]})` : ""}
                        </span>
                      </small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="address"
              header="Alamat"
              sortable={true}
              style={{ width: "150px", "vertical-align": "text-top",overflow:'auto' }}
            />
             <Column
              field="zonaTujuan"
              header="Zona Tujuan"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="layanan"
              header="Layanan"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="promoCode"
              header="Kode Promo"
              sortable={true}
              style={{ width: "90px", "vertical-align": "text-top" }}
            />
            <Column
              field="penerima"
              header="Penerima"
              sortable={false}
              style={{ width: "150px", "vertical-align": "text-top" }}
            />
            <Column
              field="noHpPenerima"
              header="Telp Penerima"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
            <Column
              field="koli"
              header="Koli"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="berat"
              header="Berat"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="beratAsli"
              header="Berat Asli"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="beratVolume"
              header="Berat Volume"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="ketVolume"
              header="Ket. Volume"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="codBiaya"
              header="Biaya COD"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="codNilai"
              header="Nilai COD"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="biaya"
              header="Biaya"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue ? colValue.toLocaleString() : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="totalBiaya"
              header="Total Biaya"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue ? colValue.toLocaleString() : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="partner3PlAwbNumber"
              header="Resi Pihak Ketiga"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="noKonfirmasi"
              header="No Konfirmasi"
              sortable={true}
              style={{ width: "240px", "vertical-align": "text-top" }}
            />
            <Column
              field="slaMin"
              header="Lead Time"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <span>
                    {rowData["slaMin"]} - {rowData["slaMax"]}
                  </span>
                );
              }}
            />
            <Column
              field="estSlaMin"
              header="Est Lead Time Partner"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <span>
                    {rowData["estSlaMin"]} - {rowData["estSlaMax"]}
                  </span>
                );
              }}
            />
            <Column
              field="slaMax"
              header="SLA Maksimal"
              sortable={true}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="slaStatusInternal"
              header="Status SLA"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="overslaDaysInternal"
              header="Over SLA (Internal)"
              sortable={true}
              filter={false}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeInternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.utc().format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="overslaDaysExternal"
              header="Over SLA (External)"
              sortable={true}
              filter={false}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeExternal"]);
                let sTglOverSla: string = "-";

                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.utc().format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="overEstSlaDays"
              header="Est Lead Over SLA Partner"
              sortable={true}
              filter={false}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const estTglOverSla = moment(rowData["estSlaMaxDate"]);
                let estsTglOverSla: string = "-";

                if (estTglOverSla.isValid()) {
                  estsTglOverSla = estTglOverSla.utc().format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{estsTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="usiaPaket"
              header="Usia Paket"
              sortable={true}
              style={{ width: "80px", "vertical-align": "text-top" }}
            />
            <Column
              field="deliveryDate"
              header="Tgl. Terima Barang"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
              body={this.columnDateFormat}
            />
            <Column
              field="penerima"
              header="Nama Penerima Barang"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="receiverRemark"
              header="Remark Penerima Barang"
              sortable={true}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingType"
              header="Last Status"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingDateTime"
              header="Tgl. Last Status"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="prevFinalTrackingType"
              header="Last Status (2)"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="prevFinalTrackingDateTime"
              header="Tgl. Last Status (2)"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingTypePublic"
              header="Final Status Public"
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingTypePublicDateTime"
              header="Tgl. Final Status Public"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemTrackingType"
              header="Last Problem"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastProblemDateTime"
              header="Tgl. Last Problem"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: "145px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingSiteCode"
              header="Kode Gerai Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="manifestTrackingSiteName"
              header="Gerai Manifest"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />

            <Column
              field="lastValidTrackingSiteName"
              header="Gerai Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidTrackingSiteCity"
              header="Kota Terakhir"
              sortable={true}
              style={{ width: "120px", "vertical-align": "text-top" }}
            />
            <Column
              field="lastValidNoteInternal"
              header="Catatan"
              sortable={false}
              style={{ width: "200px", "vertical-align": "text-top" }}
            />
            <Column
              field="isCod"
              header="Cod"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />

            <Column
              field="isCutOff"
              header="Cut Off"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtHsCode"
              header="HS Code"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="isReturn"
              header="Return"
              sortable={false}
              body={(rowData, column: ColumnProps) => {
                const returnData = rowData[column.field!] ? 'Yes' : 'No';

                return (
                  <div>
                    <div>
                      {returnData}
                    </div>
                  </div>
                );
              }}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtIsFreeOngkir"
              header="Bebas Ongkir"
              sortable={false}
              body={(rowData, column: ColumnProps) => {
                const returnData = rowData[column.field!] ? 'Yes' : 'No';

                return (
                  <div>
                    <div>
                      {returnData}
                    </div>
                  </div>
                );
              }}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtTransactionType"
              header="Transaction Type"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="prtClaimValue"
              header="Claim Value"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            {/* <Column
              field="antCount"
              header="Attempt Pengantaran"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            /> */}
            <Column
              field="prtParcelContent"
              header="Parcel Content"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
            />
            <Column
              field="tags"
              header="Tags"
              sortable={false}
              style={{ width: "100px", "vertical-align": "text-top" }}
              body={(rowData, column: ColumnProps) => {
                const returnData = !!rowData[column.field!] ? rowData[column.field!].length > 0 ? rowData[column.field!].join(", ") : '' : '';

                return (
                  <div>
                    <div>
                      {returnData}
                    </div>
                  </div>
                );
              }}
            />
          </DataTable>

          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={(e) => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}
