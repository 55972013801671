import {Formik} from "formik";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import React, {RefObject} from "react";
import {Flex} from "reflexbox";
import {IProduktivitasSummaryFilterContainerValue} from "./IProduktivitasSummaryFilterContainerValue";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import ProvinceDropdown from "../../attendance-driver/dropdown/ProvinceDropdown";
import ProvinceDropdownNew from "../../attendance-driver/dropdown/ProvinceDropdownNew";
import BranchDropdown from "../../attendance-driver/dropdown/BranchDropdown";
import CityDropdown from "../../attendance-driver/dropdown/CityDropdown";
import CityDropdownNew from "../../attendance-driver/dropdown/CityDropdownNew";
import SiteDropdown from "../../attendance-driver/dropdown/SiteDropdown";
import BranchCodeDropdown from "../../attendance-driver/dropdown/BranchCodeDropdown";
import SiteCodeDropdown from "../../attendance-driver/dropdown/SiteCodeDropdown";
import SiteCodeDropdownCopy from "../../attendance-driver/dropdown/SiteCodeDropdownCopy";
import moment from "moment";
import AppDateRangePicker from "../../../utils/AppDateRangePicker";
import {AccessHelper} from "../../../utils/AccessHelper";

export class ProduktivitasSummaryFilterContainer extends React.PureComponent<
  any,
  any
> {
  initialFormValues: IProduktivitasSummaryFilterContainerValue = {
    startDate: moment(new Date()).startOf("month").toDate(),
    endDate: moment(new Date()).toDate(),
    periodeDate: [new Date(), new Date()],
    dateToleransiFirst: "",
    dateToleransiLast: "",
    dateToleransi2: "",
    dateToleransi3: "",
    dateToleransi4: "",
    dateToleransi5: "",
    dateToleransi6: "",
    province: "",
    province1: "",
    city: "",
    city1: "",
    branchCode: "",
    siteCode: "",
    justifikasi: "",
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionsCod = [
    {
      label: "ALL",
      value: "",
    },
    {
      label: "COD",
      value: "Y",
    },
    {
      label: "NON COD",
      value: "N",
    },
  ];

  optionSiteType = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Branch",
      value: "BRANCH",
    },
    {
      label: "Drop Point",
      value: "DROPPOINT",
    },
    {
      label: "Transit",
      value: "TRANSIT",
    },
    {
      label: "Agent",
      value: "AGENT",
    },
    {
      label: "Partner",
      value: "PARTNER",
    },
    {
      label: "Hub",
      value: "HUB",
    },
    {
      label: "Line Haul",
      value: "LINEHAUL",
    },
    {
      label: "HQ",
      value: "HQ",
    },
  ];

  optionsPeriode = [
    {
      label: "DAILY",
      value: "Daily",
    },
    {
      label: "WEEKLY",
      value: "Weekly",
    },
    {
      label: "MONTHLY",
      value: "Monthly",
    },
  ];
  optionsJustifikasi = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Good",
      value: "Good",
    },
    {
      label: "Bad",
      value: "Bad",
    },
    {
      label: "Warning",
      value: "Warning",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      isRenderCity: false,
      isRenderProvince: false,
      isRenderBranch: false,
      dataLocal: "",
      periode: "",
      city: "",
      showDateToleransiFirst: false,
      showDateToleransi2: false,
      showDateToleransi3: false,
      showDateToleransi4: false,
      showDateToleransi5: false,
      showDateToleransi6: false,
      showDateToleransiLast: false,
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  // componentDidMount() {
  //   // eslint-disable-next-line no-unused-vars
  //   let dataLocal = localStorage.getItem('userData');
  //   this.setState({
  //     dataLocal:dataLocal,
  //   })

  // }

  onChangeProvince = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("province", e);
      formikProps.setFieldValue("province1", e);
      await this.setState({
        isRenderProvince: false,
        isRenderCity: false,
        isRenderBranch: false,
      });
    } else {
      formikProps.setFieldValue("province", "");
      formikProps.setFieldValue("province1", "");
      formikProps.setFieldValue("city", "");
      formikProps.setFieldValue("city1", "");
      formikProps.setFieldValue("branchCode", "");
      await this.setState({
        isRenderProvince: true,
        isRenderCity: true,
        isRenderBranch: true,
        city: "",
        branchCode: "",
      });
    }
  };

  onChangeCity = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("city", e);
      formikProps.setFieldValue("city1", e);
      this.setState({
        city: e,
      });
    } else {
      if (this.state.isRenderProvince) {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        formikProps.setFieldValue("city1", "");
        formikProps.setFieldValue("branchCode", "");
        await this.setState({
          isRenderProvince: false,
          isRenderCity: false,
          isRenderBranch: false,
          city: "",
          branchCode: "",
        });
      } else {
        if (this.state.isRenderCity) {
          formikProps.setFieldValue("province", "");
          formikProps.setFieldValue("province1", "");
          formikProps.setFieldValue("city", "");
          formikProps.setFieldValue("city1", "");
          formikProps.setFieldValue("branchCode", "");
          await this.setState({
            isRenderProvince: true,
            isRenderCity: true,
            isRenderBranch: true,
            city: "",
            branchCode: "",
          });
        } else {
          formikProps.setFieldValue("province", "");
          formikProps.setFieldValue("province1", "");
          formikProps.setFieldValue("city", "");
          formikProps.setFieldValue("city1", "");
          formikProps.setFieldValue("branchCode", "");
          await this.setState({
            isRenderProvince: false,
            isRenderCity: false,
            isRenderBranch: false,
            city: "",
            branchCode: "",
          });
        }
      }
    }
  };
  onChangeCodeBranch = async (formikProps, e) => {
    if (e) {
      formikProps.setFieldValue("siteCode", e);
      formikProps.setFieldValue("branchCode", e);
      this.setState({
        branchCode: e,
      });
    } else {
      if (this.state.isRenderProvince) {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        formikProps.setFieldValue("city1", "");
        formikProps.setFieldValue("branchCode", "");
        formikProps.setFieldValue("siteCode", "");
        await this.setState({
          isRenderProvince: false,
          isRenderCity: false,
          isRenderBranch: false,
          city: "",
          branchCode: "",
        });
      } else {
        formikProps.setFieldValue("province", "");
        formikProps.setFieldValue("province1", "");
        formikProps.setFieldValue("city", "");
        formikProps.setFieldValue("city1", "");
        formikProps.setFieldValue("branchCode", "");
        formikProps.setFieldValue("siteCode", "");
        await this.setState({
          isRenderProvince: true,
          isRenderCity: true,
          isRenderBranch: true,
          city: "",
          branchCode: "",
        });
      }
    }
  };

  // onChangeBranch = async (formikProps, e) => {
  //   // console.log(e);

  //   if (e) {
  //     formikProps.setFieldValue("name", e);
  //     formikProps.setFieldValue("branchName", e);
  //   } else {
  //     console.log(this.state.isRenderBranch)
  //     if (this.state.isRenderCode) {
  //       formikProps.setFieldValue("name", "");
  //       formikProps.setFieldValue("branchName", "");
  //     await this.setState({
  //         isRenderBranch: false,
  //     });
  //     } else {
  //       formikProps.setFieldValue("name", "");
  //       formikProps.setFieldValue("branchName", "");
  //       await this.setState({
  //         isRenderBranch: true,
  //       });
  //     }
  //   }
  // };

  render() {
    return (
      <Panel
        header='Filter'
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({filterCollapsed: e.value})}
      >
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => {}}
        >
          {(formikProps) => (
            <div className='row'>
              <Flex wrap w={1}>
                <Flex column style={{marginTop: "5px", width: "375px"}}>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%", paddingLeft: "10%"}}
                    >
                      Tanggal
                    </label>
                    <div className='col-md-8'>
                      <Flex column>
                        {/* <Calendar
                              name='startDate'
                              readOnlyInput={true}
                              dateFormat='dd-mm-yy'
                              showWeek={false}
                              // maxDate={formikProps.values.endDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange("startDate")}
                        value={formikProps.values.startDate}
                        disabledDays={[0,7]} 
                            />
                            <div className='mt-2 mb-2 text-center'>s/d</div>
                            <Calendar
                              name='endDate'
                              readOnlyInput={true}
                              dateFormat='dd-mm-yy'
                              showWeek={false}
                              // minDate={formikProps.values.startDate}
                              onBlur={formikProps.handleBlur}
                              onChange={formikProps.handleChange("endDate")}
                        value={formikProps.values.endDate}
                        disabledDays={[0,7]} 
                        /> */}
                        <AppDateRangePicker
                          name='periodeDate'
                          onChange={formikProps.handleChange("periodeDate")}
                          value={formikProps.values.periodeDate}
                          // className={style.sizingPicker}
                          // maxSelection={[14, 'd']}
                          showButtonBar={true}
                          clearButtonStyleClass='p-button-danger'
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label
                      className='col-md-4'
                      style={{marginTop: "2%", paddingLeft: "10%"}}
                    >
                      Tanggal Toleransi
                    </label>
                    {this.state.showDateToleransiFirst ? (
                      <div className='col-md-8' style={{marginTop: "10px"}}>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransiFirst'
                            onChange={formikProps.handleChange(
                              "dateToleransiFirst",
                            )}
                            value={formikProps.values.dateToleransiFirst}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />{" "}
                        </Flex>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginLeft: "10%",
                          width: "40%",
                          marginBottom: "5%",
                          marginTop: "4%",
                        }}
                      >
                        <Button
                          style={{marginLeft: "10%", width: "25%"}}
                          type='button'
                          label='+'
                          onClick={(e) => {
                            this.setState({
                              showDateToleransiFirst: true,
                            });
                          }}
                        ></Button>{" "}
                      </div>
                    )}
                  </div>
                  {this.state.showDateToleransi2 ? (
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%", paddingLeft: "10%"}}
                      ></label>
                      <div className='col-md-8'>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransi2'
                            onChange={formikProps.handleChange(
                              "dateToleransi2",
                            )}
                            value={formikProps.values.dateToleransi2}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />
                        </Flex>
                      </div>
                    </div>
                  ) : this.state.showDateToleransiFirst ? (
                    <div style={{marginLeft: "37%", width: "40%"}}>
                      <Button
                        style={{marginLeft: "1%", width: "25%"}}
                        type='button'
                        label='+'
                        onClick={(e) => {
                          this.setState({
                            showDateToleransi2: true,
                          });
                        }}
                      ></Button>{" "}
                    </div>
                  ) : null}
                  {this.state.showDateToleransi3 ? (
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%", paddingLeft: "10%"}}
                      ></label>
                      <div className='col-md-8'>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransi3'
                            onChange={formikProps.handleChange(
                              "dateToleransi3",
                            )}
                            value={formikProps.values.dateToleransi3}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />
                        </Flex>
                      </div>
                    </div>
                  ) : this.state.showDateToleransi2 ? (
                    <div style={{marginLeft: "37%", width: "40%"}}>
                      <Button
                        style={{marginLeft: "1%", width: "25%"}}
                        type='button'
                        label='+'
                        onClick={(e) => {
                          this.setState({
                            showDateToleransi3: true,
                          });
                        }}
                      ></Button>{" "}
                    </div>
                  ) : null}

                  {this.state.showDateToleransi4 ? (
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%", paddingLeft: "10%"}}
                      ></label>
                      <div className='col-md-8'>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransi4'
                            onChange={formikProps.handleChange(
                              "dateToleransi4",
                            )}
                            value={formikProps.values.dateToleransi4}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />
                        </Flex>
                      </div>
                    </div>
                  ) : this.state.showDateToleransi3 ? (
                    <div style={{marginLeft: "37%", width: "40%"}}>
                      <Button
                        style={{marginLeft: "1%", width: "25%"}}
                        type='button'
                        label='+'
                        onClick={(e) => {
                          this.setState({
                            showDateToleransi4: true,
                          });
                        }}
                      ></Button>{" "}
                    </div>
                  ) : null}
                  {this.state.showDateToleransi5 ? (
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%", paddingLeft: "10%"}}
                      ></label>
                      <div className='col-md-8'>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransi5'
                            onChange={formikProps.handleChange(
                              "dateToleransi5",
                            )}
                            value={formikProps.values.dateToleransi5}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />
                        </Flex>
                      </div>
                    </div>
                  ) : this.state.showDateToleransi4 ? (
                    <div style={{marginLeft: "37%", width: "40%"}}>
                      <Button
                        style={{marginLeft: "1%", width: "25%"}}
                        type='button'
                        label='+'
                        onClick={(e) => {
                          this.setState({
                            showDateToleransi5: true,
                          });
                        }}
                      ></Button>{" "}
                    </div>
                  ) : null}
                  {this.state.showDateToleransi6 ? (
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%", paddingLeft: "10%"}}
                      ></label>
                      <div className='col-md-8'>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransi6'
                            onChange={formikProps.handleChange(
                              "dateToleransi6",
                            )}
                            value={formikProps.values.dateToleransi6}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />
                        </Flex>
                      </div>
                    </div>
                  ) : this.state.showDateToleransi5 ? (
                    <div style={{marginLeft: "37%", width: "40%"}}>
                      <Button
                        style={{marginLeft: "1%", width: "25%"}}
                        type='button'
                        label='+'
                        onClick={(e) => {
                          this.setState({
                            showDateToleransi6: true,
                          });
                        }}
                      ></Button>{" "}
                    </div>
                  ) : null}
                  {this.state.showDateToleransiLast ? (
                    <div className='form-group'>
                      <label
                        className='col-md-4'
                        style={{marginTop: "2%", paddingLeft: "10%"}}
                      ></label>
                      <div className='col-md-8'>
                        <Flex column>
                          <AppDateRangePicker
                            name='dateToleransiLast'
                            onChange={formikProps.handleChange(
                              "dateToleransiLast",
                            )}
                            value={formikProps.values.dateToleransiLast}
                            // className={style.sizingPicker}
                            maxSelection={[0, "d"]}
                            showButtonBar={true}
                            clearButtonStyleClass='p-button-danger'
                            notRange={true}
                          />
                        </Flex>
                      </div>
                    </div>
                  ) : this.state.showDateToleransi6 ? (
                    <div style={{marginLeft: "37%", width: "40%"}}>
                      <Button
                        style={{marginLeft: "1%", width: "25%"}}
                        type='button'
                        label='+'
                        onClick={(e) => {
                          this.setState({
                            showDateToleransiLast: true,
                          });
                        }}
                      ></Button>{" "}
                    </div>
                  ) : null}
                </Flex>

                <Flex style={{width: "350px"}} column>
                  {AccessHelper.isPermitted("produktivitas_province") ? (
                    <div className='form-group'>
                      <label className='col-md-4' style={{marginTop: "3%"}}>
                        Province
                      </label>
                      <div className='col-md-8'>
                        {/* <InputText
                          name="province"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.province}
                        /> */}
                        {this.state.isRenderProvince ? (
                          <ProvinceDropdown
                            name='province'
                            onChange={async (e) => {
                              // formikProps.setFieldValue("province", e);
                              this.onChangeProvince(formikProps, e);
                            }}
                            placeholder={"Pilih Province"}
                            filters={{
                              parameter: "province",
                              value: formikProps.values.province,
                            }}
                            branchName={formikProps.values.siteCode}
                            city={formikProps.values.city}
                            value={formikProps.values.province}
                            url={"masterdata/trackingsites/list-v2"}
                          />
                        ) : (
                          <ProvinceDropdownNew
                            name='province1'
                            onChange={async (e) => {
                              // formikProps.setFieldValue("province", e);
                              this.onChangeProvince(formikProps, e);
                            }}
                            placeholder={"Pilih Province"}
                            filters={{
                              parameter: "province",
                              value: formikProps.values.province1,
                            }}
                            branchName={formikProps.values.siteCode}
                            city={formikProps.values.city}
                            value={formikProps.values.province1}
                            url={"masterdata/trackingsites/list-v2"}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                  {AccessHelper.isPermitted("produktivitas_city") ? (
                    <div className='form-group'>
                      <label className='col-md-4' style={{marginTop: "3%"}}>
                        Kota
                      </label>
                      <div className='col-md-8'>
                        {/* <InputText
                          name="city"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.city}
                        /> */}
                        {this.state.isRenderCity ? (
                          <CityDropdownNew
                            name='city1'
                            onChange={async (e) => {
                              this.onChangeCity(formikProps, e);
                            }}
                            placeholder={"Pilih Kota"}
                            filters={{
                              parameter: "city",
                              value: formikProps.values.city1,
                            }}
                            province={formikProps.values.province}
                            branchName={formikProps.values.siteCode}
                            value={this.state.city}
                            url={"masterdata/trackingsites/list-v2"}
                          />
                        ) : (
                          <CityDropdown
                            name='city'
                            onChange={async (e) => {
                              this.onChangeCity(formikProps, e);
                            }}
                            placeholder={"Pilih Kota"}
                            filters={{
                              parameter: "city",
                              value: formikProps.values.city,
                            }}
                            province={formikProps.values.province}
                            branchName={formikProps.values.siteCode}
                            value={this.state.city}
                            url={"masterdata/trackingsites/list-v2"}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                  {AccessHelper.isPermitted("produktivitas_cabang") ? (
                    <div className='form-group'>
                      <label style={{marginTop: "3%"}} className='col-md-4'>
                        Cabang
                      </label>
                      <div className='col-md-8'>
                        {/* <InputText
                          name="city"
                          type="text"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          value={formikProps.values.city}
                        /> */}
                        {this.state.isRenderBranch ? (
                          <SiteCodeDropdownCopy
                            name='branchCode'
                            onChange={(e) => {
                              this.onChangeCodeBranch(formikProps, e);
                            }}
                            placeholder={"Pilih Cabang"}
                            filters={{
                              parameter: "name",
                              value: formikProps.values.branchCode,
                            }}
                            province={formikProps.values.province}
                            city={formikProps.values.city}
                            branchName={formikProps.values.name}
                            value={this.state.branchCode}
                            url={"masterdata/trackingsites/list-v2"}
                          />
                        ) : (
                          <SiteCodeDropdown
                            name='siteCode'
                            onChange={(e) => {
                              this.onChangeCodeBranch(formikProps, e);
                            }}
                            placeholder={"Pilih Cabang"}
                            filters={{
                              parameter: "name",
                              value: formikProps.values.siteCode,
                            }}
                            province={formikProps.values.province}
                            city={formikProps.values.city}
                            branchName={formikProps.values.name}
                            value={this.state.branchCode}
                            url={"masterdata/trackingsites/list-v2"}
                          />
                        )}
                      </div>
                    </div>
                  ) : null}
                </Flex>
                <Flex column>
                  <div className='form-group'>
                    <label className='col-md-4' style={{marginTop: "3%"}}>
                      Justifikasi
                    </label>
                    <div className='col-md-8' style={{marginTop: "2%"}}>
                      {/* <InputText
                        name="province"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.province}
                      /> */}
                      <Dropdown
                        options={this.optionsJustifikasi}
                        onChange={formikProps.handleChange("justifikasi")}
                        value={formikProps.values.justifikasi}
                        placeholder='All'
                      />
                    </div>
                  </div>
                </Flex>
              </Flex>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
