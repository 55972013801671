import { observer } from 'mobx-react';
import React, { RefObject } from 'react';

import qs from 'qs';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { IdleAwbKibanaFilterContainer } from './IdleAwbFilterContainer';
import { IdleAwbKibanaSummaryContainer } from './IdleAwbKibanaSummaryContainer';
import { IdleAwbKibanaDetailContainer } from './IdleAwbKibanaDetailContainer';
import { TabView, TabPanel } from 'primereact/tabview';
import { Flex } from 'reflexbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DateHelper } from '../../utils/DateHelper';
import { Growl } from 'primereact/growl';

@observer
export class IdleAwbKibanaContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  growl;
  refSummary: RefObject<IdleAwbKibanaSummaryContainer> = React.createRef();
  refDetail: RefObject<IdleAwbKibanaDetailContainer> = React.createRef();
  refFilter: RefObject<IdleAwbKibanaFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 0,
      exportExcelLoading: false,
      showExportDialog: false,
    };
  }

  componentDidMount() {
    const query = (this.props.history.location.search)
      ? qs.parse(
        this.props.history.location.search.substr(1),
      ) as any
      : {} as any;

    if (query) {
      if (query.activeTabIndex) {
        this.setState({ activeTabIndex: parseInt(query.activeTabIndex) });
      }

      if (query.filters) {
        query.filters.startDate = (query.filters.startDate) ? new Date(query.filters.startDate) : "";
        query.filters.endDate = (query.filters.endDate) ? new Date(query.filters.endDate) : "";

        const asyncUpdateFormValuesAndLoad = async () => {
          await this.refFilter.current!.setFormValues(query.filters);
          this.loadData();
        };
        asyncUpdateFormValuesAndLoad();
      }
    }
  }

  loadData() {
    const fParams = this.refFilter.current!.formValues;

    if ((this.state as any).activeTabIndex === 1) {
      this.refDetail.current!.loadData(fParams);
    } else {
      this.refSummary.current!.loadData(fParams);
    }
  }

  downloadCsv() {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = this.refFilter.current!.formValues;
    if ((this.state as any).activeTabIndex === 1) {
      const filterParam = Object.assign({}, fParams);
      if (filterParam.startDate) filterParam.startDate = DateHelper.formatLocalDateForQueryParam(filterParam.startDate);
      if (filterParam.endDate) filterParam.endDate = DateHelper.formatLocalDateForQueryParam(filterParam.endDate);

      const resPath = 'idleawb-kibana/List';

      filterParam.format = "xls";
      this.monitoringRestService
        .post(resPath, filterParam)
        .subscribe(
          response => {
            if (response) {
              this.refDetail.current!.openCsv(response);
            }
          },
          () => {
            this.setState({ exportExcelLoading: false, showExportDialog: false });
            let msg = { severity: 'error', summary: 'Error Message', detail: 'Export failed' };
            this.growl.show(msg);
          },
          () => {
            this.setState({ exportExcelLoading: false, showExportDialog: false });
            let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
            this.growl.show(msg);
          },
        );
    }
  }

  render() {
    return (
      <div>
        <Dialog header="Informasi" visible={this.state.showExportDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showExportDialog: false })}>
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <div>
          <Flex className="card w-full">
            <Flex className="w-full" column>
              <IdleAwbKibanaFilterContainer ref={this.refFilter} />
              <br />
              <Flex>
                <Button
                  className="p-button-success mr-2"
                  label="Tampilkan"
                  onClick={() => this.loadData()}
                />
                {this.state.activeTabIndex === 1 &&
                  <Button
                    className="p-button-primary"
                    label="Export Excel"
                    icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : ""}
                    disabled={(this.state.exportExcelLoading)}
                    onClick={() => this.downloadCsv()}
                  />
                }
              </Flex>
            </Flex>
          </Flex>
        </div>
        <div>
          <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
            <TabPanel header="Summary">
              <IdleAwbKibanaSummaryContainer ref={this.refSummary} />
            </TabPanel>
            <TabPanel header="Detail">
              <IdleAwbKibanaDetailContainer ref={this.refDetail} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}
