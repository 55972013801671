import React, { PureComponent } from 'react';
import { Calendar, CalendarProps } from 'primereact/calendar';
import moment from 'moment';
import {
  AppDateRangePickerProps,
  AppDateRangePickerState,
  EventOnChange,
} from './AppDateRangePicker.model';

class AppDateRangePicker extends PureComponent<any,any> {
  constructor(props: any) {
    super(props);

    const futureYear = moment()
      .add(1, 'y')
      .format('YYYY');
    const prevYear = moment()
      .add(-15, 'y')
      .format('YYYY');
    const yearRange = `${prevYear}:${futureYear}`;

    this.state = {
      value: props.value === undefined ? [new Date(), new Date()] : props.value,
      format: props.format === undefined ? 'dd/mm/yy' : props.format,
      yearRange: props.yearRange === undefined ? yearRange : props.yearRange,
      maxDate: props.maxDate === undefined ? new Date() : props.maxDate,
    };
  }

  onChange = (e: EventOnChange): void => {
    const { maxSelection, onChange } = this.props;
    if (e.value && e.value[0]) {
      let maksDate;
      if (maxSelection !== undefined) {
        maksDate = moment(e.value[0])
          .add(maxSelection[0], maxSelection[1])
          .toDate();
      } else {
        maksDate = moment(e.value[0])
          .add(1, 'month').subtract(1, 'd')
          .toDate();
      }
      const nowDate = new Date();
      maksDate > nowDate
        ? this.setState({ maxDate: nowDate })
        : this.setState({ maxDate: maksDate });
    }

    onChange(e);
  };

  onClear = (e: Event): void => {
    this.setState({
      maxDate:
        this.props.maxDate === undefined ? new Date() : this.props.maxDate,
    });
  };

  render(): JSX.Element {
    const { ...others } = this.props;
    return (
      <Calendar
        {...others}
        value={this.props.value}
        onChange={this.onChange}
        onClearButtonClick={this.onClear}
        monthNavigator={true}
        yearNavigator={true}
        readOnlyInput={true}
        showIcon={true}
        yearRange={this.state.yearRange}
        showWeek={false}
        dateFormat={this.state.format}
        selectionMode={this.props.notRange ? undefined:"range"}
        maxDate={this.state.maxDate}
        inputStyle={this.props.inputStyle? this.props.inputStyle:undefined}
        style={this.props.inputStyle? this.props.inputStyle:undefined}
      />
    );
  }
}

export default AppDateRangePicker;
