import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";
import ReactFileReader from "react-file-reader";

import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AccessHelper } from "../../utils/AccessHelper";
import {
  ITrackingSiteFilterContainerValue,
  TrackingSiteFilterContainer,
} from "./TrackingSiteFilterContainer";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { InputText } from "primereact/inputtext";
import { Growl } from "primereact/growl";
import { Dropdown } from "primereact/dropdown";
import { TrackingSiteEditFormModal } from "./TrackingSiteEditFormModal";
import { Observable } from "rxjs";

export interface ITrackingSiteContainerQuery {
  filters: ITrackingSiteFilterContainerValue;
}

@observer
export class TrackingSiteContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  growl;
  trackingSiteData;
  tableExportData;
  userData;
  dataSource = new DataSource<any>();
  // formFilters: ITrackingSiteFilterContainerValue;
  // refFilter: RefObject<TrackingSiteFilterContainer> = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      showDialog: false,
      showErrorDialog: false,
      totalError: 0,
      dataError: "",
      expandedRows: null,
      displayUpdateDialog: false,
      selectedTrackingSiteId: null,
      countBranch: 0,
      countFirstMile: 0,
      countHub: 0,
      countLastMile: 0,
      countLineHaul: 0,
      footerTotal: {},
      showUploadModal: false,
    };

    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

    this.columnActionTemplate = this.columnActionTemplate.bind(this);
    this.onUpdateClick = this.onUpdateClick.bind(this);
    this.saveUpdate = this.saveUpdate.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
  }

  componentDidMount() {}

  loadData() {
    // this.formFilters = this.refFilter.current!.formValues;
    this.setState({ expandedRows: null });
    this.dataSource.loading = true;
    this.monitoringRestService
      .get("masterdata/trackingsites/dashboard/summary")
      .subscribe(
        (response) => {
          this.setState({
            countBranch: response.CountBranch,
            countFirstMile: response.CountFirstMile,
            countHub: response.CountHub,
            countLastMile: response.CountLastMile,
            countLineHaul: response.CountLineHaul,
          });
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
    this.monitoringRestService.get("masterdata/trackingsites").subscribe(
      (response) => {
        this.trackingSiteData = response;
        this.populateDataSource();
      },
      () => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  populateDataSource() {
    if (this.trackingSiteData && this.trackingSiteData.length) {
      this.dataSource.setData(this.trackingSiteData);
      this.dataSource.setTotal(this.trackingSiteData.length);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }

    this.calculateTotals();
  }

  async calculateTotals() {
    const filterTrackingData = await this.trackingSiteData.filter(
      (t) => t.isActive === true
    );

    const footerTotal = {
      dlvBranchCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvBranchCapacity;
      }),

      dlvOpsCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsCapacity;
      }),
      dlvOpsProbationCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsProbationCapacity;
      }),
      dlvOpsAdjustmentCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsAdjustmentCapacity;
      }),
      dlvOpsProbationAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsProbationAdjustmentCapacity;
        }
      ),

      dlvOpsDriverCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsDriverCapacity;
      }),
      dlvOpsDriverProbationCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsDriverProbationCapacity;
        }
      ),
      dlvOpsDriverAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsDriverAdjustmentCapacity;
        }
      ),
      dlvOpsDriverProbationAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsDriverProbationAdjustmentCapacity;
        }
      ),

      dlvOpsCodCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsCodCapacity;
      }),
      dlvOpsCodProbationCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsCodProbationCapacity;
        }
      ),
      dlvOpsCodAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsCodAdjustmentCapacity;
        }
      ),
      dlvOpsCodProbationAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsCodProbationAdjustmentCapacity;
        }
      ),

      dlvOpsSdsCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsSdsCapacity;
      }),
      dlvOpsSdsProbationCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsSdsProbationCapacity;
        }
      ),
      dlvOpsSdsAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsSdsAdjustmentCapacity;
        }
      ),
      dlvOpsSdsProbationAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsSdsProbationAdjustmentCapacity;
        }
      ),

      dlvOpsCargoCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsCargoCapacity;
      }),
      dlvOpsCargoProbationCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsCargoProbationCapacity;
        }
      ),
      dlvOpsCargoAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsCargoAdjustmentCapacity;
        }
      ),
      dlvOpsCargoProbationAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsCargoProbationAdjustmentCapacity;
        }
      ),

      dlvOpsFreelanceCapacity: _.sumBy(filterTrackingData, function (o: any) {
        return o.dlvOpsFreelanceCapacity;
      }),
      dlvOpsFreelanceAdjustmentCapacity: _.sumBy(
        filterTrackingData,
        function (o: any) {
          return o.dlvOpsFreelanceAdjustmentCapacity;
        }
      ),
    };

    this.setState({ footerTotal: footerTotal });
  }

  onUpdateClick(e) {
    this.setState({
      displayUpdateDialog: true,
      selectedTrackingSiteId: e["id"],
    });
  }

  saveUpdate(): Observable<any> {
    return Observable.create((observer) => {
      const trackingSiteId = this.state.selectedTrackingSiteId;
      this.monitoringRestService
        .get(`masterdata/trackingsites/${trackingSiteId}`)
        .subscribe(
          (data) => {
            try {
              const idx = _.findIndex(this.trackingSiteData, (d: any) => {
                return d.id === trackingSiteId;
              });
              this.trackingSiteData[idx] = data;

              this.dataSource.setData(this.trackingSiteData);
              this.dataSource.setTotal(this.trackingSiteData.length);
              this.calculateTotals();

              this.setState({
                selectedTrackingSiteId: null,
                displayUpdateDialog: false,
              });

              let msg = {
                severity: "success",
                summary: "Success Message",
                detail: "Update Data Success",
              };
              this.growl.show(msg);

              this.loadData();

              observer.next();
            } catch (error) {
              observer.error(error);
            }
          },
          (error) => {
            observer.error(error);
          },
          () => {
            observer.complete();
          }
        );
    });
  }

  cancelUpdate() {
    this.setState({
      selectedTrackingSiteId: null,
      displayUpdateDialog: false,
      showUploadModal: false,
    });
  }

  closeModal() {
    this.setState({
      showUploadModal: false,
    });
  }

  exportCsv() {
    this.tableExportData.exportCSV();
    let msg = {
      severity: "success",
      summary: "Success Message",
      detail: "Export Success",
    };
    this.growl.show(msg);
  }

  columnActionTemplate(rowData) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          onClick={() => this.onUpdateClick(rowData)}
        ></Button>
      </div>
    );
  }

  handleFiles = async (files) => {
    this.setState({
      showDialog: true,
    });

    const file = files[0];
    if (file !== undefined) {
      this.setState({ selectedFile: files[0] });
    } else {
      this.setState({ selectedFile: "" });
    }
    const dataNew = new FormData();
    await dataNew.append("file", files[0]);
    await dataNew.append("username", this.userData.username);

    this.monitoringRestService
      .post("masterdata/trackingsites/upload/process", dataNew)
      .subscribe(
        (response) => {
          try {
            this.setState({
              showUploadModal: false,
              showDialog: false,
            });
            this.loadData();
            let msg = {
              severity: "success",
              summary: "Success Message",
              detail: "Upload " + response.successCount + " Data Success",
            };
            this.growl.show(msg);
            if (response.errorCount > 0) {
              let dataCount = "";
              response.errorRowsIndex.forEach((element) => {
                dataCount = dataCount + " [ " + element + " ] ";
              });
              this.setState({
                showErrorDialog: true,
                totalError: response.errorCount,
                dataError: dataCount,
              });
            }
          } catch (error) {
            this.setState({
              showUploadModal: false,
              showDialog: false,
            });
            let msg = {
              severity: "success",
              summary: "Success Message",
              detail: "Upload Gagal",
            };
            this.growl.show(msg);
          }
        },
        () => {},
        () => {}
      );
  };

  render() {
    let cols: any[] = [];

    for (let i = 0; i < 5; i++) {
      if (i === 0) {
        const col = (
          <div key={i} className="p-col">
            <div className={`card`}>
              <p className="text-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total Gerai
                </span>
              </p>
              <p className="text-center" style={{ marginBottom: "0px" }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {this.state.countBranch}
                </span>
              </p>
            </div>
          </div>
        );

        cols.push(col);
      }

      if (i === 1) {
        const col = (
          <div key={i} className="p-col">
            <div className={`card`}>
              <p className="text-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total FM
                </span>
              </p>
              <p className="text-center" style={{ marginBottom: "0px" }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {this.state.countFirstMile}
                </span>
              </p>
            </div>
          </div>
        );

        cols.push(col);
      }

      if (i === 2) {
        const col = (
          <div key={i} className="p-col">
            <div className={`card`}>
              <p className="text-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total LM
                </span>
              </p>
              <p className="text-center" style={{ marginBottom: "0px" }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {this.state.countLastMile}
                </span>
              </p>
            </div>
          </div>
        );

        cols.push(col);
      }

      if (i === 3) {
        const col = (
          <div key={i} className="p-col">
            <div className={`card`}>
              <p className="text-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total HUB
                </span>
              </p>
              <p className="text-center" style={{ marginBottom: "0px" }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {this.state.countHub}
                </span>
              </p>
            </div>
          </div>
        );

        cols.push(col);
      }

      if (i === 4) {
        const col = (
          <div key={i} className="p-col">
            <div className={`card`}>
              <p className="text-center">
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Total Linehaul
                </span>
              </p>
              <p className="text-center" style={{ marginBottom: "0px" }}>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {this.state.countLineHaul}
                </span>
              </p>
            </div>
          </div>
        );

        cols.push(col);
      }
    }

    let footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer={
              "Totals:" + this.dataSource.total
                ? this.dataSource.total.toLocaleString()
                : 0
            }
            colSpan={15}
          />
          <Column
            footer={
              this.state.footerTotal.dlvBranchCapacity
                ? this.state.footerTotal.dlvBranchCapacity.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.dlvOpsCapacity
                ? this.state.footerTotal.dlvOpsCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsProbationCapacity
                ? this.state.footerTotal.dlvOpsProbationCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsAdjustmentCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsProbationAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsProbationAdjustmentCapacity.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.dlvOpsDriverCapacity
                ? this.state.footerTotal.dlvOpsDriverCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsDriverProbationCapacity
                ? this.state.footerTotal.dlvOpsDriverProbationCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsDriverAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsDriverAdjustmentCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsDriverProbationAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsDriverProbationAdjustmentCapacity.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.dlvOpsCodCapacity
                ? this.state.footerTotal.dlvOpsCodCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCodProbationCapacity
                ? this.state.footerTotal.dlvOpsCodProbationCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCodAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsCodAdjustmentCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCodProbationAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsCodProbationAdjustmentCapacity.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.dlvOpsSdsCapacity
                ? this.state.footerTotal.dlvOpsSdsCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsSdsProbationCapacity
                ? this.state.footerTotal.dlvOpsSdsProbationCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsSdsAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsSdsAdjustmentCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsSdsProbationAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsSdsProbationAdjustmentCapacity.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.dlvOpsCargoCapacity
                ? this.state.footerTotal.dlvOpsCargoCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCargoProbationCapacity
                ? this.state.footerTotal.dlvOpsCargoProbationCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCargoAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsCargoAdjustmentCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsCargoProbationAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsCargoProbationAdjustmentCapacity.toLocaleString()
                : ""
            }
          />

          <Column
            footer={
              this.state.footerTotal.dlvOpsFreelanceCapacity
                ? this.state.footerTotal.dlvOpsFreelanceCapacity.toLocaleString()
                : ""
            }
          />
          <Column
            footer={
              this.state.footerTotal.dlvOpsFreelanceAdjustmentCapacity
                ? this.state.footerTotal.dlvOpsFreelanceAdjustmentCapacity.toLocaleString()
                : ""
            }
          />
          <Column header="" colSpan={3} />
        </Row>
      </ColumnGroup>
    );

    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Tutup" icon="pi pi-times" onClick={this.cancelUpdate} />
      </div>
    );

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Dialog
          header="Informasi"
          visible={this.state.showDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showDialog: false })}
        >
          Sedang memuat data, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Dialog
          header="Informasi"
          visible={this.state.showErrorDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showErrorDialog: false })}
        >
          terdapat {this.state.totalError} Data Error, pada baris{" "}
          {this.state.dataError}
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            {/* <TrackingSiteFilterContainer ref={this.refFilter} />
            <br /> */}
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                type="button"
                className="p-button-primary mr-2"
                icon="pi pi-external-link"
                iconPos="left"
                label="CSV"
                onClick={this.exportCsv}
              ></Button>
              {AccessHelper.isPermitted("masterdata_gerai_upload") ? (
                <Button
                  className="p-button-secondary"
                  label="Upload Data"
                  onClick={() => {
                    this.setState({ showUploadModal: true });
                  }}
                />
              ) : null }
            </Flex>
          </Flex>
        </Flex>

        <div className="p-grid">{cols}</div>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={"Tidak ada data untuk ditampilkan."}
                scrollable={true}
                scrollHeight="365px"
                // frozenWidth="450px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true}
                rows={50}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => {
                  this.tableExportData = el;
                }}
                footerColumnGroup={footerGroup}
              >
                {/* onRowToggle={(e) => this.onRowToggle(e)}
                // onRowToggle={(e) => this.setState({expandedRows:e.data})}
                rowExpansionTemplate={this.rowExpansionTemplate} dataKey="trackingSite"> */}
                <Column
                  body={this.columnActionTemplate}
                  style={{ textAlign: "center", width: "5em" }}
                />
                <Column
                  field="province"
                  header="Province"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="city"
                  header="Kota"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="district"
                  header="Kecamatan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="name"
                  header="Nama"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="publicSiteName"
                  header="Nama Website"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="siteCode"
                  header="Kode Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="siteType"
                  header="Tipe"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="isActiveLabel"
                  header="Aktif"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue;
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="isFirstMileLabel"
                  header="First Mile"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue;
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="isLastMileLabel"
                  header="Last Mile"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue;
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="isHubLabel"
                  header="HUB"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue;
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="isLineHaulLabel"
                  header="Line Haul"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue;
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="otherFunctionType"
                  header="Other"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue;
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="perwakilan"
                  header="Perwakilan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={false}
                  style={{ width: "130px" }}
                />
                <Column
                  field="dlvBranchCapacity"
                  header="Dlv. Kapasitas Tempat"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />

                <Column
                  field="dlvOpsCapacity"
                  header="Dlv. Kapasitas Orang"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsProbationCapacity"
                  header="Dlv. Kapasitas Orang Probation"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang (Adjustment)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsProbationAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang Probation (Adjustment)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />

                <Column
                  field="dlvOpsDriverCapacity"
                  header="Dlv. Kapasitas Orang (Driver)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsDriverProbationCapacity"
                  header="Dlv. Kapasitas Orang Probation (Driver)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsDriverAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang (Driver - Adjustment)"
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  sortable={true}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsDriverProbationAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang Probation (Driver - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />

                <Column
                  field="dlvOpsCodCapacity"
                  header="Dlv. Kapasitas Orang (COD)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsCodProbationCapacity"
                  header="Dlv. Kapasitas Orang Probation (COD)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsCodAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang (COD - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsCodProbationAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang Probation (COD - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />

                <Column
                  field="dlvOpsSdsCapacity"
                  header="Dlv. Kapasitas Orang (Sds)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsSdsProbationCapacity"
                  header="Dlv. Kapasitas Orang Probation (Sds)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsSdsAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang (Sds - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsSdsProbationAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang Probation (Sds - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />

                <Column
                  field="dlvOpsCargoCapacity"
                  header="Dlv. Kapasitas Orang (Cargo)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsCargoProbationCapacity"
                  header="Dlv. Kapasitas Orang Probation (Cargo)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsCargoAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang (Cargo - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsCargoProbationAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang Probation (Cargo - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />

                <Column
                  field="dlvOpsFreelanceCapacity"
                  header="Dlv. Kapasitas Orang (Freelance)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dlvOpsFreelanceAdjustmentCapacity"
                  header="Dlv. Kapasitas Orang (Freelance - Adjustment)"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="note"
                  header="Catatan"
                  style={{ width: "200px" }}
                />
                <Column
                  field="usrUpd"
                  header="Update By User"
                  sortable={true}
                  body={(rowData, column: ColumnProps) => {
                    const colValue = rowData[column.field!];
                    const fmtValue = colValue ? colValue.toLocaleString() : "-";
                    return <span>{fmtValue}</span>;
                  }}
                  style={{ width: "145px" }}
                />
                <Column
                  field="dtmUpdFormatted"
                  header="Update Date Time"
                  sortable={true}
                  // body={(rowData, column: ColumnProps) => {
                  //   const colValue = rowData[column.field!];
                  //   const fmtValue = colValue ? moment(new Date(colValue)).utc().format('DD-MM-YYYY HH:mm') : '-';
                  //   return <span>{fmtValue}</span>;
                  // }}
                  style={{ width: "145px" }}
                />
              </DataTable>

              <TrackingSiteEditFormModal
                trackingSiteId={this.state.selectedTrackingSiteId}
                isOpen={this.state.displayUpdateDialog}
                onSuccess={() => this.saveUpdate()}
                onClose={() => this.cancelUpdate()}
              />

              <Dialog
                visible={this.state.showUploadModal}
                contentStyle={{ maxHeight: "500px" }}
                header="Upload Data"
                modal={true}
                footer={dialogFooter}
                onHide={() => this.closeModal()}
              >
                <div className="row">
                  <div className="col-md-8">
                    <h3>Download file template</h3>
                    <p>
                      Template ini memiliki kolom heading yang anda butuhkan
                      untuk mengimpor data gerai anda
                    </p>
                  </div>
                  <div className="col-md-4 pt-10">
                    <a
                      href={
                        process.env.REACT_APP_BACKEND_URL +
                        `/masterdata/trackingsites/upload/template`
                      }
                    >
                      <Button
                        type="button"
                        icon="pi pi-external-link"
                        iconPos="left"
                        label="Download Template"
                      ></Button>
                    </a>
                  </div>
                </div>
                <br />
                <hr />
                <br />
                <div className="row">
                  <div className="col-md-8">
                    <h3>Salin Data Anda</h3>
                    <p>
                      Gunakan aplikasi Microsoft Excel atau editor lainnya yang
                      kompatibel untuk melakukan edit dengan format file Excel.
                      Pastikan Anda mengisi data sesuai pada kolom yang tepat
                    </p>
                  </div>
                </div>
                <br />
                <hr />
                <br />
                <div className="row">
                  <div className="col-md-8">
                    <h3>Upload File Data</h3>
                    <p>
                      silahkan upload kembali file tersebut disini, file format
                      yang dapat kami proses adalah .xls dan .xlsx <br />
                      Note: proses upload ini bisa untuk menambahkan/update
                      gerai, Bilamana Kode Cabang Kosong maka akan menambahkan
                      dan nama gerai akan terisi dari nama Website
                    </p>
                  </div>
                  <div className="col-md-4 pt-10">
                    <ReactFileReader
                      handleFiles={this.handleFiles}
                      fileTypes={".xls, .xlsx"}
                    >
                      <Button
                        type="button"
                        className="btn btn-info mr-3"
                        icon="pi pi-search"
                        iconPos="left"
                        label="Browse File"
                      ></Button>
                    </ReactFileReader>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
