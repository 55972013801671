import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import { LAYANAN_OPTIONS } from "../../constants";

export interface IAwbListFilterContainerValue {
  startDate?: Date;
  endDate?: Date;
  lastStartDate?: Date;
  lastEndDate?: Date;
  customer?: string;
  partner?: string;
  serviceType?: any;
  isCod?: any;
  isManifested?: any;
  asal?: any;
  asalKota?: any;
  asalKecamatan?: any;
  tujuan?: any;
  tujuanKota?: any;
  tujuanKecamatan?: any;
  trackingSite?: any;
  city?: any;
  lastStatus?: any;
  isPartnerFeed?: any;
  province: any;
  problemStatus: any;
  is3PL: any;
  manifestTrackingSiteName: string;
  tags: string;
  noKonfirmasi: string;
  lastStatusPublic: string;
  [key: string]: any;
}

export class AwbListKibanaFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: IAwbListFilterContainerValue = {
    startDate: undefined,
    endDate: undefined,
    lastStartDate: undefined,
    lastEndDate: undefined,
    customer: "",
    partner: "",
    serviceType: "",
    isCod: "",
    isManifested: "true",
    stage: "",
    asal: "",
    asalKota: "",
    asalKecamatan: "",
    tujuan: "",
    tujuanKota: "",
    tujuanKecamatan: "",
    lastStatus: "",
    trackingSite: "",
    city: "",
    isPartnerFeed: "",
    problemStatus: "",
    province: "",
    tags: "",
    is3PL: "",
    manifestTrackingSiteName: "",
    noKonfirmasi: "",
    lastStatusPublic: "",
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionYN = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Ya",
      value: "Y",
    },
    {
      label: "Tidak",
      value: "N",
    },
  ];

  optionYNTF = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Ya",
      value: "true",
    },
    {
      label: "Tidak",
      value: "false",
    },
  ];

  optionParcelCondition = [
    {
      label: "5Kg - 10Kg",
      value: "1",
    },
    {
      label: "10Kg - 20Kg",
      value: "2",
    },
    {
      label: "> 20Kg",
      value: "3",
    },
  ];

  optionsProvince = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "BALI",
      value: '["DPS"]',
    },
    {
      label: "BANDUNG",
      value: '["BDB","BDO","BDT"]',
    },
    {
      label: "BANDUNG TRANSIT",
      value: '["BD1"]',
    },
    {
      label: "BATAM",
      value: '["BTH"]',
    },
    {
      label: "BODETABEK",
      value:
        '["BKC","BKI","BKT","BKU","BOB","BOO","BOU","CKR","DPC","DPK","TGR","TGS"]',
    },
    {
      label: "CGK",
      value: '["CGK"]',
    },
    {
      label: "INTERNATIONAL",
      value: '["INS","INT"]',
    },
    {
      label: "DENPASAR TRANSIT",
      value: '["DP0","DP1","DP2"]',
    },
    {
      label: "JAKARTA BARAT",
      value: '["CBN"]',
    },
    {
      label: "JAKARTA PUSAT",
      value: '["CGP"]',
    },
    {
      label: "JAKARTA SELATAN",
      value: '["CGS"]',
    },
    {
      label: "JAKARTA TIMUR",
      value: '["CGT"]',
    },
    {
      label: "JAKARTA UTARA",
      value: '["CGU"]',
    },
    {
      label: "JAWA BARAT",
      value:
        '["CBN","CIM","CJR","CLG","GRT","IDR","KJT","KRW","KUN","PWA","SBG","SMD","SMI","TSI"]',
    },
    {
      label: "JAWA TENGAH",
      value:
        '["BES","BTA","BYO","JO1","JO2","JOG","KDS","MGL","PKL","PML","PWT","SDA","SO1","SOC","SRG","TL1","TLG"]',
    },
    {
      label: "JAWA TIMUR",
      value: '["MDN","MLG","SUB","SUO"]',
    },
    {
      label: "JNE",
      value: '["JNE"]',
    },
    {
      label: "KALIMANTAN",
      value: '["BDJ","BPN","PKY","PNK","SRI","TRK"]',
    },
    {
      label: "MALUKU & PAPUA",
      value: '["AMQ","BIK","DJJ","MKQ","MKW","SOQ","TIM","TTE"]',
    },
    {
      label: "MEDAN TRANSIT",
      value: '["ME1"]',
    },
    {
      label: "NTB",
      value: '["LO1","LOP"]',
    },
    {
      label: "NTT",
      value: '["KOE"]',
    },
    {
      label: "PALEMBANG TRANSIT",
      value: '["PL1"]',
    },
    {
      label: "PURWOKERTO TRANSIT",
      value: '["PW1"]',
    },
    {
      label: "SDS",
      value: '["SDS"]',
    },
    {
      label: "SEMARANG TRANSIT",
      value: '["SR0","SR1"]',
    },
    {
      label: "SULAWESI",
      value: '["GTO","KDI","MDC","PLW","UPG"]',
    },
    {
      label: "SUMATERA",
      value:
        '["BKS","BTJ","DJB","MES","PDG","PGK","PK1","PKU","PLM","TJQ","TK1","TKG","TNJ"]',
    },
    {
      label: "SURABAYA TRANSIT",
      value: '["SU0","SU1","SU2"]',
    },
    {
      label: "XXX",
      value: '["XXX"]',
    },
  ];

  optionStage = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "On Process",
      value: "IN PROGRESS",
    },
    {
      label: "Delivered",
      value: "DELIVERED",
    },
    {
      label: "LOST/BROKEN",
      value: "LOST/BROKEN",
    },
    {
      label: "Returned",
      value: "RETURNED",
    },
    {
      label: "Cancelled",
      value: "CANCELLED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      isLargeParcel: false,
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  onChangeIsLargeParcel(formikProps, e) {
    this.setState({ isLargeParcel: e.value === "true" })
    formikProps.handleChange("isLargeParcel")(e)
    if (e.value !== "true") {
      formikProps.setFieldValue("isLargeParcelCondition", null)
    } else {
      formikProps.setFieldValue("isLargeParcelCondition", "1")
    }
  }

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {(formikProps) => (
            <Flex wrap w={1}>
              <Box w={350}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Periode</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                          showButtonBar={true}
                        />
                        <div
                          className="mt-2 mb-2 text-center"
                          style={{ width: "172px" }}
                        >
                          s/d
                        </div>
                        <Calendar
                          name="endDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          minDate={formikProps.values.startDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("endDate")}
                          value={formikProps.values.endDate}
                          showButtonBar={true}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Customer</label>
                    <div className="col-md-7">
                      <InputText
                        name="customer"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.customer}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Partner</label>
                    <div className="col-md-7">
                      <InputText
                        name="partner"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.partner}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Partner Feed</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionYNTF}
                        onChange={formikProps.handleChange("isPartnerFeed")}
                        value={formikProps.values.isPartnerFeed}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tgl Last Status</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="lastStartDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.lastEndDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("lastStartDate")}
                          value={formikProps.values.lastStartDate}
                          showButtonBar={true}
                        />
                        <div
                          className="mt-2 mb-2 text-center"
                          style={{ width: "172px" }}
                        >
                          s/d
                        </div>
                        <Calendar
                          name="lastEndDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          minDate={formikProps.values.lastStartDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("lastEndDate")}
                          value={formikProps.values.lastEndDate}
                          showButtonBar={true}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">No Konfirmasi</label>
                    <div className="col-md-7">
                      <InputText
                        name="noKonfirmasi"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.noKonfirmasi}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
              <Box>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Layanan</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={LAYANAN_OPTIONS}
                        onChange={formikProps.handleChange("serviceType")}
                        value={formikProps.values.serviceType}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">COD</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionYNTF}
                        onChange={formikProps.handleChange("isCod")}
                        value={formikProps.values.isCod}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">3 PL Partner</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionYNTF}
                        onChange={formikProps.handleChange("is3PL")}
                        value={formikProps.values.is3PL}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Perwakilan</label>
                    <div className="col-md-7">
                      <InputText
                        name="perwakilan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.perwakilan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Gerai Terakhir</label>
                    <div className="col-md-7">
                      <InputText
                        name="trackingSite"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.trackingSite}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Kota Terakhir</label>
                    <div className="col-md-7">
                      <InputText
                        name="city"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.city}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Status Terakhir</label>
                    <div className="col-md-7">
                      <InputText
                        name="lastStatus"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.lastStatus}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Final Status Public</label>
                    <div className="col-md-7">
                      <InputText
                        name="lastStatusPublic"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.lastStatusPublic}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Problem Status</label>
                    <div className="col-md-7">
                      <InputText
                        name="problemStatus"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.problemStatus}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Stage</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionStage}
                        onChange={formikProps.handleChange("stage")}
                        value={formikProps.values.stage}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Manifested</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionYNTF}
                        onChange={formikProps.handleChange("isManifested")}
                        value={formikProps.values.isManifested}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Barang Kargo (5Kg++)</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionYNTF}
                        onChange={(e) => this.onChangeIsLargeParcel(formikProps, e)}
                        value={formikProps.values.isLargeParcel}
                      />
                    </div>
                  </div>
                  {this.state.isLargeParcel && (
                    <div className="form-group">
                      <label className="col-md-5">Range Berat</label>
                      <div className="col-md-7">
                        <Dropdown
                          options={this.optionParcelCondition}
                          onChange={formikProps.handleChange("isLargeParcelCondition")}
                          value={formikProps.values.isLargeParcelCondition}
                          />
                      </div>
                    </div>
                  )}
                </Flex>
              </Box>
              <Box>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Provinsi</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionsProvince}
                        onChange={formikProps.handleChange("province")}
                        value={formikProps.values.province}
                        filter={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Asal</label>
                    <div className="col-md-7">
                      <InputText
                        name="asal"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.asal}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Asal (Kota)</label>
                    <div className="col-md-7">
                      <InputText
                        name="asalKota"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.asalKota}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Asal (Kecamatan)</label>
                    <div className="col-md-7">
                      <InputText
                        name="asalKecamatan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.asalKecamatan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tujuan</label>
                    <div className="col-md-7">
                      <InputText
                        name="tujuan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tujuan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tujuan (Kota)</label>
                    <div className="col-md-7">
                      <InputText
                        name="tujuanKota"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tujuanKota}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tujuan (Kecamatan)</label>
                    <div className="col-md-7">
                      <InputText
                        name="tujuanKecamatan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tujuanKecamatan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Gerai Manifest</label>
                    <div className="col-md-7">
                      <InputText
                        name="manifestTrackingSiteName"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.manifestTrackingSiteName}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tags</label>
                    <div className="col-md-7">
                      <InputText
                        name="tags"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tags}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
